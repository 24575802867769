import React from 'react';
import PrivateService from '../../services/PrivateService';
const SelectAccountViewModel = {
    getAccountList:async() => {
        return await PrivateService.getDuplicateAccount()
    },
    selectAccountRequest:async(body) => {
        return await PrivateService.setDuplicateTransInfo(body)
    },
};
export default SelectAccountViewModel;