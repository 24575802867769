import React from 'react';
import { Switch, Redirect } from "react-router-dom";
import PrivateRoute from './route/PrivateRoute';
import Notice from '../pages/notice/Notice';

const NoticeRouter = () => {
    return (
        <Switch>
          <PrivateRoute path="/notice" exact component={Notice} />
          <Redirect path="/notice/*" to="/notice" />
        </Switch>
      );
};

export default NoticeRouter;