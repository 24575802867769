import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Util from '../../helpers/Util';
import { AppContext } from '../../AppContext';
import style from './Menu.module.css'
import { ConsultTimeDescriptionIcon, FacebookBlackIcon, FavoriteNephronPageLinkIcon, InstagramBlackIcon, KakaoConsultPageLinkIcon, NoticePageLinkIcon, NotificationPageLinkIcon, PhoneConsultPageLinkIcon, PrivatePageLinkIcon, QuestionPageLinkIcon, TermsPageLinkIcon, UserInfoHeaderImage, WebPageBlackIcon, YoububeBlackIcon } from '../../assets/icons/SVG';
import AppService from '../../services/AppService';
import PrivateService from '../../services/PrivateService';
import { useLocation , useHistory } from "react-router-dom";
import AlertContext from '../../layout/modal/CustomiseModal/AlertModal/AlertContext';
import MemberService from '../../services/MemberService';

const Menu = () => {
  const { auth } = useContext(AppContext);
  const {alert : alertComp} = useContext(AlertContext)
  const [mainphone,setMainphone] = useState("")
  const [newVersion, setNewVersion] = useState('');
  const [userData,setUserData] = useState()
  const user = auth.user;
  const history = useHistory()
  useEffect(()=>{

    getUserData()
  },[auth])
  useEffect(() => {
    getNewAppInfo();  // 최신 앱 정보 조회
  }, [auth]);
  const getUserData = async() => {
    try{
      const result = await MemberService.getUserData()
      setUserData(result)
      if(result.phone){
        result.phone.forEach((element) => {
          if(element.represent){setMainphone(element.phone_no)}
        });
      }
    }catch(err){
      await alertComp('알림', '서버 에러가 발생하여 메인으로 돌아갑니다. 잠시 후 재 시도해주세요',undefined,'메인으로 돌아가기')
      history.replace('/')
    }
  }
    // 업데이트
  const onUpdate = useCallback(async () => {
    try {
      const result = await AppService.fetchItem({ os: auth.user.os });
      const data = result.data;
      const moveUrl = data.ds_url
      try{
        window.nativeApi.execute(window.NativeApiMethod.MOVE_STORT, {
          url: moveUrl
        }, "");
      }catch(err){
        console.error(err)
      }
    } catch (error) {
    } finally {
    }
    }, [auth]);
  const getNewAppInfo = useCallback(async () => {
    try {
      const result = await AppService.fetchItem({ os: auth.user.os });
      const data = result.data;
      setNewVersion(data.ds_version);
  } catch (error) {
    console.error(error)
  } finally {
  }
  }, [auth]);
  // 카카오톡 문의 버튼 핸들러
  const onKakaoInquiry = () => {
    try{
      window.nativeApi.execute(window.NativeApiMethod.OPEN_BROWSER, {
        url: "https://pf.kakao.com/_IxmbLxb"
      }, "");
    }catch(err){
      console.error(err)
    }
  }
  // 각 브라우저 Open 핸들러
  const onBrowserOpen = (action) => {
    let browserurl = ''
    switch(action){
      case 'instagram' : browserurl = 'https://www.instagram.com/superbin_official'; break;
      case 'homepage' : browserurl = 'https://www.superbin.co.kr'; break;
      case 'youtube' : browserurl = 'https://www.youtube.com/@superbin_official/videos'; break;
      case 'facebook' : browserurl = 'https://www.facebook.com/superbinofficial'; break;
      default : browserurl = false; break;
    }
    if(browserurl){
      try{
        window.nativeApi.execute(window.NativeApiMethod.OPEN_BROWSER, {
          url: browserurl
        }, "");
      }catch(err){
        console.error(err)
      }
    }
  }
  // 전화 문의 버튼 핸들러
  const onPhoneInquiry = () => {
    try{
      window.nativeApi.execute(window.NativeApiMethod.OPEN_TEL, {
        tel: "1600-6217"
      }, "");
    }catch(err){
      console.error(err)
    }
  }
    // 로그아웃 핸들러
  const onLogout = () => {
    sessionStorage.removeItem('cancel_guardian_certification')
    auth.logout();
  }
    // 내가 즐겨찾는 네프론 설정하기 버튼 핸들러
  const onSetMyNeflon = () => {
    var param = auth.user.user_sno;
    try{
      window.nativeApi.execute(window.NativeApiMethod.OPEN_MY_NEFLON, {sno:JSON.stringify(param)});
    }catch(err){
      console.error(err)
    }
  }
  return (
    <>
      <div className={style["menu-page"]}>
        <div className={style["contents"]}>
          <div className={style["menu-header"]}>
            <p className="a11y">회원 정보</p>
            <div className={style["user-info-box"]}>
              <UserInfoHeaderImage></UserInfoHeaderImage>
              <dl className="info-list">
                <dt className="a11y">이름</dt>
                <dd className={style['name']}>{userData ? userData?.nickname : ''}</dd>
                <dt className="a11y">휴대폰번호</dt>
                <dd className={style['phone']}>{Util.formatEncryptPhoneNumber(user ? mainphone : '')}</dd>
              </dl>
            </div>
            <div className={style['logout-btn']} onClick={()=>{onLogout()}}>로그아웃</div>
          </div>
          <div className={style["menu-body"]}>
            <ul className={style["navigation"]}>
              <li><PrivatePageLinkIcon></PrivatePageLinkIcon><Link to="/mypage/input-password">내 정보 관리</Link></li>
              <li><NoticePageLinkIcon></NoticePageLinkIcon><Link to="/notice">공지사항</Link></li>
              <li><QuestionPageLinkIcon></QuestionPageLinkIcon><Link to="/faq">자주하는 질문</Link></li>
              <li><NotificationPageLinkIcon></NotificationPageLinkIcon><Link to="/setting/notification">알림 설정</Link></li>
              <li onClick={()=>{onSetMyNeflon()}}><FavoriteNephronPageLinkIcon></FavoriteNephronPageLinkIcon><div className={style["no-link-action"]}>즐겨찾는 네프론 설정</div></li>
              <li onClick={()=>{onKakaoInquiry()}}><KakaoConsultPageLinkIcon></KakaoConsultPageLinkIcon><div className={style["no-link-action"]}>카카오톡 상담</div></li>
              <li onClick={()=>{onPhoneInquiry()}}><PhoneConsultPageLinkIcon></PhoneConsultPageLinkIcon><div className={style["no-link-action"]}>전화 상담</div></li>
              <li><TermsPageLinkIcon></TermsPageLinkIcon><Link to="/terms">약관 및 정책</Link></li>
            </ul>
          </div>
          <div className={style["menu-footer"]}>
              <div className={style["consert-description"]}>
                <div className={style["title"]}>
                  <ConsultTimeDescriptionIcon></ConsultTimeDescriptionIcon>
                  <p>상담 시간 안내</p>
                </div>
                <div className={style["description"]}>
                  <p>평일 10:00 - 18:00 상담 가능<br />공휴일 및 점심시간 (12:30~13:30) 상담 불가<br /><br />※ 전화 상담 시 네프론 사용, 고장, 포인트, 환전 등 이용 관련 문의는 0번을 눌러주세요.</p>
                </div>
              </div>
              <div className={style["relation-channel"]}>
                <ul className={style["relation-channel-list"]}>
                  <li onClick={()=>{onBrowserOpen('instagram')}}><InstagramBlackIcon></InstagramBlackIcon></li>
                  <li onClick={()=>{onBrowserOpen('facebook')}}><FacebookBlackIcon></FacebookBlackIcon></li>
                  <li onClick={()=>{onBrowserOpen('youtube')}}><YoububeBlackIcon></YoububeBlackIcon></li>
                  <li onClick={()=>{onBrowserOpen('homepage')}}><WebPageBlackIcon></WebPageBlackIcon></li>
                </ul>
              </div>
              <div className={style["application-info"]}>
                <p className={style["app-version"]}>앱 버전 {auth.user?.app_version? auth.user?.app_version:'[확인 불가]'}</p>
                {newVersion === auth.user?.app_version ? <></>:<span onClick={()=>{onUpdate()}} className={style["app-update"]}>최신버전으로 업데이트</span>}
              </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Menu;
