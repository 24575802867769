import axios from 'axios';
import Cookies from 'universal-cookie';
import { Auth_ROOT, VERSION, BUILD_NO, Shard_DOMANIN, Share_Domain } from '../config';
import HttpHelper from '../helpers/HttpHelper/HttpHelper';

axios.defaults.headers.common['X-Client-Version'] = VERSION;
axios.defaults.headers.common['X-Client-Build'] = BUILD_NO;
axios.defaults.headers.common['Cache-Control'] = "no-cache";
axios.defaults.headers.common['Access-Control-Allow-Header'] = "Accept, Content-Type";
axios.defaults.headers.common['Access-Control-Allow-Methods'] = "*";
axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";

const AuthService = {
    get: async (endpoint, data) => {
        try {
            const result = await axios.get(Auth_ROOT + endpoint, { params: data });
            return result.data;
        } catch (e) {
          const error_code = e.response.data.error_code;
          const message = e.response.data.message;
          if (e.response.status === 401) {
            if (error_code === -100) {
              alert(message);
              try{
                window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
              }catch(err){
                console.error(err)
              }
            }
            else if (error_code === -101){
              const testResult = await  HttpHelper.getTokenByrefreshToken()
              if(testResult){
                await AuthService.setAccessTokenByRefreshToken(testResult.access_token)
                axios.defaults.headers.common['x-token'] = testResult.access_token;
                const reloadResult = await axios.get(Auth_ROOT + endpoint, { params: data });
                return reloadResult.data;
              }else{
                alert("로그아웃 되었습니다. 다시 로그인 해주세요");
                try{
                  window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
                }catch(err){
                  console.error(err)
                }
              }
            }
            else{
              throw e;
            }
          }
          throw e;
        }
      },
      post: async (endpoint, data) => {
        const config = { headers: { 'Content-Type': 'application/json' } };
        let body;
        switch (data.constructor) {
          case Object:
            body = JSON.stringify(data);
            break;
          case FormData:
            body = data;
            break;
          default:
            body = JSON.stringify(data);
            break;
        }
        try{  
          const result = await axios.post(Auth_ROOT + endpoint, body, config);
          return result.data;
        }catch(e){
          const error_code = e.response.data.error_code;
          const message = e.response.data.message;
          if (e.response.status === 401) {
            if (error_code === -100) {
              alert(message);
              try{
                window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
              }catch(err){
                console.error(err)
              }
            }
            else if (error_code === -101){
              const testResult = await  HttpHelper.getTokenByrefreshToken()
              if(testResult){
                await AuthService.setAccessTokenByRefreshToken(testResult.access_token)
                axios.defaults.headers.common['x-token'] = testResult.access_token;
                const reloadResult = await axios.post(Auth_ROOT + endpoint, body, config);
                return reloadResult.data;
              }else{
                alert("로그아웃 되었습니다. 다시 로그인 해주세요");
                try{
                  window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
                }catch(err){
                  console.error(err)
                }
              }
            }
            else{
              throw e;
            }
          }
          throw e;
        }
      },
      put: async (endpoint, data) => {
        const config = { headers: { 'Content-Type': 'application/json' } };
        let body;
        switch (data.constructor) {
          case Object:
            body = JSON.stringify(data);
            break;
          case FormData:
            body = data;
            break;
          default:
            body = JSON.stringify(data);
            break;
        }
        try{
          const result = await axios.put(Auth_ROOT + endpoint, body, config);
          return result.data;
        }catch(e){
          const error_code = e.response.data.error_code;
          const message = e.response.data.message;
          if (e.response.status === 401) {
            if (error_code === -100) {
              alert(message);
              try{
                window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
              }catch(err){
                console.error(err)
              }
            }
            else if (error_code === -101){
              const testResult = await  HttpHelper.getTokenByrefreshToken()
              if(testResult){
                await AuthService.setAccessTokenByRefreshToken(testResult.access_token)
                axios.defaults.headers.common['x-token'] = testResult.access_token;
                const reloadResult = await axios.put(Auth_ROOT + endpoint, body, config);
                return reloadResult.data;
              }else{
                alert("로그아웃 되었습니다. 다시 로그인 해주세요");
                try{
                  window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
                }catch(err){
                  console.error(err)
                }
              }
            }
            else{
              throw e;
            }
          }
          throw e;
        }
      },
      delete: async (endpoint, data) => {
        const config = { headers: { 'Content-Type': 'application/json' } };
        let body;
        switch (data.constructor) {
          case Object:
            body = JSON.stringify(data);
            break;
          case FormData:
            body = data;
            break;
          default:
            body = JSON.stringify(data);
            break;
        }
        try{
          const result = await axios.delete(Auth_ROOT + endpoint, { data: body }, config);
          return result.data;
        }catch(e){
          const error_code = e.response.data.error_code;
          const message = e.response.data.message;
          if (e.response.status === 401) {
            if (error_code === -100) {
              alert(message);
              try{
                window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
              }catch(err){
                console.error(err)
              }
            }
            else if (error_code === -101){
              const testResult = await  HttpHelper.getTokenByrefreshToken()
              if(testResult){
                await AuthService.setAccessTokenByRefreshToken(testResult.access_token)
                axios.defaults.headers.common['x-token'] = testResult.access_token;
                const reloadResult = await axios.delete(Auth_ROOT + endpoint, { data: body }, config);
                return reloadResult.data;
              }else{
                alert("로그아웃 되었습니다. 다시 로그인 해주세요");
                try{
                  window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
                }catch(err){
                  console.error(err)
                }
              }
            }
            else{
              throw e;
            }
          }
          throw e;
        }
      },
      getAccessTokenByRefreshToken: async () => {
        const cookies = new Cookies();
        const refresh_token = cookies.get("refresh_token")
        axios.defaults.headers.common['x-refresh-token'] = refresh_token;
        const config = { headers: { 'Content-Type': 'application/json' } };
        try{
          const result = await axios.post(Auth_ROOT + "/refresh", null, config);
          return result.data.data.access_token
        }catch(error){
          return false
        }
      },
      setAccessTokenByRefreshToken: async (token) => {
        const cookies = new Cookies();
        cookies.set("token",token)
      },
}

export default AuthService;