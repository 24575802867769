import React from 'react';
import { useLocation } from "react-router-dom";
import qs from 'query-string';

const NiceAuthSuccess = () => {
  const location = useLocation();
  const { search } = location;
  const params = qs.parse(search);	// 문자열의 쿼리스트링을 Object로 변환
  try{
    window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, params, window.NativeApiCallbackMethod.CLOSE_WEB_VIEW);
  }catch(err){
    console.error(err)
  }
  return (
    <>
    </>
  )
}

export default NiceAuthSuccess;