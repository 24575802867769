import React, { useContext } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import AlertContext from '../../../../../layout/modal/CustomiseModal/AlertModal/AlertContext';
import PrivateService from '../../../../../services/PrivateService';
const ChangeMainPhoneInsertCertinfoViewModel =  {
    InsertInfo : () => {
        const {alert : alertComp} = useContext(AlertContext)
        const history = useHistory()
        const location = useLocation()
        const postSms = async(bodyData) => {
            const body = {
                phone : bodyData.phoneHeader + bodyData.phone?.replace('-',''),
                name : bodyData.name,
                rr_number : bodyData.idCardFront + bodyData.sexNumber,
                telco : bodyData.telecom
            }
            try{
                const result = await PrivateService.sendAddPhoneSms(body)
                const query = {
                    active : location.query.active,
                    oldphoneIdx:location.query.oldphoneIdx,
                    olddata: location.query.olddata ? location.query.olddata : '',
                    phone : bodyData.phoneHeader + bodyData.phone?.replace('-',''),
                    name : bodyData.name,
                    birthday : bodyData.birthday,
                    rr_number : bodyData.idCardFront + bodyData.sexNumber,
                    telco : bodyData.telecom,
                    request_seq_code : result.request_seq_code,
                    response_seq_code : result.response_seq_code,
                }
                history.replace({
                    pathname:'verificate-code',query:query
                },'verificate-code')
            }catch(err){
                if(err.response.status === 403){
                    if(err.response.data.message === "UnmatchName"){
                        await alertComp('알림', '기존 이름과 다릅니다. 본인 명의의 휴대전화 번호로만 변경 가능하며, 만약 이름과 번호가 동시에 변경된 경우 1600-6217(0번) 혹은 수퍼빈 카카오톡 채널로 문의 바랍니다.',undefined,'확인')
                    }else{
                        await alertComp('본인 정보가 아닙니다', '로그인한 아이디의 개인 정보를 입력해주세요.',undefined,'확인')
                    }
                }
                else if(err.response.status === 409){
                    if(err.response.data.message === "SendSmsFail"){
                        await alertComp('알림', '인증문자를 받을 수 없습니다. 통신사에 가입된 본인 정보가 맞으신가요? 입력하신 정보를 다시 확인해주세요.',undefined,'확인')
                    }else if(err.response.data.message === "AlreadyRegistered"){
                        await alertComp('알림', '기존 휴대전화 번호와 동일합니다. 변경할 휴대전화 번호를 입력해주세요. (본인 명의의 휴대전화 번호만 가능)',undefined,'확인')
                    }else if(err.response.data.message === "UnmatchName"){
                        
                    }
                }else{
                    await alertComp('알림', '서버 에러가 발생하여 메인으로 돌아갑니다. 잠시 후 재 시도해주세요',undefined,'메인으로 돌아가기')
                    history.replace('/')
                }
            }
        }
        return {postSms}
    }
};

export default ChangeMainPhoneInsertCertinfoViewModel;