import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation,useHistory} from 'react-router-dom';
import { AddPhoneIcon } from '../../../assets/icons/SVG';
import InfoParagraph from '../../../Modules/MyPageModules/Private/InfoParagraph/InfoParagraph';
import style from './ChangePrivateInfo.module.css'
import { AppContext } from '../../../AppContext';
import moment from 'moment';
import SexSelector from '../../../Modules/MyPageModules/Private/SexSelector/SexSelector';
import InputAddress from '../../../Modules/MyPageModules/Private/InputAddress/InputAddress';
import InputBank from '../../../Modules/MyPageModules/Private/InputBank/InputBank';
import ChangePrivateInfoViewModel from './ChangePrivateInfoViewModel';
import Input from '../../../Modules/MyPageModules/Private/Input/Input';
import Progress from '../../../layout/Progress';
import AlertContext from '../../../layout/modal/CustomiseModal/AlertModal/AlertContext';
import ConfirmContext from '../../../layout/modal/CustomiseModal/ConfirmModal/ConfirmContext';

const ChangePrivateInfo = () => {
    const history = useHistory()
    const location = useLocation()
    const { auth } = useContext(AppContext);
    const {alert : alertComp} = useContext(AlertContext)
    const {
        displayOn,
        serviceEnable,
        userMainPhoneInfo, 
        displayPrivateInfo,
        userPhoneList, 
        changePrivateInfo,
        buttonEnable,
        bankEnable ,
        setDisplayPrivateInfo,
        deleteSubPhone,
        setChangePrivateInfo,
        putPrivate
    } = ChangePrivateInfoViewModel.ChangePrivateInfoHook();
    const regEmail = /^[0-9a-zA-Zㄱ-ㅎ가-힣-_\.]*@[0-9a-zA-Zㄱ-ㅎ가-힣-_\.]*\.[0-9a-zA-Zㄱ-ㅎ가-힣-_\.]{2,}$/;
    return (
        <>
        {displayOn ? <div className={style["page-start"]}>
            <div className={style.root}>
                <div className={style.title}>개인정보 관리</div>
                <div className={style["item"]}>
                    <InfoParagraph 
                    description={'개명한 경우에만 이름 변경이 가능합니다. (타인 정보로 변경 불가능)'} 
                    title={'이름'} 
                    content={displayPrivateInfo?.name}>
                        <Link to={{pathname:'change-private-info/change-name/terms',query:{active:"change_name",olddata:auth.user?.user_name}}} as={'change-private-info/change-name/terms'}><button>변경</button></Link>
                    </InfoParagraph>
                </div>
                {userMainPhoneInfo ?
                <div className={style["item"]}>
                    <InfoParagraph 
                        description={undefined} 
                        title={'휴대전화 번호(대표번호)'} 
                        content={userMainPhoneInfo?.phone_no.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3")} 
                        >
                        <Link to={{pathname:'change-private-info/change-main-phone/terms',
                        query:{active:"change_main_phone",
                        oldphoneIdx:userMainPhoneInfo.idx,
                        olddata:userMainPhoneInfo?.phone_no.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3")}}} as={'change-private-info/change-main-phone/terms'}>
                            <button>변경</button></Link>
                    </InfoParagraph>
                </div>:
                <div className={style["item"]}>
                    <InfoParagraph 
                        description={undefined} 
                        title={'휴대전화 번호(대표번호)'} 
                        content={'번호가 등록되어 있지 않습니다'} 
                        >
                        <Link to={{pathname:'change-private-info/regist-main-phone/terms',
                        query:{active:"regist_main_phone",}}} as={'change-private-info/regist-main-phone/terms'}>
                            <button>등록</button></Link>
                    </InfoParagraph>
                </div>}
                {
                    userPhoneList?.map((data,index)=>{
                        return(
                            <div key={"phonelist"+index} className={style["item"]}>
                                <InfoParagraph description={undefined} title={'휴대전화 번호(추가'+ (index+1) + ')'} 
                                content={data.phone_no.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3")}>
                                    <button onClick={()=>{
                                        deleteSubPhone(data.idx)
                                    }}>삭제</button>
                                </InfoParagraph>
                            </div>
                        );
                    })
                }
                {!userPhoneList || userPhoneList?.length < 3 ?
                 <div className={style["item"]}>
                    <div className={style["add-phone-box"]}>
                        <div className={style["content"]}>
                            <AddPhoneIcon></AddPhoneIcon>
                            <Link to={{pathname:'change-private-info/add-sub-phone/terms',query:{active:"add_sub_phone"}}} as={'change-private-info/add-sub-phone/terms'}><p>휴대전화 번호 추가하기</p></Link>
                        </div>
                        <p>휴대전화 번호는 본인 명의 번호로만 변경 혹은 추가 가능합니다. (최대 3개) <br />번호를 여러개 사용하더라도 네프론에서는 1개의 계정으로 인식하여 개수 제한을 초과하여 투입할 수 없습니다.</p>
                    </div>
                </div>:<></>}
                <div className={style["item"]}>
                    <InfoParagraph description={undefined} title={'생년월일 (수정불가)'} 
                        content={displayPrivateInfo?.birth_date ? moment(displayPrivateInfo?.birth_date).format("YYYY년 MM월 DD일"):"정보없음"}>
                            <></>
                    </InfoParagraph>
                </div>
                <div className={style["item"]}>
                    <InfoParagraph description={undefined} title={'아이디 (수정불가) '} 
                    content={auth.user?.id}><></></InfoParagraph>
                </div>                
                <div className={style["item"]}>
                    <SexSelector title={'성별'} data={displayPrivateInfo?.sex} InputData={function (value) {
                        const data = {...displayPrivateInfo}
                        data.sex = value
                        setDisplayPrivateInfo(data)
                        const putData = {...changePrivateInfo}
                        putData.sex = value
                        setChangePrivateInfo(putData)
                    } }></SexSelector>
                    {displayPrivateInfo?.sex? <></>:<p className={style.validation}>성별을 선택해주세요.</p>}
                </div>
                <div className={style["item"]}>
                    <InputAddress title={'주소'} holder={'주소를 입력해주세요'} subholder={'상세 주소를 입력해주세요'} subValue={displayPrivateInfo?.address_detail} value={displayPrivateInfo?.address} InputAdress={function (value , code) {
                        const data = {...displayPrivateInfo}
                        data.address = value
                        setDisplayPrivateInfo(data)
                        const putData = {...changePrivateInfo}
                        putData.address = value
                        setChangePrivateInfo(putData)
                    } } InputSubAdress={function (value) {
                        const data = {...displayPrivateInfo}
                        data.address_detail = value
                        setDisplayPrivateInfo(data)
                        const putData = {...changePrivateInfo}
                        putData.address_detail = value
                        setChangePrivateInfo(putData)
                    } }></InputAddress>
                </div>
                <div className={style["item"]}>
                    <Input type='text' title={'이메일'} holder={'이메일 주소를 입력해주세요'} value={displayPrivateInfo?.email ? displayPrivateInfo?.email : ''}
                    InputData={function (value) {
                        const data = {...displayPrivateInfo}
                        data.email = value
                        setDisplayPrivateInfo(data)
                        const putData = {...changePrivateInfo}
                        putData.email = value
                        setChangePrivateInfo(putData)
                    } }></Input>
                    {regEmail.test(displayPrivateInfo?.email ? displayPrivateInfo?.email : '') || !displayPrivateInfo?.email ? <></>:<p className={style.validation}>유효한 이메일이 아닙니다.</p>}
                </div>
                <div className={style["item"]}>
                <InputBank title={'계좌번호'} 
                    bank={displayPrivateInfo?.refund_account && displayPrivateInfo.refund_account.bank ? displayPrivateInfo.refund_account.bank.name : ''} 
                    holder={'계좌번호를 입력해주세요'} 
                    accountValue={displayPrivateInfo?.refund_account && displayPrivateInfo.refund_account.account_no ? displayPrivateInfo.refund_account.account_no : ''}
                    InputData={function (value) {
                        // setAccountNumber(value)
                        const numregex = /^[0-9]+$/; 
                        let beforedata = value
                        if(!numregex.test(value)){
                            alertComp('알림','계좌번호는 숫자만 입력 가능합니다.',undefined,'확인')
                            beforedata = ''
                        }
                        const data = {...displayPrivateInfo}
                        if(data.refund_account){
                            data.refund_account.account_no = beforedata
                        }else{
                            data.refund_account = {
                                account_no : beforedata,
                                account_name : '',
                            }
                        }
                        setDisplayPrivateInfo(data)
                        const putData = {...changePrivateInfo}
                        if(putData.refund_account){
                            putData.refund_account.account_no = beforedata
                        }else{
                            putData.refund_account = {
                                account_no : beforedata,
                            }
                        }
                        setChangePrivateInfo(putData)
                    } } 
                    selectBank={function (name,code,idx) {
                    const data = {...displayPrivateInfo}
                    if(data.refund_account){
                        data.refund_account.bank = {
                            idx : idx,
                            code : code,
                            name : name
                        }
                    }else{
                        data.refund_account = {
                            bank : {
                                idx : idx,
                                code : code,
                                name : name
                            }
                        }
                    }
                    setDisplayPrivateInfo(data)
                    const putData = {...changePrivateInfo}
                    if(putData.refund_account){
                        putData.refund_account.bank_idx = idx
                    }else{
                        putData.refund_account = {
                            bank_idx : idx,
                        }
                    }
                    setChangePrivateInfo(putData)
                } }></InputBank>
                {bankEnable ?<></>:<p className={style.validation}>은행 또는 계좌번호를 입력해주세요</p>}
                </div>
                {/* <p className={style.questions}>이름, 전화번호가 변경된 경우 1600-6217(0번)로 연락 바랍니다. <br /> 통화가 어려울 경우 카카오톡 &#39;수퍼빈&#39; 채널로 문의 바랍니다.</p>
                <div className={style.space}></div> */}
                <div className={style["bottom-container-box"]}>
                    <button disabled={buttonEnable} onClick={()=>{putPrivate()}}>{changePrivateInfo ? "변경하기":"돌아가기"}</button>
                    <button onClick={()=>{
                        serviceEnable ? history.push("/mypage/cancel-affiliate-service") : history.push("/mypage/withdraw")
                        }}>회원탈퇴</button>
                </div>
            </div>
        </div>:<Progress loading={true}/>}
        </>
    );
};

export default ChangePrivateInfo;