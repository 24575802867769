import React from 'react';
import {useHistory} from 'react-router-dom';
import { ChangeDoneIcon } from '../../../../assets/icons/SVG';
import style from './RegistRefundPasswordDone.module.css'
import WidthFullBtn from '../../../../Modules/Buttons/widthFullBtn/WidthFullBtn';

const RegistRefundPasswordDone = () => {
    const history = useHistory()
    return (
        <div className={style["page-start"]}>
            <div className={style.root}>
                <ChangeDoneIcon></ChangeDoneIcon>
                <div className={`${style["title"]}`}>환전 비밀번호 등록 완료</div>
                <div className={`${style["description"]}`}>환전 비밀번호 변경이 완료되었습니다.</div>
                <div className={style["bottom-container-box"]}>
                <WidthFullBtn disabled={false} title={"메인으로 돌아가기"} onClick={()=>{window.location.replace('/')}}></WidthFullBtn>
                </div>
            </div>
        </div>
    );
};

export default RegistRefundPasswordDone;