import React, { useContext, useEffect, useState } from 'react';
import AlertContext from '../../../layout/modal/CustomiseModal/AlertModal/AlertContext';
import ViewModel from './ViewModel';
import style from '../GuardianCertification.module.css'
import SmsNumInput from '../../../Modules/MyPageModules/Private/SmsNumInput/SmsNumInput';
import WidthFullBtn from '../../../Modules/Buttons/widthFullBtn/WidthFullBtn';
import { useHistory } from 'react-router-dom';
import GuardianCertificationStore from '../store/GuardianCertificationStore';

const SmsVerification = (props) => {
    const {data,setData} = GuardianCertificationStore();
    const history = useHistory()
    const {alert : alertComp} = useContext(AlertContext)
    const [timerStatus,setTimerStatus] = useState(true)
    const [sms_auth_code,set_sms_auth_code] = useState("")
    const {on_resend_code,on_sms_verification} = ViewModel()
    const resend_code = async()=>{
        const return_data = await on_resend_code(data)
        setData(return_data)
    }
    const sms_verification = async() => {
        try{
            const result = await on_sms_verification(sms_auth_code,data)
            history.replace('/guardian-certification/success')
        }catch(error){
            switch (error.response?.status){
                case 400 : history.replace({pathname:'/guardian-certification/failed',query:{reason:"under_nineteen"}},'/guardian-certification/failed');  break;
                case 409 : await alertComp('알림', '인증번호가 잘못되었습니다. 다시 입력해주세요',undefined,'확인'); break;
                case 404 : await alertComp('알림', '인증할 대상이 없습니다. 계속해서 문제가 발생하는 경우 수퍼빈 카카오톡 채널로 문의 바랍니다.',undefined,'확인'); break;
                default : await alertComp('알림', '이용에 불편을 드려 죄송합니다. 서버 오류가 발생하였습니다. 계속해서 문제가 발생하는 경우 수퍼빈 카카오톡 채널로 문의 바랍니다.',undefined,'확인'); break;
            }
        }
    }
    return (
        <div className={style['page-start']}>
            <div className={style['root']}>
                <div>
                    <p className={style['title']}>인증번호 입력</p>
                    <p className={style['description']}>문자메시지로 수신 받은 인증번호 6자리를 아래 칸에 입력 후 [인증하기] 버튼을 눌러주세요.</p>
                    <div className={style['verification-item']}>
                        <SmsNumInput title={'인증번호'} holder={'인증번호를 입력해주세요'} value={sms_auth_code} InputData={function (value) {
                            set_sms_auth_code(value)
                        } } TimerStarted={function () {
                            setTimerStatus(true)
                            resend_code()
                        } } TimerEnded={function () {
                            setTimerStatus(false)
                        } }></SmsNumInput>
                    </div>
                    <div className={style['sub-description']}>
                        <p className={style['title']}>※ 문자메시지를 받지 못한 경우</p>
                        <p className={style['body']}>휴대전화의 차단메시지함을 확인하시거나 가입한 통신사로 문자 차단 서비스를 이용하고 있는지 확인해주세요.</p>
                    </div>
                    <div className={style['validate-end']}>
                        <WidthFullBtn onClick={function () {
                            sms_verification()
                        }}title={'인증하기'} 
                        disabled={sms_auth_code && timerStatus ? false:true}></WidthFullBtn>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SmsVerification;