import React from 'react';
import { useContext ,useEffect , useState} from 'react';
import { useLocation,useHistory} from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import AlertContext from '../../../layout/modal/CustomiseModal/AlertModal/AlertContext';
import PrivateService from '../../../services/PrivateService';
import ConfirmContext from '../../../layout/modal/CustomiseModal/ConfirmModal/ConfirmContext';
import MemberService from '../../../services/MemberService';
const ChangePrivateInfoViewModel =  {
    ChangePrivateInfoHook : () => {
        const history = useHistory()
        const { auth } = useContext(AppContext);
        const {alert : alertComp} = useContext(AlertContext)
        const {confirm:confirmComp} = useContext(ConfirmContext)
        const [displayPrivateInfo, setDisplayPrivateInfo] = useState({
            name : '',
            address : '',
            address_detail : '',
            birth_date : '',
            sex : '',
            email : '',
            ci : false,
            refund_password : false,
            privacy_contact : undefined
        });
        const [changePrivateInfo, setChangePrivateInfo] = useState();
        const [userPhoneList, setUserPhoneList] = useState();
        const [userMainPhoneInfo, setUserMainPhoneInfo] = useState();
        const [buttonEnable ,setButtonEnable] = useState(false)
        const [bankEnable ,setbankEnable] = useState(true)
        const [serviceEnable,setServiceEnable] = useState(false)
        const [displayOn,setDisplayOn] = useState(false)

        useEffect(()=>{
            getPrivacyInfo()
            getRookieData()
            getMoreData()
        },[])
        useEffect(()=>{
            checkEnableData()
        },[displayPrivateInfo?.refund_account?.account_no , displayPrivateInfo?.refund_account?.bank,displayPrivateInfo?.sex,displayPrivateInfo?.email])
        const getPrivacyInfo = async() => {
            const result = await PrivateService.getPrivacy()
            const rebuild_result = {...result}
            if(rebuild_result.refund_account?.bank?.name){
                if(rebuild_result.refund_account?.bank?.name == "지역 농축협" || rebuild_result.refund_account?.bank?.name == "NH농협은행"){
                    rebuild_result.refund_account.bank.name = '농협'
                }else{
                    rebuild_result.refund_account.bank.name = rebuild_result.refund_account.bank.name
                }
            }
            setDisplayPrivateInfo(result)
            const phoneList = []
            //첫번째에 메인 폰 넣고
            let mainPhoneEnable = false
            result.contact.forEach((element) => {
                if(element.represent){
                    setUserMainPhoneInfo(element)
                    mainPhoneEnable = true
                }
            });
            //나머지 리스트 집어넣는다
            result.contact.forEach((element) => {
                if(!element.represent){phoneList.push(element)}   
            });
            setUserPhoneList(phoneList)
            if(!mainPhoneEnable){
                await alertComp('휴대전화 번호 변경 알림', '기존에 인증하셨던 휴대전화 번호가 삭제되었습니다. <br />(사유: 타인의 전화번호로 변경) <br /> 번호가 변경되셨다면 새로운 번호로 재인증해주세요.',undefined,'번호 변경하기')
                history.replace({pathname:'change-private-info/change-main-phone/terms',query:{active:"change_main_phone"}},"change-private-info/change-main-phone/terms")
            }
            setDisplayOn(true)
        }
        const checkEnableData = () => {
            var checkBtnDisable = false
            var checkBankEnable = true
            if(displayPrivateInfo?.refund_account?.account_no && !displayPrivateInfo?.refund_account?.bank || !displayPrivateInfo?.refund_account?.account_no && displayPrivateInfo?.refund_account?.bank){
                checkBtnDisable = true
                checkBankEnable = false
            }
            const regEmail = /^[0-9a-zA-Zㄱ-ㅎ가-힣-_\.]*@[0-9a-zA-Zㄱ-ㅎ가-힣-_\.]*\.[0-9a-zA-Zㄱ-ㅎ가-힣-_\.]{2,}$/;
            if(!regEmail.test(displayPrivateInfo?.email ? displayPrivateInfo?.email : '') && displayPrivateInfo?.email){checkBtnDisable = true}
            if(!displayPrivateInfo?.sex){checkBtnDisable = true}
            setButtonEnable(checkBtnDisable)
            setbankEnable(checkBankEnable)
        }
        const deleteSubPhone = async(phone_idx) => {
            if(userPhoneList){
                try{
                    await PrivateService.deletePhone(phone_idx)
                    let deleteArr = undefined
                    userPhoneList.forEach((element , index) => {
                        if(element.idx === phone_idx){
                            deleteArr = index
                        }
                    });
                    const phoneListData = [...userPhoneList]
                    if(deleteArr !== undefined){
                        phoneListData.splice(deleteArr,1)
                    }
                    setUserPhoneList(phoneListData)
                }catch(error){
                    const result = await alertComp('삭제에 실패하였습니다', '기타 오류로 인해 수정에 실패하였습니다. 지속발생시 관리자에게 문의주세요',undefined,'메인으로 돌아가기')
                    history.replace('/')
                    return error
                }
            }
        }
        const getRookieData = async() => {
            try{
                const result = await MemberService.getRookieServiceData()
                if(result.terms){
                    result.terms.forEach((element) => {
                        if(element.terms_code === "001"){
                            setServiceEnable(true)
                        }
                    });
                }
            }catch(err){
                if(err.response?.status === 404){
                    console.error(err)
                }else{
                    await alertComp('알림', '서버 에러로 인해 사용자 정보를 불러올수 없습니다. 잠시 후 다시 시도해주세요.',undefined,'메인으로 돌아가기')
                    history.replace('/')
                }
            }
        }
        const getMoreData = async() => {
            try{
                const result = await MemberService.getMoreServiceData()
                if(result.is_used){
                    setServiceEnable(true)
                }
            }catch(err){
                await alertComp('알림', '서버 에러로 인해 사용자 정보를 불러올수 없습니다. 잠시 후 다시 시도해주세요.',undefined,'메인으로 돌아가기')
                history.replace('/')
            }
        }
        const putPrivate = async() => {
            const data = {...changePrivateInfo}
            if(!data.email) {delete data.email}
            if(!data.sex) {delete data.sex}
            if(!data.address) {delete data.address}
            if(!data.address_detail) {delete data.address_detail}
            if(!data.refund_account?.account_no) {delete data.refund_accounto}
            if(!data.refund_account?.bank_idx) {delete data.refund_account}
            if(!changePrivateInfo){
                history.goBack()
            }else{
                try{
                    const result = await PrivateService.putPrivacy(changePrivateInfo)
                    history.replace({pathname:"change-private-info/done",query:{active:"change_etc_data"}},'change-private-info/done')
                }catch(error){
                    const result = await alertComp('수정에 실패하였습니다', '기타 오류로 인해 수정에 실패하였습니다. 지속발생시 관리자에게 문의주세요',undefined,'메인으로 돌아가기')
                    history.replace('/')
                    return error
                }
            }
        }
        return {displayOn, serviceEnable, userMainPhoneInfo , userPhoneList , displayPrivateInfo, changePrivateInfo, buttonEnable , bankEnable , deleteSubPhone , setDisplayPrivateInfo , setChangePrivateInfo , putPrivate };
    }
}

export default ChangePrivateInfoViewModel;