import AuthService from './AuthService';
import Service from './Index';

const NiceAuthService = {
  // 본인인증 시작
  start: (param) => {
    return AuthService.get('/common/niceauth/start/join', param);
  },
};

export default NiceAuthService;
