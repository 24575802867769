import React, { useState, useEffect, useRef, useReducer, useCallback } from 'react';
import { useLocation } from "react-router-dom";
import qs from 'query-string';
import TextField from '@material-ui/core/TextField';
import PostCodeDialog from "../../layout/dialog/PostCodeDialog";
import JoinModal from "../../layout/modal/JoinModal";
import Util from '../../helpers/Util';
import NiceAuth from '../../helpers/NiceAuth';
import LoginService from '../../services/LoginService';
import CryptoAES from '../../helpers/CryptoAES';
import ProgressFixed from '../../layout/ProgressFixed';

const Join = () => {
  const location = useLocation();
  const { search } = location;
  const params = qs.parse(search);	// 문자열의 쿼리스트링을 Object로 변환
  const { policy_agree, personal_agree, name, birthday, mobile_no, isAuth, iReturn } = params;

  const initialUserState = {
    name: { value: name },
    mobile_no: { value: mobile_no },
    birthday: { value: birthday },
    id: { value: '', valid: true, helpText: '아이디를 입력하세요.' },
    password: { value: '', valid: true, helpText: '비밀번호를 입력하세요.' },
    passwordCheck: { value: '', valid: true, helpText: '비밀번호를 한번 더 입력하세요.' },
    gender: { value: '', valid: true, helpText: '성별을 선택하세요.' },
    address: { value: '', valid: true, helpText: '주소를 입력하세요.' },
    addressRemain: { value: '', valid: true, helpText: '나머지 주소를 입력하세요.' },
    email: { value: '', valid: true, helpText: '이메일을 입력하세요.' }
  };

  const userReducer = (state, action) => {
    const name = action.name;
    switch (action.type) {
      case 'INIT': {
        return initialUserState;
      }
      case 'CHANGE_VALUE': {
        return {
          ...state, [name]: { ...state[name], value: action.value }
        }
      }
      case 'CHANGE_VALID': {
        return {
          ...state, [name]: { ...state[name], valid: action.value }
        }
      }
      case 'CHANGE_HELPTEXT': {
        return {
          ...state, [name]: { ...state[name], helpText: action.value }
        }
      }
      default:
        break;
    }
  }

  const [user, dispatchUser] = useReducer(userReducer, initialUserState);
  const [deviceInfo, setDeviceInfo] = useState(null);  // 로딩 여부
  const [loading, setLoading] = useState(false);  // 로딩 여부

  const isParentCerti = Util.getYearsOld(birthday) < 14;
  const [parentCerti, setParentCerti] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [userAlready, setUserAlready] = useState(false);
  const [userBlock, setUserBlock] = useState(false);

  const [open, setOpen] = useState(false);

  // ref
  const userParentsName = useRef('');
  const userParentsBirthday = useRef('');
  const userId = useRef('');
  const userPassword = useRef('');
  const userPasswordCheck = useRef('');
  const userGender = useRef('');
  const userAddress = useRef('');
  const userAddressRemain = useRef('');
  const userEmail = useRef('');

  // 추가 - 각 인풋 터치 시
  // input text 에 focus 시 해당 const  
  // 채필규 2022.01.11
  const Validateid= () => {
    idValidate()
    // genderValidate()
  }
  const ValidatePass = () => {
    passwordValidate()
    // genderValidate()
  }
  const ValidatePassCheck = () => {
    passwordCheckValidate()
    // genderValidate()
  }
  // 가입하기 버튼 핸들러
  const onSubmit = () => {
    formValidate();
    inputFocus();

    // 회원가입
    if (submitted) {
      joinUser();
    }
  }

  const formValidate = () => {
    if (!user.id.value || !user.password.value || !user.passwordCheck.value || !user.gender.value) {
      setSubmitted(false);
    } else {
      let submit = true;
      // 인풋 값 입력시 Validate 진행됨. 주석 처리 /채필규 2022.01.11
      if (!idValidate()) submit = false;
      if (!passwordValidate()) submit = false;
      if (!passwordCheckValidate()) submit = false;
      if (!genderValidate()) submit = false;
      if (!addressValidate()) submit = false;
      if (!addressRemainValidate()) submit = false;
      if (!emailValidate()) submit = false;
  
      if (
        submit && (isParentCerti ? parentCerti : true)
      ) {
        setSubmitted(true);
      } else {
        setSubmitted(false);
      }
    }
  }

  // 회원가입
  const joinUser = async () => {
    setLoading(true);
    var param = {
      address: user.address.value,
      address_detail: user.addressRemain.value,
      birthday: Util.formatDate(birthday),
      cell_phone: user.mobile_no.value,
      email: user.email.value,
      name,
      parents_birthday: Util.formatDate(userParentsBirthday.current.value),
      parents_certify: (isParentCerti ? 1 : 0),
      parents_name: userParentsName.current.value,
      personal_agree,
      policy_agree,
      sex: (user.gender.value === 'N' ? '' : user.gender.value),
      userDevice: {
        agent: deviceInfo.agent,
        dev_ip: deviceInfo.dev_ip,
        dev_model: deviceInfo.dev_model,
        dev_token: deviceInfo.dev_token,
        os: deviceInfo.os,
        os_version: deviceInfo.os_version,
        app_version: deviceInfo.app_version
      },
      user_id: user.id.value,
      user_pw: CryptoAES.encrypt(user.password.value)
    };

    try {
      await LoginService.joinUser(param);
      try{
        window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {
          user_id: user.id.value,
          user_pw: user.password.value
        }, '');
      }catch(err){
        console.error(err)
      }
    } catch (error) {
      const error_code = error.response.data.error_code;
      const message = error.response.data.message;
      if (error_code < 0) {
        alert(message);
      }
    } finally {
      setLoading(false);
    }
  }

  // 아이디 값 검증
  const idValidate = useCallback(() => {
    const minId = 6;
    const maxId = 20;
    const regId = /^[A-Za-z0-9+]*$/;
    const textId = '아이디를 입력하세요.';

    if (
      user.id.value.length >= minId ||
      user.id.value.length === maxId
    ) {
      if (regId.test(user.id.value)) { // 아이디 형식 검증
        dispatchUser({ type: 'CHANGE_VALID', name: 'id', value: true });
        return true;
      } else {
        dispatchUser({ type: 'CHANGE_VALID', name: 'id', value: false });
        dispatchUser({ type: 'CHANGE_HELPTEXT', name: 'id', value: '영어, 영어+숫자만 입력 가능합니다.' });
        return false;
      }
    } else {
      dispatchUser({ type: 'CHANGE_VALID', name: 'id', value: false });
      dispatchUser({ type: 'CHANGE_HELPTEXT', name: 'id', value: '올바른 ' + textId });
      return false;
    }
  }, [user.id.value]);

  // 비밀번호 값 검증
  const passwordValidate = useCallback(() => {
    const minPassword = 8;
    const maxPassword = 20;
    const regPassword1 = /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,20}$/;
    const regPassword2 = /^(?=.*[a-zA-z])(?=.*[0-9]).{8,20}$/;
    const regPassword3 = /^(?=.*[a-zA-z])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,20}$/;
    const regPassword4 = /^(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,20}$/;
    const textPassword = '비밀번호를 입력하세요.';
    const textPasswordRight = '올바른 ' + textPassword;

    if (user.password.value) {
      if (
        user.password.value.length >= minPassword ||
        user.password.value.length === maxPassword
      ) {
        if (regPassword1.test(user.password.value) || regPassword2.test(user.password.value) || regPassword3.test(user.password.value) || regPassword4.test(user.password.value)) { // 비밀번호 형식 검증
          dispatchUser({ type: 'CHANGE_VALID', name: 'password', value: true });
          dispatchUser({ type: 'CHANGE_HELPTEXT', name: 'password', value: textPassword });
          return true;
        } else {
          dispatchUser({ type: 'CHANGE_VALID', name: 'password', value: false });
          dispatchUser({ type: 'CHANGE_HELPTEXT', name: 'password', value: textPasswordRight });
          return false;
        }
      } else {
        dispatchUser({ type: 'CHANGE_VALID', name: 'password', value: false });
        dispatchUser({ type: 'CHANGE_HELPTEXT', name: 'password', value: textPasswordRight });
        return false;
      }
    } else {
      dispatchUser({ type: 'CHANGE_VALID', name: 'password', value: false });
      dispatchUser({ type: 'CHANGE_HELPTEXT', name: 'password', value: textPassword });
      return false;
    }
  }, [user.password.value]);

  // 비밀번호 확인 값 검증
  const passwordCheckValidate = useCallback(() => {
    const minPassword = 8;
    const maxPassword = 20;
    const regPassword1 = /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,20}$/;
    const regPassword2 = /^(?=.*[a-zA-z])(?=.*[0-9]).{8,20}$/;
    const regPassword3 = /^(?=.*[a-zA-z])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,20}$/;
    const regPassword4 = /^(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,20}$/;
    const textPassword = '비밀번호를 입력하세요.';
    const textPasswordRight = '올바른 ' + textPassword;
    const textPasswordCheck = '비밀번호를 동일하게 한번 더 입력하세요.';
    const textPasswordSame = '비밀번호가 일치하지 않습니다.';

    if (user.passwordCheck.value) {
      if (
        user.passwordCheck.value.length >= minPassword ||
        user.passwordCheck.value.length === maxPassword
      ) {
        if (user.password.value === user.passwordCheck.value) {
          if (regPassword1.test(user.passwordCheck.value) || regPassword2.test(user.passwordCheck.value) || regPassword3.test(user.passwordCheck.value) || regPassword4.test(user.passwordCheck.value)) { // 비밀번호 확인 형식 검증
            dispatchUser({ type: 'CHANGE_VALID', name: 'passwordCheck', value: true });
            dispatchUser({ type: 'CHANGE_HELPTEXT', name: 'passwordCheck', value: textPasswordCheck });
            return true;
          } else {
            dispatchUser({ type: 'CHANGE_VALID', name: 'passwordCheck', value: false });
            dispatchUser({ type: 'CHANGE_HELPTEXT', name: 'passwordCheck', value: textPasswordRight });
            return false;
          }
        } else {
          dispatchUser({ type: 'CHANGE_VALID', name: 'passwordCheck', value: false });
          dispatchUser({ type: 'CHANGE_HELPTEXT', name: 'passwordCheck', value: textPasswordSame });
          return false;
        }
      } else {
        dispatchUser({ type: 'CHANGE_VALID', name: 'passwordCheck', value: false });
        dispatchUser({ type: 'CHANGE_HELPTEXT', name: 'passwordCheck', value: textPasswordRight });
        return false;
      }
    } else {
      dispatchUser({ type: 'CHANGE_VALID', name: 'passwordCheck', value: false });
      dispatchUser({ type: 'CHANGE_HELPTEXT', name: 'passwordCheck', value: textPasswordCheck });
      return false;
    }
  }, [user.password.value, user.passwordCheck.value]);

  // 성별 값 검증
  const genderValidate = useCallback(() => {
    if (user.gender.value) {
      dispatchUser({ type: 'CHANGE_VALID', name: 'gender', value: true });
      return true;
    } else {
      dispatchUser({ type: 'CHANGE_VALID', name: 'gender', value: false });
      return false;
    }
  }, [user.gender.value]);

  // 주소 값 검증
  const addressValidate = useCallback(() => {
    if (user.address.value) {
      dispatchUser({ type: 'CHANGE_VALID', name: 'address', value: true });
      return true;
    } else {
      // dispatchUser({ type: 'CHANGE_VALID', name: 'address', value: false });
      // return false;
      dispatchUser({ type: 'CHANGE_VALID', name: 'address', value: true });
      return true;
    }
  }, [user.address.value]);

  // 나머지 주소 값 검증
  const addressRemainValidate = useCallback(() => {
    if (user.addressRemain.value) {
      dispatchUser({ type: 'CHANGE_VALID', name: 'addressRemain', value: true });
      return true;
    } else {
      dispatchUser({ type: 'CHANGE_VALID', name: 'addressRemain', value: true });
      return true;
    }
  }, [user.addressRemain.value]);

  // 이메일 값 검증
  const emailValidate = useCallback(() => {
    // const regEmail = /^[0-9a-zA-Zㄱ-ㅎ가-힣-_\.]*@[0-9a-zA-Zㄱ-ㅎ가-힣-_\.]*\.[0-9a-zA-Zㄱ-ㅎ가-힣-_\.]{2,}$/i;
    const regEmail = /^[0-9a-zA-Zㄱ-ㅎ가-힣-_\.]{1,}@[0-9a-zA-Zㄱ-ㅎ가-힣-_\.]{1,}\.[0-9a-zA-Zㄱ-ㅎ가-힣-_\.]{2,}$/i;

    if (user.email.value) {
      if (regEmail.test(user.email.value)) { // 이메일 형식 검증
        dispatchUser({ type: 'CHANGE_VALID', name: 'email', value: true });
        return true;
      } else {
        dispatchUser({ type: 'CHANGE_VALID', name: 'email', value: false });
        dispatchUser({ type: 'CHANGE_HELPTEXT', name: 'email', value: '올바른 이메일 형식이 아닙니다.' });
        return false;
      }
    } else {
      dispatchUser({ type: 'CHANGE_VALID', name: 'email', value: true });
      return true;
    }
  }, [user.email.value]);

  // 포커스
  const inputFocus = () => {
    if (!(isParentCerti ? parentCerti : true)) {
      window.scrollTo(0, 0);
    } else if (!user.id.valid) {
      userId.current.querySelector('input').focus();
    } else if (!user.password.valid) {
      userPassword.current.querySelector('input').focus();
    } else if (!user.passwordCheck.valid) {
      userPasswordCheck.current.querySelector('input').focus();
    } else if (!user.address.valid) {
      userAddress.current.querySelector('input').focus();
    } else if (!user.addressRemain.valid) {
      userAddressRemain.current.querySelector('input').focus();
    } else if (!user.email.valid) {
      userEmail.current.querySelector('input').focus();
    }
  }

  // 보호자 인증하기 버튼 핸들러
  const onParentCerti = () => {
    setLoading(true);
    // 나이스 본인인증 시작
    NiceAuth.start('/login/niceAuthParentsSuccess', {}).then(() => {
    }).finally(() => {
      setLoading(false);
    });
  }

  // 부모 인증완료 핸들러
  const onParentsCertify = () => {
    setParentCerti(true);
  }

  // 모달 닫기
  const modalClose = () => {
    try{
      window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
    }catch(err){
      console.error(err)
    }
  };

  useEffect(() => {
    try{
      window.nativeApi.execute(window.NativeApiMethod.GET_USER_INFO, {}, window.NativeApiCallbackMethod.GET_USER_INFO);
      window[window.NativeApiCallbackMethod.GET_USER_INFO] = function (response) {
        setDeviceInfo(response);
      }
    }catch(err){
      console.error(err)
    }
  }, []);

  useEffect(() => {
    if (isAuth === 'false') {
      if (iReturn === '-999999') { // 블락된 회원
        setUserBlock(true);
      }
      if (iReturn === '-888888') { // 이미 가입된 회원
        setUserAlready(true);
      }
      setOpen(true);
    }

    window[window.NativeApiCallbackMethod.CLOSE_WEB_VIEW] = function (response) {
      userParentsName.current.value = response.name;
      userParentsBirthday.current.value = response.birthday;
      onParentsCertify();
    }
  }, [isAuth, iReturn]);

  useEffect(() => {
    if (user.id.value || user.password.value || user.passwordCheck.value || user.gender.value || user.address.value || user.addressRemain.value || user.email.value) {
      let submit = true;
      if (user.id.value) {  // 아이디
        if (!idValidate()) submit = false;
      }
      if (user.password.value) {  // 비밀번호
        if (!passwordValidate()) submit = false;
      }
      if (user.passwordCheck.value) { // 비밀번호 확인
        if (!passwordCheckValidate()) submit = false;
      }
      if (user.gender.value) {  // 성별
        if (!genderValidate()) submit = false;
      }
      // if (user.address.value) { // 주소
      //   if (!addressValidate()) submit = false;
      // }
      if (!addressValidate()) submit = false;
      if (!addressRemainValidate()) submit = false;
      if (!emailValidate()) submit = false;

      if (
        submit && (isParentCerti ? parentCerti : true)
      ) {
        setSubmitted(true);
      } else {
        setSubmitted(false);
      }
    }

    // if (!user.id.value || !user.password.value || !user.passwordCheck.value || !user.gender.value || !user.address.value) {
    if (!user.id.value || !user.password.value || !user.passwordCheck.value || !user.gender.value) {
      setSubmitted(false);
    }

  }, [
    user.id.value,
    user.password.value,
    user.passwordCheck.value,
    user.gender.value,
    user.address.value,
    user.addressRemain.value,
    user.email.value,
    idValidate,
    passwordValidate,
    passwordCheckValidate,
    genderValidate,
    addressValidate,
    addressRemainValidate,
    emailValidate,
    isParentCerti,
    parentCerti
  ]);

  return (
    <>
      <div className="page-join form">
        <div className="page-header">
          <p>STEP 02. 정보를 입력해주세요.</p>
          <p className="sub">원활한 사용을 위해 추가 정보를 입력해주세요.</p>
        </div>

        <div className="guardian">
          {isParentCerti && !parentCerti ?
            <div className="guardian-agree">
              <p>
                만 14세 미만은<br />보호자 동의가 필요합니다.
            </p>
              <button type="button" className="btn" onClick={onParentCerti}>
                보호자 인증하기
            </button>
            </div>
            :
            <></>
          }
          {isParentCerti && parentCerti ?
            <div className="guardian-finish">
              <p>보호자 인증완료</p>
            </div>
            :
            <></>
          }
          <input type="hidden" id="parents_name" ref={userParentsName} />
          <input type="hidden" id="parents_birthday" ref={userParentsBirthday} />
        </div>

        <form>
          <fieldset>
            <legend className="a11y">회원가입 정보 입력</legend>

            <div className="input-list">
              <TextField
                id="userName"
                name="userName"
                className="input-item"
                label="이름"
                placeholder="이름을 입력하세요."
                InputLabelProps={{ shrink: true }}
                defaultValue={user.name.value}
                disabled
              />

              <TextField
                type="tel"
                id="userTel"
                name="userTel"
                className="input-item"
                label="휴대폰번호"
                placeholder="휴대폰번호를 입력해주세요."
                InputLabelProps={{ shrink: true }}
                defaultValue={Util.formatPhoneNumber(user.mobile_no.value)}
                disabled
              />

              <TextField
                id="userBirthDay"
                name="userBirthDay"
                className="input-item"
                label="생년월일"
                placeholder="생년월일을 입력하세요."
                InputLabelProps={{ shrink: true }}
                defaultValue={Util.formatDate(user.birthday.value)}
                disabled
              />
            </div>

            <div className="input-list">
              <TextField
                id="id"
                name="id"
                className="input-item"
                label="아이디(필수)"
                autoComplete="off"
                placeholder={user.id.helpText}
                InputLabelProps={{ shrink: true }}
                onChange={event => { dispatchUser({ type: 'CHANGE_VALUE', name: 'id', value: event.target.value }); formValidate(); }}
                error={user.id.valid ? false : true}
                helperText={user.id.helpText}
                inputProps={{ minLength: 6, maxLength: 20, autocapitalize: 'none' }}
                onFocus={Validateid}
                ref={userId}
              />

              <TextField
                type="password"
                id="userPassword"
                name="userPassword"
                className="input-item"
                label="비밀번호(필수)"
                placeholder={user.password.helpText}
                InputLabelProps={{ shrink: true }}
                onChange={event => { dispatchUser({ type: 'CHANGE_VALUE', name: 'password', value: event.target.value }); formValidate(); }}
                error={user.password.valid ? false : true}
                helperText={user.password.helpText}
                inputProps={{ minLength: 8, maxLength: 20 }}
                onFocus={ValidatePass}
                ref={userPassword}
              />
              <p className="input-tip">8~20자 영문(대/소문자 구분), 숫자, 특수문자 중 2개 이상 사용</p>
              <TextField
                type="password"
                id="userPasswordCheck"
                name="userPasswordCheck"
                className="input-item input-multi"
                placeholder={user.passwordCheck.helpText}
                InputLabelProps={{ shrink: true }}
                onChange={event => { dispatchUser({ type: 'CHANGE_VALUE', name: 'passwordCheck', value: event.target.value }); formValidate(); }}
                error={user.passwordCheck.valid ? false : true}
                helperText={user.passwordCheck.helpText}
                inputProps={{ minLength: 8, maxLength: 20 }}
                onFocus={ValidatePassCheck}
                ref={userPasswordCheck}
              />
              {/* <p className="input-tip">8~20자 영문(대/소문자 구분), 숫자, 특수문자 사용</p> */}

              <div className={`input-item input-alt${user.gender.valid ? '' : ' error'}`}>
                <span className="input-label">성별(필수)</span>
                <div className="radio-wrap">
                  <label htmlFor="userGenderM" className="btn-radio">
                    <input type="radio" id="userGenderM" name="userGender" value="M" onChange={event => { dispatchUser({ type: 'CHANGE_VALUE', name: 'gender', value: event.target.value }); formValidate(); }} onFocus={formValidate} />
                    <span className="label-text">남성</span>
                  </label>
                  <label htmlFor="userGenderF" className="btn-radio">
                    <input type="radio" id="userGenderF" name="userGender" value="F" onChange={event => { dispatchUser({ type: 'CHANGE_VALUE', name: 'gender', value: event.target.value }); formValidate(); }} onFocus={formValidate} />
                    <span className="label-text">여성</span>
                  </label>
                  <label htmlFor="userGenderN" className="btn-radio" ref={userGender}>
                    <input type="radio" id="userGenderN" name="userGender" value="N" onChange={event => { dispatchUser({ type: 'CHANGE_VALUE', name: 'gender', value: event.target.value }); formValidate(); }} onFocus={formValidate} />
                    <span className="label-text">선택 안함</span>
                  </label>
                </div>
                <p className="error-text">{user.gender.helpText}</p>
              </div>

              <TextField
                id="userAddress"
                name="userAddress"
                className="input-item input-alt"
                label="주소"
                autoComplete="off"
                placeholder={user.address.helpText}
                InputLabelProps={{ shrink: true }}
                onChange={event => { dispatchUser({ type: 'CHANGE_VALUE', name: 'address', value: event.target.value }); formValidate(); }}
                error={user.address.valid ? false : true}
                helperText={user.address.helpText}
                value={user.address.value}
                inputProps={{ autocapitalize: 'none', readonly: 'readonly' }}
                onFocus={formValidate}
                ref={userAddress}
              />

              <PostCodeDialog address={user.address.value} addressChange={value => dispatchUser({ type: 'CHANGE_VALUE', name: 'address', value: value })}></PostCodeDialog>

              <TextField
                id="userAddressRemain"
                name="userAddressRemain"
                className="input-item input-multi"
                autoComplete="off"
                placeholder={user.address.helpText}
                InputLabelProps={{ shrink: true }}
                onChange={event => dispatchUser({ type: 'CHANGE_VALUE', name: 'addressRemain', value: event.target.value })}
                error={user.addressRemain.valid ? false : true}
                helperText={user.addressRemain.helpText}
                inputProps={{ autocapitalize: 'none' }}
                ref={userAddressRemain}
              />

              <TextField
                type="email"
                id="userEmail"
                name="userEmail"
                className="input-item"
                label="이메일"
                autoComplete="off"
                placeholder={user.email.helpText}
                InputLabelProps={{ shrink: true }}
                onChange={event => dispatchUser({ type: 'CHANGE_VALUE', name: 'email', value: event.target.value })}
                error={user.email.valid ? false : true}
                helperText={user.email.helpText}
                ref={userEmail}
              />
            </div>

            <div className="page-tip">
              <p className="tip-title">알아두세요!</p>
              <p>
                수퍼빈 포인트를 현금으로 전환할 경우<br />
                가입자 본인 명의의 계좌번호만 이용 가능하며,<br />
                타인(가족 포함) 명의로는 불가능 합니다.<br /><br />
                + 계좌정보는 현금전환 신청 시 입력 가능합니다.
              </p>
            </div>

            <div className="btn-wrap">
              <button type="button" className={`btn${submitted ? " btn-active" : " btn-disabled"}`} onClick={onSubmit} >
                가입하기
              </button>
            </div>
          </fieldset>
        </form>
      </div>

      <JoinModal userAlready={userAlready} userBlock={userBlock} open={open} modalClose={modalClose}></JoinModal>

      <ProgressFixed loading={loading} />
    </>
  );
}

export default Join;