import AuthService from './AuthService';
import Service from './Index';

const NotificationService = {
  fetchNotificationCount: (param) => {
    return AuthService.get('/me/notification/count', param);
  },
  fetchItems: (param) => {
    return AuthService.get('/me/notification', param);
  },
  send: (param) => {
    return Service.post('/notification', param);
  },
};

export default NotificationService;
