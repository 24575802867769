import React, { useEffect, useState } from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import PrivateRoute from './route/PrivateRoute';

import Mypage from '../pages/mypage/Mypage';
import ChangePrivateInfo from "../pages/mypage/change-private-info/ChangePrivateInfo";
import ChangePrivateInfoDone from "../pages/mypage/change-private-info/done/ChangePrivateInfoDone";
import ChangePrivateInfoFailed from "../pages/mypage/change-private-info/failed/ChangePrivateInfoFailed";
import ChangeAccountPassword from "../pages/mypage/change-account-password/ChangeAccountPassword";
import ChangeAccountPasswordDone from "../pages/mypage/change-account-password/done/ChangeAccountPasswordDone";
import ChangeRefundPassword from "../pages/mypage/change-refund-password/ChangeRefundPassword";
import ChangeRefundPasswordDone from "../pages/mypage/change-refund-password/done/ChangeRefundPasswordDone";
import MypageInputPassword from "../pages/mypage/input-password/MypageInputPassword";
import WithDraw from "../pages/mypage/withdraw/WithDraw";
import MypageAuthrizationService from '../services/MypageAuthrizationService'
import { useHistory , useLocation } from "react-router-dom";
import RegistRefundPassword from "../pages/mypage/regist-refund-password/RegistRefundPassword";
import RegistRefundPasswordDone from "../pages/mypage/regist-refund-password/done/RegistRefundPasswordDone";
import AddSubPhoneInsertCertinfo from "../pages/mypage/change-private-info/add-sub-phone/InsertCertinfo/AddSubPhoneInsertCertinfo";
import AddSubPhoneVerificationCode from "../pages/mypage/change-private-info/add-sub-phone/VerificateCode/AddSubPhoneVerificationCode";
import PrivateCertificationTerms from "../pages/mypage/change-private-info/certification-terms/PrivateCertificationTerms";
import ChangeNameInsertCertinfo from "../pages/mypage/change-private-info/change-name/InsertCertinfo/ChangeNameInsertCertinfo";
import ChangeNameVerificationCode from "../pages/mypage/change-private-info/change-name/VerificateCode/ChangeNameVerificationCode";
import ChangeMainPhoneInsertCertinfo from "../pages/mypage/change-private-info/change-main-phone/InsertCertinfo/ChangeMainPhoneInsertCertinfo";
import ChangeMainPhoneVerificationCode from "../pages/mypage/change-private-info/change-main-phone/VerificateCode/ChangeMainPhoneVerificationCode";
import CancelAffliateService from "../pages/mypage/cancel-affliate-service/CancelAffliateService";
import RegistMainPhoneInsertCertinfo from "../pages/mypage/change-private-info/regist-main-phone/InsertCertinfo/RegistMainPhoneInsertCertinfo";
import RegistMainPhoneVerificationCode from "../pages/mypage/change-private-info/regist-main-phone/VerificateCode/RegistMainPhoneVerificationCode";
function MypageRouter() {
  const history = useHistory()
  const location = useLocation()
  const [authorization,setAuthrization] = useState(false)
  useEffect(()=>{
    checkPrivateAuth()
  },[])
  const checkPrivateAuth = async() => {
    if(location.pathname === "/mypage/input-password"){
      setAuthrization(true)
    }else{
      const authorization = await MypageAuthrizationService.getSession()
      setAuthrization(authorization)
    }
  }
  return (
    <Switch>
      {authorization ?<PrivateRoute path="/mypage" exact component={Mypage}/>:<></>}
      {authorization ? <PrivateRoute path="/mypage/cancel-affiliate-service" exact component={CancelAffliateService} />:<></>}
      {authorization ? <PrivateRoute path="/mypage/change-account-password" exact component={ChangeAccountPassword} />:<></>}
      {authorization ?<PrivateRoute path="/mypage/change-account-password/done" exact component={ChangeAccountPasswordDone} />:<></>}
      {authorization ?<PrivateRoute path="/mypage/change-private-info" exact component={ChangePrivateInfo} />:<></>}
      {authorization ?<PrivateRoute path="/mypage/change-private-info/done" exact component={ChangePrivateInfoDone} />:<></>}
      {authorization ?<PrivateRoute path="/mypage/change-private-info/failed" exact component={ChangePrivateInfoFailed} />:<></>}
      {authorization ?<PrivateRoute path="/mypage/change-refund-password" exact component={ChangeRefundPassword} />:<></>}
      {authorization ?<PrivateRoute path="/mypage/change-refund-password/done" exact component={ChangeRefundPasswordDone} />:<></>}
      {authorization ?<PrivateRoute path="/mypage/regist-refund-password" exact component={RegistRefundPassword} />:<></>}
      {authorization ?<PrivateRoute path="/mypage/regist-refund-password/done" exact component={RegistRefundPasswordDone} />:<></>}
      {authorization ?<PrivateRoute path="/mypage/change-private-info/add-sub-phone/terms" exact component={PrivateCertificationTerms} />:<></>}
      {authorization ?<PrivateRoute path="/mypage/change-private-info/add-sub-phone/insert-certinfo" exact component={AddSubPhoneInsertCertinfo} />:<></>}
      {authorization ?<PrivateRoute path="/mypage/change-private-info/add-sub-phone/verificate-code" exact component={AddSubPhoneVerificationCode} />:<></>}
      {authorization ?<PrivateRoute path="/mypage/change-private-info/change-name/terms" exact component={PrivateCertificationTerms} />:<></>}
      {authorization ?<PrivateRoute path="/mypage/change-private-info/change-name/insert-certinfo" exact component={ChangeNameInsertCertinfo} />:<></>}
      {authorization ?<PrivateRoute path="/mypage/change-private-info/change-name/verificate-code" exact component={ChangeNameVerificationCode} />:<></>}
      {authorization ?<PrivateRoute path="/mypage/change-private-info/change-main-phone/terms" exact component={PrivateCertificationTerms} />:<></>}
      {authorization ?<PrivateRoute path="/mypage/change-private-info/change-main-phone/insert-certinfo" exact component={ChangeMainPhoneInsertCertinfo} />:<></>}
      {authorization ?<PrivateRoute path="/mypage/change-private-info/change-main-phone/verificate-code" exact component={ChangeMainPhoneVerificationCode} />:<></>}
      {authorization ?<PrivateRoute path="/mypage/change-private-info/regist-main-phone/terms" exact component={PrivateCertificationTerms} />:<></>}
      {authorization ?<PrivateRoute path="/mypage/change-private-info/regist-main-phone/insert-certinfo" exact component={RegistMainPhoneInsertCertinfo} />:<></>}
      {authorization ?<PrivateRoute path="/mypage/change-private-info/regist-main-phone/verificate-code" exact component={RegistMainPhoneVerificationCode} />:<></>}

      {authorization ?<PrivateRoute path="/mypage/withdraw" exact component={WithDraw} />:<></>}
      <PrivateRoute path="/mypage/input-password" exact component={MypageInputPassword} />
      <Redirect path="/mypage/*" to="/mypage" />
    </Switch>
  );
}

export default MypageRouter;
