import { useEffect, useState } from "react"
import TermsService from "../../services/TermsService";

export default function Privacy() {
  const [data,setData] = useState()
  useEffect(()=>{
    getData()
  },[])
  const getData = async() => {
    try{

      //temp
      // const resultIdx = await TermsService.getTermsPrivacyList()
      // const result = await TermsService.getTermsDetail(resultIdx)
      const result = await TermsService.get_term_current({segment : "privacy"});
      setData(result)
    }catch(err){
      window.alert(err)
      setData("")
    }
  }
  return (
    <>
      <div className="editor" dangerouslySetInnerHTML={{ __html: data?.content }} />
    </>
  );
}