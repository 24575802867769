import Service from './Index';

const AppService = {
  fetchItem: (param) => {
    return Service.get('/app/info', param);
  },
  fetchBanners: () => {
    return Service.get('/app/banner');
  },
  fetchPopups: (param) => {
    return Service.get('/app/popup', param);
  }
};

export default AppService;
