import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import TermsDialog from "../../layout/dialog/TermsDialog";
import NiceAuth from '../../helpers/NiceAuth';
import { Auth_FRONT } from '../../config';

const JoinTerms = () => {
  const history = useHistory();

  const [agreeService, setAgreeService] = useState(false);
  const [agreePrivacy, setAgreePrivacy] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  // useEffect(() => {
  //   window[window.NativeApiCallbackMethod.CLOSE_WEB_VIEW] = function (response) {
  //     history.push('/login/join?'
  //       + 'policy_agree=' + response.policy_agree
  //       + '&personal_agree=' + response.personal_agree
  //       + '&name=' + response.name
  //       + '&mobile_no=' + response.mobile_no
  //       + '&birthday=' + response.birthday
  //       + '&isAuth=' + response.isAuth
  //       + '&iReturn=' + response.iReturn);
  //   }
  // }, [history]);

  // useEffect(() => {
  //   if (agreeService && agreePrivacy) {
  //     setSubmitted(true);
  //   } else {
  //     setSubmitted(false);
  //   }
  // }, [agreeService, agreePrivacy]);
  useEffect(()=>{
    // window.location.href = Auth_FRONT+"join?redirect="+window.location.href
  })

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'agreeService':
        setAgreeService(event.target.checked);
        break;
      case 'agreePrivacy':
        setAgreePrivacy(event.target.checked);
        break;
      default:
        break;
    }
  }

  // 동의하고 본인 인증하기 버튼 핸들러
  const onSubmit = () => {
    if (submitted) {
      // 나이스 본인인증 시작
      const param = {
        policy_agree: (agreeService ? 'Y' : 'N'),
        personal_agree: (agreePrivacy ? 'Y' : 'N')
      };
      NiceAuth.start('/login/niceAuthSuccess', param).then(() => {
      }).finally(() => {
      });
    } else {
      // 필수 약관 체크 관련 로직 추가
    }
    // 본인인증 캔슬 /채필규 2021.01.11 
    // history.push('/login/join?'
    // + 'policy_agree=' + '12'
    // + '&personal_agree=' + '12'
    // + '&name=' + '12'
    // + '&mobile_no=' + '12'
    // + '&birthday=' + '12'
    // + '&isAuth=' + '12'
    // + '&iReturn=' + '12');
  }

  return (
    <>
      {/* <div className="page-join">
        <div className="page-header">
          <p>STEP 01. 약관 동의하기</p>
          <p className="sub">자원순환과 함께하는 당신이 진정한 수퍼빈입니다.</p>
        </div>

        <form>
          <fieldset>
            <legend className="a11y">약관 동의</legend>
            <TermsDialog formData={{ agreeService, agreePrivacy }} handleChange={handleChange}></TermsDialog>
            <input type="hidden" name="isOpen" />

            <div className="btn-wrap">
              <button type="button" className={`btn${submitted ? " btn-active" : " btn-disabled"}`} onClick={onSubmit}>
                동의하고 본인 인증하기
              </button>
            </div>
          </fieldset>
        </form>
      </div> */}
    </>
  )
}

export default JoinTerms;