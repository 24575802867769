import CryptoJS from "crypto-js";

const KEY = 'super_bin_aessecesljwpdoaedcfsyt';

const CryptoAES = {
  // AES 암호화
  encrypt: (text) => {
    return CryptoJS.AES.encrypt(text, KEY).toString();
  }
}

export default CryptoAES;