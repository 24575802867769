import { useContext, useEffect, useState, useCallback } from "react";
import { useHistory } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import AppService from '../../services/AppService';
import { AppContext } from '../../AppContext';

const Setting = () => {
  const history = useHistory();
  const { auth } = useContext(AppContext);
  const [newVersion, setNewVersion] = useState(auth.user.app_version);
  const [modal, setModal] = useState(false);

  // 최신 앱 정보 조회
  const getNewAppInfo = useCallback(async () => {
    try {
      const result = await AppService.fetchItem({ os: auth.user.os });
      const data = result.data;
      setNewVersion(data.ds_version);
    } catch (error) {
    } finally {
    }
  }, [auth]);

  useEffect(() => {
    getNewAppInfo();  // 최신 앱 정보 조회
  }, [getNewAppInfo]);

  // 로그아웃 팝업
  const modalOpen = () => {
    setModal(true);
  }

  const modalClose = () => {
    setModal(false);
  }

  // 로그아웃 핸들러
  const onLogout = () => {
    auth.logout();
  }

  // 업데이트
  const onUpdate = useCallback(async () => {
    try {
      const result = await AppService.fetchItem({ os: auth.user.os });
      const data = result.data;
      const moveUrl = data.ds_url
      try{
        window.nativeApi.execute(window.NativeApiMethod.MOVE_STORT, {
          url: moveUrl
        }, "");
      }catch(err){
        console.error(err)
      }
    } catch (error) {
    } finally {
    }

  }, [auth]);

  // 즐겨찾는 네프론 설정 버튼 핸들러
  const onSetMyNeflon = () => {
    try{
      window.nativeApi.execute(window.NativeApiMethod.OPEN_MY_NEFLON, {}, "");
    }catch(err){
      console.error(err)
    }
  }

  return (
    <>
      <div className="page-setting">
        <ul className="menu-list">
          <li><button type="button" className="btn" onClick={() => history.push("/setting/notification")}>알림 설정</button></li>
          <li><button type="button" className="btn" onClick={onSetMyNeflon}>즐겨찾는 네프론 설정</button></li>
          <li><button type="button" className="btn" onClick={() => history.push("/setting/terms-policies")}>약관 및 정책</button></li>
          <li>
            {newVersion === auth.user.app_version ?
              <button type="button" className="btn update">
                버전정보 (ver {auth.user.app_version})
              </button>
              :
              <button type="button" className="btn update" onClick={onUpdate}>
                버전정보 (ver {auth.user.app_version}) <span>업데이트</span>
              </button>
            }

          </li>
          <li><button type="button" className="btn" onClick={modalOpen}>로그아웃</button></li>
        </ul>
      </div>

      <Modal
        className="modal modal-bottom"
        open={modal}
        onClose={modalClose}
        disableRestoreFocus
        disableBackdropClick
      >
        <div className="modal-inner">
          <div className="modal-header">
            <header>
              <p>로그아웃</p>
            </header>
          </div>

          <div className="modal-content">
            <p>로그아웃 하시겠습니까?</p>
          </div>
          <div className="modal-footer btn-wrap half">
            <button type="button" className="btn btn-primary" onClick={modalClose}>취소</button>
            <button type="button" className="btn btn-active" onClick={onLogout}>확인</button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Setting;