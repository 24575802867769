import React from 'react';
import PrivateVerificateCodeComponent from '../../common-privateInfo-component/verificate-code-component/PrivateVerificateCodeComponent';
import RegistMainPhoneVerificationCodeViewModel from './RegistMainPhoneVerificationCodeViewModel';

const RegistMainPhoneVerificationCode = () => {
    const {verificateSms,resendSms,buttonEnable} = RegistMainPhoneVerificationCodeViewModel.VerificatePhoneSms()
    return (
        <PrivateVerificateCodeComponent setValidateion={function (data) {
            if(buttonEnable){
                verificateSms({
                    code : data
                })
            }
        } }
        resendCode={function () {
            resendSms()
        } }
        ></PrivateVerificateCodeComponent>
    );
};

export default RegistMainPhoneVerificationCode;