import React from 'react';
import PrivateVerificateCodeComponent from '../../common-privateInfo-component/verificate-code-component/PrivateVerificateCodeComponent';
import AddSubPhoneVerificationCodeViewModel from './AddSubPhoneVerificationCodeViewModel';

const AddSubPhoneVerificationCode = () => {
    const {verificateSms,resendSms,buttonEnable} = AddSubPhoneVerificationCodeViewModel.VerificatePhoneSms()
    return (
        <PrivateVerificateCodeComponent setValidateion={function (data) {
            if(buttonEnable){
                verificateSms({
                    code : data
                })
            }
        } }resendCode={function () {
            resendSms()
        } }></PrivateVerificateCodeComponent>
    );
};

export default AddSubPhoneVerificationCode;