import React, { useContext, useState } from 'react';
import { useLocation ,useHistory} from 'react-router-dom';
import style from './VerificateCode.module.css'
import SmsNumInput from '../../../Modules/MyPageModules/Private/SmsNumInput/SmsNumInput';
import WidthFullBtn from '../../../Modules/Buttons/widthFullBtn/WidthFullBtn';
import VerificateCodeViewModel from './VerificateCodeViewModel'
import AlertContext from '../../../layout/modal/CustomiseModal/AlertModal/AlertContext';
import SessionHelper from '../../../helpers/SessionHelper';
const VerificateCode = () => {
    const location = useLocation()
    const history = useHistory()
    const {alert : alertComp} = useContext(AlertContext)
    const [smsValidateNum,setSmsValidateNum] = useState("")
    const [timerStatus,setTimerStatus] = useState(true)
    const [seqcode,setSeqcode] = useState({
        request_seq_code : location.query.request_seq_code,
        response_seq_code : location.query.response_seq_code,
    })
    const routePushValidation = async() => {
        const body = {
            phone : location.query.phone,
            name : location.query.name,
            rr_number : location.query.rr_number,
            request_seq_code : seqcode.request_seq_code,
            response_seq_code : seqcode.response_seq_code,
            auth_code : smsValidateNum,
        }
        try{
            const smsResult = await VerificateCodeViewModel.SmsValidation(body)
            if(smsResult.status === "DuplicateCi"){
                await alertComp('수퍼빈 계정 통합 안내', '이미 본인 명의의 수퍼빈 계정이 존재합니다. 수퍼빈 서비스는 1개의 계정(아이디)으로만 사용이 가능합니다',undefined,'계정 통합하기')
                await SessionHelper.setCISession(smsResult.ci_access_token)
                history.replace({
                    pathname:'/select-account',query:location.query
                },'/select-account')
            }else if(smsResult.status === "DifferentCi"){
                await alertComp('본인 정보가 아닙니다', '로그인한 아이디의 개인 정보를 입력해주세요. 만약 번호, 이름이 변경된 경우 1600-6217 혹은 카카오톡 문의하기로 문의 바랍니다.',undefined,'확인')
                history.replace('/')
            }else if(smsResult.status === "AlreadyRegister"){
                await alertComp('이미 인증된 계정입니다.', '만약 인증이 되지 않았다면 1600-6217 혹은 카카오톡 문의하기로 문의 바랍니다.',undefined,'확인')
                history.replace('/')
            }else{
                history.replace({
                    pathname:'done'
                },'done')
            }
        }catch(err){
            if(err.response.status === 409){
                await alertComp('알림', '인증번호가 잘못되었습니다. 다시 입력해주세요',undefined,'확인')
            }else{
                await alertComp('알림', '어플리케이션 에러가 발생하여 메인으로 돌아갑니다. 잠시 후 재 시도해주세요',undefined,'메인으로 돌아가기')
                history.replace('/')
            }
        }
    }
    const resendCode = async() => {
        const body = {
            phone : location.query.phone,
            name : location.query.name,
            rr_number : location.query.rr_number,
            telco : Number(location.query.telco)
        }
        try{
            const result = await VerificateCodeViewModel.postSms(body)
            setSeqcode({
                request_seq_code : result.request_seq_code,
                response_seq_code : result.response_seq_code
            })
        }catch(err){
            await alertComp('알림', '서버 에러가 발생하여 메인으로 돌아갑니다. 잠시 후 재 시도해주세요',undefined,'메인으로 돌아가기')
            location.replace('/')
        }
    }
    return (
        <div className={style['page-start']}>
            <div className={style['root']}>
                <div>
                    <p className={style['title']}>인증번호 입력</p>
                    <p className={style['description']}>문자메시지로 수신 받은 인증번호 6자리를 아래 칸에 입력 후 [인증하기] 버튼을 눌러주세요.</p>
                    <div className={style['item']}>
                        <SmsNumInput title={'인증번호'} holder={'인증번호를 입력해주세요'} value={smsValidateNum} InputData={function (value) {
                            setSmsValidateNum(value);
                        } } TimerStarted={function () {
                            setTimerStatus(true)
                            resendCode()
                        } } TimerEnded={function () {
                            setTimerStatus(false)
                        } }></SmsNumInput>
                    </div>
                    <div className={style['sub-description']}>
                        <p className={style['title']}>※ 문자메시지를 받지 못한 경우</p>
                        <p className={style['body']}>휴대전화의 차단메시지함을 확인하시거나 가입한 통신사로 문자 차단 서비스를 이용하고 있는지 확인해주세요.</p>
                    </div>
                    <div className={style['validate-end']}>
                        <WidthFullBtn onClick={function () {
                            routePushValidation()
                        }}title={'인증하기'} 
                        disabled={smsValidateNum && timerStatus ? false:true}></WidthFullBtn>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerificateCode;