import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import style from './GuardianCertification.module.css'
import GuardianCertificationModal from './modal/GuardianCertificationModal';

const GuardianCertification = () => {
    const history = useHistory();
    const [modal_open,set_modal_open] = useState(false)
    return (
        <>
        <div className={style['page-start']}>
            <div className={style['root']}>
                <div>
                    <p className={style['title']}>만 14세 미만 보호자 인증</p>
                    <p className={style['description']}>만 14세 미만 보호자 인증이 필요합니다. 아래 [보호자 인증하기] 버튼을 클릭해주세요.</p>
                    <div className={style['allcheck-line']}><hr></hr></div>
                </div>
                <button className={style['next-btn']} onClick={()=>{
                    set_modal_open(true)
                }} disabled={false}>보호자 인증하기</button>
                <button className={style['cancel-btn']} onClick={()=>{
                    sessionStorage.setItem('cancel_guardian_certification','true')
                    history.push('/')
                }} disabled={false}>다음에 하기</button>
            </div>
        </div>
        {modal_open ? <GuardianCertificationModal></GuardianCertificationModal>:<></>}
        </>
    );
};

export default GuardianCertification;