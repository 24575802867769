import React from 'react';
import style from './CertificationFailed.module.css'
import { useHistory} from 'react-router-dom';
import { ChangeFailedIcon } from '../../../assets/icons/SVG';

const CertificationFailed = () => {
    const histroy = useHistory()
    // 카카오톡 문의 버튼 핸들러
    const onKakaoInquiry = () => {
        try{
        window.nativeApi.execute(window.NativeApiMethod.OPEN_BROWSER, {
            url: "https://pf.kakao.com/_IxmbLxb"
        }, "");
        }catch(err){
        console.error(err)
        }
    }
    return (
        <div className={style["page-start"]}>
            <div className={style.root}>
            <ChangeFailedIcon></ChangeFailedIcon>
                <div className={`${style["title"]} ${style["centerItem"]}`}>수퍼빈 회원 인증 실패</div>
                <div className={`${style["sub-title"]} ${style["centerItem"]}`}>본인과 다른 정보를 입력하였습니다. 로그인한 계정 명의의 전화번호로만 인증 가능합니다.</div>
                <div className={`${style["sub-title"]} ${style["centerItem"]}`}><br />문의사항이 있는 경우 수퍼빈 카카오톡 채널로 문의해주세요.</div>
                <div className={style["superbin-kakao"]}>
                    <div onClick={()=>{onKakaoInquiry()}}>
                        <div className={style["content"]}>
                            <p>수퍼빈 카카오톡 채널로 문의하기</p>
                            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 1L8.5 8L1.5 15" stroke="#3997FE" stroke-width="2"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className={style["bottom-container-box"]}>
                    <button onClick={()=>{histroy.replace("/")}}>메인으로 돌아가기</button>
                </div>
            </div>
        </div>
    );
};

export default CertificationFailed;