import HttpHelper from "../helpers/HttpHelper/HttpHelper";

const CIService =  {
    requestSmsCode : (body) => {
        return HttpHelper.post("/cpoint/ci/request-auth",body,null)
    },
    valificateSmsCode : (body) => {
        return HttpHelper.post("/cpoint/ci/verify-auth",body,null)
    },
};

export default CIService;