import React from 'react';
import style from './InfoParagraph.module.css'
const InfoParagraph = ({title,content,children,description}) => {
    return (
        <div className={style["paragraph-box"]}>
            <div>
                <div>
                    <p className={style.title}>{title}</p>
                    <p className={style.contents}>{content}</p>
                </div>
                <div>
                    {children}
                </div>
            </div>
            {description ? (<p className={style.description}>{description}</p>):(<></>)}
        </div>
    );
}

export default InfoParagraph;