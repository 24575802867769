/** @format */

import React, { useState, useEffect, useRef, useCallback } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import BoardService from "../../services/BoardService";
import Util from "../../helpers/Util";

const Faq = () => {
    const [list, setList] = useState([]); // 리스트
    const [totalCount, setTotalCount] = useState(0); // 전체 건수
    const [currentPage, setCurrentPage] = useState(0); // 현재 페이지
    const limit = 100;
    const [offset, setOffset] = useState(0);
    const [search, setSearch] = useState(false); // 검색 여부
    const [searchWord, setSearchWord] = useState(""); // 검색어
    const [code, setCode] = useState(""); // 카테고리 코드
    const searchInput = useRef("");

    // 리스트 조회
    const searchList = useCallback(async () => {
        const param = {
            limit,
            offset,
            search_word: searchWord,
            filter: "ds_str9=in:app<>all" + (code ? ",ds_str10=" + code : ""),
        };
        try {
            const result = await BoardService.fetchItems("faq", param);
            const data = result.data;
            setCurrentPage(offset / limit);
            setTotalCount(data.total_count);
            setList(data.list);
        } catch (error) {
        } finally {
        }
    }, [code, offset, limit, searchWord]);

    // 더보기 버튼 핸들러
    const onMore = () => {
        setOffset((currentPage + 1) * limit);
    };

    const onCategory = (event) => {
        const btn = document.querySelectorAll(".category-select .btn");
        const categoryCode = event.target.dataset.code;

        setSearch(false);
        setSearchWord("");
        setCode(categoryCode);

        // 버튼 class toggle
        [].map.call(btn, (item) => {
            item.classList.remove("active");
        });
        event.target.classList.add("active");
    };

    const onSearch = () => {
        const val = searchInput.current.value;

        if (val !== "") {
            setSearch(true);
            setSearchWord(val);
        } else {
            setSearch(false);
            setSearchWord("");
        }
    };

    // 검색어 입력 후 엔터키 기능
    const onEnter = (event) => {
        if (event.key === "Enter") {
            onSearch();
            event.target.blur();
        }
    };

    const createEmpty = () => {
        return (
            <>
                {search ? (
                    <div className="item-empty">
                        <p>검색 결과가 없습니다.</p>
                        <p className="sub">검색어가 정확한지 확인해주세요.</p>
                    </div>
                ) : (
                    <div className="item-empty">
                        <p>자주하는 질문이 없습니다.</p>
                    </div>
                )}
            </>
        );
    };

    useEffect(() => {
        searchList();
    }, [searchList]);

    return (
        <>
            <div className="page-board faq">
                <div className="search-wrap">
                    <div className="input-item">
                        <input
                            type="text"
                            className="input-control"
                            placeholder="질문을 검색해보세요."
                            onKeyPress={onEnter}
                            ref={searchInput}
                        />
                        <button type="button" className="btn-search" onClick={onSearch}>
                            <span className="a11y">검색</span>
                        </button>
                    </div>

                    <div className="category-select">
                        {list && (
                            <>
                                <button
                                    type="button"
                                    className="btn active"
                                    data-code=""
                                    onClick={onCategory}
                                >
                                    전체
                                </button>
                                <button
                                    type="button"
                                    className="btn"
                                    data-code="user"
                                    onClick={onCategory}
                                >
                                    회원정보
                                </button>
                                <button
                                    type="button"
                                    className="btn"
                                    data-code="point"
                                    onClick={onCategory}
                                >
                                    포인트/환전
                                </button>
                                <button
                                    type="button"
                                    className="btn"
                                    data-code="nephron"
                                    onClick={onCategory}
                                >
                                    네프론
                                </button>
                                <button
                                    type="button"
                                    className="btn"
                                    data-code="culture"
                                    onClick={onCategory}
                                >
                                    문화사업
                                </button>
                                <button
                                    type="button"
                                    className="btn"
                                    data-code="app"
                                    onClick={onCategory}
                                >
                                    앱관련
                                </button>
                                <button
                                    type="button"
                                    className="btn"
                                    data-code="etc"
                                    onClick={onCategory}
                                >
                                    기타
                                </button>
                            </>
                        )}
                    </div>
                </div>

                <div className="accordion-list">
                    {list.length < 1
                        ? createEmpty()
                        : list.map((item, index) => {
                              return (
                                  <Accordion key={item.ds_title} className="accordion-item">
                                      <AccordionSummary
                                          aria-controls={"accordion-content" + index}
                                          id={"accordion-header" + index}
                                          className="accordion-header"
                                      >
                                          <p
                                              className="title"
                                              dangerouslySetInnerHTML={{ __html: item.ds_title }}
                                          ></p>
                                      </AccordionSummary>
                                      <AccordionDetails className="accordion-content">
                                          <p
                                              dangerouslySetInnerHTML={{
                                                  __html: Util.unescapeHtml(item.ds_content),
                                              }}
                                          ></p>
                                          {/* 카테고리 */}
                                          {item.ds_code1 && (
                                              <ul className="category">
                                                  {item.ds_str10 === "nephron" && <li>네프론</li>}
                                                  {item.ds_str10 === "point" && (
                                                      <li>포인트/환전</li>
                                                  )}
                                                  {item.ds_str10 === "user" && <li>회원정보</li>}
                                                  {item.ds_str10 === "culture" && <li>문화사업</li>}
                                                  {item.ds_str10 === "app" && <li>앱 관련</li>}
                                                  {item.ds_str10 === "etc" && <li>기타</li>}
                                              </ul>
                                          )}
                                      </AccordionDetails>
                                  </Accordion>
                              );
                          })}
                </div>

                {limit + offset < totalCount ? (
                    <div className="btn-wrap">
                        <button type="button" className="btn btn-active" onClick={onMore}>
                            더보기
                        </button>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default Faq;
