import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Util from '../../helpers/Util'

export default function BannerModal(props) {

  const [checked, setChecked] = useState(false);

  // 팝업 닫기
  const onClickBannerClose = (idx) => {
    if (checked) {
      window.localStorage.setItem('banner_' + idx, Util.formatDate(new Date()));
    }
    props.bannerClose();
    window.localStorage.setItem('modalPopupState', false);
  }

  // 오늘 하루 그만보기 클릭 핸들러
  const onClickCheckbox = () => {
    setChecked(!checked);
  }

  const onClickPop = (url) => {
    if (url) {
      try{
        window.nativeApi.execute(window.NativeApiMethod.OPEN_BROWSER, {
          url: url
        }, "");
      }catch(err){
        console.error(err)
      }
    }
  }

  return (
    <>
      <Modal
        className="modal modal-banner"
        open={props.banner}
        onClose={props.bannerClose}
        disableRestoreFocus
        disableBackdropClick
      >
        <div className="modal-inner">
          <div className="modal-content" style={{ backgroundImage: `url(${props.item.image_url})` }} onClick={() => onClickPop(`${props.item.link_url}`)}></div>
          <div className="modal-footer btn-wrap">
            <label htmlFor="today_check" className="checkbox">
              <input type="checkbox" name="today_check" id="today_check" onClick={onClickCheckbox} />
              <span className="cover"></span>
              오늘 하루 그만보기
            </label>
            <button type="button" className="btn" onClick={() => onClickBannerClose(props.item.idx)}>닫기</button>
          </div>
        </div>
      </Modal>
    </>
  );
}