import React, { useEffect, useState } from 'react';
import RegistRefundPasswordViewModel from './RegistRefundPasswordViewModel';
import {useHistory , useLocation} from 'react-router-dom';
import style from './RegistRefundPassword.module.css'
import RefundPasswordModule from '../../../Modules/RefundModules/RefundPasswordModule/RefundPasswordModule';
import RefundPasswordVerificationModule from '../../../Modules/RefundModules/RefundPasswordVerificationModule/RefundPasswordVerificationModule';

const RegistRefundPassword = () => {
    const history = useHistory()
    const location = useLocation()
    const { 
        passwordValidate,
        firstPassword,
        secondPassword,
        secondPasswordData,
        firstPasswordData,
        setSecondPasswordData,
        setFirstPasswordData 
    } = RegistRefundPasswordViewModel.SetPasswordValidationHook();
    const { 
        onPostPassword
    } = RegistRefundPasswordViewModel.PostPasswordHook();
    useEffect(()=>{
        if(secondPassword && passwordValidate){
            onPostPassword(secondPassword)
        }
    },[secondPassword])
    return (
    <div className={style["page-start"]}>
        {!firstPassword?
        <RefundPasswordModule 
            title={'환전비밀번호 변경'} 
            description={'포인트 환전 시 사용할 비밀번호를 설정해주세요. (숫자 6자리)'}
            passwordArr={firstPasswordData}
            onClick={function(data){
            if(data === "지우기"){setFirstPasswordData([])}
            else{
                const passwordArr = [...firstPasswordData]
                passwordArr.push(data)
                setFirstPasswordData(passwordArr)
            }}}>
        </RefundPasswordModule>
        :
        <RefundPasswordVerificationModule
            title={'환전비밀번호 변경'} 
            description={'앞서 입력한 숫자 6자리를 동일하게 재입력해주세요'}
            passwordArr={secondPasswordData}
            passwordValidate={passwordValidate}
            onClick={function(data){
            if(data === "지우기"){setSecondPasswordData([])}
            else{
                const passwordArr = [...secondPasswordData]
                passwordArr.push(data)
                setSecondPasswordData(passwordArr)
            }}}>
        </RefundPasswordVerificationModule>
        }
    </div>
    );
};

export default RegistRefundPassword;