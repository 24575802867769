import { useState } from 'react';
import AlertContext from './AlertContext';
import AlertModal from './AlertModal';

const AlertDialog = ({ children }) => {
    const [state, setState] = useState();
  
    const alert = (title ,  subtitle , description ,okBtnTitle) => {
      return new Promise((resolve) => {
      setState({
          title:title ?? '',
          subtitle:subtitle ?? '',
          description:description ?? '',
          okBtnTitle:okBtnTitle ?? '',
          onClose:() => {
              setState(undefined)
              resolve(undefined)
          }
      })
      });
    };
  
    return (
      <AlertContext.Provider value={{ alert }}>
        {children}
        {state && <AlertModal
          title={state.title} 
          okBtnTitle={state.okBtnTitle}
          description={state.description}
          subTitle={state.subtitle}
          onOk={state.onClose} />}
      </AlertContext.Provider>
    );
  };
  
  export default AlertDialog;