import { useEffect, useState } from "react"
import TermsService from "../../services/TermsService";

export default function Service() {
  const [data,setData] = useState()
  useEffect(()=>{
    getData()
  },[])
  const getData = async() => {
    try{
      //temp
      // const resultIdx = await TermsService.getTermsServiceList()
      // const result = await TermsService.getTermsDetail(resultIdx)
      const result = await TermsService.get_term_current({segment : "service"});
      // console.table(result);
      setData(result)
    }catch(err){
      window.alert(err)
      setData("")
    }
  }
  return (
    <>
      <div className="editor" dangerouslySetInnerHTML={{ __html: data?.content }} />
    </>
  );
}