import React from 'react';
import PrivateService from './PrivateService';
const MypageAuthrizationService = {
    getSession : async() =>{
        try{
            await PrivateService.getPrivateVerification()
            return true
        }catch(err){
            window.location.href = '/mypage/input-password'
            return false
        }
    },
};

export default MypageAuthrizationService;