import React from 'react';
import style from './ConfirmModal.module.css'
import ModalBox from '../ModalBox/ModalBox';
import PrimaryButton from '../../CustomiseButton/PrimaryButton/PrimaryButton';
const ConfirmModal = ({title,subTitle,description,okBtnTitle,cancelBtnTitle,onOk,onCancel}) => {
    return (
        <>
        <ModalBox transparent={true}>
          <div className={style["modal"]}>
            <p className={style["title"]}>{title}</p>
            {subTitle ? <p className={style["sub-title"]}>{subTitle}</p>:<></>}
            {description ? <p className={style["description"]}>{description}</p>:<></>}
            <div className={style["btn-box"]}>
              <PrimaryButton 
              type={"ghost"}
              title={cancelBtnTitle} 
              disabled={false} 
              onClick={onCancel}></PrimaryButton>
              <PrimaryButton
              title={okBtnTitle}
              disabled={false} 
              onClick={onOk}></PrimaryButton>
            </div>
          </div>
        </ModalBox>
        </>
    );
};
export default ConfirmModal;