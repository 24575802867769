export const useMyLocation = () => {
  return {
    set_status_to_deivce(device_id, stauts_list) {
      let status = null;
      if (device_id) {
        stauts_list.map((element) => {
          if (device_id === element?.device_id) {
            status = element;
          }
        });
      }
      if (status === null) {
        status = {
          client: null,
          connection: null,
          device_id: null,
          device_status: null,
          status: null,
          storage: [],
        };
      }
      return status;
    },
    set_device_name_by_list(device_list, search_id) {
      let device = null;
      device_list.map((element) => {
        if (element.id === search_id) {
          device = element;
        }
      });
      if (device === null) {
        device = {
          id: null,
          name: null,
        };
      }
      return device;
    },
  };
};
