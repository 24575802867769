import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import Service from "../terms/Service";
import Privacy from "../terms/Privacy";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// export default function 
const TermsDialog = (props) => {
  const { agreeService, agreePrivacy } = props.formData;
  const [open, setOpen] = useState(false);
  const [component, setComponent] = useState('');

  useEffect(() => {
    // Dialog 오픈 후 네이티브 back키 기능 동작 시 Dialog 닫힘 기능
    if (open) {
      try{
        window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {}, window.NativeApiCallbackMethod.IS_LAYER)
        window[window.NativeApiCallbackMethod.IS_LAYER] = function (response) {
          termsClose();
        }
      }catch(err){
        console.error(err)
      }
    }
  }, [open]);

  const btnData = {
    agreeService: false,
    agreePrivacy: false
  }

  const termsOpen = (event) => {
    btnData[event.target.dataset.btn] = true;
    setComponent(btnData);
    setOpen(true);
  };

  const termsClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="check-list">
        <div className={`check-item${agreeService ? " item-active" : ""}`}>
          <label htmlFor="agreeService" className="check-label">
            <input type="checkbox" name="agreeService" id="agreeService" onClick={props.handleChange} />
            <span className="check-text require">서비스 이용약관</span>
          </label>
          <button type="button" className="btn" data-btn="agreeService" onClick={termsOpen}>보기</button>
        </div>

        <div className={`check-item${agreePrivacy ? " item-active" : ""}`}>
          <label htmlFor="agreePrivacy" className="check-label">
            <input type="checkbox" name="agreePrivacy" id="agreePrivacy" onClick={props.handleChange} />
            <span className="check-text require">개인정보 처리방침</span>
          </label>
          <button type="button" className="btn" data-btn="agreePrivacy" onClick={termsOpen}>보기</button>
        </div>
      </div>

      <Dialog className="dialog" fullScreen open={open} onClose={termsClose} >
        <div className="dialog-header">
          <button type="button" className="btn-close" onClick={termsClose}>
            <span className="a11y">닫기</span>
          </button>
          <h2 className="dialog-title">
            {component.agreeService && '서비스 이용약관'}
            {component.agreePrivacy && '개인정보 처리방침'}
          </h2>
        </div>
        <div className="dialog-content">
          <div className="terms-wrap">
            {component.agreeService && <Service />}
            {component.agreePrivacy && <Privacy />}
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default TermsDialog;