import { useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { AppContext } from '../../AppContext';
import Progress from '../../layout/Progress';

const Login = () => {
  const history = useHistory();
  // const { auth } = useContext(AppContext);

  // useEffect(() => {
  //   if (window.nativeApi.isIOS) {
  //     window.nativeApi.execute(window.NativeApiMethod.GET_USER_INFO, {}, window.NativeApiCallbackMethod.GET_USER_INFO);
  //     window[window.NativeApiCallbackMethod.GET_USER_INFO] = function (response) {
  //       if (!response.user_sno) {
  //         auth.logout();
  //       } else {
  //         history.push('/home');
  //       }
  //     }
  //   }
  // }, [auth.user]);

  return (
    <>
      <Progress loading={true} />
    </>
  )
}

export default Login;