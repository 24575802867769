import React from 'react';
import PrivateInsertInfoComponent from '../../common-privateInfo-component/insert-certInfo-component/PrivateInsertInfoComponent';
import AddSubPhoneInsertCertinfoViewModel from './AddSubPhoneInsertCertinfoViewModel';
const AddSubPhoneInsertCertinfo = () => {
    const {postSms} = AddSubPhoneInsertCertinfoViewModel.InsertInfo()
    return (
        <PrivateInsertInfoComponent 
            title={'번호 추가를 위한 본인인증'} 
            description={'본인 명의의 전화번호를 다수 보유하여, 이를 수퍼빈 서비스에 이용하고 싶은 경우 추가할 전화번호와 본인 정보를 기재하여 인증해주세요. (최대 3개까지 등록 가능)'} 
            setData={function (data) {
                postSms(data)
        } }></PrivateInsertInfoComponent>
    );
};

export default AddSubPhoneInsertCertinfo;