import { useContext } from "react";
import axios from "axios";
import { Route, Redirect, useLocation } from "react-router-dom";
import ReactCookies from 'react-cookie';
import { AppContext } from '../../AppContext';
import { useEffect } from "react";
import {Cookies} from 'react-cookie'
import Progress from '../../layout/Progress';

const PrivateRoute = ({ ...rest }) => {
  const { auth } = useContext(AppContext);
  const location = useLocation();  
  return (
    <>
      {/*로그 확인용*/}
      {/* {window.nativeApi.execute(window.NativeApiMethod.CHANGE_URL_WEB_VIEW,{url:JSON.stringify(rest) + "202201_PrivateRoute"})} */}
      {/* auth.user값이 없으면 Progress Loading Bar 돌리고 있고 들어오면 들어온 링크로 날림 */}
      {auth.user ? <Route {...rest} /> : <Progress loading={true}/> }
    </>
  );
}

export default PrivateRoute;