import React, { useContext, useState , useEffect } from 'react';
import PrivateService from '../../../services/PrivateService';
import { useLocation ,useHistory} from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import AlertContext from '../../../layout/modal/CustomiseModal/AlertModal/AlertContext';
import MemberService from '../../../services/MemberService';
import ConfirmContext from '../../../layout/modal/CustomiseModal/ConfirmModal/ConfirmContext';

const CancelAffliateServiceViewModel =  {
    SetDisplayStatusHook : () => {
        const history = useHistory()
        const {confirm: confirmComp } = useContext(ConfirmContext);
        const {alert: alertComp } = useContext(AlertContext);
        const { auth } = useContext(AppContext)
        const [userData,setUserData] = useState({name:''})
        const [rookieGet,setRookieGet] = useState(undefined)
        const [moreGet,setMoreGet] = useState(undefined)
        const [display, setValue] = useState({
            superbinMore : {
                display : false,
                idx : undefined,
                consent_at : ''
            },
            superbinRookie : {
                display: false,
                idx : undefined,
                consent_at : ''
            }
        });
        const getUserInfo = async() => {
            try{
                const result = await MemberService.getUserData()
                setUserData({
                    name : result.nickname
                })
                const serviceDisplayStatus = {...display}
                const more = await getMoreData()
                if(more){
                    serviceDisplayStatus.superbinMore = more
                }
                const rookie = await getRookieData()
                if(rookie?.display){
                    serviceDisplayStatus.superbinRookie = rookie
                }
                setValue(serviceDisplayStatus)
            }catch(err){
                await alertComp('알림','서버 오류로 인해 정보 불러오기에 실패하였습니다. 다시 시도해주세요',undefined,'메인으로 돌아가기')
                history.replace('/')
            }

        }
        const getRookieData = async() => {
            let rookieServiceStatus = {
                display: false,
                idx : undefined,
                consent_at : ''
            }
            try{
                const result = await MemberService.getRookieServiceData()
                if(result.terms){
                    result.terms.forEach((element) => {
                        if(element.terms_code === "001"){
                            rookieServiceStatus = {
                                display:true,
                                idx:element.idx,
                                consent_at:element.consent_at ?? '확인불가'
                            }
                        }
                    });
                }
                setRookieGet(true)
                return rookieServiceStatus
            }catch(err){
                if(err.response?.status === 404){
                    console.error(err)
                    setRookieGet(true)
                }else{
                    history.back()
                }
            }
        }
        const getMoreData = async() => {
            let moreServiceStatus = {
                display : false,
                idx : undefined,
                consent_at : ''
            }
            try{
                const result = await MemberService.getMoreServiceData()
                if(result.is_used){
                    moreServiceStatus = {
                        display:true,
                        idx:result.member.id ?? '확인불가',
                        consent_at:result.member.certification_at ?? '확인불가'
                    }
                }
                setMoreGet(true)
                return moreServiceStatus
            }catch(err){
                history.back()
            }
        }
        useEffect(()=>{
            getUserInfo()
            getRookieData()
            getMoreData()
        },[])
        return { userData,display ,rookieGet,moreGet};
    },
    
    SetWithdrawServiceHook : () => {
        const {alert: alertComp } = useContext(AlertContext);
        const [superbinServiceStatus, setSuperbinService] = useState({
            superbinMoreDisabled : false,
            superbinRookieDisabled : false
        });
        useEffect(()=>{
            const serviceStatus = {            
                superbinMoreDisabled : false,
                superbinRookieDisabled : false
            }
            setSuperbinService(serviceStatus)
        },[])
        const setSuperbinMoreStatus = async(idx) => {
            try{
                const result = await MemberService.deleteMoreService()
                const superbinSerivceData = {...superbinServiceStatus}
                superbinSerivceData.superbinMoreDisabled = true
                setSuperbinService(superbinSerivceData)
            }catch(err){
                alertComp('알림','서버 오류로 인해 탈퇴에 실패하였습니다. 다시 시도해주세요',undefined,'확인')
            }
        };
        const setSuperbinRookieStatus = async(idx) => {
            try{
                const result = await MemberService.deleteRookieService()
                const superbinSerivceData = {...superbinServiceStatus}
                superbinSerivceData.superbinRookieDisabled = true
                setSuperbinService(superbinSerivceData)
            }catch(err){
                alertComp('알림','서버 오류로 인해 탈퇴에 실패하였습니다. 다시 시도해주세요',undefined,'확인')
            }
        };
        return { superbinServiceStatus, setSuperbinMoreStatus , setSuperbinRookieStatus };
    }
};

export default CancelAffliateServiceViewModel;