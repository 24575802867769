import style from './LoadingModal.module.css'
import ModalBox from '../ModalBox/ModalBox';
import LoadingSpinner from '../../../../assets/animation/loading/LoadingSpinner'
const LoadingModal = () => {
    return (
        <>
        <ModalBox transparent={true}>
            <div className={style["root"]}>
                <LoadingSpinner />
                <p className={style["loading-text"]}>계정 통합 중</p>
            </div>
        </ModalBox>
        </>
    );
};

export default LoadingModal;