import React from "react";
import {Route, Switch, Redirect } from "react-router-dom";

import PrivateRoute from './route/PrivateRoute';

import Loaction from '../pages/location/Location';

function LocationRouter() {
  return (
    <Switch>
      <Route path="/location" component={Loaction} />
      <Route path="/location" exact component={Loaction} />
      <Redirect path="/location/*" to="/location" />
    </Switch>
  );
}

export default LocationRouter;
