import create from 'zustand'
const GuardianCertificationStore = create(set => ({
    data: {
        name: "",
        phone: "",
        telecom: undefined,
        idCardFront: "",
        sexNumber: undefined,
        phoneHeader: "010",
        birthday: "",
        request_seq_code: undefined,
        response_seq_code: undefined,
    },
    setData: (set_data) => set({ data: set_data })
}));

export default GuardianCertificationStore;