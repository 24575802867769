import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import moment from 'moment';
import UserService from '../../services/UserService';

export default function PushModal(props) {
  const [push, setPush] = useState(false);  // 푸시 알림 수신 여부
  const [agree, setAgree] = useState(false);  // 푸시 알림 수신 동의
  const [disagree, setDisagree] = useState(false);  // 푸시 알림 수신 거절
  const year = moment().format('YYYY');
  const month = moment().format('MM');
  const day = moment().format('DD');

  useEffect(() => {
    pushCheck();
  }, []);

  const pushCheck = () => {
    setPush(true);
  }

  // 동의
  const onPushAgree = () => {
    let formData = new FormData();
    formData.append('noti_all_yn', '1');
    formData.append('noti_bm_yn', '1');
    setAppNotificationSetting(true, formData);  // 설정 변경
  }

  // 거부
  const onPushDisagree = () => {
    let formData = new FormData();
    formData.append('noti_all_yn', '0');
    formData.append('noti_bm_yn', '0');
    setAppNotificationSetting(false, formData);  // 설정 변경
  }

  // 설정 변경
  const setAppNotificationSetting = async (isAgree, data) => {
    // window.nativeApi.execute(window.NativeApiMethod.CHANGE_URL_WEB_VIEW,{url:isAgree + data[0] + "setAppNotificationSetting"});
    try {
      await UserService.updateAppNotificationSetting(data);
      props.auth.user.is_first = false;
      setPush(false);
      if (isAgree) {
        setAgree(true);
      } else {
        setDisagree(true);
      }

    } catch (error) {
      // window.nativeApi.execute(window.NativeApiMethod.CHANGE_URL_WEB_VIEW,{url:JSON.stringify(error.response.data) + "setAppNotificationSetting"});
      const error_code = error.response.data.error_code;
      const message = error.response.data.message;
      if (error_code < 0) {
        alert(message);
      }
    } finally {
    }
  }

  return (
    <>
      <Modal
        className="modal modal-confirm"
        open={props.open}
        onClose={props.modalClose}
        disableRestoreFocus
        disableBackdropClick
      >
        <div className="modal-inner">
          {push &&
            <>
              <div className="modal-header">
                <header>
                  <p><em>‘수퍼빈’</em>에서 알림을 보내고자 합니다.</p>
                </header>
              </div>

              <div className="modal-content">
                <p>경고, 사운드 및 아이콘 배지가 알림에 포함될 수 있습니다. 설정에서 이를 구성할 수 있습니다.</p>
              </div>
            </>
          }

          {agree &&
            <>
              <div className="modal-header">
                <header>
                  <p>Push 수신 동의</p>
                </header>
              </div>

              <div className="modal-content">
                <p>Push 알림 수신을 동의하셨습니다.<br />(동의 날짜 : {year}년 {month}월 {day}일)</p>
              </div>
            </>
          }

          {disagree &&
            <>
              <div className="modal-header">
                <header>
                  <p>Push 수신 거절</p>
                </header>
              </div>

              <div className="modal-content">
                <p>Push 알림 수신을 거절하셨습니다.<br />(동의 날짜 : {year}년 {month}월 {day}일)</p>
              </div>
            </>
          }
          <div className="modal-footer btn-wrap">
            {push ?
              <>
                <button type="button" className="btn" onClick={onPushDisagree}>거부</button>
                <button type="button" className="btn" onClick={onPushAgree}>허용</button>
              </>
              : <button type="button" className="btn" onClick={props.modalClose}>확인</button>
            }
          </div>
        </div>
      </Modal>
    </>
  );
}