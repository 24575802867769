import React from 'react';

const RecertificationTermsViewModel =  {
    checkAllChecked : (data) => {
        var checker = true
        if(!data.privacy){checker = false}
        if(!data.service){checker = false}
        if(!data.telecom){checker = false}
        if(!data.identification){checker = false}
        return checker
    }
};

export default RecertificationTermsViewModel;