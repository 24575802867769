import React from 'react';
import style from './PrimaryButton.module.css'
import Button from '../Button/Button';

const PrimaryButton = ({title,type,disabled,onClick}) => {
    return (
        <div className={`${style["button-box"]} ${style[String(type ? type : 'primary')]}`}>
            <Button title={title} onClick={onClick} disabled={disabled}></Button>
        </div>
    );
};

export default PrimaryButton;