import React, { useContext, useEffect, useState } from 'react';
import { useHistory , useLocation} from 'react-router-dom';
import DefaultInput from '../../../Modules/MyPageModules/Private/DefaultInput/DefaultInput';
import style from './InsertCertificationInfo.module.css'
import InsertCertificationInfoViewModel from './InsertCertificationInfoViewModel';
import IDCardInput from '../../../Modules/MyPageModules/Private/IDCardInput/IDCardInput';
import PhoneInput from '../../../Modules/MyPageModules/Private/PhoneInput/PhoneInput'
import WidthFullBtn from '../../../Modules/Buttons/widthFullBtn/WidthFullBtn';
import TelecomInput from '../../../Modules/MyPageModules/Private/TelecomInput/TelecomInput';
import AlertContext from '../../../layout/modal/CustomiseModal/AlertModal/AlertContext';
const InsertCertificationInfo = () => {
    const history = useHistory()
    const location = useLocation()
    const {alert : alertComp} = useContext(AlertContext)
    const [bodyData,setBodyData] = useState({
        name: "",
        phone: "",
        telecom: undefined,
        idCardFront: "",
        sexNumber: undefined,
        phoneHeader: "010",
        birthday: "",
        request_seq_code: undefined,
        response_seq_code: undefined
    })
    useEffect(()=>{
        if(bodyData.idCardFront && bodyData.sexNumber){
            const birthday = InsertCertificationInfoViewModel.birthDayReplace(bodyData.idCardFront,bodyData.sexNumber)
            const data = {...bodyData}
            data.birthday = birthday
            setBodyData(data)
        }
    },[bodyData.idCardFront, bodyData.sexNumber])
    const routePushValidation = async() => {
        try{
            const result = await InsertCertificationInfoViewModel.postSms(bodyData)
            const body = {
                phone : bodyData.phoneHeader + bodyData.phone?.replace('-',''),
                name : bodyData.name,
                rr_number : bodyData.idCardFront + bodyData.sexNumber,
                request_seq_code : result.request_seq_code,
                response_seq_code : result.response_seq_code,
                telco : bodyData.telecom
            }
            history.replace({
                pathname:'verificate-code',query:body 
            },'verificate-code')
        }catch(err){
            console.error(err)
            if(err.response?.status === 403){
                await alertComp('본인 정보가 아닙니다', '로그인한 아이디의 개인 정보를 입력해주세요. 만약 번호, 이름이 변경된 경우 1600-6217 혹은 카카오톡 문의하기로 문의 바랍니다.',undefined,'확인')
            }else{
                alertComp('알림','서버 오류로 인해 인증에 실패하였습니다. 다시 시도해주세요',undefined,'확인')
                history.replace('/')
            }
        }
    }
    return (
        <div className={style['page-start']}>
            <div className={style['root']}>
                <div>
                    <p className={style['title']}>수퍼빈 회원 인증</p>
                    <p className={style['description']}>이름, 생년월일, 통신사, 휴대전화 번호를 순서대로 입력하세요.</p>
                    <div className={style['item']}>
                        <DefaultInput type='text' title={'이름'} holder={'이름을 입력해주세요'} value={bodyData.name}
                        InputData={function (value) {
                            const data = {...bodyData}
                            data.name = (value).replace(/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi,'')
                            setBodyData(data)
                        } } description={'본인 이름을 한글 또는 영문으로 입력해주세요. (통신사 가입 명의)'} 
                        onFocus={function () {
                        } } onBlur={function () {
                            const data = {...bodyData}
                            const checkedData = InsertCertificationInfoViewModel.checkLocalString(data.name)
                            if(checkedData){
                                data.name = checkedData
                                setBodyData(data)
                            }else{
                                data.name = ""
                                setBodyData(data)
                                alertComp('알림','이름을 정확히 입력해주세요',undefined,'확인')
                            }
                        } }></DefaultInput>
                    </div>
                    <div className={style['item']}>
                        <IDCardInput title={'생년월일/성별'} holder={'생년월일 앞자리 입력'} value={bodyData.idCardFront} type={'text'} description={'주민번호 앞 번호(생년월일), 뒷 번호(성별) 첫 자리까지만 입력해주세요.'}
                        onFocus={function () {
                        } } onBlur={function () {
                        } } InputDataFront={function (value) {
                            const data = { ...bodyData };
                            data.idCardFront = value;
                            data.idCardFront = data.idCardFront.replace(/[^0-9]/g, '');
                            data.idCardFront = data.idCardFront.replace(/^(\d{3,4})(\d{4})$/, `$1-$2`);
                            if (data.idCardFront.length < 7) {
                                setBodyData(data);
                            }
                        } } InputDataBack={function (value) {
                            const data = { ...bodyData };
                            data.sexNumber = value
                            setBodyData(data)
                        } }></IDCardInput>
                    </div>
                    <div className={`${style['item']}`}>
                        <TelecomInput value={bodyData.telecom} InputData={function (value){
                            const data = {...bodyData}
                            data.telecom = value
                            setBodyData(data)
                        } }></TelecomInput>
                    </div>
                    <div className={`${style['item']}`}>
                        <PhoneInput phoneHeader={''} phoneBody={bodyData.phone} 
                        setPhoneHeader={function (dataValue) {
                            const data = { ...bodyData };
                            data.phoneHeader = dataValue
                            setBodyData(data)
                        } } setPhoneBody={function (dataValue) {
                            const data = {...bodyData}
                            data.phone = dataValue
                            setBodyData(data)
                        } } description={'휴대전화 번호는 숫자로만 입력해주세요.'}></PhoneInput>
                    </div>
                </div>
                <div className={style['validate-end']}>
                    <WidthFullBtn onClick={function () {
                        routePushValidation()
                    } } title={'인증 문자 받기'} 
                    disabled={bodyData.name && bodyData.phone && bodyData.birthday && bodyData.idCardFront && bodyData.sexNumber ? false:true}></WidthFullBtn>
                </div>
            </div>
        </div>
    );
};

export default InsertCertificationInfo;