
import axios, { AxiosError } from 'axios';
import { Cookies } from 'react-cookie'
import SessionHelper from '../SessionHelper'
import { API_BFF_APP_URL } from '../../config';
import AuthService from '../../services/AuthService';
const AuthHttpHelper = {
  authrizeAccessToken: async () => {
    const cookies = new Cookies();
    const token = cookies.get("token")
    const instance = axios.create({
        baseURL : API_BFF_APP_URL,
        headers : {
            Authorization: `Bearer ${token}`
        }
    })
    try{
        const response = await instance.get("/me")
        return response.data
    }catch(error){
        if(error.response?.status === 401){
            const data = await AuthHttpHelper.getTokenByrefreshToken()
            if(data.access_token){
                const refreshInstance = axios.create({
                  baseURL : API_BFF_APP_URL,
                  headers : {
                      Authorization: `Bearer ${data.access_token}`
                  }
              })
                const response = await refreshInstance.get("/me")
                return response.data
            }else{
              AuthHttpHelper.authrizationFailed()
            }
        }else{
            throw error
        }
    }
  },
  authrizationFailed: async () => {
    alert("로그아웃 되었습니다. 다시 로그인 해주세요");
    try{
      window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
    }catch(err){
      console.error(err)
    }
  },
  getTokenByrefreshToken: async () => {
    const cookies = new Cookies();
    const refresh_token = cookies.get("refresh_token")
    const refreshCall = axios.create({
        baseURL : API_BFF_APP_URL,
        headers : {
            Authorization: `Bearer ${refresh_token}`,
        }
    })
    try{
        const response = await refreshCall.post('/auth/refresh')
        AuthService.setAccessTokenByRefreshToken(response.data.access_token)
        return response.data
    }catch(error){
        return false
    }
  },
  validatePrivateToken: async () => {
    const sessionStorageValue = SessionHelper.getSession()
    const token = sessionStorageValue ? sessionStorageValue : ''
    const instance = axios.create({
        baseURL : API_BFF_APP_URL,
        headers : {
            Authorization: `Bearer ${token}`
        }
    })
    try{
        const response = await instance.post('/privacy-auth/verify')
        return response
    }catch(error){
        throw error
    }
  },
  get: async (url) => {
    const sessionStorageValue = SessionHelper.getSession()
    const token = sessionStorageValue ? sessionStorageValue : ''
    const instance = axios.create({
        baseURL : API_BFF_APP_URL,
        headers : {
            Authorization: `Bearer ${token}`,
        }
    })
    try{
        const response = await instance.get(url)
        return response.data
    }catch(error){
        if(error instanceof AxiosError){
            if(error.response?.status === 401){
              window.location.href = '/mypage/input-password'
            }else{
                throw error
            }
        } else {
            throw error
        }
    }
  },
  post: async (url, data) => {
    const sessionStorageValue = SessionHelper.getSession()
    const token = sessionStorageValue ? sessionStorageValue : ''
    const instance = axios.create({
        baseURL : API_BFF_APP_URL,
        headers : {
            Authorization: `Bearer ${token}`,
        }
    })
    try{
        const response = await instance.post(url,data)
        return response.data
    }catch(error){
      if(error instanceof AxiosError){
          if(error.response?.status === 401){
            window.location.href = '/mypage/input-password'
          }else{
              throw error
          }
      } else {
        throw error
      }
    }
  },
  put: async (url, data) => {
    const sessionStorageValue = SessionHelper.getSession()
    const token = sessionStorageValue ? sessionStorageValue : ''
    const instance = axios.create({
        baseURL : API_BFF_APP_URL,
        headers : {
            Authorization: `Bearer ${token}`,
        }
    })
    try{
        const response = await instance.put(url,data)
        return response.data
    }catch(error){
      if(error instanceof AxiosError){
        if(error.response?.status === 401){
          window.location.href = '/mypage/input-password'
        }else{
            throw error
        }
      } else {
        throw error
      }
    }
  },
  delete: async (url) => {
    const sessionStorageValue = SessionHelper.getSession()
    const token = sessionStorageValue ? sessionStorageValue : ''
    const instance = axios.create({
        baseURL : API_BFF_APP_URL,
        headers : {
            Authorization: `Bearer ${token}`,
        }
    })
    try{
        const response = await instance.delete(url)
        return response.data
    }catch(error){
      if(error instanceof AxiosError){
        if(error.response?.status === 401){
          window.location.href = '/mypage/input-password'
        }else{
            throw error
        }
      } else {
        throw error
      }
    }
  },
  deleteWithBody: async (url,body) => {
    const sessionStorageValue = SessionHelper.getSession()
    const token = sessionStorageValue ? sessionStorageValue : ''
    const instance = axios.create({
        baseURL : API_BFF_APP_URL,
        data:body,
        headers : {
            Authorization: `Bearer ${token}`,
        }
    })
    try{
        const response = await instance.delete(url)
        return response.data
    }catch(error){
      if(error instanceof AxiosError){
        if(error.response?.status === 401){
          window.location.href = '/mypage/input-password'
        }else{
            throw error
        }
      } else {
        throw error
      }
    }
  },
};

export default AuthHttpHelper;
