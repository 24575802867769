import React, { useEffect, useState } from 'react';
import { RefundCryptoAES } from '../../../helpers/CryptoHelper'
import { useHistory } from 'react-router-dom';
import PrivateService from '../../../services/PrivateService'

const RegistRefundPasswordViewModel = {
    SetPasswordValidationHook : () => {
        const [firstPassword,setFirstPassword] = useState("")
        const [secondPassword,setSecondPassword] = useState("")
        const [secondPasswordData,setSecondPasswordData] = useState([])
        const [firstPasswordData,setFirstPasswordData] = useState([])
        const [passwordValidate,setPasswordValidate] = useState(true)
        useEffect(()=>{
            setFirstPassword(onSetFirstValue(firstPasswordData))
            setSecondPassword(onSetSecondValue(secondPasswordData))
        },[secondPasswordData,firstPasswordData])
        useEffect(()=>{
            const secondArr = [...secondPasswordData]
            var secondArrString = ""
            secondArr.forEach(element => {
                secondArrString = secondArrString + element
            });
            if(secondArrString){
                if(firstPassword === secondArrString){
                    setPasswordValidate(true)
                }else{
                    setPasswordValidate(false)
                }
            }
        },[secondPasswordData])
        const onSetFirstValue = (firstPasswordArr) => {
            if(firstPasswordArr.length < 6){
                const newArr = [...firstPasswordArr]
                return ""
            }else if(firstPasswordArr.length === 6){
                const newArr = [...firstPasswordArr]
                var firstInputString = ""
                newArr.forEach(element => {
                    firstInputString = firstInputString + element
                });
                return firstInputString
            }
        }
        const onSetSecondValue = (secondPasswordArr) => {
            if(secondPasswordArr.length < 6){
                const newArr = [...secondPasswordArr]
                return ""
            }else if(secondPasswordArr.length === 6){
                const newArr = [...secondPasswordArr]
                var secondInputString = ""
                newArr.forEach(element => {
                    secondInputString = secondInputString + element
                });
                return secondInputString
            }
        }
        return { passwordValidate,firstPassword,secondPassword,secondPasswordData,firstPasswordData,setSecondPasswordData,setFirstPasswordData };
    },
    PostPasswordHook : () => {
        const history = useHistory()
        const onPostPassword = async(password) => {
            try{
                const body = {
                    password: RefundCryptoAES.encrypt(password)
                }
                const result = await PrivateService.postRefundPassword(body)
                history.replace('regist-refund-password/done')
                return result
            }catch(err){
                return err
            }
        }
        return { onPostPassword };
    },
};

export default RegistRefundPasswordViewModel;