import React from 'react';
import Modal from '@material-ui/core/Modal';

export default function JoinModal(data) {
  return (
    <>
      <Modal
        className="modal modal-bottom"
        open={data.open}
        onClose={data.modalClose}
        disableRestoreFocus
        disableBackdropClick
      >
        <div className="modal-inner">
          {data.userBlock &&
            <>
              <div className="modal-header">
                <header>
                  <p>Block 회원</p>
                </header>
              </div>

              <div className="modal-content">
                <p>인증한 정보는 서비스 이용이 차단된 회원으로<br /> 가입이 불가능합니다.</p>
                <p>
                  <em>문의처 : 1600-6217</em><br />
                  (평일 10:00 ~ 18:00, 점심시간 12:30 ~ 13:30)
                </p>
              </div>
            </>
          }

          {data.userAlready &&
            <>
              <div className="modal-header">
                <header>
                  <p>이미 가입된 회원</p>
                </header>
              </div>

              <div className="modal-content">
                <p>
                  아이디/비밀번호를 분실하셨다면<br />
                  <em>[아이디 찾기] [비밀번호 찾기]</em>를 이용해주세요.</p>
              </div>
            </>
          }
          <div className="modal-footer btn-wrap">
            <button type="button" className="btn btn-active" onClick={data.modalClose}>확인</button>
          </div>
        </div>
      </Modal>
    </>
  );
}