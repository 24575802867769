import React, { useEffect, useState } from 'react';
import {useHistory} from 'react-router-dom';
import style from './RefundPasswordVerificationModule.module.css'
import RefundPasswordVerificationModuleModelView from './RefundPasswordVerificationModuleModelView';
import { RefundPasswordExitIcon } from '../../../assets/icons/SVG';
const RefundPasswordVerificationModule = ({passwordArr,title, description , passwordValidate , onClick}) => {
    const history = useHistory()
    const [randoms,setRandoms] = useState([])
    useEffect(()=>{
        randomNumber()
    },[])
    const randomNumber = () => {
        const randomNumber = RefundPasswordVerificationModuleModelView.numberReload()
        setRandoms(randomNumber)
    }
    return (
            <div className={style.root}>
                <div className={style["password-header"]}>
                    <div className={style.exit}>
                        <div className={style['exit-box']} onClick={()=>{history.goBack()}}>
                            <RefundPasswordExitIcon></RefundPasswordExitIcon>
                        </div>
                    </div>
                    <div className={style.title}>{title}</div>
                    <div className={style["sub-title-refund"]}>{description}</div>
                    <div className={style["input-view-info"]}>{passwordValidate ? <></>:<p>앞서 입력한 번호와 일치하지 않습니다.</p>}</div>
                    <ul className={style["input-view-password"]}>
                        <li><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="7" fill={passwordArr[0]=== undefined? "#61ACFE":"white"}/></svg></li>
                        <li><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="7" fill={passwordArr[1]=== undefined? "#61ACFE":"white"}/></svg></li>
                        <li><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="7" fill={passwordArr[2]=== undefined? "#61ACFE":"white"}/></svg></li>
                        <li><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="7" fill={passwordArr[3]=== undefined? "#61ACFE":"white"}/></svg></li>
                        <li><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="7" fill={passwordArr[4]=== undefined? "#61ACFE":"white"}/></svg></li>
                        <li><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="7" fill={passwordArr[5]=== undefined? "#61ACFE":"white"}/></svg></li>
                    </ul>
                </div>
                <ul className={style["password-footer"]}>
                    {randoms.map((item,index)=>{
                        return(
                            <li onClick={()=>{
                                if(item === "재배열"){randomNumber()}
                                else{onClick(item)}
                            }} className={index == 9 || index == 11? style["password-footer-not-numbers"]:style["password-footer-numbers"]} key={item}>{item}</li>
                        )
                    })}
                </ul>
            </div>  
    );
};

export default RefundPasswordVerificationModule;