// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Point_point-item-description__SYooQ{\n    font-size: 13px;\n    line-height: 16px;\n    font-weight: 400;\n    padding: 9px 0px 31px 19px;\n}", "",{"version":3,"sources":["webpack://src/pages/point/Point.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,0BAA0B;AAC9B","sourcesContent":[".point-item-description{\n    font-size: 13px;\n    line-height: 16px;\n    font-weight: 400;\n    padding: 9px 0px 31px 19px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"point-item-description": "Point_point-item-description__SYooQ"
};
export default ___CSS_LOADER_EXPORT___;
