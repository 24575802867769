import React, { useContext, useEffect } from 'react';
import style from './PrivateInsertInfoComponent.module.css'
import PrivateInsertInfoComponentViewModel from './PrivateInsertInfoComponentViewModel';
import DefaultInput from '../../../../../Modules/MyPageModules/Private/DefaultInput/DefaultInput';
import IDCardInput from '../../../../../Modules/MyPageModules/Private/IDCardInput/IDCardInput';
import TelecomInput from '../../../../../Modules/MyPageModules/Private/TelecomInput/TelecomInput';
import PhoneInput from '../../../../../Modules/MyPageModules/Private/PhoneInput/PhoneInput';
import WidthFullBtn from '../../../../../Modules/Buttons/widthFullBtn/WidthFullBtn';
import AlertContext from '../../../../../layout/modal/CustomiseModal/AlertModal/AlertContext';
const PrivateInsertInfoComponent = ({title , description , setData}) => {
    const {bodyData,setBodyData,checkLocalString} = PrivateInsertInfoComponentViewModel.PrivateInsertInfoHook()
    const {alert : alertComp} = useContext(AlertContext)

    return (
        <div className={style['page-start']}>
            <div className={style['root']}>
                <div>
                    <p className={style['title']}>{title}</p>
                    <p className={style['description']}>{description}</p>
                    <div className={style['item']}>
                        <DefaultInput type='text' title={'이름'} holder={'이름을 입력해주세요'} value={bodyData.name}
                        InputData={function (value) {
                            const data = {...bodyData}
                            data.name = (value).replace(/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi,'')
                            setBodyData(data)
                        } } description={'본인 이름을 한글 또는 영문으로 입력해주세요. (통신사 가입 명의)'} 
                        onFocus={function () {
                        } } onBlur={function () {
                            const data = {...bodyData}
                            const checkedData = checkLocalString(data.name)
                            if(checkedData){
                                data.name = checkedData
                                setBodyData(data)
                            }else{
                                data.name = ""
                                setBodyData(data)
                                alertComp('알림', '이름을 정확히 입력해주세요.',undefined,'확인')
                            }
                        } }></DefaultInput>
                    </div>
                    <div className={style['item']}>
                        <IDCardInput title={'생년월일/성별'} holder={'생년월일 앞자리 입력'} value={bodyData.idCardFront} type={'text'} description={'주민번호 앞 번호(생년월일), 뒷 번호(성별) 첫 자리까지만 입력해주세요.'}
                        onFocus={function () {
                        } } onBlur={function () {
                        } } InputDataFront={function (value) {
                            const data = { ...bodyData };
                            data.idCardFront = value;
                            data.idCardFront = data.idCardFront.replace(/[^0-9]/g, '');
                            data.idCardFront = data.idCardFront.replace(/^(\d{3,4})(\d{4})$/, `$1-$2`);
                            if (data.idCardFront.length < 7) {
                                setBodyData(data);
                            }
                        } } InputDataBack={function (value) {
                            const data = { ...bodyData };
                            data.sexNumber = value
                            setBodyData(data)
                        } }></IDCardInput>
                    </div>
                    <div className={`${style['item']}`}>
                        <TelecomInput value={bodyData.telecom} InputData={function (value) {
                            const data = {...bodyData}
                            data.telecom = value
                            setBodyData(data)
                        } }></TelecomInput>
                    </div>
                    <div className={`${style['item']} ${style['telecom']}`}>
                        <PhoneInput phoneHeader={''} phoneBody={bodyData.phone} 
                        setPhoneHeader={function (dataValue) {
                            const data = { ...bodyData };
                            data.phoneHeader = dataValue
                            setBodyData(data)
                        } } setPhoneBody={function (dataValue) {
                            const data = {...bodyData}
                            data.phone = dataValue
                            setBodyData(data)
                        } } description={'휴대전화 번호는 숫자로만 입력해주세요.'}></PhoneInput>
                    </div>
                </div>
                <div className={style['validate-end']}>
                    <WidthFullBtn onClick={function () {
                        setData(bodyData)
                    } } title={'인증 문자 받기'} 
                    disabled={bodyData.name && bodyData.phone && bodyData.birthday && bodyData.idCardFront && bodyData.sexNumber ? false:true}></WidthFullBtn>
                </div>
            </div>
        </div>
    );
};

export default PrivateInsertInfoComponent;