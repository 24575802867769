import React from 'react';
import PrivateInsertInfoComponent from '../../common-privateInfo-component/insert-certInfo-component/PrivateInsertInfoComponent';
import ChangeNameInsertCertinfoViewModel from './ChangeNameInsertCertinfoViewModel';
const ChangeNameInsertCertinfo = () => {
    const {postSms} = ChangeNameInsertCertinfoViewModel.InsertInfo()
    return (
        <PrivateInsertInfoComponent 
            title={'이름 변경을 위한 본인인증 (개명 신청)'} 
            description={'사용하고 계신 통신사에 먼저 개명이 완료되어있어야 합니다. 통신사에 개명이 완료되었다면 개명한 이름과 본인 정보를 기재하여 인증해주세요.'} 
            setData={function (data) {
                postSms(data)
        } }></PrivateInsertInfoComponent>
    );
};

export default ChangeNameInsertCertinfo;