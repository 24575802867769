import React, { useState } from 'react';
import style from './SexSelector.module.css'
const SexSelector = (props) => {
    return (
        <div className={style["input-box"]}>
            <p className={style.title}>{props.title}</p>
            <div>
                <button className={props.data === "M"? style["selected"]:style["not-selected"]} onClick={()=>{
                    props.InputData("M")
                    }}>남성</button>
                <button className={props.data === "F"? style["selected"]:style["not-selected"]} onClick={()=>{props.InputData("F")}}>여성</button>
                <button className={props.data === "N" || props.data === null? style["selected"]:style["not-selected"]} onClick={()=>{props.InputData("N")}}>선택 안함</button>
            </div>
        </div>
    );
};

export default SexSelector;