import React, { useEffect, useRef } from 'react';
import { IdCardSecretImg } from '../../../../assets/icons/SVG';
import style from './IDCardInput.module.css'
const IDCardInput = (props) => {
    const sexNumRef = useRef("")
    useEffect(()=>{
        if(props.value.length >= 6){
            sexNumRef.current.focus()
        }
    },[props.value])
    return (
        <div className={style["input-box"]}>
            <p className={style.title}>{props.title}</p>
            <div className={style["id-card-box"]}>
                <input type={props.type}
                    placeholder={props.holder} 
                    value={props.value}
                    onFocus={()=>{props.onFocus()}}
                    onBlur={()=>{props.onBlur()}}
                    onChange={(e)=>{
                        props.InputDataFront(e.target.value)
                        }} className={style.title}>
                </input>
                <div className={style["id-card-backword"]}>
                    <p>-</p>
                    <input ref={sexNumRef} type="number" placeholder={"1"} pattern='\d*' maxLength={1} onChange={(e)=>{
                        if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(0, e.target.maxLength);
                        props.InputDataBack(e.target.value)
                    }}>
                    </input>
                    <IdCardSecretImg></IdCardSecretImg>
                    <IdCardSecretImg></IdCardSecretImg>
                    <IdCardSecretImg></IdCardSecretImg>
                    <IdCardSecretImg></IdCardSecretImg>
                    <IdCardSecretImg></IdCardSecretImg>
                </div>        
            </div>
            {props.description ? <div className={style["description"]}>{props.description}</div>:<></>}
        </div>
    );
};

export default IDCardInput;