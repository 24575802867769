import React, { useState } from 'react';
import style from './InputAddress.module.css'
import PostCode from './PostCode/PostCode';
const InputAddress = (props) => {
    const [postModale,setPostModal] = useState(false)
    return (
        <div className={style["input-box"]}>
            <p className={style.title}>{props.title}</p>
            <div className={style["address-input"]}>
                <div>
                    <input onClick={()=>{setPostModal(true)}} placeholder={props.holder} value={props.value? props.value:""} className={style.title} disabled></input>
                    <hr></hr>
                </div>
                <button onClick={()=>{setPostModal(true)}}>주소검색</button>
            </div>
            <div>  
                <input placeholder={props.subholder} value={props.subValue}
                onClick={()=>{
                    if(!props.value){
                    alert('[주소검색] 버튼을 눌러 주소를 선택해주세요.')
                    document.activeElement.blur()
                }}}
                onChange={(e)=>{
                    if(props.value){
                        const value = e.target.value
                        props.InputSubAdress(value)
                    }
                }} className={style["address-sub-input"]}></input>
            </div>
            <hr></hr>
            {postModale? 
            <PostCode setPostCodeValue={function (address, code) {
                    props.InputAdress(address, code);
                    setPostModal(false);
                } } onClose={function () {
                    setPostModal(false)
                } }></PostCode>:<></>}

        </div>
    );
};

export default InputAddress;