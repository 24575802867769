import React, { useEffect, useState } from 'react';
const PrivateInsertInfoComponentViewModel =  {
    PrivateInsertInfoHook : () => {
        const [bodyData,setBodyData] = useState({
            name: "",
            phone: "",
            telecom: undefined,
            idCardFront: "",
            sexNumber: undefined,
            phoneHeader: "010",
            birthday: "",
            request_seq_code: undefined,
            response_seq_code: undefined
        })
        useEffect(()=>{
            if(bodyData.idCardFront && bodyData.sexNumber){
                const birthday = birthDayReplace(bodyData.idCardFront,bodyData.sexNumber)
                const data = {...bodyData}
                data.birthday = birthday
                setBodyData(data)
            }
        },[bodyData.idCardFront, bodyData.sexNumber])
        const checkLocalString = (data) => {
            let trimString = data?.trim()
            const check_eng = /[a-zA-Z]/;    // 영 문자 
            const check_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글체크
            if(trimString){
                if(check_kor.test(trimString) && check_eng.test(trimString)){
                    return false
                }else{
                    if(check_kor.test(trimString)){
                        trimString = trimString.replace(/ /gi,"")
                    }
                    return trimString
                }
            }else{
                return false
            }
        }
        const birthDayReplace = (idcardFront,sexNum) => {
            var divisionCode = sexNum;
            var dateOfBirth = ""
            if(divisionCode == 1 || divisionCode == 2 || divisionCode == 5 || divisionCode == 6){
                dateOfBirth = "19"+idcardFront;
            }else if(divisionCode == 3 || divisionCode == 4 || divisionCode == 7 || divisionCode == 8){
                dateOfBirth = "20"+idcardFront;
            }else if(divisionCode == 9 || divisionCode == 0){
                dateOfBirth = "18"+idcardFront;
            }
            dateOfBirth.replace(/^(\d{4})(\d{2})(\d{1})$/, `$1-$2-$3`);
            var result1 = [dateOfBirth.substring(0,4),dateOfBirth.substring(4,6),dateOfBirth.substring(6,8)]
            dateOfBirth = result1[0]+"-"+ result1[1]+"-"+ result1[2]
            return dateOfBirth;
        }

        return {bodyData,setBodyData,checkLocalString}
    }
    
};

export default PrivateInsertInfoComponentViewModel;