import React from 'react';
import SmsNumTimer from './SmsNumTimer/SmsNumTimer'
import style from './SmsNumInput.module.css'
const SmsNumInput = (props) => {
    return (
        <div className={style["input-box"]}>
            <p className={style.title}>{props.title}</p>
            <input type={'number'}
             placeholder={props.holder} 
             value={props.value}
             onChange={(e)=>{
                const value = e.target.value
                props.InputData(value)
                }} className={style.title}></input>
            <SmsNumTimer TimerStarted={function () {
                props.TimerStarted()
            } } TimerEnded={function () {
                props.TimerEnded()
            } }></SmsNumTimer>
        </div>
    );
};

export default SmsNumInput;