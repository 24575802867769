
import axios, { AxiosError } from 'axios';
import { Cookies } from 'react-cookie'
import SessionHelper from '../SessionHelper'
import { API_BFF_APP_URL } from '../../config';
import HttpHelper from './HttpHelper';
const PrivateHttpHelper = {
  getPrivateToken: async (data) => {
    const cookies = new Cookies();
    const token = cookies.get("token")
    const instance = axios.create({
        baseURL : API_BFF_APP_URL,
        headers : {
            Authorization: `Bearer ${token}`
        }
    })
    try{
        const response = await instance.post("/privacy-auth",data)
        return response.data
    }catch(error){
      if(error.response?.status === 401){
        const refreshData = await HttpHelper.getTokenByrefreshToken()
        if(refreshData.access_token){
            const Reinstance = axios.create({
                baseURL : API_BFF_APP_URL,
                headers : {
                    Authorization: `Bearer ${refreshData.access_token}`,
                }
            })
            const Reresponse = await Reinstance.post("/privacy-auth",data)
            return Reresponse.data
        }else{
            HttpHelper.authrizationFailed()
        }
      }else{
          throw error
      }
    }
  },
  validatePrivateToken: async () => {
    const sessionStorageValue = SessionHelper.getSession()
    const token = sessionStorageValue ? sessionStorageValue : ''
    const instance = axios.create({
        baseURL : API_BFF_APP_URL,
        headers : {
            Authorization: `Bearer ${token}`
        }
    })
    try{
        const response = await instance.post('/privacy-auth/verify')
        return response.data
    }catch(error){
      if(error.response?.status === 401){
        const refreshData = await HttpHelper.getTokenByrefreshToken()
        if(refreshData.access_token){
            const Reinstance = axios.create({
                baseURL : API_BFF_APP_URL,
                headers : {
                    Authorization: `Bearer ${refreshData.access_token}`,
                }
            })
            const Reresponse = await Reinstance.post("/privacy-auth/verify")
            return Reresponse.data
        }else{
            HttpHelper.authrizationFailed()
        }
      }else{
          throw error
      }
    }
  },
  get: async (url) => {
    const sessionStorageValue = SessionHelper.getSession()
    const token = sessionStorageValue ? sessionStorageValue : ''
    const instance = axios.create({
        baseURL : API_BFF_APP_URL,
        headers : {
            Authorization: `Bearer ${token}`,
        }
    })
    try{
        const response = await instance.get(url)
        return response.data
    }catch(error){
      if(error.response?.status === 401){
        window.location.href = '/mypage/input-password'
      }else{
          throw error
      }
    }
  },
  post: async (url, data) => {
    const sessionStorageValue = SessionHelper.getSession()
    const token = sessionStorageValue ? sessionStorageValue : ''
    const instance = axios.create({
        baseURL : API_BFF_APP_URL,
        headers : {
            Authorization: `Bearer ${token}`,
        }
    })
    try{
        const response = await instance.post(url,data)
        return response.data
    }catch(error){
      if(error.response?.status === 401){
        window.location.href = '/mypage/input-password'
      }else{
          throw error
      }
    }
  },
  put: async (url, data) => {
    const sessionStorageValue = SessionHelper.getSession()
    const token = sessionStorageValue ? sessionStorageValue : ''
    const instance = axios.create({
        baseURL : API_BFF_APP_URL,
        headers : {
            Authorization: `Bearer ${token}`,
        }
    })
    try{
        const response = await instance.put(url,data)
        return response.data
    }catch(error){
        if(error.response?.status === 401){
          window.location.href = '/mypage/input-password'
        }else{
            throw error
        }
    }
  },
  delete: async (url) => {
    const sessionStorageValue = SessionHelper.getSession()
    const token = sessionStorageValue ? sessionStorageValue : ''
    const instance = axios.create({
        baseURL : API_BFF_APP_URL,
        headers : {
            Authorization: `Bearer ${token}`,
        }
    })
    try{
        const response = await instance.delete(url)
        return response.data
    }catch(error){
        if(error.response?.status === 401){
          window.location.href = '/mypage/input-password'
        }else{
            throw error
        }
    }
  },
  deleteWithBody: async (url,body) => {
    const sessionStorageValue = SessionHelper.getSession()
    const token = sessionStorageValue ? sessionStorageValue : ''
    const instance = axios.create({
        baseURL : API_BFF_APP_URL,
        headers : {
            Authorization: `Bearer ${token}`,
        }
    })
    try{
        const response = await instance.delete(url,{data : body})
        return response.data
    }catch(error){
        if(error.response?.status === 401){
          window.location.href = '/mypage/input-password'
        }else{
            throw error
        }
    }
  },
};

export default PrivateHttpHelper;
