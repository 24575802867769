import React from 'react';
import { Switch, Redirect } from "react-router-dom";
import PrivateRoute from './route/PrivateRoute';
import VerificateCode from '../pages/certification/verificate-code/VerificateCode';
import CertificationDone from '../pages/certification/done/CertificationDone';
import CertificationTerms from '../pages/certification/terms/CertificationTerms';
import InsertCertificationInfo from '../pages/certification/insert-certinfo/InsertCertificationInfo';
import CertificationFailed from '../pages/certification/failed/CertificationFailed';
function CertificationRouter() {
    return (
        <Switch>
          <PrivateRoute path="/certification/terms" component={CertificationTerms} />
          <PrivateRoute path="/certification/insert-certinfo" component={InsertCertificationInfo} />
          <PrivateRoute path="/certification/verificate-code" component={VerificateCode} />
          <PrivateRoute path="/certification/done" component={CertificationDone} />
          <PrivateRoute path="/certification/failed" component={CertificationFailed} />
          <Redirect path="/certification/*" to="/certification/terms" />
        </Switch>
      );
}
export default CertificationRouter;