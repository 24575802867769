import Service from './Index';

import axios from 'axios';
import { API_ROOT, VERSION, BUILD_NO } from '../config';

axios.defaults.headers.common['X-Client-Version'] = VERSION;
axios.defaults.headers.common['X-Client-Build'] = BUILD_NO;
axios.defaults.headers.common['Cache-Control'] = "no-cache";
axios.defaults.headers.common['Access-Control-Allow-Header'] = "Accept, Content-Type";
axios.defaults.headers.common['Access-Control-Allow-Methods'] = "*";
axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";

const NephronService = {
  getAllDeviceStatus: () => {
    return Service.getFromOther("/device/status", null );
  },
  getFindDeviceStatus: (param) => {
    return Service.getFromOther("/device", param );
  },
  getDevice: (deviceId) => {
    return Service.getFromOther("/device/" + deviceId, null );
  },
  getDeviceStatus: (deviceId) => {
    return Service.getFromOther("/device/" + deviceId + "/status", null );
  },
  getDeviceLocations: (param) => {
    return Service.getFromOther("/location", param );
  },
  getDeviceLocationItem: (idx) => {
    return Service.getFromOther("/location/" + idx, null );
  },
};

export default NephronService;
