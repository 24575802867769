import React from 'react';
import Modal from '@material-ui/core/Modal';
const NoMainPhoneModal = (props) => {
    return (
      <>
        <Modal
          className="modal modal-alert"
          open={props.open}
          onClose={props.onClose}
          disableRestoreFocus
          disableBackdropClick
        >
          <div className="modal-inner">
                <div className="modal-header">
                  <header>
                    <p>휴대전화 번호 변경 알림 <br /><br /></p>
                  </header>
                </div>
                <div className="modal-content">
                  <p>
                    기존에 인증하셨던 휴대전화 번호가 
                    삭제되었습니다. 
                    <br />
                    (사유: 타인의 전화번호로 변경)
                    <br /><br />
                    번호가 변경되셨다면 새로운 번호로 재인증해주세요.
                    <br /><br />
                  </p>
                </div>
                <div className="modal-footer btn-wrap">
                  <button type="button" className="btn btn-active" onClick={props.onClose}>번호 변경하기</button>
                </div>
          </div>
        </Modal>
      </>
    );
};

export default NoMainPhoneModal;