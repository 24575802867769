import React from 'react';
import ModalBox from '../ModalBox/ModalBox';
import style from './AlertModal.module.css'
import PrimaryButton from '../../CustomiseButton/PrimaryButton/PrimaryButton';
const AlertModal = ({title,subTitle,description,okBtnTitle,onOk}) => {
    return (
        <ModalBox transparent={true}>
              <div className={style["modal"]}>
              <p className={style["title"]}>{title}</p>
              {subTitle ? <p dangerouslySetInnerHTML={{__html:subTitle}} className={style["sub-title"]}></p>:<></>}
              {description ? <p className={style["description"]}>{description}</p>:<></>}
              <div className={style["btn-box"]}>
                <PrimaryButton
                title={okBtnTitle} 
                disabled={false} 
                onClick={onOk}></PrimaryButton>
              </div>
            </div>
        </ModalBox>
    );
};

export default AlertModal;