import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import 'moment/locale/ko';
import moment, { now } from 'moment';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Progress from '../../layout/Progress';
import PointService from '../../services/PointService';
import Util from '../../helpers/Util';
import AccordianDisableList from '../../Modules/AccordianDisableList/AccordianDisableList';

const SaveList = () => {
  const [open, setOpen] = useState(false);  // 달력 오픈여부
  const [detailValue, setTabDetail] = useState(0);
  const [startValue, setStartValue] = useState(Util.todayDt("YYYY.MM.DD"));
  const [endValue, setEndValue] = useState(Util.todayDt("YYYY.MM.DD"));
  const [submitted, setSubmitted] = useState(false);

  const [saveLoading, setSaveLoading] = useState([false, false, false, false, false]);  // 포인트 적립 내역 상세 로딩 여부
  const [loading, setLoading] = useState(false);  // 포인트 적립 내역 상세 로딩 여부
  const [expanded, setExpanded] = useState(false);

  /* 포인트 적립 내역 상세 조회 조건 */
  const [list, setList] = useState([[], [], [], [], []]);
  const [totalCount, setTotalCount] = useState([0, 0, 0, 0, 0]);  // 전체 건수
  const [currentPage, setCurrentPage] = useState([0, 0, 0, 0, 0]);  // 현재 페이지
  const limit = 10;
  const [offset, setOffset] = useState([0, 0, 0, 0, 0]);
  const [listStartDt, setListStartDt] = useState(Util.todayDt("YYYY.MM.DD"));
  const [listEndDt, setListEndDt] = useState(Util.todayDt("YYYY.MM.DD"));

  // 포인트 적립 내역 조회
  const searchList = useCallback(async () => {
    let tabIndex = detailValue;

    setSaveLoading(thisState => {
      let state = [...thisState];
      state[tabIndex] = true;
      return state;
    });

    setExpanded(false);

    try {
      const filter = { 
        reg_at:"btw:"+moment(listStartDt, "YYYY.MM.DD").format("YYYY-MM-DD")+","+moment(listEndDt, "YYYY.MM.DD").add(1, 'days').format("YYYY-MM-DD")
      }
      const param = {
        order : JSON.stringify(["-idx"]),
        filter: filter,
        paging : {
          offset: offset[tabIndex],
          limit : limit
        }
      };
      const data = await PointService.getPointList(param);
      setCurrentPage(thisState => {
        let state = [...thisState];
        state[tabIndex] = offset[tabIndex] / limit;
        return state;
      });
      setTotalCount(thisState => {
        let state = [...thisState];
        state[tabIndex] = data.paging.total_cnt;
        return state;
      });
      setList(thisState => {
        let state = { ...thisState };
        state[tabIndex] = [
          ...state[tabIndex],
          ...data.list
        ];
        return state;
      });
    } catch (error) {
    } finally {
      setSaveLoading(thisState => {
        let state = [...thisState];
        state[tabIndex] = false;
        return state;
      });
    }
  }, [detailValue, listEndDt, listStartDt, offset]);

  // 더보기 버튼 핸들러
  const onMore = (index) => {
    setOffset(thisState => {
      let state = [...thisState];
      state[index] = (currentPage[index] + 1) * limit;
      return state;
    });
  }
  const detailChange = (event, newValue) => {
    setOffset([0, 0, 0, 0, 0]);
    setList([[], [], [], [], []]);

    const today = Util.todayDt("YYYY.MM.DD");
    switch (newValue) {
      case 0: // 오늘
        setListStartDt(today);
        setListEndDt(today);
        break;
      case 1: // 어제
        const yesterday = Util.addDaysDt("YYYY.MM.DD", -1);
        setListStartDt(yesterday);
        setListEndDt(yesterday);
        break;
      case 2: // 7일
        setListStartDt(Util.addDaysDt("YYYY.MM.DD", -6));
        setListEndDt(today);
        break;
      case 3: // 30일
        setListStartDt(Util.addDaysDt("YYYY.MM.DD", -29));
        setListEndDt(today);
        break;
      case 4: // 달력
        setListStartDt(startValue);
        setListEndDt(endValue);
        break;
      default:
        break;
    }

    setSaveLoading([false, false, false, false, false]);

    setTabDetail(newValue);
  };

  // 달력 열기
  const handleClickOpen = () => {
    setOpen(true);
  };

  // 달력 닫기
  const handleClose = () => {
    setOpen(false);
  };

  const dayValid = (current) => {
    const maxDay = moment(new Date()).subtract(6, 'months');
    return current.isBetween(maxDay, new Date());
  }

  const startChange = (event) => {
    setStartValue(moment(event).format('YYYY.MM.DD'));
  }

  const endChange = (event) => {
    setEndValue(moment(event).format('YYYY.MM.DD'));
  }

  // 날짜 검색하기
  const onSearchDate = () => {
    if (submitted) {
      setList(thisState => {
        let state = { ...thisState };
        state[4] = [];
        return state;
      });
      setCurrentPage(thisState => {
        let state = [...thisState];
        state[4] = 0;
        return state;
      });
      setTotalCount(thisState => {
        let state = [...thisState];
        state[4] = 0;
        return state;
      });
      setOffset(thisState => {
        let state = [...thisState];
        state[4] = 0;
        return state;
      });
      setListStartDt(startValue);
      setListEndDt(endValue);
      setOpen(false);
    }
  }

  const onClickItem = async (expanded, listIndex, index, no_sno) => {
    setExpanded(expanded ? no_sno : false);
    if (expanded) {
      setLoading(true);
      try {
        const result = await PointService.getPointDetail(no_sno);
        const data = result;
        let pet_count = 0
        let can_count = 0
        data.waste.items.forEach(element => {
          if(element.waste_type_code === "PET"){
            pet_count = element.amount
          }
          if(element.waste_type_code === "CAN"){
            can_count = element.amount
          }
        });
        setList(thisState => {
          let state = { ...thisState };
          if (state[listIndex][index]) {
            state[listIndex][index].can_count = can_count;
            state[listIndex][index].pet_count = pet_count;
          }
          return state;
        });
        setLoading(false);
      } catch (error) {
        alert("데이터를 불러오는데 실패하였습니다. 잠시 후 다시 시도해주세요")
      } finally {
      }
    }
  }

  const dateValidate = useCallback(() => {
    if (startValue && endValue) {
      const startDay = Number(startValue.split('.').join(''));
      const endDay = Number(endValue.split('.').join(''));

      if (startDay <= endDay) {
        setSubmitted(true);
      } else {
        setSubmitted(false);
        return false
      }
    } else {
      setSubmitted(false);
      return false
    }
    return true
  }, [startValue, endValue]);

  useEffect(() => {
    dateValidate();
  }, [dateValidate]);

  useEffect(() => { // 조회 내용 없거나 날짜 검색조건 변경시
    searchList(); // 포인트 적립 내역 조회
  }, [searchList]);

  const renderInput = (props, openCalendar, closeCalendar) => {
    return (
      <>
        <input {...props} />
        <button type="button" className="btn-close" onClick={closeCalendar}>
          <span className="a11y">닫기</span>
        </button>
      </>
    )
  }

  return (
    <>
      <div className="page-point">
        <div className="point-save-list">
          <div className="point-save-item detail">
            <p className="point-save-header">포인트 내역 상세 조회</p>
            <div className="point-save-content">

              <div className="tab-wrap">
                <AppBar position="static" color="default">
                  <Tabs
                    className="tab-control"
                    value={detailValue}
                    onChange={detailChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab className="btn" label="오늘" {...DetailA11yProps(0)} />
                    <Tab className="btn" label="어제" {...DetailA11yProps(1)} />
                    <Tab className="btn" label="일주일" {...DetailA11yProps(2)} />
                    <Tab className="btn" label="30일" {...DetailA11yProps(3)} />
                    <Tab className="btn calendar" {...DetailA11yProps(4)} onClick={handleClickOpen} />
                  </Tabs>
                </AppBar>
                <DetailTabPanel className="tab-content state-info" value={detailValue} index={0}>
                  <div className="accordion-list">
                    {!list[0].length ?
                      saveLoading[0] ? <></>
                        :
                        <DetailTabPanel className="tab-content" value={detailValue} index={0}>
                          <div className="item-empty">해당 일자에 포인트 내역이 없습니다.</div>
                        </DetailTabPanel>
                      :
                      list[0].map((item, index) => {
                        return (
                          <>
                          {item.transaction_action?.code === "D101" ? 
                          <Accordion key={index} className="accordion-item" expanded={expanded === item.idx} onChange={(event, expanded) => { onClickItem(expanded, 0, index, item.idx) }}>
                            <AccordionSummary
                              aria-controls="accordion-content1"
                              id="accordion-header1"
                              className="accordion-header"
                            >
                              <div className="location">
                                <p className="title">
                                  {item.display_text}
                                </p>
                                <p className="date">{Util.formatDateTime(item.reg_at)}</p>
                              </div>
                              <div className="point">
                                <p><em>{Util.formatNumber(item.point)} P</em> 적립</p>
                              </div>
                            </AccordionSummary>
                            {
                              loading ?
                                <Progress loading={loading} /> :
                                <AccordionDetails className="accordion-content">
                                  <div className="state-list">
                                    <em>캔</em>
                                    <span>{item.can_count} 개</span>
                                    <em>페트</em>
                                    <span>{item.pet_count} 개</span>
                                  </div>
                                </AccordionDetails>
                            }
                          </Accordion>:
                          <AccordianDisableList item = {item} />}
                          </>
                        );
                      })
                    }
                  </div>
                  <Progress loading={saveLoading[0]} />
                  {
                    !saveLoading[0] && limit + offset[0] < totalCount[0] ?
                      <div className="btn-wrap">
                        <button type="button" className="btn btn-active" onClick={() => { onMore(0) }} >더보기</button>
                      </div>
                      :
                      <></>
                  }
                </DetailTabPanel>
                <DetailTabPanel className="tab-content" value={detailValue} index={1}>
                  <div className="accordion-list">
                    {!list[1].length ?
                      saveLoading[1] ? <></>
                        :
                        <DetailTabPanel className="tab-content" value={detailValue} index={1}>
                          <div className="item-empty">해당 일자에 포인트 내역이 없습니다.</div>
                        </DetailTabPanel>
                      :
                      list[1].map((item, index) => {
                        return (
                          <>
                          {item.transaction_action?.code === "D101" ? 
                          <Accordion key={index} className="accordion-item" expanded={expanded === item.idx} onChange={(event, expanded) => { onClickItem(expanded, 1, index, item.idx) }}>
                            <AccordionSummary
                              aria-controls="accordion-content1"
                              id="accordion-header1"
                              className="accordion-header"
                            >
                              <div className="location">
                                <p className="title">
                                  {item.display_text}
                                </p>
                                <p className="date">{Util.formatDateTime(item.reg_at)}</p>
                              </div>
                              <div className="point">
                                <p><em>{Util.formatNumber(item.point)} P</em> 적립</p>
                              </div>
                            </AccordionSummary>
                            {
                              loading ?
                                <Progress loading={loading} /> :
                                <AccordionDetails className="accordion-content">
                                  <div className="state-list">
                                    <em>캔</em>
                                    <span>{item.can_count} 개</span>
                                    <em>페트</em>
                                    <span>{item.pet_count} 개</span>
                                  </div>
                                </AccordionDetails>
                            }
                          </Accordion>:
                          <AccordianDisableList item = {item} />}
                          </>
                        );
                      })
                    }
                  </div>
                  <Progress loading={saveLoading[1]} />
                  {
                    !saveLoading[1] && limit + offset[1] < totalCount[1] ?
                      <div className="btn-wrap">
                        <button type="button" className="btn btn-active" onClick={() => { onMore(1) }} >더보기</button>
                      </div>
                      :
                      <></>
                  }
                </DetailTabPanel>
                <DetailTabPanel className="tab-content" value={detailValue} index={2}>
                  <div className="accordion-list">
                    {!list[2].length ?
                      saveLoading[2] ? <></>
                        :
                        <DetailTabPanel className="tab-content" value={detailValue} index={2}>
                          <div className="item-empty">해당 일자에 포인트 내역이 없습니다.</div>
                        </DetailTabPanel>
                      :
                      list[2].map((item, index) => {
                        return (
                          <>
                          {item.transaction_action?.code === "D101" ? 
                          <Accordion key={index} className="accordion-item" expanded={expanded === item.idx} onChange={(event, expanded) => { onClickItem(expanded, 2, index, item.idx) }}>
                            <AccordionSummary
                              aria-controls="accordion-content1"
                              id="accordion-header1"
                              className="accordion-header"
                            >
                              <div className="location">
                                <p className="title">
                                  {item.display_text}
                                </p>
                                <p className="date">{Util.formatDateTime(item.reg_at)}</p>
                              </div>
                              <div className="point">
                                <p><em>{Util.formatNumber(item.point)} P</em> 적립</p>
                              </div>
                            </AccordionSummary>
                            {
                              loading ?
                                <Progress loading={loading} /> :
                                <AccordionDetails className="accordion-content">
                                  <div className="state-list">
                                    <em>캔</em>
                                    <span>{item.can_count} 개</span>
                                    <em>페트</em>
                                    <span>{item.pet_count} 개</span>
                                  </div>
                                </AccordionDetails>
                            }
                          </Accordion>:
                          <AccordianDisableList item = {item} />}
                          </>
                        );
                      })
                    }
                  </div>
                  <Progress loading={saveLoading[2]} />
                  {
                    !saveLoading[2] && limit + offset[2] < totalCount[2] ?
                      <div className="btn-wrap">
                        <button type="button" className="btn btn-active" onClick={() => { onMore(2) }} >더보기</button>
                      </div>
                      :
                      <></>
                  }
                </DetailTabPanel>
                <DetailTabPanel className="tab-content" value={detailValue} index={3}>
                  <div className="accordion-list">
                    {!list[3].length ?
                      saveLoading[3] ? <></>
                        :
                        <DetailTabPanel className="tab-content" value={detailValue} index={3}>
                          <div className="item-empty">해당 일자에 포인트 내역이 없습니다.</div>
                        </DetailTabPanel>
                      :
                      list[3].map((item, index) => {
                        return (
                          <>
                          {item.transaction_action?.code === "D101" ? 
                          <Accordion key={index} className="accordion-item" expanded={expanded === item.idx} onChange={(event, expanded) => { onClickItem(expanded, 3, index, item.idx) }}>
                            <AccordionSummary
                              aria-controls="accordion-content1"
                              id="accordion-header1"
                              className="accordion-header"
                            >
                              <div className="location">
                                <p className="title">
                                  {item.display_text}
                                </p>
                                <p className="date">{Util.formatDateTime(item.reg_at)}</p>
                              </div>
                              <div className="point">
                                <p><em>{Util.formatNumber(item.point)} P</em> 적립</p>
                              </div>
                            </AccordionSummary>
                            {
                              loading ?
                                <Progress loading={loading} /> :
                                <AccordionDetails className="accordion-content">
                                  <div className="state-list">
                                    <em>캔</em>
                                    <span>{item.can_count} 개</span>
                                    <em>페트</em>
                                    <span>{item.pet_count} 개</span>
                                  </div>
                                </AccordionDetails>
                            }
                          </Accordion>:
                          <AccordianDisableList item = {item} />}
                          </>
                        );
                      })
                    }
                  </div>
                  <Progress loading={saveLoading[3]} />
                  {
                    !saveLoading[3] && limit + offset[3] < totalCount[3] ?
                      <div className="btn-wrap">
                        <button type="button" className="btn btn-active" onClick={() => { onMore(3) }} >더보기</button>
                      </div>
                      :
                      <></>
                  }
                </DetailTabPanel>
                <DetailTabPanel className="tab-content" value={detailValue} index={4}>
                  {/* 기간 선택했을때 적립 내역이 있는 경우 */}
                  <div className="period">
                    <span>{listStartDt} ~ {listEndDt}</span>
                    <span className="total">총 <em>{totalCount[4]}건</em></span>
                  </div>
                  <div className="accordion-list">
                    {!list[4].length ?
                      saveLoading[4] ? <></>
                        :
                        <DetailTabPanel className="tab-content" value={detailValue} index={4}>
                          <div className="item-empty">해당 일자에 포인트 내역이 없습니다.</div>
                        </DetailTabPanel>
                      :
                      list[4].map((item, index) => {
                        return (
                          <>
                          {item.transaction_action?.code === "D101" ? 
                          <Accordion key={index} className="accordion-item" expanded={expanded === item.idx} onChange={(event, expanded) => { onClickItem(expanded, 4, index, item.idx) }}>
                            <AccordionSummary
                              aria-controls="accordion-content1"
                              id="accordion-header1"
                              className="accordion-header"
                            >
                              <div className="location">
                                <p className="title">
                                  {item.display_text}
                                </p>
                                <p className="date">{Util.formatDateTime(item.reg_at)}</p>
                              </div>
                              <div className="point">
                                <p><em>{Util.formatNumber(item.point)} P</em> 적립</p>
                              </div>
                            </AccordionSummary>
                            {
                              loading ?
                                <Progress loading={loading} /> :
                                <AccordionDetails className="accordion-content">
                                  <div className="state-list">
                                    <em>캔</em>
                                    <span>{item.can_count} 개</span>
                                    <em>페트</em>
                                    <span>{item.pet_count} 개</span>
                                  </div>
                                </AccordionDetails>
                            }
                          </Accordion>:
                          <AccordianDisableList item = {item} />}
                          </>
                        );
                      })
                    }
                  </div>
                  <Progress loading={saveLoading[4]} />
                  {
                    !saveLoading[4] && limit + offset[4] < totalCount[4] ?
                      <div className="btn-wrap">
                        <button type="button" className="btn btn-active" onClick={() => { onMore(4) }} >더보기</button>
                      </div>
                      :
                      <></>
                  }
                </DetailTabPanel>
              </div>

            </div>
          </div>
        </div>
      </div>

      <Modal
        className="modal modal-bottom modal-calendar"
        open={open}
        onClose={handleClose}
        disableRestoreFocus
        disableBackdropClick
      >
        <div className="modal-inner">
          <div className="modal-header">
            <header>
              <p>날짜 지정설정 검색</p>
              <p className="sub">최대 6개월까지 조회가 가능합니다.</p>
            </header>
          </div>
          <div className="modal-content">
            <form>
              <fieldset>
                <legend className="a11y">날짜 지정</legend>
                <div className="date-wrap">
                  <span className="a11y">시작일</span>
                  <Datetime
                    inputProps={{
                      id: "startDay",
                      name: "startDay",
                      readOnly: true
                    }}
                    locale="kr"
                    dateFormat="YYYY.MM.DD"
                    timeFormat={false}
                    closeOnSelect
                    isValidDate={dayValid}
                    onChange={startChange}
                    value={startValue}
                    renderInput={(props, openCalendar, closeCalendar) => renderInput(props, openCalendar, closeCalendar)}
                  />
                  <span className="tilde">~</span>
                  <span className="a11y">종료일</span>
                  <Datetime
                    inputProps={{
                      id: "endDay",
                      name: "endDay",
                      readOnly: true
                    }}
                    locale="kr"
                    dateFormat="YYYY.MM.DD"
                    timeFormat={false}
                    closeOnSelect
                    isValidDate={dayValid}
                    onChange={endChange}
                    value={endValue}
                    renderInput={(props, openCalendar, closeCalendar) => renderInput(props, openCalendar, closeCalendar)}
                  />
                </div>
              </fieldset>
            </form>
          </div>
          <div className="modal-footer btn-wrap">
            <button type="button" className="btn btn-primary btn-close" onClick={handleClose}>
              <span className="a11y">닫기</span>
            </button>
            <button type="button" className={`btn${submitted ? " btn-active" : " btn-disabled"}`} onClick={onSearchDate}>날짜 검색하기</button>
          </div>
        </div>
      </Modal>

    </>
  )
}

function RecycleTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`recycle-tabpanel-${index}`}
      aria-labelledby={`recycle-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

RecycleTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function DetailTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`detail-tabpanel-${index}`}
      aria-labelledby={`detail-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

DetailTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function DetailA11yProps(index) {
  return {
    id: `detail-tab-${index}`,
    'aria-controls': `detail-tabpanel-${index}`,
  };
}


export default SaveList;
