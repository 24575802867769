import React from 'react';
import CIService from '../../../services/CIService';

const VerificateCodeViewModel =  {
    CheckDuplicateAccount : async() => {
        return false
    },
    SmsValidation : async(body) => {
        const result = await CIService.valificateSmsCode(body)
        return result
    },
    postSms :async(body) => {
        const result = await CIService.requestSmsCode(body)
        return result
    },
    checkAction : (query) => {
        var result ={}
        if(query?.active){
            switch(query.active){
                case "change_name": result = "change"; break;
                case "change_main_phone": result = "change"; break;
                case "change_sub_phone": result = "change"; break;
                case "add_sub_phone": result = "change"; break;
                case "certification": result = "certification"; break;
            }
            return result
        }else{
            return false
        }
    },
};

export default VerificateCodeViewModel;