import React, { useContext, useState, useEffect, useCallback } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import qs from 'query-string';
import { AppContext } from '../../AppContext';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Progress from '../../layout/Progress';
import PointService from '../../services/PointService';
import AppService from '../../services/AppService';
import PushModal from '../../layout/modal/PushModal';
import BannerModal from '../../layout/modal/BannerModal';
import MyNephronService from '../../services/MyNephronService';
import Modal from '@material-ui/core/Modal';
import NephronService from '../../services/NephronService';
import Util from '../../helpers/Util'
import cPointImg from '../../assets/img/cpoint_image/cpoint-main-banner.png'
import NoMainPhoneModal from "../../layout/modal/NoMainPhoneModal";
import AlertContext from "../../layout/modal/CustomiseModal/AlertModal/AlertContext";
import ConfirmContext from "../../layout/modal/CustomiseModal/ConfirmModal/ConfirmContext";
import MemberService from "../../services/MemberService";
import PrivateService from "../../services/PrivateService";
import style from "./Home.module.css"
const Home = () => {
  const location = useLocation();
  const history = useHistory();
  const { search } = location;
  const params = qs.parse(search);	// 문자열의 쿼리스트링을 Object로 변환
  const { isGuide } = params;
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);  // 로딩여부
  const [points, setPoints] = useState(0); // 현재 보유 포인트
  const [favorites, setFavorites] = useState([]); // 즐겨찾는 네프론 리스트
  const [favoritesAll,setFavoritesAll] = useState([]);
  const [banners, setBanners] = useState([]); // 뉴스 배너 리스트
  const [open, setOpen] = useState(false);  // 푸시 알림 수신 팝업
  const [popups, setPopups] = useState([]); // 팝업 리스트
  const [popupsOpen, setPopupsOpen] = useState([]);  // 팝업
  const [open_info, setOpen_info] = useState(false);  // 푸시 알림 수신 팝업
  const [noPhoneModalOpen, setNoPhoneModalOpen] = useState(false);  // 팝업
  const [pointAccount,setPointAccount] = useState()
  const [userData,setUserData] = useState()
  const {alert:alertComp} = useContext(AlertContext)
  const {confirm:confirmComp} = useContext(ConfirmContext)
  const updateDeviceStatus = (deviceList, statusList) =>{
    deviceList.forEach((device) =>  {
      statusList.forEach((item) =>{

        if(device.ds_device_id === item.deviceId){
          device.status = item.status.total_status;
          device.storage = item.storage
        }
        if(item.storage[0].type.startsWith("pp") || item.storage[0].type.startsWith("PP")){
          device.storage = []
          device.storage[0] = item.storage[0]
        }
      })
    });
    return deviceList;
  }
  const getUserData = async() => {
    try{
      const result = await MemberService.getUserData()
      setUserData(result)
      getUserMainPhone(result)
    }catch(err){
      alertComp()
    }
  }
  //개인정보 조회
  const getUserMainPhone = async(data) => {
    var mainPhoneEnable = false
    data?.phone?.forEach((element) => {
      if(element.represent === true){
        mainPhoneEnable = true
      }
    });
    if(!mainPhoneEnable){
      const result = await confirmComp('휴대전화 번호 변경 알림', '기존에 인증하셨던 휴대전화 번호가 삭제되었습니다. (사유: 타인의 전화번호로 변경)  번호가 변경되셨다면 새로운 번호로 재인증해주세요.',undefined,'로그아웃','번호 변경하기')
      if(result){
        auth.logout()
      }else{
        history.push({pathname:'/mypage/input-password',query:{active:'no_main_phone'}},'/mypage/input-password') 
      }
    }
  } 
  // 내가 즐겨찾는 네프론 조회
  const searchFavorites = async () => {
    const param = {
    };
    setLoading(true);
    try {
      const result = await MyNephronService.fetchItems(param);
      const data = result.data;
      let i = 0
      for(const item of data.list){
        const detailstatus = await NephronService.getDeviceStatus(item.ds_device_id);
        data.list[i].status = detailstatus.status.total_status
        data.list[i].storage = detailstatus.storage
        if(data.list[i].storage[0].type.startsWith("pp") || data.list[i].storage[0].type.startsWith("PP")){
          let storageJust = []
          storageJust = data.list[i].storage[0]
          data.list[i].storage = []
          data.list[i].storage[0] = storageJust
        }
        i++
      }
      
      setFavorites(data.list);
    } catch (error) {
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
    const paramall = {
      limit: 5
    };
    setLoading(true);
    try {
      const result = await MyNephronService.fetchItems(paramall);
      const data = result.data;
      const status = await NephronService.getAllDeviceStatus();
      setFavoritesAll(updateDeviceStatus(data.list, status));
    } catch (error) {
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  // 배너 리스트 조회
  const searchBanners = async () => {
    try {
      const result = await AppService.fetchBanners();
      const data = result.data;
      setBanners(data.list);
    } catch (error) {
    } finally {
    }
  };

  // 팝업 리스트 조회
  const searchPopups = useCallback(async () => {
    const param = {
      os: auth.user.os
    };
    try {
      const result = await AppService.fetchPopups(param);
      const data = result.data;

      let popupArray = [];
      data.list.forEach((item, index) => {
        var nowDate = Util.formatDate(new Date());
        var bannerDate = window.localStorage.getItem('banner_' + item.idx);
        if (nowDate !== bannerDate) {
          popupArray.push(item);
        }
      });

      let openArray = [];
      popupArray.forEach((item, index) => {
        if (index === 0) {   // 첫번째 팝업 먼저 열기 설정
          openArray.push(true);
        } else {
          openArray.push(false);
        }
      });

      setPopups(popupArray);
      setPopupsOpen(openArray);
    } catch (error) {
    } finally {
      
    }
  }, [auth]);

  // 내가 즐겨찾는 네프론 설정하기 버튼 핸들러
  const onSetMyNeflon = () => {
    var param = auth.user.user_sno;
    try{
      window.nativeApi.execute(window.NativeApiMethod.OPEN_MY_NEFLON, {sno:JSON.stringify(param)});
    }catch(err){
      console.error(err)
    }
  }

  // 푸쉬 모달 열기
  const modalOpen = () => {
    setOpen(true);
  }

  // 푸쉬 모달 닫기
  const modalClose = () => {
    setOpen(false);
  }

  const InfoOpen = () => {
    setOpen_info(!open_info)
  }
  // 배너 모달 닫기
  const bannerClose = (index) => {
    setPopupsOpen(thisState => {
      let state = [...thisState];
      state[index] = false;
      if (index < popupsOpen.length - 1) {
        state[index + 1] = true;
      }
      return state;
    });
  }

  // 배너 클릭 핸들러
  const onClickBanner = (url) => {
    if (url) {
      try{
        window.nativeApi.execute(window.NativeApiMethod.OPEN_BROWSER, {
          url: url
        }, "");
        }catch(err){
          console.error(err)
        }
    }
  }

  const goLocation = useCallback(() => {
    history.push('/location');
  }, [history]);
  const getPointAccount = async() => {
    try{
      const result = await PointService.getPointAccount();
      setPointAccount(result)
    }catch(err){
      alert('사용자 포인트 정보를 가져오는데 실패하였습니다.')
    }
  }
  // useEffect(()=>{
  //   setPoints(auth.user.point_account.balance ? auth.user.point_account.balance : 0)
  // },[])

  useEffect(() => {
    try{
      window.nativeApi.execute(window.NativeApiMethod.CLERA_HISTORY, {}, '');
      window['searchFavorites'] = searchFavorites;
      window['goLocation'] = goLocation;
    }catch(err){
      console.error(err)
    }
  }, [goLocation]);

  useEffect(() => {
    if (auth.user !== null) {
      searchFavorites(); // 내가 즐겨찾는 네프론 조회
      // searchPoints(); // 포인트 조회
      searchBanners();  // 배너 리스트 조회
      getUserData()
    }

    if (auth.user.is_start) {
      searchPopups();  // 팝업 리스트 조회
      //auth.setIsStart(false);  // 최초 실행값 수정 - 롤백 채필규 2020.02.21
    }
    getPointAccount()
    if (auth.user.is_first) {
      if (isGuide === 'Y') {
        modalOpen();  // 푸쉬 모달 열기
      } else {
        history.replace('/home/guide');
      }
    }
  }, [auth, isGuide, history, searchPopups]);

  useEffect(()=>{
    setNoPhoneModalOpen(false)
  },[])

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    variableWidth: true
  };

  return (
    <>
      <div className="page-home">
        <div className="c-point-info">
          <Link to={"/cpoint/info"}><img src={cPointImg}></img></Link>
        </div>
        <div className="point-info">
          <h3 className="a11y">포인트 내역</h3>
          <p className="user-info"><em>{userData?.nickname}</em> 님의 포인트</p>
          <p className="user-point">{Util.formatNumber(pointAccount?.balance)}</p>
          <div className="btn-wrap">
            {pointAccount?.balance < 2000 ?
              <Link className="btn full" to="/point/save/list">포인트 내역</Link>
              : <>
                <Link className="btn" to="/refund/request">환전하기</Link>
                <Link className="btn" to="/point/save/list">포인트 내역</Link>
              </>
            }
          </div>
        </div>
        <div className="news">
          <h3 className="title-text">수퍼빈 뉴스</h3>
          <Slider className="banner-slider" {...settings}>
            {banners.length ?
              banners.map((item, index) => {
                return (
                  <div className="banner-item" key={index} onClick={() => onClickBanner(item.link_url)}>
                    <div className="banner" style={{ backgroundImage: `url('${item.image_url}')` }}>
                      <p style={{display:"none"}}>{item.title}</p>
                    </div>
                  </div>
                )
              })
              :
              <></>
            }
          </Slider>
        </div>
        <div className="favorite-nefron">
          <div className="nefron-title">
            <div className="nefron-control-left">
              <h3 className="title-text">즐겨찾는 네프론</h3>
              <button type="button" className="btn btn-infomation" onClick={InfoOpen}><span className="a11y">즐겨찾기 정보</span></button>
            </div>
            <div className="nefron-control">
              <button type="button" className="btn btn-refresh" onClick={searchFavorites}><span className="a11y">목록 새로고침</span></button>
              <button type="button" className="btn btn-setting" onClick={onSetMyNeflon}><span className="a11y">즐겨찾기 설정</span></button>
            </div>
          </div>
          <div className={style["favorite-nefron-decoration"]}>
              <p>※ 각 지역별 통신 상황에 따라 네프론의 상태가 실제와 다를 수 있으니 참고로만 활용해주시길 부탁드립니다</p>
          </div>
          {/* 즐겨찾기 */}
          {/* 최대 4개 노출 */}
          <div className="nefron-content nefron-list">
            <Progress loading={loading} />
            {
              !loading ?
                favorites.length ?
                  favorites.map((item, index) => {
                    return (
                      <div className="nefron-item" key={index}>
                          <p className="nefron-location">
                            <span className="a11y">위치</span>
                            <p className="nefron-title">{item.ds_inst_place}</p>
                            {/* <p className="status">{item.storage[0].type}</p> */}
                            <p className="status">
                              {item.storage.map((itemstr,index)=>{
                                return(
                                  // startsWith("ava") || status.startsWith("AVA")
                                  <>
                                    {itemstr.type.startsWith("pe")||itemstr.type.startsWith("PE")? <span className="title">페트</span>:<></>}
                                    {itemstr.type.startsWith("c")||itemstr.type.startsWith("C")? <span className="title">캔</span>:<></>}
                                    {itemstr.type.startsWith("pp")||itemstr.type.startsWith("PP")? <span className="title">PP뚜껑</span>:<></>}
                                    {Util.getNeflonCondition(item) == "possible" && <span className={itemstr.full? "true":"false"}>{itemstr.full? "용량 꽉참":"사용 가능"}</span>}
                                    {Util.getNeflonCondition(item) == "exceed" && <span className="true">용량 꽉참</span>}
                                    {Util.getNeflonCondition(item) == "impossible" && <span className="true">사용 불가</span>}
                                    <span className="slice">{index<item.storage.length-1? "|":""}</span>
                                  </>
                                )
                              })}
                            </p>
                          </p>
                          <Link to={"/nephron?nephron=" + item.ds_device_id}>상세</Link>
                      </div>
                    );
                  })
                  :
                  <div className="nefron-item item-empty">
                    <p>즐겨찾는 네프론이 없습니다.</p>
                    <p className="sub">
                      내가 자주 가는 네프론을 등록하세요.<br />
                      현재 상태를 확인할 수 있습니다.
                    </p>
                    <Link className="btn btn-active" to="/location">네프론 등록하기</Link>
                  </div>
                :
                <></>
            }
          </div>
        </div>
      </div>
      <PushModal open={open} modalClose={modalClose} auth={auth} />
      {
      window.localStorage.getItem('modalPopupState') == 'true' ?
        popupsOpen.length ?
          popups.map((item, index) => {
            return (
              <BannerModal key={index} item={item} banner={popupsOpen[index]} bannerClose={() => bannerClose(index)} />
            )
          })
          :<></>
        :<></>
      }
      <Modal
        className="modal modal-alert modal-photo-info"
        open={open_info}
        disableRestoreFocus
        disableBackdropClick
      >
        <div className="main-modal-inner">
          <div className="modal-content">
            <div className="btn-close-box">
              <button className="btn-close" onClick={InfoOpen}>
                <span className="a11y">닫기</span>
              </button>
            </div>
            <p className="title">네프론 실시간 상태 보는 방법</p>
            <br></br>
            <div className={style["nefron-modal-decoration"]}>
              <p>※ 각 지역별 통신 상황에 따라 네프론의 상태가 실제와 다를 수 있으니 참고로만 활용해주시길 부탁드립니다</p>
            </div>
            <p>네프론을 검색한 후 [상세] 버튼 클릭 &gt; 우측 상단의 별표를 눌러 즐겨찾는 네프론을 등록할 수 있습니다.</p>
            <br></br>
            <p>※ (새로고침) 버튼을 눌러 네프론 실시간 상태를 불러올 수 있어요.</p>
            <br></br>
            <p className="title">네프론 상태 안내</p>
            <br></br>
            <p className="subtitle green">사용 가능(가능)</p>
            <p>투입 가능한 상태</p>
            <br></br>
            <p className="subtitle gray">용량 꽉참(꽉참)</p>
            <p>적재함이 가득차 투입이 불가능한 상황 </p>
            <br></br>
            <p className="subtitle gray">사용 불가(불가)</p>
            <p>기기 고장으로 인해 모든 자원 투입이 불가능한 상황</p>
            <div className="photo-view" style={{backgroundColor: 'white'}}></div>
          </div>
        </div>
      </Modal>
      <NoMainPhoneModal
        onClose={()=>{
          history.push({pathname:"/mypage/input-password" , query:{status:"no_main_phone"}})
        }}
        open={noPhoneModalOpen}></NoMainPhoneModal>
    </>
  )
}

export default Home;