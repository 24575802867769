import { NavLink } from 'react-router-dom';

const Gnb = () => {
  return (
    <>
      <nav className="nav">
        <ul className="nav-list">
          <li><NavLink className="btn btn-home" to="/home" activeClassName="active">홈</NavLink></li>
          <li><NavLink className="btn btn-point" to="/point" activeClassName="active" >포인트</NavLink></li>
          <li><NavLink className="btn btn-location" to="/location" activeClassName="active" >위치</NavLink></li>
          <li><NavLink className="btn btn-menu" to="/menu" activeClassName="active" >메뉴</NavLink></li>
        </ul>
      </nav>
    </>
  );
}

export default Gnb;
