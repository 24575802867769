import Service from './Index';
import AuthService from './AuthService';
import HttpHelper from '../helpers/HttpHelper/HttpHelper'
const MemberService = {
  //삭제 필요 
  getUserInfobyAuth:(param) => {
    return AuthService.post('/getUserinfo',param)
  },
  //삭제 필요
  getUserInfo: () => {
    return AuthService.get('/me', {});
  },
  guardianRequestSms: (param) => {
    return HttpHelper.post('/me/guardian/send-authcode', param);
  },
  setGuardian: (param) => {
    return HttpHelper.post('/me/guardian', param);
  },
  getUserData: () => {
    return HttpHelper.get('/me', null);
  },
  getRefundAccount : () => {
    return HttpHelper.get("/refund-account",null,null)
  },
  getMoreServiceData : () => {
    return HttpHelper.get("/me/service/more",null,null)
  },
  getRookieServiceData : () => {
      return HttpHelper.get("/me/service/rookie",null,null)
  },
  deleteMoreService : () => {
      return HttpHelper.delete("/me/service/more",null)
  },
  deleteRookieService : () => {
      return HttpHelper.delete("/me/service/rookie",null)
  },
  updateUserInfo: (param) => {
    return AuthService.put('/me', param);
  },
  updatePassword: (param) => {
    return AuthService.post('/me/password', param);
  },
  validatePassword: (param) => {
    return AuthService.post('/me/password/validate', param);
  },
  updateExChangePassword: (param) => {
    return AuthService.put('/me/exchange-password', param);
  },
  fetchAppNotificationSetting: () => {
    return AuthService.get('/me/app-notification', {});
  },
  updateAppNotificationSetting: (param) => {
    // for (var pair of param.entries()) {
      // window.nativeApi.execute(window.NativeApiMethod.CHANGE_URL_WEB_VIEW,{url:pair[0]+ ', ' + pair[1] + "setAppNotificationSetting_param"});
    // }
    
    return AuthService.put('/me/app-notification', param);
  },
  withdrawal: (param) => {
    return AuthService.delete('/me', param);
  }
};

export default MemberService;
