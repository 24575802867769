import React from 'react';
import { Switch, Redirect } from "react-router-dom";

import GuardianCertification from '../pages/guardian-certification/GuardianCertification';
import PrivateRoute from './route/PrivateRoute';
import GuardianCertificationFailed from '../pages/guardian-certification/failed/GuardianCertificationFailed';
import GuardianCertificationSuccess from '../pages/guardian-certification/success/GuardianCertificationSuccess';

const GuardianRouter = () => {
    return (
        <Switch>
          <PrivateRoute path="/guardian-certification/success" component={GuardianCertificationSuccess} />
          <PrivateRoute path="/guardian-certification/failed" component={GuardianCertificationFailed} />
          <PrivateRoute path="/guardian-certification" component={GuardianCertification} />
          
          <Redirect path="/guardian-certification/*" to="/" />
        </Switch>
    );
};

export default GuardianRouter;