import React from 'react';

const ChangeAccountPasswordViewModel =  {
    checkPasswordStatus:(data) => {
        const passwordString = ChangeAccountPasswordViewModel.checkPassStringValue(data)
        const passwordLength = ChangeAccountPasswordViewModel.checkPassLengthValue(data) 
        const passwordEnableBtn = passwordString && passwordLength ? true:false
        return{
            regex: passwordString,
            length : passwordLength,
            enable : passwordEnableBtn
        }
    },
    checkPassLengthValue:(data) => {
        var checkValue = true
        if(data.length < 8 || data.length > 20){
            checkValue = false
        }
        return checkValue 
    },
    checkPassStringValue:(data) => {
        var checkValue = true
        const regex = /[A-Za-z0-9]/g
        const regexNumber = /[0-9]/g
        const regexString = /[A-Za-z]/g
        const pwRegex = /^[0-9a-zA-Z!@#$",./:;<=>?~[^_`\]{|()'￦₩}*&%^+*\\\-=]*$/
        if(regex.test(data)){
            var countStringCorrectCount = 0
            regexString.test(data)? countStringCorrectCount++ : countStringCorrectCount = countStringCorrectCount+0
            regexNumber.test(data)? countStringCorrectCount++ : countStringCorrectCount = countStringCorrectCount+0
            if(countStringCorrectCount < 2){
                checkValue = false
            }
            if(!pwRegex.test(data)){checkValue = false}
        }else{
            checkValue = false
        }
        return checkValue
    }
};

export default ChangeAccountPasswordViewModel;