import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import PrivateRoute from './route/PrivateRoute';

import Home from "../pages/home/Home";
import Guide from "../pages/home/Guide";



function HomeRouter() {
  return (
    <Switch>
      <Route path="/home/guide" component={Guide} />
      <PrivateRoute path="/home" component={Home} />
      <Redirect path="/home/*" to="/home" />
    </Switch>
  );
}

export default HomeRouter;
