// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TransParentButton_transaction-button-box__3zkah > button{\n    background-color: transparent; \n    color: #333333; \n    border: solid 1px;\n    border-color: #333333; \n    border-radius: 5px;\n    padding: 10px 12px;\n    font-size: 16px;\n}\n.TransParentButton_transaction-button-box__3zkah > button:disabled{\n    background-color: transparent; \n    color: #A7A7A7; \n    border: solid 1px;\n    border-color: #A7A7A7; \n    border-radius: 5px;\n    padding: 10px 12px;\n    font-size: 16px;\n}", "",{"version":3,"sources":["webpack://src/layout/modal/CustomiseButton/TransparentButton/TransParentButton.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,cAAc;IACd,iBAAiB;IACjB,qBAAqB;IACrB,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,6BAA6B;IAC7B,cAAc;IACd,iBAAiB;IACjB,qBAAqB;IACrB,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".transaction-button-box > button{\n    background-color: transparent; \n    color: #333333; \n    border: solid 1px;\n    border-color: #333333; \n    border-radius: 5px;\n    padding: 10px 12px;\n    font-size: 16px;\n}\n.transaction-button-box > button:disabled{\n    background-color: transparent; \n    color: #A7A7A7; \n    border: solid 1px;\n    border-color: #A7A7A7; \n    border-radius: 5px;\n    padding: 10px 12px;\n    font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transaction-button-box": "TransParentButton_transaction-button-box__3zkah"
};
export default ___CSS_LOADER_EXPORT___;
