// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MyContentsBoxLayout_page-start__3zfzt{background: #fff; height: 100vh; display: flex;}\n.MyContentsBoxLayout_page-contents__1edOR{max-width: 488px; padding: 60px 24px;}\n\n@media (max-width: 400px) { \n    .MyContentsBoxLayout_page-contents__1edOR{padding: 60px 20px;}\n  }", "",{"version":3,"sources":["webpack://src/layout/CustomLayout/MyContentsBoxLayout/MyContentsBoxLayout.module.css"],"names":[],"mappings":"AAAA,uCAAY,gBAAgB,EAAE,aAAa,EAAE,aAAa,CAAC;AAC3D,0CAAe,gBAAgB,EAAE,kBAAkB,CAAC;;AAEpD;IACI,0CAAe,kBAAkB,CAAC;EACpC","sourcesContent":[".page-start{background: #fff; height: 100vh; display: flex;}\n.page-contents{max-width: 488px; padding: 60px 24px;}\n\n@media (max-width: 400px) { \n    .page-contents{padding: 60px 20px;}\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page-start": "MyContentsBoxLayout_page-start__3zfzt",
	"page-contents": "MyContentsBoxLayout_page-contents__1edOR"
};
export default ___CSS_LOADER_EXPORT___;
