import React, { useState } from 'react';
import MypageAuthrizationService from '../../../services/MypageAuthrizationService';
import { useLocation, useHistory } from 'react-router-dom';
import { CryptoAES } from '../../../helpers/CryptoHelper'
import DefaultInput from '../../../Modules/MyPageModules/Private/DefaultInput/DefaultInput';
import PrivateService from '../../../services/PrivateService'
import SessionHelper from '../../../helpers/SessionHelper';
import style from './MypageInputPassword.module.css'
const MypageInputPassword = () => {
    const location = useLocation()
    const history = useHistory()
    const [password,setPassword] = useState()
    const onClickNext = async() => {
        try{
            //비밀번호 확인!
            const body = {
                password: CryptoAES.encrypt(password)
            }
            const result = await PrivateService.getPrivateToken(body)
            try{
                SessionHelper.setSession(result.access_token)
                if(location.query?.active){
                    history.replace({pathname:'change-private-info/regist-main-phone/terms',query:{active:"regist_main_phone"}},"change-private-info/regist-main-phone/terms")
                }else{
                    history.replace("/mypage")
                }
            }catch(err){
                console.error(err)
            }
        }catch(error){
            //비밀번호 틀림
            if(error.response.status == 403){
                alert('잘못된 비밀번호입니다. 정확한 비밀번호를 다시 입력해주세요.')
            }else{
                alert('기타 오류입니다. 지속시 관리자에게 문의주세요.')
            }
        }
    }
    return (
        <div className={style["page-start"]}>
            <div className={style["root"]}>
                <div className={style.title}>정보 변경을 위한 로그인 비밀번호 입력</div>
                <div className={style["sub-title"]}>개인정보, 로그인 비밀번호, 환전 비밀번호를 변경하기 위해 아래 칸에 사용 중인 로그인 시 입력하는 계정 비밀번호를 입력해주세요.</div>
                <div className={style["container-box"]}>
                    <DefaultInput type='password' title={'비밀번호'} holder={'비밀번호를 입력해주세요.'} value={password}
                        InputData={function (value) {
                            setPassword(value)
                        } } description={null} 
                        onFocus={function () {
                        } } onBlur={function () {
                        } } ></DefaultInput>
                    <div className={style["input-info"]}>
                        <div>   
                            <p className={style["input-info-title"]}>※ 비밀번호를 분실하셨나요?</p>
                            <p className={style["input-info-contents"]}>로그아웃 후 비밀번호 변경을 눌러 비밀번호를 변경해주세요.</p>
                        </div>
                    </div>
                </div>
                <div className={style["bottom-container-box"]}>
                    <button disabled={!password} onClick={()=>{onClickNext()}}>확인</button>
                </div>
            </div>
        </div>

    );
};

export default MypageInputPassword;