import React, { useEffect } from 'react';
import {useHistory} from 'react-router-dom';
import { ChangeDoneIcon } from '../../../../assets/icons/SVG';
import style from './ChangeAccountPasswordDone.module.css'
import WidthFullBtn from '../../../../Modules/Buttons/widthFullBtn/WidthFullBtn';
import MypageAuthrizationService from '../../../../services/MypageAuthrizationService';
const ChangeAccountPasswordDone = () => {
    const history = useHistory()
    useEffect(()=>{
        const authorization = MypageAuthrizationService.getSession()
        if(!authorization){
          history.replace('/mypage/input-password')
        }
    },[])
    return (
        <div className={style["page-start"]}>
            <div className={style.root}>
                <ChangeDoneIcon></ChangeDoneIcon>
                <div className={`${style["title"]}`}>비밀번호 변경 완료</div>
                <div className={`${style["description"]}`}>비밀번호 변경이 완료되었습니다.</div>
                <div className={style["bottom-container-box"]}>
                <WidthFullBtn disabled={false} title={"확인"} onClick={()=>{history.replace('/')}}></WidthFullBtn>
                </div>
            </div>
        </div>
    );
};

export default ChangeAccountPasswordDone;