// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SmsNumTimer_root__11WGm{padding-top: 12px; display: flex; align-items: flex-start; justify-content: space-between;}\n.SmsNumTimer_timer__NijyZ{font-size: 14px; line-height: 22px; color: #2681F3;}\n.SmsNumTimer_reload-timer__DCm7c{font-size: 16px; line-height: 22px; color: #424242; border-bottom: 1px solid #424242; cursor: pointer;}", "",{"version":3,"sources":["webpack://src/Modules/MyPageModules/Private/SmsNumInput/SmsNumTimer/SmsNumTimer.module.css"],"names":[],"mappings":"AAAA,yBAAM,iBAAiB,EAAE,aAAa,EAAE,uBAAuB,EAAE,8BAA8B,CAAC;AAChG,0BAAO,eAAe,EAAE,iBAAiB,EAAE,cAAc,CAAC;AAC1D,iCAAc,eAAe,EAAE,iBAAiB,EAAE,cAAc,EAAE,gCAAgC,EAAE,eAAe,CAAC","sourcesContent":[".root{padding-top: 12px; display: flex; align-items: flex-start; justify-content: space-between;}\n.timer{font-size: 14px; line-height: 22px; color: #2681F3;}\n.reload-timer{font-size: 16px; line-height: 22px; color: #424242; border-bottom: 1px solid #424242; cursor: pointer;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "SmsNumTimer_root__11WGm",
	"timer": "SmsNumTimer_timer__NijyZ",
	"reload-timer": "SmsNumTimer_reload-timer__DCm7c"
};
export default ___CSS_LOADER_EXPORT___;
