import AuthService from './AuthService';
import Service from './Index';

const LoginService = {
  joinUser: (param) => {
    return AuthService.post('/join', param);
  },
};

export default LoginService;
