// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WidthFullBtn_btn-box__24Ug3 > button{background-color: #3997FE; border: none; border-radius: 12px; width: 100%; padding: 19px; font-weight: 700; font-size: 18px; line-height: 22px; text-align: center; color: #FFFFFF;}\n.WidthFullBtn_btn-box__24Ug3 > button:disabled{background: #BDBDBD;}", "",{"version":3,"sources":["webpack://src/Modules/Buttons/widthFullBtn/WidthFullBtn.module.css"],"names":[],"mappings":"AAAA,sCAAkB,yBAAyB,EAAE,YAAY,EAAE,mBAAmB,EAAE,WAAW,EAAE,aAAa,EAAE,gBAAgB,EAAE,eAAe,EAAE,iBAAiB,EAAE,kBAAkB,EAAE,cAAc,CAAC;AACrM,+CAA2B,mBAAmB,CAAC","sourcesContent":[".btn-box > button{background-color: #3997FE; border: none; border-radius: 12px; width: 100%; padding: 19px; font-weight: 700; font-size: 18px; line-height: 22px; text-align: center; color: #FFFFFF;}\n.btn-box > button:disabled{background: #BDBDBD;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn-box": "WidthFullBtn_btn-box__24Ug3"
};
export default ___CSS_LOADER_EXPORT___;
