const GoApp = () => {
  return (
    <>
      <div className="page-login">
        <ul className="login-list">
          <li>
            앱 실행 또는 스토어로 이동.
          </li>
        </ul>
      </div>
    </>
  )
}

export default GoApp;