import { createContext } from "react"
import AuthStore from './store/AuthStore';

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const auth = AuthStore();

  return (
    <AppContext.Provider value={{
      auth
    }}>
      {children}
    </AppContext.Provider>
  )

}