import React, { useContext, useEffect, useState } from 'react';
import { useLocation ,useHistory} from 'react-router-dom';
import ChangePrivateInfoDoneViewModel from './ChangePrivateInfoDoneViewModel'
import style from './ChangePrivateInfoDone.module.css'
import { ChangeDoneIcon } from '../../../../assets/icons/SVG';
import { AppContext } from '../../../../AppContext';
const ChangePrivateInfoDone = () => {
    const {userMainPhoneInfo,userPhoneList,contents} = ChangePrivateInfoDoneViewModel.ChangePrivateInfoHook()
    const history = useHistory()
    return (
        <div className={style["page-start"]}>
            <div className={style.root}>
                <ChangeDoneIcon></ChangeDoneIcon>
                <div className={`${style["title"]} ${style["centerItem"]}`}>{contents.title}</div>
                <div className={`${style["sub-title"]} ${style["centerItem"]}`}>{contents.description}</div>
                {userMainPhoneInfo?.phone_no ? 
                    <ul className={style['phone-list-box']}>
                        <li className={style['phone-list']}>
                            <div className={style['title']}>
                                <p>현재 등록된</p>
                                <p>휴대전화 번호(대표번호)</p>
                            </div>
                            <div className={style['phone']}>{userMainPhoneInfo.phone_no}</div>
                        </li>
                        {userPhoneList?.map((item,index) => {
                            return (
                                <li key={"phonelist" + index} className={style['phone-list']}>
                                    <div className={style['title']}>
                                        <p>현재 등록된</p>
                                        <p>휴대전화 번호(추가{index + 1})</p>
                                    </div>
                                    <div className={style['phone']}>{item.phone_no}</div>
                                </li>
                            )
                        })}
                    </ul> : <></>}
                <div className={style["bottom-container-box"]}>
                    <button onClick={()=>{history.replace("/")}}>메인으로 돌아가기</button>
                </div>
            </div>
        </div>
    );
};

export default ChangePrivateInfoDone;