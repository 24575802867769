import React, { useEffect, useState } from 'react';
import { useLocation ,useHistory } from 'react-router-dom';

const PrivateCertificationTermsViewModel =  {
    PrivateCertificationTermsHook : () => {
        const location = useLocation()
        const history = useHistory()
        const [description,setDiscription] = useState("")
        const [allchecked,setAllchecked] = useState(false)
        const [checkItem,setCheckItem] = useState({
            privacy:false,
            service:false,
            identification:false,
            telecom:false
        })
        useEffect(()=>{
            const result = checkAllChecked(checkItem)
            setAllchecked(result)
            setDescriptionData(location.query.active)
        },[checkItem])
        const checkAllChecked = (data) => {
            var checker = true
            if(!data.privacy){checker = false}
            if(!data.service){checker = false}
            if(!data.telecom){checker = false}
            if(!data.identification){checker = false}
            return checker
        }
        const insertCertinfo = () => {
            history.replace({pathname:'insert-certinfo',query:location.query},'insert-certinfo')
        }
        const setDescriptionData = (active) => {
            switch(active){
                case "change_main_phone" : setDiscription("번호 변경을 위한"); break;
                case "add_sub_phone" : setDiscription("번호 추가를 위한"); break;
                case "regist_main_phone" : setDiscription("번호 등록을 위한"); break;
                case "change_name" : setDiscription("이름 변경을 위한"); break;
            }
        }
        return { description ,allchecked , checkItem ,setCheckItem , insertCertinfo};
    },
};

export default PrivateCertificationTermsViewModel;