import React, { useState } from 'react';
import ConfirmContext from './ConfirmContext';
import ConfirmModal from './ConfirmModal';

const ConfirmDialog = ({ children }) => {
  const [state, setState] = useState();
  const confirm = (title ,subtitle, description , cancelBtnTitle ,okBtnTitle ) => {
    return new Promise((resolve) => {
      setState({
        title : title ?? '',
        subtitle: subtitle ?? '',
        description: description ?? '',
        okBtnTitle: okBtnTitle ?? '',
        cancelBtnTitle: cancelBtnTitle ?? '',
        onClickCancel: () => {
          setState(undefined);
          resolve(true);
        },
        onClickOK: () => {
          setState(undefined);
          resolve(false);
        },
      });
    });
  };

  return (
    <ConfirmContext.Provider value={{ confirm }}>
      {children}
      {state && (
        <ConfirmModal 
        title={state.title} 
        subTitle={state.subtitle}
        description={state.description}
        okBtnTitle={state.okBtnTitle} 
        cancelBtnTitle={state.cancelBtnTitle} 
        onCancel={state.onClickCancel}
        onOk={state.onClickOK} 
        ></ConfirmModal>
      )}
    </ConfirmContext.Provider>
  );
};

export default ConfirmDialog;