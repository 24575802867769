import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';

const PointBankModal = (props) => {
  const [open, setOpen] = useState(false);
  const [bank, setBank] = useState('');
  const [code, setCode] = useState('');
  const [idx , setIdx] = useState(undefined)
  const bankChange = (item) => {
    setBank(item.name);
    setCode(item.code);
    setIdx(item.idx)
  };
  useEffect(()=>{
    if(props.bankModal){
      modalOpen(true)
    }
  },[props])
  const modalOpen = (event) => {
    setOpen(true);
  };

  const modalClose = () => {
    setOpen(false);
    props.setCloseModal()
  };

  const bankSelect = () => {
    props.bankChange(bank, code , idx);
    modalClose();
  };

  return (
    <>
      {/* <button type="button" className="btn-select" onClick={modalOpen}>
        <span className="a11y">은행 선택</span>
      </button> */}
      <Modal
        className="modal modal-bottom modal-bank-select"
        open={open}
        onClose={modalClose}
        disableRestoreFocus
        disableBackdropClick
      >
        <div className="modal-inner">
          <div className="modal-header">
            <header>
              <p>은행 선택</p>
              <button type="button" className="btn btn-close" onClick={modalClose}>
                <span className="a11y">닫기</span>
              </button>
            </header>
          </div>

          <div className="modal-content bank-select-content">
            <div className="bank-wrap">
              <p className="bank-title">은행</p>
              <ul className="bank-list">
                {
                  props.banks ?
                    props.banks.map((item, index) => {
                      if(item.type === "B"){
                        return (
                          <li key={index}>
                            <label htmlFor={`bankSelect` + item.code} className="btn-radio">
                              <input type="radio" id={`bankSelect` + item.code} name="bankSelect" value={item.code} onChange={() => bankChange(item)} />
                              <span className="label-text">{item.name}</span>
                            </label>
                          </li>
                        )
                      }
                    })
                    :
                    <></>
                }
              </ul>
            </div>

            <div className="bank-wrap">
              <p className="bank-title">증권사</p>
              <ul className="bank-list">
                {
                  props.banks ?
                    props.banks.map((item, index) => {
                      if(item.type === "F"){
                        return (
                          <li key={index}>
                            <label htmlFor={`bankSelect` + item.code} className="btn-radio">
                              <input type="radio" id={`bankSelect` + item.code} name="bankSelect" value={item.code} onChange={() => bankChange(item)} />
                              <span className="label-text">{item.name}</span>
                            </label>
                          </li>
                        )
                      }
                    })
                    :
                    <></>
                }
              </ul>
            </div>
          </div>
          <div className="modal-footer btn-wrap">
            <button type="button" className={`btn${bank ? " btn-active" : " btn-disabled"}`} onClick={bankSelect}>선택</button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PointBankModal;