import React from 'react';
import style from './WidthFullBtn.module.css'
const WidthFullBtn = (props) => {
    return (
        <div className={style["btn-box"]}>
            <button disabled={props.disabled} onClick={props.onClick}>{props.title}</button>
        </div>
    );
};

export default WidthFullBtn;