import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NotificationService from '../services/NotificationService';

const Header = (props) => {
  const [count, setCount] = useState();

  // 앱 알림 카운트 조회
  const fetchItem = async () => {
    const param = {
      filter: 'ds_receive_yn=N'
    };
    try {
      const result = await NotificationService.fetchNotificationCount(param);
      const data = result.data;
      setCount(data);

    } catch (error) {
      const error_code = error.response.data.error_code;
      const message = error.response.data.message;
      if (error_code < 0) {
        alert(message);
      }
    } finally {
    }
  }

  useEffect(() => {
    if (props.user) {
      fetchItem();  // 앱 알림 카운트 조회
    }
  }, [props.user]);

  return (
    <header>
      <div className="header">
        <h1 className="logo">
          <Link to="/home"><span className="a11y">수퍼빈</span></Link>
        </h1>
        <Link to="/notification" className="btn btn-notification">
          <span className="a11y">알림</span>
          <span className="notification-number">{count}</span>
        </Link>
      </div>
    </header>
  );
}

export default Header;