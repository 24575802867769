import React from 'react';
import Button from '../Button/Button';
import style from './TransParentButton.module.css'
const TransParentButton = ({title,disabled,onClick}) => {
    return (
        <div className={style['transaction-button-box']}>
            <Button title={title} onClick={onClick} disabled={disabled}></Button>
        </div>
    );
};

export default TransParentButton;