// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RefundPasswordRegist_page-start__3xGHw{\n    height: 100vh;\n    background: rgba(0, 0, 0, 0.8);\n    padding : 5px 5px;\n    margin : -19px -18px;\n    display: flex;\n}", "",{"version":3,"sources":["webpack://src/pages/refund/password/regist/RefundPasswordRegist.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,iBAAiB;IACjB,oBAAoB;IACpB,aAAa;AACjB","sourcesContent":[".page-start{\n    height: 100vh;\n    background: rgba(0, 0, 0, 0.8);\n    padding : 5px 5px;\n    margin : -19px -18px;\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page-start": "RefundPasswordRegist_page-start__3xGHw"
};
export default ___CSS_LOADER_EXPORT___;
