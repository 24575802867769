import { useEffect, useState } from "react";
import axios from 'axios';
import { Cookies } from 'react-cookie'
import UserService from "../services/UserService";
import AuthHttpHelper from "../helpers/HttpHelper/AuthHttpHelper";
const AuthStore = () => {
  const [user, setUser] = useState(null);
  const cookies = new Cookies();
  //채필규
  const searchToken = async () =>{
    if(cookies.get("token")){
      try{
        window.nativeApi.execute(window.NativeApiMethod.GET_TOKEN_INFO, {}, window.NativeApiCallbackMethod.GET_TOKEN_INFO);
        window[window.NativeApiCallbackMethod.GET_TOKEN_INFO] = function (response) {
          // cookies.set("token",response.token? response.token : response)
          if(!response.refresh_token){
            alert("토큰을 불러오지 못했습니다. 다시 로그인 해주세요");
            logout()
          }else{
            cookies.set("refresh_token",response.refresh_token)
            searchUserInfo(cookies.get("token"))
          }
        }
      }catch(err){
        alert("기기 오류로 로그아웃 되었습니다. 다시 로그인 해주세요");
        logout()
      }
      // searchUserInfo(cookies.get("token"))
    }else{
      try{
        window.nativeApi.execute(window.NativeApiMethod.GET_TOKEN_INFO, {}, window.NativeApiCallbackMethod.GET_TOKEN_INFO);
        window[window.NativeApiCallbackMethod.GET_TOKEN_INFO] = function (response) {
          cookies.set("token",response.token? response.token : response)
          cookies.set("refresh_token",response.refresh_token)
          if(!response.refresh_token || !response.token){
            alert("토큰을 불러오지 못했습니다. 다시 로그인 해주세요");
            logout()
          }else{
            searchUserInfo(response.token? response.token : response)
          }
        }
      }catch(err){
        alert("기기 오류로 로그아웃 되었습니다. 다시 로그인 해주세요");
        logout()
      }
    }
  }

  const searchUserInfo = async (token) => {
    axios.defaults.headers.common['x-token'] = token;
    try {
      //test용
      const result = await AuthHttpHelper.authrizeAccessToken()
      // setUser(result)
      //test용
      window.nativeApi.execute(window.NativeApiMethod.USER_INFO_GET, {}, window.NativeApiCallbackMethod.USER_INFO_GET);
      window[window.NativeApiCallbackMethod.USER_INFO_GET] = function (response) {
        //Api에서 가져온 값과 Native에서 가져온 값(response) 함침
        response.os = response.os? response.os:"Null"
        response.app_version = response.app_version? response.app_version:"Null"
        var dataAdd = Object.assign(result, response);
        dataAdd.user_sno = dataAdd.no_sno;
        dataAdd.user_name = dataAdd.ds_name;
        dataAdd.cell_phone = dataAdd.ds_cell_phone;
        dataAdd.is_start = true;
        setUser(dataAdd)
      }
    } catch (error) {
      console.error(error)
      alert("사용자 정보가 만료 되었습니다. 다시 로그인 해주세요");
      logout()
    } finally {
      window.localStorage.setItem('modalPopupState', true);
    }
  };
  useEffect(() => {
    if(!window.location.pathname.startsWith('/login') && !window.location.pathname.startsWith('/mypage/withdraw-success')){
      searchToken()
    }
  }, []);

  // // 로그아웃
  const logout = () => {
    try{
      window.nativeApi.execute(window.NativeApiMethod.LOGOUT, {}, window.NativeApiCallbackMethod.LOGOUT);
      window[window.NativeApiCallbackMethod.LOGOUT] = function (response) {
      }
      setUser(null);
    }catch(err){
      console.error(err)
    }
  }

  return {
    user,
    logout
  };
}

export default AuthStore;