import React from 'react';
import PrivateVerificateCodeComponent from '../../common-privateInfo-component/verificate-code-component/PrivateVerificateCodeComponent';
import ChangeMainPhoneVerificationCodeViewModel from './ChangeMainPhoneVerificationCodeViewModel';

const ChangeMainPhoneVerificationCode = () => {
    const {verificateSms,resendSms,buttonEnable} = ChangeMainPhoneVerificationCodeViewModel.VerificatePhoneSms()
    return (
        <PrivateVerificateCodeComponent setValidateion={function (data) {
            if(buttonEnable){
                verificateSms({
                    code : data
                })
            }
        } }
        resendCode={function () {
            resendSms()
        } }></PrivateVerificateCodeComponent>
    );
};

export default ChangeMainPhoneVerificationCode;