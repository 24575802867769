// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TelecomInput_input-box__1L5hs{width: 100%;}\n.TelecomInput_input-box__1L5hs > p{font-weight: 400; font-size: 14px; line-height: 17px; color: #9E9E9E; }\n.TelecomInput_telecom-box__pI1FF{padding: 10px 0px; display: flex;}\n.TelecomInput_telecom-box__pI1FF > button{flex: 1 1; margin: 0px 7px 0px 0px;}\n.TelecomInput_telecom-box__pI1FF > button:last-of-type{margin: 0px;}", "",{"version":3,"sources":["webpack://src/Modules/MyPageModules/Private/TelecomInput/TelecomInput.module.css"],"names":[],"mappings":"AAAA,+BAAW,WAAW,CAAC;AACvB,mCAAe,gBAAgB,EAAE,eAAe,EAAE,iBAAiB,EAAE,cAAc,EAAE;AACrF,iCAAa,iBAAiB,EAAE,aAAa,CAAC;AAC9C,0CAAsB,SAAO,EAAE,uBAAuB,CAAC;AACvD,uDAAmC,WAAW,CAAC","sourcesContent":[".input-box{width: 100%;}\n.input-box > p{font-weight: 400; font-size: 14px; line-height: 17px; color: #9E9E9E; }\n.telecom-box{padding: 10px 0px; display: flex;}\n.telecom-box > button{flex: 1; margin: 0px 7px 0px 0px;}\n.telecom-box > button:last-of-type{margin: 0px;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-box": "TelecomInput_input-box__1L5hs",
	"telecom-box": "TelecomInput_telecom-box__pI1FF"
};
export default ___CSS_LOADER_EXPORT___;
