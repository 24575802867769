import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import qs from 'query-string';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Service from "../../layout/terms/Service";
import Privacy from "../../layout/terms/Privacy";

const TermsAndPoilcies = () => {
  const location = useLocation();
  const { search } = location;
  const params = qs.parse(decodeURI(search));	// 문자열의 쿼리스트링을 Object로 변환
  const { page } = params;

  const [value, setValue] = useState(Number(page) || 0);
  const TabPanel = (props) => {

    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="page-setting">
        <div className="tab-wrap tab-terms">
          <AppBar position="static" color="default">
            <Tabs
              className="tab-control"
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab className="btn" label="이용약관" {...a11yProps(0)} />
              <Tab className="btn" label="개인정보처리방침" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel className="tab-content" value={value} index={0}>
            <Service />
          </TabPanel>
          <TabPanel className="tab-content" value={value} index={1}>
            <Privacy />
          </TabPanel>
        </div>
      </div>
    </>
  )
}
export default TermsAndPoilcies;