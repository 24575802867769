import React, { useEffect } from 'react';
import style from './ModalBox.module.css'
const ModalBox = ({children,transparent}) => {
    useEffect(() => {
        document.body.style.cssText = `
          position: fixed; 
          top: -${window.scrollY}px;
          overflow-y: scroll;
          width: 100%;`;
        return () => {
          const scrollY = document.body.style.top;
          document.body.style.cssText = "";
          window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
        };
      }, []);
    return (
        <>
        <div className={style["modal-background"]}></div>
            <div className={style["modal-wrap"]}>
                <div className={transparent ? style["modal-transparents"]:style["modal"]}>
                    {children}
                </div>
            </div>
        </>
    );
};

export default ModalBox;