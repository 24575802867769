// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChangeAccountPasswordDone_page-start__1UNsX{\n    background: white;\n    display: flex;\n}\n.ChangeAccountPasswordDone_root__2OQgz{width: 100%; text-align: center; margin: auto;}\n.ChangeAccountPasswordDone_root__2OQgz > svg{margin-bottom: 30px;}\n.ChangeAccountPasswordDone_root__2OQgz > .ChangeAccountPasswordDone_title__2up-f{font-weight: 700; font-size: 24px; line-height: 34px; padding-bottom: 18px;}\n.ChangeAccountPasswordDone_root__2OQgz > .ChangeAccountPasswordDone_sub-title__31T9f{font-weight: 400; font-size: 18px; line-height: 28px; color: #757575;}\n.ChangeAccountPasswordDone_bottom-container-box__1p3QQ{padding-top: 61px;}", "",{"version":3,"sources":["webpack://src/pages/mypage/change-account-password/done/ChangeAccountPasswordDone.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;AACjB;AACA,uCAAM,WAAW,EAAE,kBAAkB,EAAE,YAAY,CAAC;AACpD,6CAAY,mBAAmB,CAAC;AAChC,iFAAe,gBAAgB,EAAE,eAAe,EAAE,iBAAiB,EAAE,oBAAoB,CAAC;AAC1F,qFAAmB,gBAAgB,EAAE,eAAe,EAAE,iBAAiB,EAAE,cAAc,CAAC;AACxF,uDAAsB,iBAAiB,CAAC","sourcesContent":[".page-start{\n    background: white;\n    display: flex;\n}\n.root{width: 100%; text-align: center; margin: auto;}\n.root > svg{margin-bottom: 30px;}\n.root > .title{font-weight: 700; font-size: 24px; line-height: 34px; padding-bottom: 18px;}\n.root > .sub-title{font-weight: 400; font-size: 18px; line-height: 28px; color: #757575;}\n.bottom-container-box{padding-top: 61px;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page-start": "ChangeAccountPasswordDone_page-start__1UNsX",
	"root": "ChangeAccountPasswordDone_root__2OQgz",
	"title": "ChangeAccountPasswordDone_title__2up-f",
	"sub-title": "ChangeAccountPasswordDone_sub-title__31T9f",
	"bottom-container-box": "ChangeAccountPasswordDone_bottom-container-box__1p3QQ"
};
export default ___CSS_LOADER_EXPORT___;
