import { useEffect , useContext, useState} from 'react';
import { Link ,useHistory} from 'react-router-dom';
import { LockerIcon, MoneyIcon, PersonIcon, RightIcon } from '../../assets/icons/SVG';
import style from './Mypage.module.css'
import MypageAuthrizationService from '../../services/MypageAuthrizationService';
import MypageViewModel from './MypageViewModel';
import { AppContext } from '../../AppContext';
const Mypage = () => {
  const history = useHistory()
  const { auth } = useContext(AppContext);
  /** 
   * description : 
   * 환전 비밀번호가 있는지 없는지 확인하여 각 페이지로 이동을 시킴.
  */
  const checkRegistedExchangePassword = async() => {
    if(auth.user.refund_password){
      history.push({pathname:"/mypage/change-refund-password",query:{active:"refund_password_change"}},'/mypage/change-refund-password')
    }else{
      history.push('/mypage/regist-refund-password')
    }
  }
  return (
    <div className={style["page-start"]}>
        <div className={style["root"]}>
            <div className={style.title}>내 정보 관리</div>
            <div className={style["sub-title"]}>수퍼빈에 등록된 회원님의 정보를 수정할 수 있습니다.</div>
            <ul>
              <li>
                <Link to="/mypage/change-private-info">
                  <PersonIcon></PersonIcon><p>개인정보 변경</p><RightIcon></RightIcon>
                </Link>
              </li>
              <li>
                <Link to="/mypage/change-account-password">
                  <LockerIcon></LockerIcon><p>로그인 비밀번호 변경</p><RightIcon></RightIcon>
                </Link>
              </li>
              <li>
                <a onClick={()=>{checkRegistedExchangePassword()}}>
                  <MoneyIcon></MoneyIcon><p>환전 비밀번호 변경</p><RightIcon></RightIcon>
                </a>
              </li>
            </ul>
        </div>
    </div>
  );
}

export default Mypage;