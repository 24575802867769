import React from 'react';

const ChangePrivateInfoFailedViewModel =  {
    checkActivationName:async(query) => {
        var result ={}
        if(!query?.active){
            return {
                title:"비정상 상태",
                description:"현재 비정상 페이지의 상태입니다."
            }; 
        }else{
            switch(query.active){
                case "change_name": 
                result = {
                    title:"이름 변경 실패",
                    description:"본인과 다른 정보를 입력하였습니다. 로그인한 계정의 개인정보로만 변경 가능합니다."
                }; 
                break;
                case "change_main_phone": 
                result = {
                    title:"번호 변경 실패",
                    description:"본인과 다른 정보를 입력하였습니다. 로그인한 계정의 명의의 전화번호로만 변경 가능합니다."
                }; 
                break;
                case "change_sub_phone": 
                result = {
                    title:"번호 변경 실패",
                    description:"본인과 다른 정보를 입력하였습니다. 로그인한 계정의 명의의 전화번호로만 변경 가능합니다."
                }; 
                break;
                case "add_sub_phone": 
                result = {
                    title:"번호 추가 실패",
                    description:"본인과 다른 정보를 입력하였습니다. 로그인한 계정 명의의 전화번호로만 추가 가능합니다."
                }; 
                break;
                case "change_etc_data": 
                result = {
                    title:"내 정보 변경 실패",
                    description:"내 정보 변경에 실패하였습니다. 지속 발생 시 관리자에게 문의 부탁드립니다."
                }; 
                break;
                default:
                result = {
                    title:"정상적이지 않은 상태",
                    description:""
                }
                break;
            }
        }

        return result
    }
};

export default ChangePrivateInfoFailedViewModel;