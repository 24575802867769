import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Util from '../../../../helpers/Util';
import DefinitionService from '../../../../services/DefinitionService';
const RefundRequestDone = () => {
    const history = useHistory()
    const location = useLocation()
    const [bank_name,setBankName] = useState("")
    const onExchangeList = () => {
      history.replace('/refund')
    }
    const getBank = async () => {
      try {
        const result = await DefinitionService.getBank(location.query.bank_idx);
        setBankName(result.name)
      } catch (error) {
        console.error(error)
      }
    };
    useEffect(()=>{
      getBank()
    },[])
    return (
      <>
        <div className="page-point">
          <div className="exchange-complete">
            <div className="complete-visual">
              <p>환전 신청 완료</p>
              <p className="sub">
                환전 신청이 완료되었습니다.
                <br />
                차주 월/화요일에 입금 예정입니다.
                <span>(공휴일 제외)</span>
              </p>
            </div>
            <div>
              <div className="complete-info">
                <dl className="info-list">
                  <dt>예금주</dt>
                  <dd>{location.query.account_name}</dd>
                  <dt>신청일</dt>
                  <dd>{Util.todayDt("YYYY.MM.DD")}</dd>
                  <dt>금액</dt>
                  <dd>{Util.formatNumber(location.query.points)} 원</dd>
                  <dt>계좌정보</dt>
                  <dd>
                    <span className="bank-name">{bank_name ?? "은행조회실패"}</span>
                    {location.query.account}
                  </dd>
                  <dt>입금예정일</dt>
                  <dd>다음 주 월~화요일(공휴일 제외)</dd>
                </dl>
              </div>
              <div className="btn-wrap">
                <button className="btn btn-primary" onClick={onExchangeList}>
                  환전 내역 보러가기
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default RefundRequestDone;