import React from 'react';
import style from './ConfirmModal.module.css'
import WidthFullBtn from '../../Buttons/widthFullBtn/WidthFullBtn';
const ComformModal = (props) => {
    /** 
    * @param
    * title
    * description
    * onClick
    * onCancel
    */
    return (
        <div className={style["modal-wrap"]}>
            <div className={style["modal"]}>
                <div className={style['title']}><p>{props.title}</p></div>
                <div className={style['description']}><p>{props.description}</p></div>
                <div className={style['bottom-btn']}>
                    <WidthFullBtn disabled={false} title={props.btnTitle} onClick={()=>{props.onClick()}}></WidthFullBtn>
                    <WidthFullBtn disabled={false} title={props.btnTitle2} onClick={()=>{props.onCancel()}}></WidthFullBtn>
                </div>
            </div>
        </div>
    );
};

export default ComformModal;