import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import PrivateRoute from './route/PrivateRoute';

import Setting from '../pages/setting/Setting';
import NotificationSetting from '../pages/setting/NotificationSetting';
import Myrobot from '../pages/setting/Myrobot';
import TermsAndPoilcies from '../pages/terms/TermsAndPoilcies';
import Superbin from '../pages/setting/Superbin';

function SettingRouter() {
  return (
    <Switch>
      <PrivateRoute path="/setting" exact component={Setting} />
      <PrivateRoute path="/setting/notification" component={NotificationSetting} />
      {/* <PrivateRoute path="/setting/myrobot" component={Myrobot} /> */}
      {/* <Route path="/setting/terms-policies" component={TermsAndPoilcies} /> */}
      {/* <PrivateRoute path="/setting/superbin" component={Superbin} /> */}
      <Redirect path="/setting/*" to="/setting" />
    </Switch>
  );
}

export default SettingRouter;
