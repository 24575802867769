import React, { useState } from 'react';
import style from './NvmoModal.module.css'
import { TermCheckIcon } from '../../../assets/icons/SVG';
import WidthFullBtn from '../../Buttons/widthFullBtn/WidthFullBtn';
const NvmoModal = (props) => {
    const [select,setSelect] = useState(0)
    const [thirdParty,setThirdParty] = useState(false)
    return (
        <div className={style["modal-wrap"]}>
            <div className={style["modal"]}>
                <div className={style['title']}><p>알뜰폰 통신사를 선택해주세요.</p></div>
                <div className={style["select-btn-box"]}>
                    <div className={style["select-btn-margin-box"]}>
                        <button onClick={()=>{setSelect(5)}} className={`${style["select-btn"]} ${select === 5? style["select"]:style["not-select"]}`}>알뜰폰 SKT</button>
                    </div>
                    <div className={style["select-btn-margin-box"]}>
                        <button onClick={()=>{setSelect(6)}} className={`${style["select-btn"]} ${select === 6? style["select"]:style["not-select"]}`}>알뜰폰 KT</button>
                    </div>
                    <div className={style["select-btn-margin-box"]}>
                        <button onClick={()=>{setSelect(7)}} className={`${style["select-btn"]} ${select === 7? style["select"]:style["not-select"]}`}>알뜰폰 LGU+</button>
                    </div>
                </div>
                <div className={style["checkBtn"]} onClick={()=>{setThirdParty(!thirdParty);}}>
                    <TermCheckIcon checked={thirdParty}></TermCheckIcon>
                    <div>
                        <p onClick={()=>{setThirdParty(!thirdParty);}}>[필수] 개인정보 제 3자 제공 동의</p>
                        <a href="https://cert.vno.co.kr/app/agree/agree_m_05.jsp">
                            <p>보기</p>
                        </a>  
                    </div>
                </div>
                <div>
                    <WidthFullBtn disabled={!thirdParty} title={'확인'} onClick={()=>{props.SelectData(select)}}></WidthFullBtn>
                </div>
            </div>
        </div>
    );
};

export default NvmoModal;