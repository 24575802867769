// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GuardianCertificationSuccess_root__3HG5G{max-width: 440px; margin: auto; padding: 0px 20px 20px 20px;}\n.GuardianCertificationSuccess_root__3HG5G > div > .GuardianCertificationSuccess_title__zQMBu{font-weight: 700; font-size: 24px; padding-bottom: 18px; color: black;}\n.GuardianCertificationSuccess_description__2mKeC{font-size: 18px; line-height: 28px; color: #757575; line-height: 28px; word-break: keep-all; padding-bottom: 50px;}\n.GuardianCertificationSuccess_page-start__2gifn{\n    background: white;\n    display: flex;\n}\n.GuardianCertificationSuccess_next-btn__3pEdH{background-color: #3997FE; width: 100%; font-size: 18px; font-weight: bold; color: white; padding: 19px; border-radius: 10px; border: none; cursor: pointer;}\n", "",{"version":3,"sources":["webpack://src/pages/guardian-certification/success/GuardianCertificationSuccess.module.css"],"names":[],"mappings":"AAAA,0CAAM,gBAAgB,EAAE,YAAY,EAAE,2BAA2B,CAAC;AAClE,6FAAqB,gBAAgB,EAAE,eAAe,EAAE,oBAAoB,EAAE,YAAY,CAAC;AAC3F,iDAAa,eAAe,EAAE,iBAAiB,EAAE,cAAc,EAAE,iBAAiB,EAAE,oBAAoB,EAAE,oBAAoB,CAAC;AAC/H;IACI,iBAAiB;IACjB,aAAa;AACjB;AACA,8CAAU,yBAAyB,EAAE,WAAW,EAAE,eAAe,EAAE,iBAAiB,EAAE,YAAY,EAAE,aAAa,EAAE,mBAAmB,EAAE,YAAY,EAAE,eAAe,CAAC","sourcesContent":[".root{max-width: 440px; margin: auto; padding: 0px 20px 20px 20px;}\n.root > div > .title{font-weight: 700; font-size: 24px; padding-bottom: 18px; color: black;}\n.description{font-size: 18px; line-height: 28px; color: #757575; line-height: 28px; word-break: keep-all; padding-bottom: 50px;}\n.page-start{\n    background: white;\n    display: flex;\n}\n.next-btn{background-color: #3997FE; width: 100%; font-size: 18px; font-weight: bold; color: white; padding: 19px; border-radius: 10px; border: none; cursor: pointer;}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "GuardianCertificationSuccess_root__3HG5G",
	"title": "GuardianCertificationSuccess_title__zQMBu",
	"description": "GuardianCertificationSuccess_description__2mKeC",
	"page-start": "GuardianCertificationSuccess_page-start__2gifn",
	"next-btn": "GuardianCertificationSuccess_next-btn__3pEdH"
};
export default ___CSS_LOADER_EXPORT___;
