import React, { useEffect, useState } from 'react';
import {useLocation, useHistory } from 'react-router-dom';
import CertificationTermsViewModel from './CertificationTermsViewModel';
import style from './CertificationTerms.module.css'
import { TermCheckIcon } from '../../../assets/icons/SVG';
const CertificationTerms = () => {
    const history = useHistory()
    const location = useLocation()
    const [allchecked,setAllchecked] = useState(false)
    const [checkItem,setCheckItem] = useState({
        privacy:false,
        service:false,
        identification:false,
        telecom:false
    })
    useEffect(()=>{
        const result = CertificationTermsViewModel.checkAllChecked(checkItem)
        setAllchecked(result)
    },[checkItem])
    const onBrowserOpen = (action) => {
        let browserurl = ''
        switch(action){
          case '1' : browserurl = 'https://cert.vno.co.kr/app/agree/agree_m_01.jsp'; break;
          case '2' : browserurl = 'https://cert.vno.co.kr/app/agree/agree_m_02.jsp'; break;
          case '4' : browserurl = 'https://cert.vno.co.kr/app/agree/agree_m_04.jsp'; break;
          case '3' : browserurl = 'https://cert.vno.co.kr/app/agree/agree_m_03.jsp'; break;
          default : browserurl = false; break;
        }
        if(browserurl){
          try{
            window.nativeApi.execute(window.NativeApiMethod.OPEN_BROWSER, {
              url: browserurl
            }, "");
          }catch(err){
            console.error(err)
          }
        }
      }
    return (
        <div className={style['page-start']}>
            <div className={style['root']}>
                <div>
                    <p className={style['title']}>수퍼빈 회원 인증</p>
                    <p className={style['description']}>수퍼빈 회원 인증(본인 인증)을 시작합니다. 본인인증기관 약관에 동의해주세요.</p>
                    <div className={style['item']}>
                        <div className={style['checkBtn']} onClick={()=>{
                            const data = {...checkItem}
                            data.privacy = !allchecked
                            data.service = !allchecked
                            data.identification = !allchecked
                            data.telecom = !allchecked
                            setCheckItem(data)
                        }}>
                            <TermCheckIcon checked={allchecked}></TermCheckIcon>
                            <p>전체동의</p>
                        </div>
                    </div>
                    <div className={style['allcheck-line']}><hr></hr></div>
                    <div className={style['item']}>
                        <div className={style['checkBtn']} onClick={()=>{
                            const data = {...checkItem}
                            data.privacy = !data.privacy
                            setCheckItem(data)
                        }}>
                            <TermCheckIcon checked={checkItem.privacy}></TermCheckIcon>
                            <p onClick={()=>{}}>[필수] 개인정보 이용 동의</p>
                        </div>
                        <a href="https://cert.vno.co.kr/app/agree/agree_m_01.jsp">
                            <p onClick={()=>{onBrowserOpen("1")}}>보기</p>
                        </a>
                    </div>
                    <div className={style['item']}>
                        <div className={style['checkBtn']} onClick={()=>{
                            const data = {...checkItem}
                            data.identification = !data.identification
                            setCheckItem(data)
                        }}>
                            <TermCheckIcon checked={checkItem.identification}></TermCheckIcon>
                            <p onClick={()=>{}}>[필수] 고유식별 정보처리 동의</p>
                        </div>
                        <a href="https://cert.vno.co.kr/app/agree/agree_m_02.jsp">
                            <p onClick={()=>{onBrowserOpen("2")}}>보기</p>
                        </a>
                    </div>
                    <div className={style['item']}>
                        <div className={style['checkBtn']} onClick={()=>{
                            const data = {...checkItem}
                            data.service = !data.service
                            setCheckItem(data)
                        }}>
                            <TermCheckIcon checked={checkItem.service}></TermCheckIcon>
                            <p onClick={()=>{}}>[필수] 서비스 이용약관 동의</p>
                        </div>
                        <a href="https://cert.vno.co.kr/app/agree/agree_m_04.jsp">
                            <p onClick={()=>{onBrowserOpen("4")}}>보기</p>
                        </a>
                    </div>
                    <div className={style['item']}>
                        <div className={style['checkBtn']} onClick={()=>{
                            const data = {...checkItem}
                            data.telecom = !data.telecom
                            setCheckItem(data)
                        }}>
                            <TermCheckIcon checked={checkItem.telecom}></TermCheckIcon>
                            <p onClick={()=>{}}>[필수] 통신사 이용약관 동의</p>
                        </div>
                        <a href="https://cert.vno.co.kr/app/agree/agree_m_03.jsp">
                            <p onClick={()=>{onBrowserOpen("3")}}>보기</p>
                        </a>
                    </div>
                </div>
                <button className={style['next-btn']} onClick={()=>{
                    history.replace({pathname:'insert-certinfo',query:location.query},'insert-certinfo')
                }} disabled={!allchecked}>동의하고 본인 인증하기</button>
            </div>
        </div>
    );
};

export default CertificationTerms;