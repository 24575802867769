import React, { useEffect, useState } from 'react';
import NvmoModal from '../../../Modal/NvmoModal/NvmoModal';
import TelecomBtn from '../../../Buttons/TelecomBtn/TelecomBtn';
import style from './TelecomInput.module.css'
const TelecomInput = (props) => {
    const [nvmoModal,setNvmoModal] = useState(false)
    const telecomName = ["","SKT","KT","LGU+","","알뜰폰 SKT","알뜰폰 KT","알뜰폰 LGU+"]
    const [isTelecomBudget,setIsTelecomBudget] = useState(false)
    const [TelecomBudgetName,setTelecomBudgetName] = useState("")
    useEffect(()=>{
        if(props.value){
            if(props.value >= 5){
                setIsTelecomBudget(true)
                setTelecomBudgetName(telecomName[props.value])
            }
            else{setIsTelecomBudget(false)}
        }else{
            setIsTelecomBudget(false)
        }
    },[props.value])
    return (
        <div className={style["input-box"]}>
            <p>통신사</p>
            <div className={style['telecom-box']}>
                <TelecomBtn enabled={props.value === 1} title={'SKT'} onBtnClick={function () {
                    props.InputData(1)
                } }></TelecomBtn>
                <TelecomBtn enabled={props.value === 2} title={'KT'} onBtnClick={function () {
                    props.InputData(2)
                } }></TelecomBtn>
                <TelecomBtn enabled={props.value === 3} title={'LGU+'} onBtnClick={function () {
                    props.InputData(3)
                } }></TelecomBtn>
                <TelecomBtn enabled={isTelecomBudget} title={isTelecomBudget ? TelecomBudgetName : "알뜰폰"} onBtnClick={function () {
                    setNvmoModal(true)
                } }></TelecomBtn>
            </div>
            {nvmoModal ? <NvmoModal
            SelectData={function (selectData) {
                props.InputData(selectData)
                setNvmoModal(false)
            } }></NvmoModal>:<></>}
        </div>
    );
};

export default TelecomInput;