import React, { useContext, useState } from 'react';

const PrivateVerificateCodeComponentViewModel =  {
    VerificateCodeHook : () => {
        const [smsValidateNum,setSmsValidateNum] = useState("")
        const [timerStatus,setTimerStatus] = useState(true)

        return{smsValidateNum,timerStatus,setSmsValidateNum,setTimerStatus}
    }
};

export default PrivateVerificateCodeComponentViewModel;