import React from "react";
import { Switch, Redirect } from "react-router-dom";
import PrivateRoute from './route/PrivateRoute';
import RefundPasswordRegist from "../pages/refund/password/regist/RefundPasswordRegist";
import RefundPasswordVerification from "../pages/refund/password/verification/RefundPasswordVerification";
import RefundRequest from "../pages/refund/request/RefundRequest";
import RefundRequestDone from "../pages/refund/request/done/RefundRequestDone";
import Refund from "../pages/refund/Refund";
function PointRouter() {
    return (
      <Switch>
        <PrivateRoute path="/refund" exact component={Refund} />
        <PrivateRoute path="/refund/request" exact  component={RefundRequest} />
        <PrivateRoute path="/refund/request/done" exact component={RefundRequestDone} />
        <PrivateRoute path="/refund/password/verification" exact component={RefundPasswordVerification} />
        <PrivateRoute path="/refund/password/regist" exact component={RefundPasswordRegist} />
        <Redirect path="/refund/*" to="/refund" />
      </Switch>
    );
  }
  
  export default PointRouter;