import React, { useState } from 'react';
import style from './DefaultInput.module.css'
const DefaultInput = (props) => {
    const [focus,setFocus] = useState()
    return (
        <div className={style["input-box"]}>
            <p className={style.title}>{props.title}</p>
            <input type={props.type}
             placeholder={props.holder} 
             value={props.value}
             onFocus={()=>{setFocus(true);props.onFocus()}}
             onBlur={()=>{setFocus(false);props.onBlur()}}
             onChange={(e)=>{
                const value = e.target.value
                props.InputData(value)
                }} className={style.title}></input>
            <hr className={focus ? style["onfocus"]:style["nonfocus"]}></hr>
            {props.description ? <div className={style["description"]}>{props.description}</div>:<></>}
        </div>
    );
};

export default DefaultInput;