import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import Progress from '../../layout/Progress';
import 'moment/locale/ko';
import moment from 'moment';
import PointService from '../../services/PointService';
import Util from '../../helpers/Util';

const Refund = () => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(0);
    const [startValue, setStartValue] = useState(Util.todayDt("YYYY.MM.DD"));
    const [endValue, setEndValue] = useState(Util.todayDt("YYYY.MM.DD"));
    const [submitted, setSubmitted] = useState(false);
  
    const [loading, setLoading] = useState([false, false, false, false, false]);  // 로딩 여부
  
    /* 환전 내역 조회 조건 */
    const [list, setList] = useState([[], [], [], [], []]); // 리스트
    const [totalCount, setTotalCount] = useState([0, 0, 0, 0, 0]);  // 전체 건수
    const [currentPage, setCurrentPage] = useState([0, 0, 0, 0, 0]);  // 현재 페이지
    const limit = 10;
    const [offset, setOffset] = useState([0, 0, 0, 0, 0]);
    const [startDt, setStartDt] = useState(Util.todayDt("YYYY.MM.DD"));
    const [endDt, setEndDt] = useState(Util.todayDt("YYYY.MM.DD"));
  
    // 환전 내역 조회
    const searchList = useCallback(async () => {
      let tabIndex = value;
      setLoading(thisState => {
        let state = [...thisState];
        state[tabIndex] = true;
        return state;
      });
  
      try {
        const filter = { 
          mod_at:"btw:"+moment(startDt, "YYYY.MM.DD").format("YYYY-MM-DD")+","+moment(endDt, "YYYY.MM.DD").add(1, 'days').format("YYYY-MM-DD")
        }

        const param = {
          order : JSON.stringify(["-idx"]),
          filter: filter,
          paging : {
            offset: offset[tabIndex],
            limit : limit
          }
        };
        const data = await PointService.getRefundList(param);
        data.list.forEach(element => {
          const account_start = element.transaction_method.account_no.slice(0,3)
          const account_end =  element.transaction_method.account_no.slice(-3)
          let middle_string = ""
          for(let i=0; i<element.transaction_method.account_no.length-6; i++){
            middle_string = middle_string + '*'
          }
          element.display_account_no = account_start + middle_string + account_end
        });
        // const data = result.data;
        // console.log(result)
        setCurrentPage(thisState => {
          let state = [...thisState];
          state[tabIndex] = offset[tabIndex] / limit;
          //window.nativeApi.execute(window.NativeApiMethod.CHANGE_URL_WEB_VIEW,{url:JSON.stringify(state) + "Exchange List setCurrentPage"});
          return state;
        });
        setTotalCount(thisState => {
          let state = [...thisState];
          state[tabIndex] = data.paging.total_cnt;
          //window.nativeApi.execute(window.NativeApiMethod.CHANGE_URL_WEB_VIEW,{url:JSON.stringify(state) + "Exchange List setTotalCount"});
          return state;
        });
        setList(thisState => {
          let state = { ...thisState };
          state[tabIndex] = [
            ...state[tabIndex],
            ...data.list
          ];
          //window.nativeApi.execute(window.NativeApiMethod.CHANGE_URL_WEB_VIEW,{url:JSON.stringify(state) + "Exchange List setList"});
          return state;
        });
  
      } catch (error) {
        // window.nativeApi.execute(window.NativeApiMethod.CHANGE_URL_WEB_VIEW,{url:JSON.stringify(error) + "Exchange List ERR"});
      } finally {
        setLoading(thisState => {
          let state = [...thisState];
          state[tabIndex] = false;
          return state;
        });
      }
    }, [value, startDt, endDt, offset]);
  
    // 더보기 버튼 핸들러
    const onMore = (index) => {
      setOffset(thisState => {
        let state = [...thisState];
        state[index] = (currentPage[index] + 1) * limit;
        return state;
      });
    }
    useEffect(()=>{
      handleChange(null , 2)
    },[])
    // 탭 변경 핸들러
    const handleChange = (event, newValue) => {
      setList([[], [], [], [], []]);
      setOffset([0, 0, 0, 0, 0]);
  
      const today = Util.todayDt("YYYY.MM.DD");
      switch (newValue) {
        case 0: // 오늘
          setStartDt(today);
          setEndDt(today);
          break;
        case 1: // 어제
          const before_yesterday = Util.addDaysDt("YYYY.MM.DD", -1)
          const yesterday = Util.addDaysDt("YYYY.MM.DD", -1);
          setStartDt(before_yesterday);
          setEndDt(yesterday);
          break;
        case 2: // 7일
          setStartDt(Util.addDaysDt("YYYY.MM.DD", -6));
          setEndDt(today);
          break;
        case 3: // 30일
          setStartDt(Util.addDaysDt("YYYY.MM.DD", -29));
          setEndDt(today);
          break;
        case 4: // 달력
          setStartDt(startValue);
          setEndDt(endValue);
          break;
        default:
          break;
      }
      setValue(newValue);
    };
  
    // 달력 열기
    const handleClickOpen = (event) => {
      setOpen(true);
    };
  
    // 달력 닫기
    const handleClose = () => {
      setOpen(false);
    };
  
    const dayValid = (current) => {
      const maxDay = moment(new Date()).subtract(6, 'months');
      return current.isBetween(maxDay, new Date());
    }
  
    const startChange = (event) => {
      setStartValue(moment(event).format('YYYY.MM.DD'));
    }
  
    const endChange = (event) => {
      setEndValue(moment(event).format('YYYY.MM.DD'));
    }
  
    // 날짜 검색하기
    const onSearchDate = () => {
      if (submitted) {
        setList(thisState => {
          let state = { ...thisState };
          state[4] = [];
          return state;
        });
        setCurrentPage(thisState => {
          let state = [...thisState];
          state[4] = 0;
          return state;
        });
        setTotalCount(thisState => {
          let state = [...thisState];
          state[4] = 0;
          return state;
        });
        setOffset(thisState => {
          let state = [...thisState];
          state[4] = 0;
          return state;
        });
        setStartDt(startValue);
        setEndDt(endValue);
        setOpen(false);
      }
    }
  
    const dateValidate = useCallback(() => {
      if (startValue && endValue) {
        const startDay = Number(startValue.split('.').join(''));
        const endDay = Number(endValue.split('.').join(''));
  
        if (startDay <= endDay) {
          setSubmitted(true);
        } else {
          setSubmitted(false);
          return false
        }
      } else {
        setSubmitted(false);
        return false
      }
      return true
    }, [startValue, endValue]);
  
    useEffect(() => {
      dateValidate();
    }, [dateValidate]);
  
    useEffect(() => { // 더보기 클릭시
      searchList(); // 환전 적립 내역 조회
    }, [searchList]);
  
    const renderInput = ( props, openCalendar, closeCalendar ) => {
      return (
        <>
          <input {...props} />
          <button type="button" className="btn-close" onClick={closeCalendar}>
            <span className="a11y">닫기</span>
          </button>
        </>
      )
    }
  
    return (
      <>
        <div className="page-point">
          <div className="tab-wrap">
            <AppBar position="static" color="default">
              <Tabs
                className="tab-control"
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab className="btn" label="오늘" {...a11yProps(0)} />
                <Tab className="btn" label="어제" {...a11yProps(1)} />
                <Tab className="btn" label="일주일" {...a11yProps(2)} />
                <Tab className="btn" label="30일" {...a11yProps(3)} />
                <Tab className="btn calendar" {...a11yProps(4)} onClick={handleClickOpen} />
              </Tabs>
            </AppBar>
            <TabPanel className="tab-content" value={value} index={0}>
              <div className="exchange-list">
                {!list[0].length ?
                  loading[0] ? <></>
                    :
                    <div className="item-empty">환전 내역이 없습니다.</div>
                  :
                  list[0].map((item, index) => {
                    var status_class = '';
                    switch (item.status_code) {
                      case 'REQ': // 신청완료
                        status_class = 'request';
                        break;
                      case 'CAN': // 신청취소
                        status_class = 'cancel';
                        break;
                      case 'FIN': // 환전완료
                        status_class = 'finish';
                        break;
                      default:
                        break;
                    }
                    return (
                      <div className='exchange-item-box'>
                        <div key={index} className={"exchange-item " + status_class}>
                          <div className="item-info">
                            <span className="state"></span>
                            <p>{item.transaction_method.bank.name} {item.display_account_no} </p>
                            <p className="date">{Util.formatDateTime(item.reg_at)}</p>
                          </div>
                          <span className="point">{Util.formatNumber(item.request_point)}</span>
                        </div>
                        {status_class === "request"? <span className='exchange-item-description'>입금 예정일 : 신청일 기준 그 다음 주 월~화요일 (공휴일 제외)</span>:<></>}
                      </div>
                    );
                  })
                }
              </div>
              <Progress loading={loading[0]} />
              {
                !loading[0] && limit + offset[0] < totalCount[0] ?
                  <div className="btn-wrap">
                    <button type="button" className="btn btn-active" onClick={() => { onMore(0) }} >더보기</button>
                  </div>
                  :
                  <></>
              }
            </TabPanel>
            <TabPanel className="tab-content" value={value} index={1}>
              <div className="exchange-list">
                {!list[1].length ?
                  loading[1] ? <></>
                    :
                    <div className="item-empty">환전 내역이 없습니다.</div>
                  :
                  list[1].map((item, index) => {
                    var status_class = '';
                    switch (item.status_code) {
                      case 'REQ': // 신청완료
                        status_class = 'request';
                        break;
                      case 'CAN': // 신청취소
                        status_class = 'cancel';
                        break;
                      case 'FIN': // 환전완료
                        status_class = 'finish';
                        break;
                      default:
                        break;
                    }
                    return (
                      <div className='exchange-item-box'>
                        <div key={index} className={"exchange-item " + status_class}>
                          <div className="item-info">
                            <span className="state"></span>
                            <p>{item.transaction_method.bank.name} {item.display_account_no} </p>
                            <p className="date">{Util.formatDateTime(item.reg_at)}</p>
                          </div>
                          <span className="point">{Util.formatNumber(item.request_point)}</span>
                        </div>
                        {status_class === "request"? <span className='exchange-item-description'>입금 예정일 : 신청일 기준 그 다음 주 월~화요일 (공휴일 제외)</span>:<></>}
                      </div>
                    );
                  })
                }
              </div>
              <Progress loading={loading[1]} />
              {
                !loading[1] && limit + offset[1] < totalCount[1] ?
                  <div className="btn-wrap">
                    <button type="button" className="btn btn-active" onClick={() => { onMore(1) }} >더보기</button>
                  </div>
                  :
                  <></>
              }
            </TabPanel>
            <TabPanel className="tab-content" value={value} index={2}>
              <div className="exchange-list">
                {!list[2].length ?
                  loading[2] ? <></>
                    :
                    <div className="item-empty">환전 내역이 없습니다.</div>
                  :
                  list[2].map((item, index) => {
                    var status_class = '';
                    switch (item.status_code) {
                      case 'REQ': // 신청완료
                        status_class = 'request';
                        break;
                      case 'CAN': // 신청취소
                        status_class = 'cancel';
                        break;
                      case 'FIN': // 환전완료
                        status_class = 'finish';
                        break;
                      default:
                        break;
                    }
                    return (
                      <div className='exchange-item-box'>
                        <div key={index} className={"exchange-item " + status_class}>
                          <div className="item-info">
                            <span className="state"></span>
                            <p>{item.transaction_method.bank.name} {item.display_account_no} </p>
                            <p className="date">{Util.formatDateTime(item.reg_at)}</p>
                          </div>
                          <span className="point">{Util.formatNumber(item.request_point)}</span>
                        </div>
                        {status_class === "request"? <span className='exchange-item-description'>입금 예정일 : 신청일 기준 그 다음 주 월~화요일 (공휴일 제외)</span>:<></>}
                      </div>
                    );
                  })
                }
              </div>
              <Progress loading={loading[2]} />
              {
                !loading[2] && limit + offset[2] < totalCount[2] ?
                  <div className="btn-wrap">
                    <button type="button" className="btn btn-active" onClick={() => { onMore(2) }} >더보기</button>
                  </div>
                  :
                  <></>
              }
            </TabPanel>
            <TabPanel className="tab-content" value={value} index={3}>
              <div className="exchange-list">
                {!list[3].length ?
                  loading[3] ? <></>
                    :
                    <div className="item-empty">환전 내역이 없습니다.</div>
                  :
                  list[3].map((item, index) => {
                    var status_class = '';
                    switch (item.status_code) {
                      case 'REQ': // 신청완료
                        status_class = 'request';
                        break;
                      case 'CAN': // 신청취소
                        status_class = 'cancel';
                        break;
                      case 'FIN': // 환전완료
                        status_class = 'finish';
                        break;
                      default:
                        break;
                    }
                    return (
                      <div className='exchange-item-box'>
                        <div key={index} className={"exchange-item " + status_class}>
                          <div className="item-info">
                            <span className="state"></span>
                            <p>{item.transaction_method.bank.name} {item.display_account_no} </p>
                            <p className="date">{Util.formatDateTime(item.reg_at)}</p>
                          </div>
                          <span className="point">{Util.formatNumber(item.request_point)}</span>
                        </div>
                        {status_class === "request"? <span className='exchange-item-description'>입금 예정일 : 신청일 기준 그 다음 주 월~화요일 (공휴일 제외)</span>:<></>}
                      </div>
                    );
                  })
                }
              </div>
              <Progress loading={loading[3]} />
              {
                !loading[3] && limit + offset[3] < totalCount[3] ?
                  <div className="btn-wrap">
                    <button type="button" className="btn btn-active" onClick={() => { onMore(3) }} >더보기</button>
                  </div>
                  :
                  <></>
              }
            </TabPanel>
            <TabPanel className="tab-content" value={value} index={4}>
              {/* 기간 선택했을때 적립 내역이 있는 경우 */}
              <div className="period">
                <span>{startDt} ~ {endDt}</span>
                <span className="total">총 <em>{totalCount[4]}건</em></span>
              </div>
              <div className="exchange-list">
                {!list[4].length ?
                  loading[4] ? <></>
                    :
                    <div className="item-empty">환전 내역이 없습니다.</div>
                  :
                  list[4].map((item, index) => {
                    var status_class = '';
                    switch (item.status_code) {
                      case 'REQ': // 신청완료
                        status_class = 'request';
                        break;
                      case 'CAN': // 신청취소
                        status_class = 'cancel';
                        break;
                      case 'FIN': // 환전완료
                        status_class = 'finish';
                        break;
                      default:
                        break;
                    }
                    return (
                      <div className='exchange-item-box'>
                        <div key={index} className={"exchange-item " + status_class}>
                          <div className="item-info">
                            <span className="state"></span>
                            <p>{item.transaction_method.bank.name} {item.display_account_no} </p>
                            <p className="date">{Util.formatDateTime(item.reg_at)}</p>
                          </div>
                          <span className="point">{Util.formatNumber(item.request_point)}</span>
                        </div>
                        {status_class === "request"? <span className='exchange-item-description'>입금 예정일 : 신청일 기준 그 다음 주 월~화요일 (공휴일 제외)</span>:<></>}
                      </div>
                    );
                  })
                }
              </div>
              <Progress loading={loading[4]} />
              {
                !loading[4] && limit + offset[4] < totalCount[4] ?
                  <div className="btn-wrap">
                    <button type="button" className="btn btn-active" onClick={() => { onMore(4) }} >더보기</button>
                  </div>
                  :
                  <></>
              }
            </TabPanel>
          </div>
        </div>
  
        <Modal
          className="modal modal-bottom modal-calendar"
          open={open}
          onClose={handleClose}
          disableRestoreFocus
          disableBackdropClick
        >
          <div className="modal-inner">
            <div className="modal-header">
              <header>
                <p>날짜 지정설정 검색</p>
                <p className="sub">최대 6개월까지 조회가 가능합니다.</p>
              </header>
            </div>
            <div className="modal-content">
              <form>
                <fieldset>
                  <legend className="a11y">날짜 지정</legend>
                  <div className="date-wrap">
                    <span className="a11y">시작일</span>
                    <Datetime
                      inputProps={{
                        id: "startDay",
                        name: "startDay",
                        readOnly: true
                      }}
                      locale="kr"
                      dateFormat="YYYY.MM.DD"
                      timeFormat={false}
                      closeOnSelect
                      isValidDate={dayValid}
                      onChange={startChange}
                      value={startValue}
                      renderInput={( props, openCalendar, closeCalendar ) => renderInput( props, openCalendar, closeCalendar )}
                    />
                    <span className="tilde">~</span>
                    <span className="a11y">종료일</span>
                    <Datetime
                      inputProps={{
                        id: "endDay",
                        name: "endDay",
                        readOnly: true
                      }}
                      locale="kr"
                      dateFormat="YYYY.MM.DD"
                      timeFormat={false}
                      closeOnSelect
                      isValidDate={dayValid}
                      onChange={endChange}
                      value={endValue}
                      renderInput={( props, openCalendar, closeCalendar ) => renderInput( props, openCalendar, closeCalendar )}
                    />
                  </div>
                </fieldset>
              </form>
            </div>
            <div className="modal-footer btn-wrap">
              <button type="button" className="btn btn-primary btn-close" onClick={handleClose}>
                <span className="a11y">닫기</span>
              </button>
              <button type="button" className={`btn${submitted ? " btn-active" : " btn-disabled"}`} onClick={onSearchDate}>날짜 검색하기</button>
            </div>
          </div>
        </Modal>
      </>
    )
}
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  const a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

export default Refund;