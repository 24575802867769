import React, { useContext,useState, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { Link } from "react-router-dom";
import PointService from '../../services/PointService';
import Util from '../../helpers/Util';
import Progress from '../../layout/Progress';
import style from './Point.module.css'
const Point = () => {
  const { auth } = useContext(AppContext);
  const [exchangablePoints, setExchangablePoints] = useState(0); // 환전 가능 포인트
  const [exchangedPoints, setExchangedPoints] = useState(0); // 환전 완료 포인트
  const [loading, setLoading] = useState(false);  // 로딩여부<02.08채필규>
  const [pointAccount,setPointAccount] = useState()
  useEffect(() => {
    // searchPoints(); // 포인트 조회
    getrefundSummaryData();
    getPointAccount();
  }, []);

  const getrefundSummaryData = async() => {
    setLoading(true);
    try{
      const result = await PointService.getTotalRefundRequest();
      setExchangablePoints(result.request_point);
      setExchangedPoints(result.complete_point);
    }catch(err){

    }
    setLoading(false);
  }
  const getPointAccount = async() => {
    try{
      const result = await PointService.getPointAccount();
      setPointAccount(result)
    }catch(err){
      alert('사용자 포인트 정보를 가져오는데 실패하였습니다.')
    }
  }
  return (
    <>
      <div className="page-point sub-title">
        <div className="page-header">
          <p className="sub">1포인트 당 현금 1원의 가치를 지닙니다.</p>
        </div>

        <div className="point-retention">
          <p className="retention">
            <span>현재 보유 포인트</span>
            <em className="total">{Util.formatNumber(pointAccount?.balance ? pointAccount?.balance.toLocaleString() : 0)}</em>
          </p>
          <Link className="btn btn-exchange" to="/point/save/list">포인트 내역</Link>
        </div>
        <Progress loading={loading} />
        {
        !loading ?
        <div className="point-list">
          <div className="point-item">
            <p className="point-header possible">환전 가능 포인트</p>
            <div className="point-content">
              <p className="total">{pointAccount?.balance && pointAccount?.balance >= 2000 ? (Math.floor(pointAccount?.balance /10 )*10).toLocaleString() : 0}</p>
              {pointAccount?.balance < 2000 ? <></> : <Link className="btn btn-exchange" to="/refund/request">환전 신청</Link>}
            </div>
          </div>
          <div className={style["point-item-description"]}>
            <p>*2,000P 이상 부터 환전 신청할 수 있습니다</p>
          </div>
          <div className="point-item">
            <p className="point-header finish">환전 완료 포인트</p>
            <div className="point-content">
              <p className="total">{Util.formatNumber(exchangedPoints)}</p>
              <Link className="btn btn-exchange" to="/refund">환전 내역</Link>
            </div>
          </div>
        </div>
        :
        <></>
        }
      </div>
    </>
  )
}

export default Point;