import { useLocation } from 'react-router-dom';

const SubHeader = (data) => {
  const location = useLocation();

  const subTitle = () => {
    const pathName = data.location.pathname;

    if (pathName.indexOf('/login') > -1) {
      if (pathName.indexOf('/join') > -1) {
        return '회원가입'
      } else {
        if (pathName.indexOf('/login/niceAuth') > -1) {
          return '휴대폰 본인인증'
        } else if (pathName.indexOf('/login/terms') > -1) {
          return '회원가입'
        } else {
          return '로그인'
        }
      }
    } else if (pathName.indexOf('/notification') > -1) {
      return '알림'
    } else if (pathName.indexOf('/point') > -1) {
      if (pathName.indexOf('/save/list') > -1) {
        return '포인트 내역'
      } else if (pathName.indexOf('/exchange/form') > -1) {
        return '환전 신청'
      } else if (pathName.indexOf('/exchange-password') > -1) {
        return '환전 비밀번호 설정'
      } else if (pathName.indexOf('/exchange/password') > -1) {
        return '환전 비밀번호 입력'
      } else if (pathName.indexOf('/exchange/complete') > -1) {
        return '' // 환전 신청 완료
      } else if (pathName.indexOf('/exchange/list') > -1) {
        return '환전 내역'
      } else {
        return '포인트 현황'
      }
    }else if (pathName.indexOf('/refund') > -1) {
      if (pathName.indexOf('/save/list') > -1) {
        return '포인트 적립 내역'
      } else if (pathName.indexOf('/request') > -1) {
        return '환전 신청'
      } else if (pathName.indexOf('/password/regist') > -1) {
        return '환전 비밀번호 설정'
      } else if (pathName.indexOf('/password/verification') > -1) {
        return '환전 비밀번호 입력'
      } else if (pathName.indexOf('request/done') > -1) {
        return '' // 환전 신청 완료
      } else if (pathName.indexOf('/') > -1) {
        return '환전 내역'
      } else {
        return '포인트 현황'
      }
    } else if (pathName.indexOf('/location') > -1) {
      return '위치'
    } else if (pathName.indexOf('/mypage') > -1) {
      if (pathName.indexOf('/password') > -1) {
        if (pathName.indexOf('/exchange') > -1) {
          return '환전 비밀번호 변경'
        } else {
          return '계정 비밀번호 변경'
        }
      } else if (pathName.indexOf('/etc-information') > -1) {
        return '기타 정보 변경'
      } else if (pathName.indexOf('/exchange-password') > -1) {
        return '환전 비밀번호 입력'
      } else if (pathName.indexOf('/withdraw') > -1) {
        return '회원 탈퇴'
      } else if (pathName.indexOf('/menu') > -1) {
        return '메뉴'
      }else if (pathName.indexOf('/change-private-info') > -1) {
        return '개인정보 관리'
      }else if (pathName.indexOf('/change-private-info/done') > -1) {
        return '개인정보 관리'
      }else if (pathName.indexOf('/change-private-info/failed') > -1) {
        return '개인정보 관리'
      }else if (pathName.indexOf('/change-account-password') > -1) {
        return '로그인 비밀번호 변경'
      }else {
        return '내 정보 관리'
      }
    } else if (pathName.indexOf('/faq') > -1) {
      if (pathName.indexOf('/') > -1) {
        return '자주하는 질문'
      } else {
        return '자주하는 질문'
      }
    } else if (pathName.indexOf('/notice') > -1) {
      if (pathName.indexOf('/') > -1) {
        return '공지사항'
      }else{
        return '공지사항'
      }
    }else if (pathName.indexOf('/terms') > -1) {
      if (pathName.indexOf('/') > -1) {
        return '약관 및 정책'
      }else{
        return '약관 및 정책'
      }
    } else if (pathName.indexOf('/setting') > -1) {
      if (pathName.indexOf('/notification') > -1) {
        return '알림설정'
      } else if (pathName.indexOf('/superbin') > -1) {
        return '수퍼빈 소개'
      } else {
        return '환경설정'
      }
    }else if (pathName.indexOf('/certification') > -1) {
      if (pathName.indexOf('/faq') > -1) {
        return '자주하는 질문'
      } else if (pathName.indexOf('/notice') > -1) {
        return '공지사항'
      }else{
        return '본인인증'
      }
    }
    else if (pathName.indexOf('/cpoint') > -1) {
      return '탄소중립포인트 녹색생활 실천'
    }
  }

  const goBack = () => {
    const pathName = data.location.pathname;
    if (pathName.indexOf('/login/terms') > -1) {
      try{
        window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
      }catch(err){
        console.error(err)
      }
    } else {
      data.history.goBack();
    }
  }

  // url에 따라 헤더 className 설정
  const setHeaderClassName = () => {
    let className = '';
    switch (location.pathname) {
      case '/location':
        className = 'a11y';
        break;
      case '/point/exchange/exchange-password':
        className = 'header-primary';
        break;
      case '/point/exchange/password':
        className = 'header-primary';
        break;
      case '/refund/request/done':
        className = 'header-primary';
        break;
      case '/point/exchange/complete':
        className = 'header-primary';
        break;
      case '/mypage/exchange-password':
        className = 'header-primary';
        break;
      case '/menu':
        className = 'a11y';
        break;
      case '/setting/superbin':
        className = 'header-transparent';
        break;
      default:
        className = '';
        break;
    }
    return className;
  }

  return (
    <>
      { data.location.pathname.indexOf('/login/niceAuth') > -1 || data.location.pathname.indexOf('/error') > -1 || data.location.pathname === '/login' || data.location.pathname === '/login/terms' ?
        <></>
        :
        <header>
          <div className={`header header-sub ${setHeaderClassName()}`}>
            <h1 className="a11y">수퍼빈</h1>
            <button type="button" className="btn-prev" onClick={goBack}>
              <span className="a11y">이전으로</span>
            </button>
            <h2 className="title">{subTitle()}</h2>
          </div>
        </header>
      }
    </>
  );
}

export default SubHeader;
