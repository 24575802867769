import React from 'react';
import CmsApiService from './CMSApiService';
import moment from 'moment';

const TermsService = {
    getTermsPrivacyList: async() => {
        const filter = {
            terms_type_code:"is:002",
            announce_at: `lte:${moment().format('YYYY-MM-DD HH:mm:ss')}`
        }
        const paging = {
            offset: 0,
            limit: 1,
        };
        const body = {
            filter:JSON.stringify(filter),
            order:JSON.stringify(['-announce_at', '-idx']),
            paging:JSON.stringify(paging)
        }
        const resultList = await CmsApiService.get('/terms/', body);
        return resultList.list[0].idx
    },
    getTermsServiceList: async() => {
        const filter = {
            terms_type_code:"is:001",
            announce_at: `lte:${moment().format('YYYY-MM-DD HH:mm:ss')}`
        }
        const paging = {
            offset: 0,
            limit: 1,
        };
        const body = {
            filter:JSON.stringify(filter),
            order:JSON.stringify(['-announce_at', '-idx']),
            paging:JSON.stringify(paging)
        }
        const resultList = await CmsApiService.get('/terms/', body);
        return resultList.list[0].idx
    },
    getTermsDetail: async(idx) => {
        return CmsApiService.get('/terms/'+idx, null);
    },
    //temp
    async get_term_current({ segment }) {
        const data = await CmsApiService.get(`/terms/${segment}`);
        return data;
    }
};

export default TermsService;