// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Home_favorite-nefron-decoration__1pCzP{\n    padding: 12px 2px 2px 2px;\n}\n.Home_favorite-nefron-decoration__1pCzP > p{\n    font-size: 13px;\n    font-weight: 400;\n    line-height: 20px;\n    text-align: left;\n    color: #505050;\n}\n.Home_nefron-modal-decoration__44n53 {\n    padding: 12px 16px;\n    margin-bottom: 18px;\n    border-radius: 7px;\n    background-color: rgba(247, 247, 247, 1);\n}\n.Home_nefron-modal-decoration__44n53 > p {\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 22px;\n    text-align: left;\n    color: #505050;\n}", "",{"version":3,"sources":["webpack://src/pages/home/Home.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,wCAAwC;AAC5C;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".favorite-nefron-decoration{\n    padding: 12px 2px 2px 2px;\n}\n.favorite-nefron-decoration > p{\n    font-size: 13px;\n    font-weight: 400;\n    line-height: 20px;\n    text-align: left;\n    color: #505050;\n}\n.nefron-modal-decoration {\n    padding: 12px 16px;\n    margin-bottom: 18px;\n    border-radius: 7px;\n    background-color: rgba(247, 247, 247, 1);\n}\n.nefron-modal-decoration > p {\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 22px;\n    text-align: left;\n    color: #505050;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"favorite-nefron-decoration": "Home_favorite-nefron-decoration__1pCzP",
	"nefron-modal-decoration": "Home_nefron-modal-decoration__44n53"
};
export default ___CSS_LOADER_EXPORT___;
