import React, { useEffect, useState } from "react";
import Terms from "./Terms";
import Validation from "./Validation";
import GuardianCertificateModalBox from "../../../Modules/Modal/GuardianCertificateModal/GuardianCertificateModalBox";
import { useHistory , useLocation } from 'react-router-dom';

import style from "../GuardianCertification.module.css";
import ViewModel from "./ViewModel";
import SmsVerification from "./SmsVerification";
const GuardianCertificationModal = (props) => {
    const history = useHistory()
    const { step, set_step } = ViewModel();
    const set_modal_container_view = () => {
        if (step === 0) {
        return (
            <Terms
            onOkClick={function () {
                set_step(1);
            }}
            ></Terms>
        );
        }
        if (step === 1) {
        return (
            <Validation
            onValidate={function () {
                set_step(2);
            }}
            ></Validation>
        );
        }
        if (step === 2) {
        return (
            <SmsVerification></SmsVerification>
        );
        }
    };
    return (
        <GuardianCertificateModalBox>
            <div className={style["modal-contents"]}>
                <div
                className={style["modal-close-btn"]}
                onClick={() => {
                    history.replace({pathname:'/guardian-certification/failed',query:{reason:"cancel"}},'/guardian-certification/failed')
                }}
                />
                {set_modal_container_view()}
            </div>
        </GuardianCertificateModalBox>
    );
};

export default GuardianCertificationModal;
