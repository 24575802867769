// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TelecomBtn_telecom-btn__2XRYJ{background-color: transparent; border: 1px solid #3997FE; padding: 15px 0px; border-radius: 8px; color: #3997FE; font-weight: 400; font-size: 16px; line-height: 19px;}\n.TelecomBtn_telecom-disabled-btn__2f_kL{background-color: transparent; border: 1px solid #BDBDBD; padding: 15px 0px; border-radius: 8px; color: #9E9E9E; font-weight: 400; font-size: 16px; line-height: 19px;}", "",{"version":3,"sources":["webpack://src/Modules/Buttons/TelecomBtn/TelecomBtn.module.css"],"names":[],"mappings":"AAAA,+BAAa,6BAA6B,EAAE,yBAAyB,EAAE,iBAAiB,EAAE,kBAAkB,EAAE,cAAc,EAAE,gBAAgB,EAAE,eAAe,EAAE,iBAAiB,CAAC;AACnL,wCAAsB,6BAA6B,EAAE,yBAAyB,EAAE,iBAAiB,EAAE,kBAAkB,EAAE,cAAc,EAAE,gBAAgB,EAAE,eAAe,EAAE,iBAAiB,CAAC","sourcesContent":[".telecom-btn{background-color: transparent; border: 1px solid #3997FE; padding: 15px 0px; border-radius: 8px; color: #3997FE; font-weight: 400; font-size: 16px; line-height: 19px;}\n.telecom-disabled-btn{background-color: transparent; border: 1px solid #BDBDBD; padding: 15px 0px; border-radius: 8px; color: #9E9E9E; font-weight: 400; font-size: 16px; line-height: 19px;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"telecom-btn": "TelecomBtn_telecom-btn__2XRYJ",
	"telecom-disabled-btn": "TelecomBtn_telecom-disabled-btn__2f_kL"
};
export default ___CSS_LOADER_EXPORT___;
