import React, { useContext, useEffect, useState } from 'react';
import { Link ,useHistory , useLocation} from 'react-router-dom';
import style from './SelectAccount.module.css'
import WidthFullBtn from '../../Modules/Buttons/widthFullBtn/WidthFullBtn';
import SelectAccountViewModel from './SelectAccountViewModel';
import { SelectAccountCheckedIcon, SelectAccountNonCheckedIcon } from '../../assets/icons/SVG';
import ConfirmContext from '../../layout/modal/CustomiseModal/ConfirmModal/ConfirmContext';
import AlertContext from '../../layout/modal/CustomiseModal/AlertModal/AlertContext';
import LoadingModal from '../../layout/modal/CustomiseModal/LoadingModal/LoadingModal'
const SelectAccount = () => {
    const history = useHistory()
    const {confirm:confirmComp} = useContext(ConfirmContext)
    const {alert:alertComp} = useContext(AlertContext)
    const [loginAccount,setLoginAccount] = useState()
    const [duplicateAccount,setDuplicateAccount] = useState()
    const [loadingModal,setLoadingModal] = useState(false)
    const [transactionBody , setTransactionBody] = useState({
        selected_member_account_idx: undefined,
        deprecated_member_account_idx: undefined
    })
    useEffect(()=>{
        getInfo()
    },[])
    const getInfo = async() => {
        try{
            const result = await SelectAccountViewModel.getAccountList()
            setLoginAccount(result.access_member_account)
            setDuplicateAccount(result.duplicate_member_account)
        }catch(err){
            switch(err){
                case 400 : await alertComp('알림', '통합할 계정이 존재하지 않습니다. 통합이 되지 않는다면 1600-6217 혹은 카카오톡 문의하기로 문의 바랍니다.',undefined,'확인'); history.replace('/'); break;
                case 401 : await alertComp('알림', '통합 인증이 만료되어 메인으로 돌아갑니다. 잠시 후 재 시도해주세요',undefined,'메인으로 돌아가기'); history.replace('/'); break;
                default : await alertComp('알림.', '서버 에러가 발생하여 메인으로 돌아갑니다. 잠시 후 재 시도해주세요',undefined,'메인으로 돌아가기'); history.replace('/'); break;
            }
        }
    }
    const SelectAccount = async() => {
        if(transactionBody.selected_member_account_idx && transactionBody.deprecated_member_account_idx){
            let confirm = false
            if(loginAccount?.idx !== transactionBody.selected_member_account_idx){
                confirm = await confirmComp('계정통합 주의사항','선택한 계정은 현재 로그인 된 아이디가 아닙니다.통합 즉시 수퍼빈앱에서 로그아웃되며, 개명 혹은 번호를 변경한 경우라면 통합한 계정으로 로그인 후 정보를 재변경 해야합니다. 선택한 계정으로 통합하시겠습니까?',undefined,'통합하기','다시선택')
            }else{
                confirm = true
            }
            if(confirm){
                setLoadingModal(true)
                try{
                    const result = await SelectAccountViewModel.selectAccountRequest(transactionBody)
                    setLoadingModal(false)
                    window.location.replace('/')
                }catch(err){
                    setLoadingModal(false)
                    switch(err){
                        case 400 : await alertComp('알림', '통합할 계정이 존재하지 않습니다. 통합이 되지 않는다면 1600-6217 혹은 카카오톡 문의하기로 문의 바랍니다.',undefined,'확인'); history.replace('/'); break;
                        case 401 : await alertComp('알림', '통합 인증이 만료되어 메인으로 돌아갑니다. 잠시 후 재 시도해주세요',undefined,'메인으로 돌아가기'); history.replace('/'); break;
                        default : await alertComp('알림.', '서버 에러가 발생하여 메인으로 돌아갑니다. 잠시 후 재 시도해주세요',undefined,'메인으로 돌아가기'); history.replace('/'); break;
                    }
                }
            }
        }
    }
    const onClickLoginAccount = () => {
        const data = {
            selected_member_account_idx : loginAccount.idx,
            deprecated_member_account_idx : duplicateAccount.idx
        }
        setTransactionBody(data)
    }
    const onClickDuplicateAccount = () => {
        const data = {
            selected_member_account_idx : duplicateAccount.idx,
            deprecated_member_account_idx : loginAccount.idx
        }
        setTransactionBody(data)
    }
    return (
        <div className={style['page-start']}>
            <div className={style['root']}>
                <div>
                    <p className={style['title']}>사용 계정 선택</p>
                    <p className={style['description']}>원하시는 계정을 선택해주세요.</p>
                    <div className={style['item']}>
                        <ul className={style["account-list"]}>
                            <li onClick={()=>{onClickLoginAccount()}}>
                                {loginAccount?.idx === transactionBody.selected_member_account_idx ? <SelectAccountCheckedIcon />:<SelectAccountNonCheckedIcon />}
                                <div>
                                    <div className={style["title"]}>
                                        <p className={loginAccount?.idx === transactionBody.selected_member_account_idx ? style["select"]:style["noselect"]}>{loginAccount?.id}</p>
                                        <div><p>현재 로그인한 계정</p></div>
                                    </div>
                                    <p>{loginAccount?.nickname}</p>
                                    <p>{loginAccount?.represent_phone?.phone_no ? loginAccount?.represent_phone?.phone_no : '번호없음'}</p>
                                    <p>{loginAccount?.rookie?.is_used ? '수퍼빈루키':''} {loginAccount?.rookie?.is_used && loginAccount?.more?.is_used ? ', ':'' } {loginAccount?.more?.is_used ? '수퍼빈모아':''} {loginAccount?.rookie?.is_used || loginAccount?.more?.is_used ? ' 이용 중':''}</p>
                                </div>
                            </li>
                            <li onClick={()=>{onClickDuplicateAccount()}}>
                                {duplicateAccount?.idx === transactionBody.selected_member_account_idx ? <SelectAccountCheckedIcon />:<SelectAccountNonCheckedIcon />}
                                <div>
                                    <div className={style["title"]}>
                                        <p className={duplicateAccount?.idx === transactionBody.selected_member_account_idx ? style["select"]:style["noselect"]}>{duplicateAccount?.id}</p>
                                    </div>
                                    <p>{duplicateAccount?.nickname}</p>
                                    <p>{duplicateAccount?.represent_phone?.phone_no ? duplicateAccount?.represent_phone?.phone_no : '번호없음'}</p>
                                    <p>{duplicateAccount?.rookie?.is_used ? '수퍼빈루키':''} {duplicateAccount?.rookie?.is_used && duplicateAccount?.more?.is_used ? ', ':'' } {duplicateAccount?.more?.is_used ? '수퍼빈모아':''} {duplicateAccount?.rookie?.is_used || duplicateAccount?.more?.is_used ? ' 이용 중':''}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <p className={style['description']}>※ 계정 통합 시 번호를 여러개 사용하더라도 네프론에서는 1개의 계정으로 인식하여 번호마다 개수 제한만큼 투입할 수 없습니다.</p>
                    <p className={style['description']}>※ 선택되지 않은 계정은 자동 탈퇴되며, 탈퇴 계정의 보유 포인트(내역포함)는 선택된 계정에 자동 연동됩니다.</p>
                    <div className={style['validate-end']}>
                        <WidthFullBtn onClick={function () {
                            SelectAccount()
                        }}
                        title={'선택'}
                        disabled={transactionBody.selected_member_account_idx && transactionBody.deprecated_member_account_idx ? false:true}
                        />
                    </div>
                </div>
            </div>
            {loadingModal ?<LoadingModal></LoadingModal>:<></>}
        </div>
    );
};

export default SelectAccount;