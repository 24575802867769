import React from 'react';
import PrivateHttpHelper from '../helpers/HttpHelper/PrivateHttpHelper'
import CIHttpHelper from '../helpers/HttpHelper/CIHttpHelper';

const PrivateService =  {
    getPrivateToken: async (body) => {
        return PrivateHttpHelper.getPrivateToken(body)
    },
    getPrivateVerification : () => {
        return PrivateHttpHelper.validatePrivateToken()
    },
    putAccountPassword : (body) => {
        return PrivateHttpHelper.put('/me/password',body)
    },
    postRefundPassword : (body) => {
        return PrivateHttpHelper.post("/refund-password",body)
    },
    putRefundPassword : (body) => {
        return PrivateHttpHelper.put("/refund-password",body)
    },
    getPrivacy : () => {
        return PrivateHttpHelper.get("/me/privacy")
    },
    putPrivacy : (data) => {
        return PrivateHttpHelper.put("/me/privacy",data)
    },
    deletePhone : (idx) => {
        return PrivateHttpHelper.delete("/me/phone/"+idx)
    },
    withDrawAccount : (body) => {
        return PrivateHttpHelper.deleteWithBody("/me",body)
    },
    withDrawService : (idx) => {
        return PrivateHttpHelper.delete("/me/service/"+idx)
    },
    sendAddPhoneSms : (body) => {
        return PrivateHttpHelper.post('/me/phone/request-auth',body)
    },
    postAddSubPhone : (body) => {
        return CIHttpHelper.post('/me/phone',body)
    },
    putChangeMainPhone : (idx , body) => {
        return CIHttpHelper.put('/me/phone/'+idx,body)
    },
    putChangeMainPhone : (idx , body) => {
        return CIHttpHelper.put('/me/phone/'+idx,body)
    },
    sendChangeNameSms : (body) => {
        return PrivateHttpHelper.post('/me/privacy/name/request-auth',body)
    },
    verificateChangeNameSms : (body) => {
        return PrivateHttpHelper.post('/me/privacy/name/verify-auth',body)
    },
    verificateAddPhoneSms : (body) => {
        return PrivateHttpHelper.post('/me/phone/verify-auth',body)
    },
    postChangeName : (body) => {
        return CIHttpHelper.post('/me/privacy/name',body)
    },
    getDuplicateAccount : () => {
        return CIHttpHelper.getWithAccessToken("/account-transfer")
    },
    setDuplicateTransInfo : (body) => {
        return CIHttpHelper.postWithAccessToken("/account-transfer",body)
    },
    withDrawAccount : (body) => {
        return PrivateHttpHelper.deleteWithBody("/me",body)
    },
    withDrawService : (idx) => {
        return PrivateHttpHelper.delete("/me/service/"+idx)
    },
};
export default PrivateService;