import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const Guide = () => {
  const history = useHistory();

  const [step, setStep] = useState('');
  const stepData = {
    step2: false,
    step3: false
  }

  const handleClick = (event) => {
    const stepNum = event.target.dataset.step;
    for (let key in stepData) {
      if (key === 'step' + stepNum) {
        stepData[key] = true;
      } else {
        stepData[key] = false;
      }
    }
    setStep(stepData);
  }

  const onEnter = () => {
    history.replace('/home?isGuide=Y');
  }

  return (
    <>
      <div className="page-guide">
        <div className={`step${step.step2 || step.step3 ? "" : " step1"}`}>
          <div className="guide-header">
            <p>쓰레기도 돈이다, 재활용도 놀이다.</p>
            <p className="sub">
              쓰레기가 돈이 되고, 재활용이 새로운 문화가<br />
              될 수 있다는 것을 경험할 수 있습니다.
            </p>
          </div>

          <div className="guide-visual">
            <p className="a11y">STEP 1</p>
            <div className="step-dots">
              <span className="active"></span>
              <span></span>
              <span></span>
            </div>
          </div>

          <div className="btn-wrap">
            <button type="button" className="btn btn-primary" data-step="2" onClick={handleClick}>다음</button>
          </div>
        </div>

        <div className={`step${step.step2 ? " step2" : ""}`}>
          <div className="guide-header">
            <p>포인트를 현금으로,</p>
            <p className="sub">
              앱을 통해 수퍼빈 포인트를 현금으로<br />
              전환할 수 있습니다.
            </p>
          </div>

          <div className="guide-visual">
            <p className="a11y">STEP 2</p>
            <div className="step-dots">
              <span></span>
              <span className="active"></span>
              <span></span>
            </div>
          </div>

          <div className="btn-wrap">
            <button type="button" className="btn btn-primary" data-step="3" onClick={handleClick}>다음</button>
          </div>
        </div>

        <div className={`step${step.step3 ? " step3" : ""}`}>
          <div className="guide-header">
            <p>네프론 상태 확인</p>
            <p className="sub">
              미리 네프론의 상태를 확인하고<br />
              방문할 수 있습니다.
            </p>
          </div>

          <div className="guide-visual">
            <p className="a11y">STEP 3</p>
            <div className="step-dots">
              <span></span>
              <span></span>
              <span className="active"></span>
            </div>
          </div>

          <div className="btn-wrap">
            {/* <Link className="btn btn-active" to="/home">들어가기</Link> */}
            <button type="button" className="btn btn-active" onClick={onEnter}>들어가기</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Guide;