import Service from './Index';
import AuthService from './AuthService';
import HttpHelper from '../helpers/HttpHelper/HttpHelper'
const DefinitionService = {
  //삭제 필요
  getBankList: () => {
    const param = {
      filter: {
        is_used: "is:1",
      },
    };
    return HttpHelper.get("/bank-code", param);
  },
  getBank: (idx) => {
    const param = {
    };
    return HttpHelper.get("/bank-code/"+idx, param);
  },
};

export default DefinitionService;
