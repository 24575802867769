import axios, { AxiosError } from 'axios';
import SessionHelper from '../SessionHelper'
import { API_BFF_APP_URL } from '../../config';
import { Cookies } from 'react-cookie';

const CIHttpHelper =  {
    get: async (url) => {
        const sessionStorageValue = SessionHelper.getSession()
        const sessionStorageCIValue = SessionHelper.getCISession()
        const token = sessionStorageValue ? sessionStorageValue : ''
        const instance = axios.create({
            baseURL : API_BFF_APP_URL,
            headers : {
                Authorization: `Bearer ${token}`,
                'ci-authorization' : `Bearer ${sessionStorageCIValue}`
            }
        })
        try{
            const response = await instance.get(url)
            return response.data
        }catch(error){
          if(error.response?.status === 401){
            window.location.href = '/mypage/input-password'
          }else{
              throw error
          }
        }
      },
      post: async (url, data) => {
        const sessionStorageValue = SessionHelper.getSession()
        const sessionStorageCIValue = SessionHelper.getCISession()
        const token = sessionStorageValue ? sessionStorageValue : ''
        const instance = axios.create({
            baseURL : API_BFF_APP_URL,
            headers : {
                Authorization: `Bearer ${token}`,
                'ci-authorization' : `Bearer ${sessionStorageCIValue}`
            }
        })
        try{
            const response = await instance.post(url,data)
            return response.data
        }catch(error){
          if(error instanceof AxiosError){
              window.location.href = '/mypage/input-password'
            }else{
                throw error
            }
        }
      },
      put: async (url, data) => {
        const sessionStorageValue = SessionHelper.getSession()
        const sessionStorageCIValue = SessionHelper.getCISession()
        const token = sessionStorageValue ? sessionStorageValue : ''
        const instance = axios.create({
            baseURL : API_BFF_APP_URL,
            headers : {
                Authorization: `Bearer ${token}`,
                'ci-authorization' : `Bearer ${sessionStorageCIValue}`
            }
        })
        try{
            const response = await instance.put(url,data)
            return response.data
        }catch(error){
            if(error.response?.status === 401){
              window.location.href = '/mypage/input-password'
            }else{
                throw error
            }
        }
      },
      delete: async (url) => {
        const sessionStorageValue = SessionHelper.getSession()
        const sessionStorageCIValue = SessionHelper.getCISession()
        const token = sessionStorageValue ? sessionStorageValue : ''
        const instance = axios.create({
            baseURL : API_BFF_APP_URL,
            headers : {
                Authorization: `Bearer ${token}`,
                'ci-authorization' : `Bearer ${sessionStorageCIValue}`
            }
        })
        try{
            const response = await instance.delete(url)
            return response.data
        }catch(error){
            if(error.response?.status === 401){
              window.location.href = '/mypage/input-password'
            }else{
                throw error
            }
        }
      },
      deleteWithBody: async (url,body) => {
        const sessionStorageValue = SessionHelper.getSession()
        const sessionStorageCIValue = SessionHelper.getCISession()
        const token = sessionStorageValue ? sessionStorageValue : ''
        const instance = axios.create({
            baseURL : API_BFF_APP_URL,
            data:body,
            headers : {
                Authorization: `Bearer ${token}`,
                'ci-authorization' : `Bearer ${sessionStorageCIValue}`
            }
        })
        try{
            const response = await instance.delete(url)
            return response.data
        }catch(error){
            if(error.response?.status === 401){
              window.location.href = '/mypage/input-password'
            }else{
                throw error
            }
        }
      },
      getWithAccessToken: async (url) => {
        const cookies = new Cookies();
        const accessToken = cookies.get("token")
        const sessionStorageCIValue = SessionHelper.getCISession()
        const token = accessToken ? accessToken : ''
        const instance = axios.create({
            baseURL : API_BFF_APP_URL,
            headers : {
                Authorization: `Bearer ${token}`,
                'ci-authorization' : `Bearer ${sessionStorageCIValue}`
            }
        })
        try{
            const response = await instance.get(url)
            return response.data
        }catch(error){
            throw error
        }
    },
    postWithAccessToken: async (url,data) => {
        const cookies = new Cookies();
        const accessToken = cookies.get("token")
        const sessionStorageCIValue = SessionHelper.getCISession()
        const token = accessToken ? accessToken : ''
        const instance = axios.create({
            baseURL : API_BFF_APP_URL,
            headers : {
                Authorization: `Bearer ${token}`,
                'ci-authorization' : `Bearer ${sessionStorageCIValue}`
            }
        })
        try{
            const response = await instance.post(url,data)
            return response.data
        }catch(error){
            throw error
        }
    },
    };


export default CIHttpHelper;