import React, { useEffect, useState ,useContext } from 'react';
import { RefundCryptoAES } from '../../../helpers/CryptoHelper'
import { useHistory } from 'react-router-dom';
import PrivateService from '../../../services/PrivateService'
import AlertContext from '../../../layout/modal/CustomiseModal/AlertModal/AlertContext';
const ChangeRefundPasswordViewModel = {
    SetPasswordValidationHook : () => {
        const [firstPassword,setFirstPassword] = useState("")
        const [secondPassword,setSecondPassword] = useState("")
        const [secondPasswordData,setSecondPasswordData] = useState([])
        const [firstPasswordData,setFirstPasswordData] = useState([])
        const [passwordValidate,setPasswordValidate] = useState(true)
        useEffect(()=>{
            setFirstPassword(onSetFirstValue(firstPasswordData))
            setSecondPassword(onSetSecondValue(secondPasswordData))
        },[secondPasswordData,firstPasswordData])
        useEffect(()=>{
            const secondArr = [...secondPasswordData]
            var secondArrString = ""
            secondArr.forEach(element => {
                secondArrString = secondArrString + element
            });
            if(secondArrString){
                if(firstPassword === secondArrString){
                    setPasswordValidate(true)
                }else{
                    setPasswordValidate(false)
                }
            }
        },[secondPasswordData])
        const onSetFirstValue = (firstPasswordArr) => {
            if(firstPasswordArr.length < 6){
                const newArr = [...firstPasswordArr]
                return ""
            }else if(firstPasswordArr.length === 6){
                const newArr = [...firstPasswordArr]
                var firstInputString = ""
                newArr.forEach(element => {
                    firstInputString = firstInputString + element
                });
                return firstInputString
            }
        }
        const onSetSecondValue = (secondPasswordArr) => {
            if(secondPasswordArr.length < 6){
                const newArr = [...secondPasswordArr]
                return ""
            }else if(secondPasswordArr.length === 6){
                const newArr = [...secondPasswordArr]
                var secondInputString = ""
                newArr.forEach(element => {
                    secondInputString = secondInputString + element
                });
                return secondInputString
            }
        }
        return { passwordValidate,firstPassword,secondPassword,secondPasswordData,firstPasswordData,setSecondPasswordData,setFirstPasswordData };
    },
    PutPasswordHook : () => {
        const history = useHistory()
        const {alert : alertComp} = useContext(AlertContext)
        const onPutPassword = async(password) => {
            try{
                const body = {
                    password: RefundCryptoAES.encrypt(password)
                }
                const result = await PrivateService.putRefundPassword(body)
                history.replace('change-refund-password/done')
                return result
            }catch(err){
                if(err.response.status === 409){
                    await alertComp("기존 비밀번호와 같습니다","변경하실 비밀번호가 기존 비밀번호와 동일합니다.",undefined,'확인')
                    history.replace('/')
                }else{
                    await alertComp('알림','서버 오류로 인해 비밀번호 변경에 실패하였습니다. 다시 시도해주세요',undefined,'확인')
                    history.replace('/')
                }
            }
        }
        return { onPutPassword };
    },
}; 
export default ChangeRefundPasswordViewModel;