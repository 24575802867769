import React, { useContext, useState } from 'react';
import PrivateService from '../../../../../services/PrivateService';
import AlertContext from '../../../../../layout/modal/CustomiseModal/AlertModal/AlertContext';
import SessionHelper from '../../../../../helpers/SessionHelper';
import { useLocation , useHistory } from "react-router-dom";

const ChangeMainPhoneVerificationCodeViewModel =  {
    VerificatePhoneSms : () => {
        const [buttonEnable,setButtonEnable] = useState(true)
        const location = useLocation()
        const [seqcode,setSeqcode] = useState({
            request_seq_code : location.query.request_seq_code,
            response_seq_code : location.query.response_seq_code,
        })
        const history = useHistory()
        const {alert : alertComp} = useContext(AlertContext)
        const verificateSms = async(data) => {
            setButtonEnable(false)
            const body = {
                phone : location.query.phone,
                name : location.query.name,
                rr_number : location.query.rr_number,
                request_seq_code : seqcode.request_seq_code,
                response_seq_code : seqcode.response_seq_code,
                auth_code : data.code,
            }
            try{
                const result = await PrivateService.verificateAddPhoneSms(body)
                if(result.status === "DuplicateCi"){
                    await SessionHelper.setCISession(result.ci_access_token)
                    await duplicateCIStatus()
                }else if(result.status === "DifferentCi"){
                    history.replace({
                        pathname:'../failed',query:location.query
                    },'../failed')
                }else{
                    //성공 상태
                    try{
                        await SessionHelper.setCISession(result.ci_access_token)
                        await changeMainPhoneRequest()
                        history.replace({
                            pathname:'../done',query:location.query
                        },'../done')
                    }catch(err){
                        await alertComp('알림', '어플리케이션 에러가 발생하여 메인으로 돌아갑니다. 잠시 후 재 시도해주세요',undefined,'메인으로 돌아가기')
                        history.replace('/')
                    }
                }
            }catch(err){
                if(err.response.status === 409){
                    setButtonEnable(true)
                    await alertComp('알림', '인증번호가 잘못되었습니다. 다시 입력해주세요',undefined,'확인')
                }else{
                    setButtonEnable(true)
                    await alertComp('알림', '서버 에러가 발생하여 메인으로 돌아갑니다. 잠시 후 재 시도해주세요',undefined,'메인으로 돌아가기')
                    history.replace('/')
                }
            }
        }
        const resendSms = async() => {
            const body = {
                phone : location.query.phone,
                name : location.query.name,
                rr_number : location.query.rr_number,
                telco : Number(location.query.telco)
            }
            try{
                const result = await PrivateService.sendAddPhoneSms(body)
                setSeqcode({
                    request_seq_code : result.request_seq_code,
                    response_seq_code : result.response_seq_code
                })
            }catch(err){
                await alertComp('알림', '서버 에러가 발생하여 메인으로 돌아갑니다. 잠시 후 재 시도해주세요',undefined,'메인으로 돌아가기')
                history.replace('/')
            }
        }
        const changeMainPhoneRequest = async() => {
            const body = {
                phone_no: location.query.phone,
                represent: true
            }
            try{
                const result = await PrivateService.putChangeMainPhone(String(location.query.oldphoneIdx),body)
            }catch(err){
                await alertComp('알림', '서버 에러가 발생하여 메인으로 돌아갑니다. 잠시 후 재 시도해주세요',undefined,'메인으로 돌아가기')
                history.replace('/')
            }

        }
        const duplicateCIStatus = async() => {
            try{
                await alertComp('수퍼빈 계정 통합 안내', '이미 본인 명의의 수퍼빈 계정이 존재합니다. 수퍼빈 서비스는 1개의 계정(아이디)으로만 사용이 가능합니다',undefined,'계정 통합하기')
                await changeMainPhoneRequest()
                history.replace({
                    pathname:'/select-account',query:location.query
                },'/select-account')
            }catch(err){
                await alertComp('알림', '서버 에러가 발생하여 메인으로 돌아갑니다. 잠시 후 재 시도해주세요',undefined,'메인으로 돌아가기')
                history.replace('/')
            }
        }
        return {verificateSms,resendSms,buttonEnable}
    }
};

export default ChangeMainPhoneVerificationCodeViewModel;