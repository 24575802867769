import React, { useEffect, useState } from 'react';
import style from './InputBank.module.css'
import PointBankModal from '../../../../layout/modal/PointBankModal';
import DefinitionService from '../../../../services/DefinitionService'
const InputBank = ({bank,title,holder,accountValue,InputData,selectBank}) => {
    const [bankModal,setBankModal] = useState(false)
    const [banks,setBanks] = useState([])
    useEffect(()=>{
        getBankList()
    },[])
    const getBankList = async() => {
        try{
            const result = await DefinitionService.getBankList()
            result.list.forEach((element) => {
                element.name = setBankName(element.name)
            });
            setBanks(result.list)
        }catch(error){
            alert(error)
        }
    }
    const setBankName = (name)=>{
        var modifyName = ""
        switch(name){
            //은행사
            case "국민은행": modifyName = "국민"; break;
            case "산업은행": modifyName = "산업"; break;
            case "기업은행": modifyName = "기업"; break;
            case "지역 농축협": modifyName = "농협"; break;
            case "NH농협은행": modifyName = "농협"; break;
            case "우리은행": modifyName = "우리"; break;
            case "SC제일은행": modifyName = "SC"; break;
            case "하나은행": modifyName = "하나"; break;
            case "케이뱅크": modifyName = "케이뱅크"; break;
            case "우체국": modifyName = "우체국"; break;
            case "한국씨티은행": modifyName = "한국씨티"; break;
            case "경남은행": modifyName = "경남"; break;
            case "광주은행": modifyName = "광주"; break;
            case "대구은행": modifyName = "대구"; break;
            case "부산은행": modifyName = "부산"; break;
            case "전북은행": modifyName = "전북"; break;
            case "제주은행": modifyName = "제주"; break;
            case "카카오뱅크": modifyName = "카카오뱅크"; break;
            case "케이뱅크": modifyName = "케이뱅크"; break;
            case "새마을금고": modifyName = "새마을금고"; break;
            case "신협": modifyName = "신협"; break;
            case "산업은행": modifyName = "산업"; break;
            case "수협은행": modifyName = "수협"; break;
            case "도이치은행": modifyName = "도이치"; break;
            case "BOA은행": modifyName = "BOA"; break;
            case "비엔피파리바은행": modifyName = "BNP"; break;
            case "중국공상은행": modifyName = "중국공상"; break;
            case "HSBC은행": modifyName = "HSBC"; break;
            case "제이피모간체이스은행": modifyName = "JP"; break;
            case "산림조합중앙회": modifyName = "산림조합"; break;
            case "저축은행": modifyName = "저축은행"; break;
            case "토스뱅크": modifyName = "토스뱅크"; break;
            //증권사
            case "현대차증권": modifyName = "현대차증권"; break;
            case "KB증권": modifyName = "KB증권"; break;
            case "SK증권": modifyName = "SK증권"; break;
            case "대신증권": modifyName = "대신증권"; break;
            case "DB금융투자": modifyName = "DB금융투자"; break;
            case "메리츠증권": modifyName = "메리츠증권"; break;
            case "부국증권": modifyName = "부국증권"; break;
            case "신영증권": modifyName = "신영증권"; break;
            case "유진투자증권": modifyName = "유진투자증권"; break;
            case "이베스트투자증권": modifyName = "이베스트"; break;
            case "케이프투자증권": modifyName = "케이프증권"; break;
            case "키움증권": modifyName = "키움증권"; break;
            case "한국포스증권": modifyName = "포스증권"; break;
            case "하나증권": modifyName = "하나증권"; break;
            case "신한금융투자": modifyName = "신한금융투자"; break;
            case "한화투자증권": modifyName = "한화금융투자"; break;
            default:modifyName = name; break;
        }
        return modifyName
    }
    return (
        <div className={style["input-box"]}>
            <p className={style.title}>{title}</p>
            <div className={style.dropdown}>
                <div className={bank? style["bank-value-act"]:style[""]}>
                    <p onClick={()=>{setBankModal(true)}} className='bank'>{bank? bank:'은행명'}&nbsp;&nbsp;&nbsp;</p>
                    <svg onClick={()=>{setBankModal(true)}} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.4999 10.2691L13 16.769L6.50013 10.2691" stroke="#BCBCBC" strokeWidth="1.5"/>
                    </svg>
                </div>
                <input type="text" value={accountValue} placeholder={holder} onChange={(e)=>{
                    const value = e.currentTarget.value
                    InputData(value)
                    }} className={style.title}></input>
            </div>
            <hr></hr>
            {/* {bankModal?  */}
            <PointBankModal banks={banks} setCloseModal={function (value) {
                setBankModal(false)
            }} bankModal={bankModal} bankChange={selectBank}></PointBankModal>
            {/* :<></>}       */}
        </div>
    );
};

export default InputBank;