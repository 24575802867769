import React, { useEffect, useState } from 'react';
import style from './PhoneInput.module.css'
const PhoneInput = (props) => {
    const [inputFocus,setInputFocus] = useState(false)
    const [phoneHeaderData,setPhoneHeaderData] = useState("010")
    useEffect(()=>{
        props.setPhoneHeader(phoneHeaderData)
    },[phoneHeaderData])
    return (
        <div className={style["input-box"]}>
            <p className={style.title}>{"휴대전화 번호"}</p>
            <div className={style["phone-box"]}>
                {/* 모바일 / PC */}
                <select onChange={(event)=>{
                    setPhoneHeaderData(event.target.value)
                }} className='input-selector'>
                    <option value='010'>010&nbsp;&nbsp;&nbsp;</option>
                    <option value='011'>011&nbsp;&nbsp;&nbsp;</option>
                    <option value='019'>019&nbsp;&nbsp;&nbsp;</option>
                </select>
                <div className={style["phone-body"]}>
                <input type='text' value={props.phoneBody} onChange={(event)=>{
                    var data = event.target.value
                    data = data.replace(/[^0-9]/g, '')
                    data = data.replace(/^(\d{3,4})(\d{4})$/, `$1-$2`)
                    props.setPhoneBody(data)}}
                    onFocus = {()=>setInputFocus(true)}
                    onBlur = {()=>setInputFocus(false)} 
                    placeholder='휴대전화 번호를 입력해주세요'></input>
                </div>
            </div>
        <hr className={inputFocus? style["onfocus"]:style["nonfocus"]}></hr>
        {props.description ? <div className={style["description"]}>{props.description}</div>:<></>}
        </div>
    );
};

export default PhoneInput;