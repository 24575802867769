import React from 'react';
import style from './TelecomBtn.module.css'
const TelecomBtn = (props) => {
    return (
        <>
            {props.enabled ?<button onClick={()=>{props.onBtnClick()}} className={style["telecom-btn"]}>{props.title}</button>:
            <button onClick={()=>{props.onBtnClick()}} className={style["telecom-disabled-btn"]}>{props.title}</button>}
        </>
    );
};

export default TelecomBtn;