import React, { useEffect, useState } from 'react';
import {useHistory} from 'react-router-dom';
import ChangeAccountPasswordViewModel from './ChangeAccountPasswordViewModel';
import style from './ChangeAccountPassword.module.css'
import { AvailabilityIcon, Subtract } from '../../../assets/icons/SVG';
import DefaultInput from '../../../Modules/MyPageModules/Private/DefaultInput/DefaultInput';
import WidthFullBtn from '../../../Modules/Buttons/widthFullBtn/WidthFullBtn';
import { CryptoAES } from '../../../helpers/CryptoHelper';
import AlertModal from '../../../Modules/Modal/AlertModal/AlertModal';
import MypageAuthrizationService from '../../../services/MypageAuthrizationService';
import PrivateService from '../../../services/PrivateService'
const ChangeAccountPassword = () => {
    const history = useHistory()
    const [logicNewPassword,setLogicNewPassword] = useState(false)
    const [password,setPassword] = useState("")
    const [checkPassword,setCheckPassword] = useState("")
    const [passwordStatus,setPasswordStatus] = useState(false)
    const [alertModal,setAlertModal] = useState({
        onFocus : false,
        title : "기존 비밀번호와 동일합니다. 새로운 비밀번호로 다시 변경해주세요.",
        description : null,
        btnTitle:"메인으로 이동"
    })
    useEffect(()=>{
        const status = ChangeAccountPasswordViewModel.checkPasswordStatus(password)
        setPasswordStatus(status)
    },[password,checkPassword])
    const modifyPassword = async() => {
        try{
            const body = {
                password: CryptoAES.encrypt(String(password),"account")
            }
            await PrivateService.putAccountPassword(body)
            history.replace("change-account-password/done")
        }catch(error){
            alert("서버 오류로 인해 인증에 실패하였습니다. 다시 시도해주세요")
        }
    }
    return (
        <div className={style["page-start"]}>
            {!logicNewPassword? 
            <>
                <div className={style.root}>
                    <div className={style.title}>로그인 비밀번호 변경</div>
                    <div className={style["sub-title"]}>새로운 비밀번호를 입력하세요</div>
                    <div className={style["container-box"]}>
                        <DefaultInput type='password' title={'비밀번호'} holder={'비밀번호를 입력해주세요.'} value={password}
                        InputData={function (value) {
                            setPassword(value);
                        } } description={null} 
                        onFocus={function () {
                        } } onBlur={function () {
                        } }></DefaultInput>
                        <div className={`${style["password-avaliable-box"]}`}>
                            <AvailabilityIcon color={passwordStatus.regex? '#3997FE':'#E0E0E0'}></AvailabilityIcon>
                            <p>8~20자 사이</p>
                        </div>
                        <div className={`${style["password-avaliable-box"]}`}>
                            <AvailabilityIcon color={passwordStatus.length? '#3997FE':'#E0E0E0'}></AvailabilityIcon>
                            <p className={style["arrow_text"]}>영문(대소문자 구분), 숫자, 특수문자 중 2개 사용 
                                <div className={style["subtract-box"]}>
                                    <div className={style["subtract"]}>
                                        <Subtract></Subtract>
                                    </div>
                                    <div className={style["arrow_box"]}>
                                        <p className={style["arrow_title"]}>사용 가능한 특수문자 종류 안내</p>
                                        <p className={style["arrow_contents"]}> ! &#34; # $ % & &#39; ( ) * + , - . / : ; &#60; &#61; &#62; &#63; @ [ ₩ ] ^ _ ` &#123; &#124; &#125; ~</p>
                                    </div>
                                </div>
                            </p>
                        </div>
                    </div>
                    <div className={style["bottom-container-box"]}>
                        <WidthFullBtn disabled={!passwordStatus.enable} title={"확인"} onClick={()=>{setLogicNewPassword(true)}}></WidthFullBtn>
                    </div>
                </div>
            </>
            :
            <>
            <div className={style.root}>
                <div className={style.title}>로그인 비밀번호 변경</div>
                <div className={style["sub-title"]}>방금 입력하신 비밀번호를 한번 더 입력해주세요</div>
                <div className={style["container-box"]}>
                    <DefaultInput type='password' title={'비밀번호'} holder={'비밀번호를 입력해주세요.'} value={checkPassword}
                        InputData={function (value) {
                            setCheckPassword(value);
                        } } description={null} 
                        onFocus={function () {
                        } } onBlur={function () {
                    } }></DefaultInput>
                    <div className={`${style["password-avaliable-box"]}`}>
                        {password != checkPassword && checkPassword ?
                        <p className={style["password-check"]}>직전에 입력한 비밀번호와 다릅니다.</p>:<></>
                        }
                    </div>
                </div>
                <div className={style["bottom-container-box"]}>
                <WidthFullBtn disabled={password != checkPassword} title={"확인"} onClick={()=>{modifyPassword()}}></WidthFullBtn>
                </div>
            </div>
            </>}
            {alertModal.onFocus? <AlertModal
                title={alertModal.title}
                description={alertModal.description} 
                btnTitle={alertModal.btnTitle} 
                onClick={function () {
                    const data = {...alertModal}
                    data.onFocus = false
                    setAlertModal(data)
                } }></AlertModal>:<></>}
        </div>
    );
};

export default ChangeAccountPassword;