import Service from './Index';
import AuthService from './AuthService';
const MyNephronService = {
  fetchItems: (param) => {
    return AuthService.get('/me/favorites', param);
  },
  create: (param) => {
    return AuthService.post('/me/favorites', param);
  },
  delete: (param) => {
    return AuthService.delete(`/me/favorites`, param);
  },
};

export default MyNephronService;
