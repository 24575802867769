import React from 'react';
import PrivateInsertInfoComponent from '../../common-privateInfo-component/insert-certInfo-component/PrivateInsertInfoComponent';
import RegistMainPhoneInsertCertinfoViewModel from './RegistMainPhoneInsertCertinfoViewModel';
const RegistMainPhoneInsertCertinfo = () => {
    const {postSms} = RegistMainPhoneInsertCertinfoViewModel.InsertInfo()
    return (
        <PrivateInsertInfoComponent 
            title={'번호 추가를 위한 본인인증'} 
            description={'수퍼빈은 본인 명의의 휴대전화 번호가 있어야 서비스 이용이 가능합니다. 통신사에 가입된 본인의 정보를 입력하여 인증해주세요.'} 
            setData={function (data) {
                postSms(data)
        } }></PrivateInsertInfoComponent>
    );
};

export default RegistMainPhoneInsertCertinfo;