import React, { useEffect, useState } from 'react';
import {useLocation , useHistory} from 'react-router-dom';
import style from './ChangeRefundPasswordDone.module.css'
import { ChangeDoneIcon } from '../../../../assets/icons/SVG';
import WidthFullBtn from '../../../../Modules/Buttons/widthFullBtn/WidthFullBtn';
const ChangeRefundPasswordDone = () => {
    const history = useHistory()
    return (
        <div className={style["page-start"]}>
            <div className={style.root}>
                <ChangeDoneIcon></ChangeDoneIcon>
                <div className={`${style["title"]}`}>환전 비밀번호 변경 완료</div>
                <div className={`${style["description"]}`}>환전 비밀번호 변경이 완료되었습니다.</div>
                <div className={style["bottom-container-box"]}>
                <WidthFullBtn disabled={false} title={"메인으로 돌아가기"} onClick={()=>{window.location.replace('/')}}></WidthFullBtn>
                </div>
            </div>
        </div>
    );
};

export default ChangeRefundPasswordDone;