// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RegistRefundPasswordDone_page-start__10jrM{\n    background: white;\n    display: flex;\n}\n.RegistRefundPasswordDone_root__ffMMu{width: 100%; text-align: center; margin: auto;}\n.RegistRefundPasswordDone_root__ffMMu > svg{margin-bottom: 30px;}\n.RegistRefundPasswordDone_root__ffMMu > .RegistRefundPasswordDone_title__3t3hn{font-weight: 700; font-size: 24px; line-height: 34px; padding-bottom: 18px;}\n.RegistRefundPasswordDone_root__ffMMu > .RegistRefundPasswordDone_sub-title__1mSv7{font-weight: 400; font-size: 18px; line-height: 28px; color: #757575;}\n.RegistRefundPasswordDone_bottom-container-box__2wnBn{padding-top: 61px;}", "",{"version":3,"sources":["webpack://src/pages/mypage/regist-refund-password/done/RegistRefundPasswordDone.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;AACjB;AACA,sCAAM,WAAW,EAAE,kBAAkB,EAAE,YAAY,CAAC;AACpD,4CAAY,mBAAmB,CAAC;AAChC,+EAAe,gBAAgB,EAAE,eAAe,EAAE,iBAAiB,EAAE,oBAAoB,CAAC;AAC1F,mFAAmB,gBAAgB,EAAE,eAAe,EAAE,iBAAiB,EAAE,cAAc,CAAC;AACxF,sDAAsB,iBAAiB,CAAC","sourcesContent":[".page-start{\n    background: white;\n    display: flex;\n}\n.root{width: 100%; text-align: center; margin: auto;}\n.root > svg{margin-bottom: 30px;}\n.root > .title{font-weight: 700; font-size: 24px; line-height: 34px; padding-bottom: 18px;}\n.root > .sub-title{font-weight: 400; font-size: 18px; line-height: 28px; color: #757575;}\n.bottom-container-box{padding-top: 61px;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page-start": "RegistRefundPasswordDone_page-start__10jrM",
	"root": "RegistRefundPasswordDone_root__ffMMu",
	"title": "RegistRefundPasswordDone_title__3t3hn",
	"sub-title": "RegistRefundPasswordDone_sub-title__1mSv7",
	"bottom-container-box": "RegistRefundPasswordDone_bottom-container-box__2wnBn"
};
export default ___CSS_LOADER_EXPORT___;
