import React, { useEffect } from 'react';
import style from './AccordianDisableList.module.css'
import Util from '../../helpers/Util';
const AccordianDisableList = ({item}) => {
    return (
        <div className={style["page-start"]}>
            <div className={style["page-contents"]}>
            <div className={style["location"]}>
                <p className={style["title"]}>
                    {item.transaction_action.name}
                </p>
                <p className={style["date"]}>{Util.formatDateTime(item.reg_at)}</p>
                </div>
                <div className={style["point"]}>
                <p><em className={item.transaction_type === 'W' ? style["deduction"]:style[""]}>{Util.formatNumber(item.point)} P</em> {item.transaction_type === 'W' ? '차감':'적립'}</p>
            </div>
            </div>
        </div>
    );
};
export default AccordianDisableList;