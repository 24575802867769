import React from 'react';
import { useLocation,useHistory} from 'react-router-dom';
import { useContext ,useEffect , useState} from 'react';
import PrivateService from '../../../../services/PrivateService';

const ChangePrivateInfoDoneViewModel =  {
    ChangePrivateInfoHook : () => {
        const location = useLocation()
        const [userPhoneList, setUserPhoneList] = useState();
        const [userMainPhoneInfo, setUserMainPhoneInfo] = useState();
        const [contents, setContents] = useState({
            title: "",
            description: ""
        });
        useEffect(()=>{
            if(location.query?.phone){
                const changedPhone = String(location.query.phone)
                changedPhone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
                switch(location.query.active){
                    case "change_main_phone": 
                    setContents({
                        title:"번호 변경 완료",
                        description:"전화번호가 "+ changedPhone +"(으)로 변경되었습니다. 지금부터 이전 번호 "+location.query.olddata+"번으로는 "+location.query.name+"님의 이름으로 사용이 불가합니다."                    
                    });
                    break;
                    case "regist_main_phone": 
                    setContents({
                        title:"번호 변경 완료",
                        description:"전화번호 "+ changedPhone +"(으)로 등록되었습니다. 지금부터"+changedPhone+"번으로 서비스 사용이 가능합니다.",
                    });
                    break;
                    case "add_sub_phone": 
                    setContents({
                        title:"번호 추가 완료",
                        description:"전화번호 "+ changedPhone +"가 추가되었습니다.",
                    });
                    setAddPhoneInfo();
                    break;
                    case "change_name": 
                    setContents({
                        title:"이름 변경 완료",
                        description:"이름이 " + location.query.name + "(으)로 변경되었습니다."
                    });
                    break;
                    case "change_etc_data": 
                    setContents({
                        title:"내 정보 변경 완료",
                        description:"내 정보 변경이 완료되었습니다."
                    });
                    default :
                    setContents({
                        title:"에러",
                        description:"에러상태입니다."
                    })
                }
            }else{
                switch(location.query.active){
                    case "change_etc_data": 
                    setContents({
                        title:"내 정보 변경 완료",
                        description:"내 정보 변경이 완료되었습니다."
                    });
                    break;
                    default :
                    setContents({
                        title:"에러",
                        description:"에러상태입니다."
                    })
                    break;
                }
            }
            
        },[])
        const setAddPhoneInfo = async() => {
            const result = await PrivateService.getPrivacy()
            const phoneList = []
            //첫번째에 메인 폰 넣고
            result.contact.forEach((element) => {
                if(element.represent){
                    element.phone_no.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
                    setUserMainPhoneInfo(element)
                }
            });
            //나머지 리스트 집어넣는다
            result.contact.forEach((element) => {
                if(!element.represent){
                    element.phone_no.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
                    phoneList.push(element)
                }   
            });
            setUserPhoneList(phoneList)
        }
        
        return {userMainPhoneInfo,userPhoneList,contents}
    },
    checkActivationName:async(query) => {
        var result ={}
        switch(query.active){
            case "change_name": result = ChangePrivateInfoDoneViewModel.activeChangeNameData(query); break;
            case "change_main_phone": result = ChangePrivateInfoDoneViewModel.activeChangeMainPhoneData(query); break;
            case "change_sub_phone": result = ChangePrivateInfoDoneViewModel.activeChangeSubPhoneData(query); break;
            case "add_sub_phone": result = await ChangePrivateInfoDoneViewModel.activeAddSubPhoneData(query); break;
            case "change_etc_data": result = await ChangePrivateInfoDoneViewModel.activeChangeEtcData(); break;
        }
        return result
    },
    activeChangeNameData:(data) =>{
        const contentsData = {
            title:"이름 변경 완료",
            description:"이름이 " + data.name + "(으)로 변경되었습니다.",
            phoneList:null
        }
        return contentsData
    },
    activeChangeMainPhoneData:(data) =>{
        const contentsData = {
            title:"번호 변경 완료",
            description:"전화번호가 "+ data.phone +"(으)로 변경되었습니다. 지금부터 이전 번호 "+data.olddata+"번으로는 김페트님의 이름으로 사용이 불가합니다.",
            phoneList:null
        }
        return contentsData
    },
    activeChangeSubPhoneData:(data) =>{
        const contentsData = {
            title:"번호 변경 완료",
            description:"전화번호가 "+ data.phone +"(으)로 변경되었습니다. 지금부터 이전 번호 "+data.olddata+"번으로는 김페트님의 이름으로 사용이 불가합니다.",
            phoneList:null
        }
        return contentsData
    },
    activeAddSubPhoneData:async(data) =>{
        const contentsData = {
            title:"번호 추가 완료",
            description:"전화번호 "+ data.phone +"가 추가되었습니다.",
            phoneList:await ChangePrivateInfoDoneViewModel.getUserPhoneData()
        }
        return contentsData
    },
    activeChangeEtcData:async() =>{
        const contentsData = {
            title:"내 정보 변경 완료",
            description:"내 정보 변경이 완료되었습니다.",
            phoneList:null
        }
        return contentsData
    },
    getUserPhoneData:async()=>{
        return ["01000000000","01000000001","01000000002"]
    }
};
export default ChangePrivateInfoDoneViewModel;