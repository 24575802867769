// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PrimaryButton_button-box__Rg6nq > button{\n    border: none;\n    padding: 19px;\n    font-size: 20px;\n    line-height: 22px;\n    border-radius: 12px;\n    width: 100%;\n    color: #FFFFFF;\n}\n.PrimaryButton_button-box__Rg6nq > button:disabled{ \n    border: none;\n    padding: 19px;\n    font-size: 18px;\n    line-height: 22px;\n    border-radius: 12px;\n    width: 100%;\n    color: #FFFFFF;\n}\n.PrimaryButton_primary__1j7W6 > button{\n    background-color: #3997FE;\n}\n.PrimaryButton_primary__1j7W6 > button:disabled{\n    background-color: #EAECF1;\n}\n.PrimaryButton_danger__J47U0 > button{\n    background-color: red;\n}\n.PrimaryButton_ghost__d4ujQ > button{\n    background-color: transparent;\n    color: #222222; \n    border: solid 1px;\n    border-color: #2222221A;\n}", "",{"version":3,"sources":["webpack://src/layout/modal/CustomiseButton/PrimaryButton/PrimaryButton.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,WAAW;IACX,cAAc;AAClB;AACA;IACI,YAAY;IACZ,aAAa;IACb,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,WAAW;IACX,cAAc;AAClB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,qBAAqB;AACzB;AACA;IACI,6BAA6B;IAC7B,cAAc;IACd,iBAAiB;IACjB,uBAAuB;AAC3B","sourcesContent":[".button-box > button{\n    border: none;\n    padding: 19px;\n    font-size: 20px;\n    line-height: 22px;\n    border-radius: 12px;\n    width: 100%;\n    color: #FFFFFF;\n}\n.button-box > button:disabled{ \n    border: none;\n    padding: 19px;\n    font-size: 18px;\n    line-height: 22px;\n    border-radius: 12px;\n    width: 100%;\n    color: #FFFFFF;\n}\n.primary > button{\n    background-color: #3997FE;\n}\n.primary > button:disabled{\n    background-color: #EAECF1;\n}\n.danger > button{\n    background-color: red;\n}\n.ghost > button{\n    background-color: transparent;\n    color: #222222; \n    border: solid 1px;\n    border-color: #2222221A;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button-box": "PrimaryButton_button-box__Rg6nq",
	"primary": "PrimaryButton_primary__1j7W6",
	"danger": "PrimaryButton_danger__J47U0",
	"ghost": "PrimaryButton_ghost__d4ujQ"
};
export default ___CSS_LOADER_EXPORT___;
