import NiceAuthService from '../services/NiceAuthService';

const REDIRECT_ORIGIN = window.location.origin;

const NiceAuth = {
  // 나이스 본인인증 시작
  start: async (redirect_url, param) => {
    //redirect_url : /login/niceAuthSuccess
    param.redirect_origin = REDIRECT_ORIGIN;
    param.redirect_url = redirect_url;

    // try {
       const result = await NiceAuthService.start(param);
       const data = result.data;

    //   if (data.isStart) {
    //     window.nativeApi.execute(window.NativeApiMethod.OPEN_WEB_VIEW, { url: REDIRECT_ORIGIN + `/login/niceAuth?sEncData=${data.sEncData}` }, '');
    //   }

    // } catch (error) {
    //   console.error('처리 중 오류가 발생했습니다.');
    // } finally {
    // }
    //테스트
    try{
      window.nativeApi.execute(window.NativeApiMethod.OPEN_WEB_VIEW, { url: REDIRECT_ORIGIN + `/login/niceAuth?sEncData=${data.sEncData}` }, '');
    }catch(err){
      console.error(err)
    }
  }
}



export default NiceAuth;