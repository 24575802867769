
import axios from 'axios';
import { CMS_API_ROOT, VERSION, BUILD_NO } from '../config';

axios.defaults.headers.common['X-Client-Version'] = VERSION;
axios.defaults.headers.common['X-Client-Build'] = BUILD_NO;
axios.defaults.headers.common['Cache-Control'] = "no-cache";
axios.defaults.headers.common['Access-Control-Allow-Header'] = "Accept, Content-Type";
axios.defaults.headers.common['Access-Control-Allow-Methods'] = "*";
axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";

const CmsApiService = {
  get: async (endpoint, data) => {
    try {
      const result = await axios.get(CMS_API_ROOT + endpoint, { params: data });
      return result.data;

    } catch (e) {
      const error_code = e.response.data.error_code;
      const message = e.response.data.message;
      if (e.response.status === 401) {
        if (error_code === -100) {
          alert(message);
          try{
            window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
          }catch(err){
            console.log(err)
          }
        }
        throw e;
      }
      throw e;
    }
  },
  post: async (endpoint, data) => {
    const config = { headers: { 'Content-Type': 'application/json' } };
    let body;
    switch (data.constructor) {
      case Object:
        body = JSON.stringify(data);
        break;
      case FormData:
        body = data;
        break;
      default:
        body = JSON.stringify(data);
        break;
    }

    const result = await axios.post(CMS_API_ROOT + endpoint, body, config);
    return result.data;
  }
};

export default CmsApiService;
