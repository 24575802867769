import { useEffect, useState } from "react";
import MemberService from "../../../services/MemberService";

const ViewModel = () => {
  // main_modal
  const [step, set_step] = useState(0);
  const [certification_data, set_certification_data] = useState({
    name: "",
    phone: "",
    telecom: undefined,
    rr_number: undefined,
    request_seq_code: undefined,
    response_seq_code: undefined,
  });
  // terms
  const [allchecked, setAllchecked] = useState(false);
  const [checkItem, setCheckItem] = useState({
    privacy: false,
    service: false,
    identification: false,
    telecom: false,
  });
  const checkAllChecked = (data) => {
    var checker = true;
    if (!data.privacy) {
      checker = false;
    }
    if (!data.service) {
      checker = false;
    }
    if (!data.telecom) {
      checker = false;
    }
    if (!data.identification) {
      checker = false;
    }
    return checker;
  };
  const onBrowserOpen = (action) => {
    let browserurl = "";
    switch (action) {
      case "1":
        browserurl = "https://cert.vno.co.kr/app/agree/agree_m_01.jsp";
        break;
      case "2":
        browserurl = "https://cert.vno.co.kr/app/agree/agree_m_02.jsp";
        break;
      case "4":
        browserurl = "https://cert.vno.co.kr/app/agree/agree_m_04.jsp";
        break;
      case "3":
        browserurl = "https://cert.vno.co.kr/app/agree/agree_m_03.jsp";
        break;
      default:
        browserurl = false;
        break;
    }
    if (browserurl) {
      try {
        window.nativeApi.execute(
          window.NativeApiMethod.OPEN_BROWSER,
          {
            url: browserurl,
          },
          ""
        );
      } catch (err) {
        console.error(err);
      }
    }
  };

  //validation
  const validation_check = async (bodyData) => {
    try {
      const request_body = {
        name:bodyData.name,
        rr_number: bodyData.idCardFront + bodyData.sexNumber,
        phone : bodyData.phoneHeader + bodyData.phone?.replace('-',''),
        telco : bodyData.telecom
      }
      const result = await MemberService.guardianRequestSms(request_body)
      const data = {...request_body}
      data.request_seq_code = result.request_seq_code
      data.response_seq_code = result.response_seq_code
      return data
    } catch (err) {
      throw err
    }
  };
  const on_birthday_replace = (idcardFront, sexNum) => {
    var divisionCode = Number(sexNum);
    var dateOfBirth = "";
    if (divisionCode === 1 || divisionCode === 2 || divisionCode === 5 || divisionCode === 6) {
      dateOfBirth = "19" + idcardFront;
    } else if (divisionCode === 3 || divisionCode === 4 || divisionCode === 7 || divisionCode === 8) {
      dateOfBirth = "20" + idcardFront;
    } else if (divisionCode === 9 || divisionCode === 0) {
      dateOfBirth = "18" + idcardFront;
    }
    dateOfBirth.replace(/^(\d{4})(\d{2})(\d{1})$/, `$1-$2-$3`);
    var result1 = [dateOfBirth.substring(0, 4), dateOfBirth.substring(4, 6), dateOfBirth.substring(6, 8)];
    dateOfBirth = result1[0] + "-" + result1[1] + "-" + result1[2];
    return dateOfBirth;
  };
  const on_check_local_string = (data) => {
    let trimString = data?.trim();
    const check_eng = /[a-zA-Z]/; // 영 문자
    const check_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글체크
    if (trimString) {
      if (check_kor.test(trimString) && check_eng.test(trimString)) {
        return false;
      } else {
        if (check_kor.test(trimString)) {
          trimString = trimString.replace(/ /gi, "");
        }
        return trimString;
      }
    } else {
      return false;
    }
  };
  const on_check_no_adult = (idCardFront,sexNum) => {
    var divisionCode = sexNum;
    var dateOfBirth = ""
    if(divisionCode == 1 || divisionCode == 2 || divisionCode == 5 || divisionCode == 6){
      dateOfBirth = "19"+idCardFront;
    }else if(divisionCode == 3 || divisionCode == 4 || divisionCode == 7 || divisionCode == 8){
      dateOfBirth = "20"+idCardFront;
    }else if(divisionCode == 9 || divisionCode == 0){
      dateOfBirth = "18"+idCardFront;
    }
    var today = new Date();
    var yyyy = today.getFullYear();
    var mm = today.getMonth() < 9 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1); // getMonth()
    var dd  = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
    return parseInt(String(yyyy)+String(mm)+String(dd)) - parseInt(dateOfBirth) - (190000 - 1) <= 0
  }
  //verification
  const on_resend_code = async(data) => {
    const request_body = {
      phone: data.phone,
      rr_number: data.rr_number,
      telco: data.telco,
      name: data.name,
    };
    try{
      const result = await MemberService.guardianRequestSms(request_body)
      const data = {...request_body}
      data.request_seq_code = result.request_seq_code
      data.response_seq_code = result.response_seq_code
      return data
    }catch(err){
      throw err
    }
  };
  const on_sms_verification = async(auth_code,data) => {
    const request_body = {
      name: data.name,
      rr_number: data.rr_number,
      request_seq_code: data.request_seq_code,
      response_seq_code: data.response_seq_code,
      auth_code: auth_code,
    };
    try {
      const result = await MemberService.setGuardian(request_body)
      return result
    } catch (err) {
      throw err;
    }
  };
  return {
    checkAllChecked,
    onBrowserOpen,
    allchecked,
    setAllchecked,
    checkItem,
    setCheckItem,
    validation_check,
    on_birthday_replace,
    on_check_local_string,
    certification_data,
    step,
    set_step,
    on_resend_code,
    on_sms_verification,
    on_check_no_adult
  };
};

export default ViewModel;
