import React from 'react';
import style from './LoadingSpinner.module.css'
const LoadingSpinner = () => {
    return (
        <div className={style["spinner"]}>
            <div className={style["bar1"]}></div>
            <div className={style["bar2"]}></div>
            <div className={style["bar3"]}></div>
            <div className={style["bar4"]}></div>
            <div className={style["bar5"]}></div>
            <div className={style["bar6"]}></div>
            <div className={style["bar7"]}></div>
            <div className={style["bar8"]}></div>
            <div className={style["bar9"]}></div>
            <div className={style["bar10"]}></div>
            <div className={style["bar11"]}></div>
            <div className={style["bar12"]}></div>
        </div>
    );
};

export default LoadingSpinner;