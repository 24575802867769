import { BrowserRouter as Router } from 'react-router-dom';
import { AppContextProvider } from './AppContext';
import AlertDialog from './layout/modal/CustomiseModal/AlertModal/AlertDialog';
import RootRouter from './router/RootRouter';
import './assets/css/style.css';
import ConfirmDialog from './layout/modal/CustomiseModal/ConfirmModal/ConfirmDialog';
const App = () => {
  return (
    <AppContextProvider>
      <ConfirmDialog>
        <AlertDialog>
          <div className="App wrap">
            <Router>
              <RootRouter />
            </Router>
          </div>
        </AlertDialog>
      </ConfirmDialog>
    </AppContextProvider>
  );
}

export default App;