import React from 'react';
import PointService from '../../../../services/PointService';
import {RefundCryptoAES} from '../../../../helpers/CryptoHelper'
const RefundPasswordVerificationViewModel = {
    onSetFirstValue : (firstPasswordArr) => {
        if(firstPasswordArr.length < 6){
            const newArr = [...firstPasswordArr]
            return ""
        }else if(firstPasswordArr.length === 6){
            const newArr = [...firstPasswordArr]
            var firstInputString = ""
            newArr.forEach(element => {
                firstInputString = firstInputString + element
            });
            return firstInputString
        }
    },
    onPasswordVerification : async(password) => {
        const body = {
            password : RefundCryptoAES.encrypt(password)
        }
        const result = await PointService.getRefundRequestToken(body)
        return result
    },
    onRefundRequest : async(body,access_token) => {
        const result = await PointService.postRefundRequest(body,access_token)
        return result
    }
};

export default RefundPasswordVerificationViewModel;