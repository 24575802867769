import React from 'react';
import style from './MyContentsBoxLayout.module.css'
const MyContentsBoxLayout = ({children}) => {
    return (
        <div className={style["page-start"]}>
            <div className={style["page-contents"]}>
                {children}
            </div>
        </div>
    );
};

export default MyContentsBoxLayout;