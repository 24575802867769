import CircularProgress from '@material-ui/core/CircularProgress';

const Progress = (data) => {
  return (
    <>
      {
        data.loading &&
        <>
          <div className="progress-wrap fixed">
            <CircularProgress />
          </div>
        </>
      }
    </>
  );
}

export default Progress;