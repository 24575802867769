import React, { useEffect } from "react";
import ViewModel from "./ViewModel";
import style from "../GuardianCertification.module.css";
import { TermCheckIcon } from "../../../assets/icons/SVG";
const Terms = (props) => {
  const { checkAllChecked, onBrowserOpen, allchecked, setAllchecked, checkItem, setCheckItem } = ViewModel();
  useEffect(() => {
    const result = checkAllChecked(checkItem);
    setAllchecked(result);
  }, [checkItem]);

  return (
    <div className={style["page-start"]}>
      <div className={style["root"]}>
        <div>
          <p className={style["title"]}>본인 인증 전 약관 동의</p>
          <p className={style["description"]}>보호자 확인을 위해 보호자 본인 인증을 시작합니다. 본인 인증 기관 약관에 동의해주세요.</p>
          <div className={style["item"]}>
            <div
              className={style["checkBtn"]}
              onClick={() => {
                const data = { ...checkItem };
                data.privacy = !allchecked;
                data.service = !allchecked;
                data.identification = !allchecked;
                data.telecom = !allchecked;
                setCheckItem(data);
              }}
            >
              <TermCheckIcon checked={allchecked}></TermCheckIcon>
              <p>전체동의</p>
            </div>
          </div>
          <div className={style["allcheck-line"]}>
            <hr></hr>
          </div>
          <div className={style["item"]}>
            <div
              className={style["checkBtn"]}
              onClick={() => {
                const data = { ...checkItem };
                data.privacy = !data.privacy;
                setCheckItem(data);
              }}
            >
              <TermCheckIcon checked={checkItem.privacy}></TermCheckIcon>
              <p onClick={() => {}}>[필수] 개인정보 이용 동의</p>
            </div>
            <a>
              <p
                onClick={() => {
                  onBrowserOpen("1");
                }}
              >
                보기
              </p>
            </a>
          </div>
          <div className={style["item"]}>
            <div
              className={style["checkBtn"]}
              onClick={() => {
                const data = { ...checkItem };
                data.identification = !data.identification;
                setCheckItem(data);
              }}
            >
              <TermCheckIcon checked={checkItem.identification}></TermCheckIcon>
              <p onClick={() => {}}>[필수] 고유식별 정보처리 동의</p>
            </div>
            <a>
              <p
                onClick={() => {
                  onBrowserOpen("2");
                }}
              >
                보기
              </p>
            </a>
          </div>
          <div className={style["item"]}>
            <div
              className={style["checkBtn"]}
              onClick={() => {
                const data = { ...checkItem };
                data.service = !data.service;
                setCheckItem(data);
              }}
            >
              <TermCheckIcon checked={checkItem.service}></TermCheckIcon>
              <p onClick={() => {}}>[필수] 서비스 이용약관 동의</p>
            </div>
            <a>
              <p
                onClick={() => {
                  onBrowserOpen("4");
                }}
              >
                보기
              </p>
            </a>
          </div>
          <div className={style["item"]}>
            <div
              className={style["checkBtn"]}
              onClick={() => {
                const data = { ...checkItem };
                data.telecom = !data.telecom;
                setCheckItem(data);
              }}
            >
              <TermCheckIcon checked={checkItem.telecom}></TermCheckIcon>
              <p onClick={() => {}}>[필수] 통신사 이용약관 동의</p>
            </div>
            <a>
              <p
                onClick={() => {
                  onBrowserOpen("3");
                }}
              >
                보기
              </p>
            </a>
          </div>
        </div>
        <button className={style["agree-btn"]} onClick={() => {props.onOkClick()}} disabled={!allchecked}>
          동의하고 인증 정보 입력하기
        </button>
      </div>
    </div>
  );
};

export default Terms;
