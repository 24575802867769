import React, { useState, useEffect, useRef } from 'react';
import introImg1 from '../../assets/img/bg_intro_superbin1.png';
import introImg2 from '../../assets/img/bg_intro_superbin2.gif';
import introImg3 from '../../assets/img/bg_intro_superbin3.gif';
import introImg5 from '../../assets/img/bg_intro_superbin5.gif';

const Superbin = () => {
  const [scroll, setScroll] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(window.innerHeight);
    window.addEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    onAnimate();
  }, [scroll]);


  const onScroll = (e) => {
    const scrollTop = ('scroll', e.srcElement.scrollingElement.scrollTop);
    setScroll({ scrollTop });
  };

  const onAnimate = () => {
    const offsetItem = (height / 1.75) + (height / 5)
    const introItem = document.querySelectorAll('.intro-item');
    const introItemTop1 = introItem1.current.offsetTop;
    const introItemTop2 = introItem2.current.offsetTop - offsetItem;
    const introItemTop3 = introItem3.current.offsetTop - offsetItem;
    const introItemTop4 = introItem4.current.offsetTop - offsetItem;
    const introItemTop5 = introItem5.current.offsetTop - offsetItem;
    const introItemTop6 = introItem6.current.offsetTop - offsetItem;
    const introItemTop7 = introItem7.current.offsetTop - offsetItem;

    const offsetDec = (height / 5);
    const introDec = document.querySelectorAll('.intro-dec');
    const introDecTop1 = introItemTop3 + introDec1.current.offsetTop;
    const introDecTop2 = introItemTop3 + introDec2.current.offsetTop - offsetDec;
    const introDecTop3 = introItemTop3 + introDec3.current.offsetTop - offsetDec;
    const introDecTop4 = introItemTop5 + introDec4.current.offsetTop - offsetDec;
    const introDecTop5 = introItemTop6 + introDec5.current.offsetTop - offsetDec;
    const introDecTop6 = introItemTop6 + introDec6.current.offsetTop - offsetDec;
    const introDecTop7 = introItemTop6 + introDec7.current.offsetTop - offsetDec;

    // 아래로 애니메이션 효과
    if (scroll.scrollTop >= 0 && scroll.scrollTop <= introItemTop2) {
      introItem1.current.classList.remove('active');
      introArrow.current.classList.add('active');
    } else if (scroll.scrollTop > introItemTop2) {
      introArrow.current.classList.remove('active');
    }

    if (scroll.scrollTop > introItemTop1 && scroll.scrollTop <= introItemTop2) {
      introItem1.current.classList.add('active');
    } else if (scroll.scrollTop > introItemTop2 && scroll.scrollTop <= introItemTop3) {
      introItem2.current.classList.add('active');
    } else if (scroll.scrollTop > introItemTop3 && scroll.scrollTop <= introItemTop4) {
      introItem3.current.classList.add('active');
    } else if (scroll.scrollTop > introItemTop4 && scroll.scrollTop <= introItemTop5) {
      introItem4.current.classList.add('active');
    } else if (scroll.scrollTop > introItemTop5 && scroll.scrollTop <= introItemTop6) {
      introItem5.current.classList.add('active');
    } else if (scroll.scrollTop > introItemTop6 && scroll.scrollTop <= introItemTop7) {
      introItem6.current.classList.add('active');
    } else if (scroll.scrollTop > introItemTop7) {
      introItem7.current.classList.add('active');
    }

    if (scroll.scrollTop > introDecTop1 && scroll.scrollTop <= introDecTop2) {
      introDec1.current.classList.add('active');
    } else if (scroll.scrollTop > introDecTop2 && scroll.scrollTop <= introDecTop3) {
      introDec2.current.classList.add('active');
    } else if (scroll.scrollTop > introDecTop3 && scroll.scrollTop <= introDecTop4) {
      introDec3.current.classList.add('active');
    } else if (scroll.scrollTop > introDecTop4 && scroll.scrollTop <= introDecTop5) {
      introDec4.current.classList.add('active');
    } else if (scroll.scrollTop > introDecTop5 && scroll.scrollTop <= introDecTop6) {
      introDec5.current.classList.add('active');
    } else if (scroll.scrollTop > introDecTop6 && scroll.scrollTop <= introDecTop7) {
      introDec6.current.classList.add('active');
    } else if (scroll.scrollTop > introDecTop7) {
      introDec7.current.classList.add('active');
    }
  }

  // 수퍼빈 홈페이지 버튼 핸들러
  const onSuperbinHomepage = () => {
    try{
      window.nativeApi.execute(window.NativeApiMethod.OPEN_BROWSER, {
        url: "http://www.superbin.co.kr/"
      }, "");
    }catch(err){
      console.error(err)
    }
  }

  // 인스타그램 앱 버튼 핸들러
  const onInstagram = () => {
    try{
      window.nativeApi.execute(window.NativeApiMethod.OPEN_BROWSER, {
        url: "https://www.instagram.com/superbin_official"
      }, "");
    }catch(err){
      console.error(err)
    }
  }

  // 페이스북 앱 버튼 핸들러
  const onFacebook = () => {
    try{
      window.nativeApi.execute(window.NativeApiMethod.OPEN_BROWSER, {
        url: "https://www.facebook.com/superbinofficial"
      }, "");
    }catch(err){
      console.error(err)
    }
  }

  const introArrow = useRef('');
  const introItem1 = useRef('');
  const introItem2 = useRef('');
  const introItem3 = useRef('');
  const introItem4 = useRef('');
  const introItem5 = useRef('');
  const introItem6 = useRef('');
  const introItem7 = useRef('');

  const introDec1 = useRef('');
  const introDec2 = useRef('');
  const introDec3 = useRef('');
  const introDec4 = useRef('');
  const introDec5 = useRef('');
  const introDec6 = useRef('');
  const introDec7 = useRef('');

  return (
    <>
      <div className="page-setting">
        <div className="intro-list">
          <div className="intro-item" data-intro="1" ref={introItem1}>
            <img className="intro-bg" src={introImg1} alt=""/>
            <div className="intro-title">
              <span className="a11y">쓰레기도 돈이다. 재활용도 놀이다.</span>
            </div>
            <div className="intro-img"></div>
            <div className="intro-arrow active" ref={introArrow}></div>
          </div>

          <div className="intro-item" data-intro="2" ref={introItem2}>
            <img className="intro-bg" src={introImg2} alt=""/>
            <div className="intro-title">
              <p>수퍼빈을<br />소개합니다.</p>
              <p className="sub">
                수퍼빈은 4차 산업기술과<br />
                디자인 기반 문화컨텐츠를 접목하여<br />
                재활용 문화를 다시 쓰고자 합니다.
              </p>
            </div>
          </div>

          <div className="intro-item" data-intro="3" ref={introItem3}>
            <div className="intro-title">
              <em>01</em>
              <p>수퍼빈의<br />순환자원 선별 &amp; 수집</p>
            </div>

            <dl className="intro-dec" data-intro-dec="1" ref={introDec1}>
              <dt>네프론</dt>
              <dd>
                인공지능과 빅데이터를 통해<br />
                폐기물을 인식, 분류하는 로봇입니다.
              </dd>
            </dl>

            <dl className="intro-dec" data-intro-dec="2" ref={introDec2}>
              <dt>수퍼빈모아</dt>
              <dd>
                사용자와 필드매니저가 직접<br />
                만나 순환자원을 거래합니다.
              </dd>
            </dl>

            <dl className="intro-dec" data-intro-dec="3" ref={introDec3}>
              <dt>필드서비스</dt>
              <dd>
                네프론과 사용자를 케어하고<br />
                수집된 폐기물을 오염 없이 운반합니다.
              </dd>
            </dl>
          </div>

          <div className="intro-item" data-intro="4" ref={introItem4}>
            <img className="intro-bg" src={introImg3} alt=""/>
            <div className="intro-title">
              <em>02</em>
              <p>수퍼빈의<br />현금 보상</p>
              <p className="sub">수퍼빈은 캔, 페트 1개당<br /> 10원으로 보상합니다.</p>
            </div>
          </div>

          <div className="intro-item" data-intro="5" ref={introItem5}>
            <div className="intro-title">
              <em>03</em>
              <p>수퍼빈의<br />소재화 공정</p>
            </div>

            <dl className="intro-dec" data-intro-dec="4" ref={introDec4}>
              <dt>아이엠팩토리</dt>
              <dd>
                순환자원을 다시 쓸 수 있는 재생소재로<br />
                가공하여 부가가치를 창출합니다.
              </dd>
            </dl>
          </div>

          <div className="intro-item" data-intro="6" ref={introItem6}>
            <div className="intro-title">
              <em>04</em>
              <p>수퍼빈의<br />문화 비즈니스</p>
              <p className="sub">디자인 기반 문화컨텐츠를 통해<br />새로운 재활용 문화를 정착시킵니다.</p>
            </div>

            <div className="intro-dec" data-intro-dec="5" ref={introDec5}>
              <p>수퍼큐브</p>
            </div>

            <div className="intro-dec" data-intro-dec="6" ref={introDec6}>
              <p>쓰레기카페</p>
            </div>

            <div className="intro-dec" data-intro-dec="7" ref={introDec7}>
              <p>플로깅 캠페인</p>
            </div>
          </div>

          <div className="intro-item" data-intro="7" ref={introItem7}>
            <img className="intro-bg" src={introImg5} alt=""/>
            <p className="intro-title">
              수퍼빈은 순환경제를 실현시키고<br />
              새로운 재활용 문화를 정착하여<br />
              깨끗한 지구를 만들어갑니다.<br />
              우리의 현재는 미래의 후손들에게<br />
              빌려온 것이기 때문입니다.
            </p>
            <div className="btn-wrap shortcut">
              <button type="button" className="btn btn-superbin" onClick={onSuperbinHomepage}>
                <span className="a11y">수퍼빈 홈페이지</span>
              </button>
              <button type="button" className="btn btn-instagram" onClick={onInstagram}>
                <span className="a11y">인스타그램 앱</span>
              </button>
              <button type="button" className="btn btn-facebook" onClick={onFacebook}>
                <span className="a11y">페이스북 앱</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Superbin;