import React from 'react';

const SessionHelper =  {
    getSession : () =>{
        return sessionStorage.getItem('accessValidation')
    },
    getCISession : () =>{
        return sessionStorage.getItem('accessCIValidation')
    },
    setSession : (value) => {
        try{
            sessionStorage.setItem('accessValidation',value)
        }catch(error){
            return error
        }
    },
    setCISession : (value) => {
        try{
            sessionStorage.setItem('accessCIValidation',value)
        }catch(error){
            return error
        }
    },
    delSession : () => {
        try{
            sessionStorage.removeItem('accessValidation')
        }
        catch(error){
            return error
        }
    }
};

export default SessionHelper;