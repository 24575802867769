import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import PrivateRoute from './route/PrivateRoute';
import CpointInfo from "../pages/cpoint/info/CpointInfo";

function CpointRouter() {
    return (
    <>
    <Switch>
      <PrivateRoute path="/cpoint/info" exact component={CpointInfo} />
      {/* <PrivateRoute path="/cpoint/info" exact component={Menu} /> */}
      <Redirect path="/cpoint/*" to="/mypage/info" />
    </Switch>
    </>
    );
}
export default CpointRouter;