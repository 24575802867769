import { useContext, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory
} from 'react-router-dom';

import Header from '../layout/Header';
import SubHeader from '../layout/SubHeader';
import Gnb from '../layout/Gnb';

import LoginRouter from './LoginRouter';
import HomeRouter from './HomeRouter';
import PointRouter from './PointRouter';
import LocationRouter from './LocationRouter';
import MypageRouter from './MypageRouter';
import SettingRouter from './SettingRouter';
import ErrorRouter from './ErrorRouter';
import RefundRouter from './RefundRouter'
import { AppContext } from '../AppContext';
import Nephron from "../pages/nephron/nephron";
import CpointRouter from "./CpointRouter";
import Menu from "../pages/menu/Menu";
import SelectAccount from "../pages/select-account/SelectAccount";
import FAQRouter from "./FAQRouter";
import NoticeRouter from "./NoticeRouter";
import TermsRouter from "./TermsRouter";
import CertificationRouter from "./CertificationRouter";
import NotificationRouter from "./NotificationRouter";
import GuardianRouter from "./GuardianRouter";

const RootRouter = () => {
  const location = useLocation();
  const history = useHistory();
  const { auth } = useContext(AppContext);
  const myHeader = new Headers();
  myHeader.get('Accept-Encoding');
  useEffect(()=>{
    if(auth.user?.under_fourteen === 'under' && !auth.user?.regist_guardian && !sessionStorage.getItem('cancel_guardian_certification')){
      history.replace('/guardian-certification')
    }
  },[auth])
  // window.nativeApi.execute(window.NativeApiMethod.CHANGE_URL_WEB_VIEW,{url:JSON.stringify(myHeader) + "1212"});
  return (
    <>
      <Route
        render={({ location, history }) => {
          const pathName = location.pathname;
          if (pathName.indexOf('/home') > -1) {
            return (
              pathName.indexOf('/home') > -1
                ? pathName.indexOf('/home/guide') > -1 ? null : <Header user={auth.user} />
                : <SubHeader location={location} history={history} />   
            )
          }else if(pathName.indexOf('/nephron') > -1){
            return (
              null   
            )
          }else if(pathName.indexOf('/mypage/exchange-password') > -1){
            return (
              null   
            )
          }else if(pathName.indexOf('/refund/password/regist') > -1){
            return (
              null   
            )
          }else if(pathName.indexOf('/mypage/change-refund-password') > -1){
            return (
              null   
            )
          }else if(pathName.indexOf('/mypage/regist-refund-password') > -1){
            return (
              null   
            )
          }else if(pathName.indexOf('/refund/password/verification') > -1){
            return (
              null   
            )
          }
          else{
            return(<SubHeader location={location} history={history} />  )
          }
          // return (
          //   pathName.indexOf('/home') > -1
          //     ? pathName.indexOf('/home/guide') > -1 ? null : <Header user={auth.user} />
          //     : <SubHeader location={location} history={history} />   
          // )
        }}
      ></Route>

      <Route
        render={({ location, history }) => {
          const pathName = location.pathname;
          if (pathName.indexOf('/home') > -1) {
            return pathName.indexOf('/home/guide') > -1 ? null : <Gnb />
          } else if (pathName.indexOf('/point') > -1) {
            if (pathName.indexOf('/point/exchange/list') > -1) {
              return <Gnb />
            } else if (pathName.indexOf('/point/') > -1) {
              return null
            }
            return <Gnb />
          } else if (pathName.indexOf('/location') > -1) {
            return <Gnb />
          } else if (pathName.indexOf('/menu') > -1) {
            return <Gnb />
          }
        }}
      ></Route>

      <div className={
        `content ${(location.pathname === '/location' || 
        location.pathname === '/setting/superbin' || 
        location.pathname === '/home/guide' || 
        location.pathname === '/menu' || 
        location.pathname === '/nephron' || 
        location.pathname === '/cpoint/info' || 
        location.pathname === '/mypage/change-refund-password' || 
        location.pathname === '/mypage/regist-refund-password' ||
        location.pathname === '/refund/password/verification' ||
        location.pathname === '/refund/password/regist') ? 'content-full' : ''}`}>
        <Route
          render={({ location }) => {
            return (
              <Switch location={location}>
                <Route path="/login" component={LoginRouter} />
                <Route path="/faq" exact component={FAQRouter} />
                <Route path="/notice" exact component={NoticeRouter} />
                <Route path="/notification" exact component={NotificationRouter} />
                <Route path="/terms" component={TermsRouter} />
                <Route path="/home" component={HomeRouter} />
                <Route path="/point" component={PointRouter} />
                <Route path="/refund" component={RefundRouter} />
                <Route path="/location" component={LocationRouter} />
                <Route path="/mypage" component={MypageRouter} />
                <Route path="/cpoint" component={CpointRouter} />
                <Route path="/menu" component={Menu} />
                <Route path="/setting" component={SettingRouter} />
                <Route path="/select-account" exact component={SelectAccount} />
                <Route path="/certification" component={CertificationRouter} />
                <Route path="/guardian-certification" component={GuardianRouter} />
                <Route path="/error" component={ErrorRouter} />
                <Route path="/nephron" exact component={Nephron} />
                <Redirect exact path="/" to="/home" />
                <Redirect path="/*" to="/error" />
              </Switch>
            );
          }}
        ></Route>
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default RootRouter;
