import React, { useContext, useEffect, useState } from 'react';
import { useHistory , useLocation } from 'react-router-dom';
import style from './GuardianCertificationFailed.module.css'
import AlertContext from '../../../layout/modal/CustomiseModal/AlertModal/AlertContext';
const GuardianCertificationFailed = () => {
    const location = useLocation()
    const [reason,set_reason] = useState('')
    const {alert : alertComp} = useContext(AlertContext)
    useEffect(()=>{
        cancel_reason()
    },[])
    const cancel_reason = async() => {
        if(!location.query?.reason){
            await alertComp('알림', '잘못된 경로로 접근하였습니다.',undefined,'확인');
            window.location.replace('/')
            return null
        }
        switch(location.query.reason){
            case "error" : set_reason("네트워크 오류로 보호자 인증을 실패했습니다. 다시 시도 부탁드립니다."); break;
            case "cancel" : set_reason("인증이 취소되어 보호자 인증을 실패했습니다. 다시 시도 부탁드립니다."); break;
            case "under_nineteen" : set_reason("실제 법정대리인(만 19세 이상)의 정보로 다시 시도 부탁드립니다."); break;
            default : set_reason("알수없는 이유로 인해 보호자 인증을 실패하였습니다. 다시 시도 부탁드립니다."); break;
        }
    }
    return (
        <div className={style['page-start']}>
            <div className={style['root']}>
                <div>
                    <p className={style['title']}>만 14세 미만 보호자 인증 실패</p>
                    <p className={style['description']}>{reason}</p>
                    <div className={style['allcheck-line']}><hr></hr></div>
                </div>
                <button className={style['next-btn']} onClick={()=>{
                    window.location.replace('/')
                }} disabled={false}>보호자 인증하기</button>
                <button className={style['cancel-btn']} onClick={()=>{
                    sessionStorage.setItem('cancel_guardian_certification','true')
                    window.location.replace('/')
                }} disabled={false}>다음에 하기</button>
            </div>
        </div>
    );
};

export default GuardianCertificationFailed;