
import axios, { AxiosError } from 'axios';
import { Cookies } from 'react-cookie'
import { API_BFF_APP_URL } from '../../config';

const HttpHelper = {
  setAccessTokenByRefreshToken: async (token) => {
    const cookies = new Cookies();
    cookies.set("token",token)
  },
  authrizationFailed: async () => {
    alert("로그아웃 되었습니다. 다시 로그인 해주세요");
    try{
      window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
    }catch(err){
      console.error(err)
    }
  },
  getTokenByrefreshToken: async () => {
    const cookies = new Cookies();
    const refresh_token = cookies.get("refresh_token")
    const refreshCall = axios.create({
        baseURL : API_BFF_APP_URL,
        headers : {
            Authorization: `Bearer ${refresh_token}`,
        }
    })
    try{
        const response = await refreshCall.post('/auth/refresh')
        return response.data
    }catch(error){
        return false
    }
  },
  get: async (url,param) => {
    const cookies = new Cookies();
    const token = cookies.get("token")
    const instance = axios.create({
        baseURL : API_BFF_APP_URL,
        headers : {
            Authorization: `Bearer ${token}`,
        }
    })
    try{
        const response = await instance.get(url , {params:param})
        return response.data
    }catch(error){
        if(error.response?.status === 401){
            const refreshData = await HttpHelper.getTokenByrefreshToken()
            if(refreshData.access_token){
                const Reinstance = axios.create({
                    baseURL : API_BFF_APP_URL,
                    headers : {
                        Authorization: `Bearer ${refreshData.access_token}`,
                    }
                })
                const Reresponse = await Reinstance.get(url , {params:param})
                return Reresponse.data
            }else{
                HttpHelper.authrizationFailed()
            }
        }else{
            throw error
        }
    }
  },
  post: async (url, data) => {
    const cookies = new Cookies();
    const token = cookies.get("token")
    const instance = axios.create({
        baseURL : API_BFF_APP_URL,
        headers : {
            Authorization: `Bearer ${token}`,
        }
    })
    try{
        const response = await instance.post(url,data)
        return response.data
    }catch(error){
        if(error.response?.status === 401){
            const refreshData = await HttpHelper.getTokenByrefreshToken()
            if(refreshData.access_token){
                const Reinstance = axios.create({
                    baseURL : API_BFF_APP_URL,
                    headers : {
                        Authorization: `Bearer ${refreshData.access_token}`,
                    }
                })
                HttpHelper.setAccessTokenByRefreshToken(refreshData.access_token)
                // cookies.set("token",refreshData.access_token)
                const Reresponse = await Reinstance.post(url,data)
                return Reresponse.data
            }else{
                HttpHelper.authrizationFailed()
            }
        }else{
            throw error
        }
    }
  },
  put: async (url, data) => {
    const cookies = new Cookies();
    const token = cookies.get("token")
    const instance = axios.create({
        baseURL : API_BFF_APP_URL,
        headers : {
            Authorization: `Bearer ${token}`,
        }
    })
    try{
        const response = await instance.put(url,data)
        return response.data
    }catch(error){
        if(error.response?.status === 401){
            const refreshData = await HttpHelper.getTokenByrefreshToken()
            if(refreshData.access_token){
                const Reinstance = axios.create({
                    baseURL : API_BFF_APP_URL,
                    headers : {
                        Authorization: `Bearer ${refreshData.access_token}`,
                    }
                })
                const Reresponse = await Reinstance.post(url,data)
                return Reresponse.data
            }else{
                HttpHelper.authrizationFailed()
            }
        }else{
            throw error
        }
    }
  },
  delete: async (url) => {
    const cookies = new Cookies();
    const token = cookies.get("token")
    const instance = axios.create({
        baseURL : API_BFF_APP_URL,
        headers : {
            Authorization: `Bearer ${token}`,
        }
    })
    try{
        const response = await instance.delete(url)
        return response.data
    }catch(error){
        if(error.response?.status === 401){
            const refreshData = await HttpHelper.getTokenByrefreshToken()
            if(refreshData.access_token){
                const Reinstance = axios.create({
                    baseURL : API_BFF_APP_URL,
                    headers : {
                        Authorization: `Bearer ${refreshData.access_token}`,
                    }
                })
                const Reresponse = await Reinstance.delete(url)
                return Reresponse.data
            }else{
                HttpHelper.authrizationFailed()
            }
        }else{
            throw error
        }
    }
  },
  deleteWithBody: async (url,body) => {
    const cookies = new Cookies();
    const token = cookies.get("token")
    const instance = axios.create({
        baseURL : API_BFF_APP_URL,
        data:body,
        headers : {
            Authorization: `Bearer ${token}`,
        }
    })
    try{
        const response = await instance.delete(url)
        return response.data
    }catch(error){
        if(error instanceof AxiosError){
            if(error.response?.status === 401){
                const data = await HttpHelper.getTokenByrefreshToken()
                if(data.access_token){
                    const Reinstance = axios.create({
                        baseURL : API_BFF_APP_URL,
                        data:body,
                        headers : {
                            Authorization: `Bearer ${data.access_token}`,
                        }
                    })
                    return await Reinstance.delete(url)
                }else{
                    HttpHelper.authrizationFailed()
                }
            }else{
                throw error
            }
        } else {
            throw error
        }
    }
  },
};

export default HttpHelper;
