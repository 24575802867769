import React, { useState, useEffect, useReducer, useRef, useCallback, useContext } from 'react';
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import PointBankModal from '../../../layout/modal/PointBankModal';
import Util from '../../../helpers/Util';
import PointService from '../../../services/PointService';
import ProgressFixed from '../../../layout/ProgressFixed';
import RefundRequestViewModel from './RefundRequestViewModel'
import MemberService from '../../../services/MemberService';
import AlertContext from '../../../layout/modal/CustomiseModal/AlertModal/AlertContext';
const RefundRequest = () => {
    const { auth , banks , getRefundAccount } = RefundRequestViewModel.RefundRequestHook()
    const [bankModal,setBankModal] = useState(false)
    const {alert : alertComp} = useContext(AlertContext)
    const history = useHistory();
    const initialUserState = {
      bank: { value: '', valid: true, helpText: '은행을 선택하세요.' },
      bankCode: { value: '' },
      bankAccount: { value: '', valid: true, helpText: '계좌번호를 입력하세요.' },
      amount: { value: '', valid: true, helpText: '환전 신청금액을 입력하세요.' }
    };
  
    const exchangeReducer = (state, action) => {
      const name = action.name;
      switch (action.type) {
        case 'INIT': {
          return initialUserState;
        }
        case 'CHANGE_VALUE': {
          return {
            ...state, [name]: { ...state[name], value: action.value }
          }
        }
        case 'CHANGE_VALID': {
          return {
            ...state, [name]: { ...state[name], valid: action.value }
          }
        }
        case 'CHANGE_HELPTEXT': {
          return {
            ...state, [name]: { ...state[name], helpText: action.value }
          }
        }
        default:
          break;
      }
    }
  
    const [exchange, dispatchExchange] = useReducer(exchangeReducer, initialUserState);
    const [pointAccount,setPointAccount] = useState()
    const [userData,setUserData] = useState()

    const [loading, setLoading] = useState(false);  // 로딩 여부
    const [points, setPoints] = useState(null);
    const [user, setUser] = useState(null);
    const [submitted, setSubmitted] = useState(false);
  
    // ref 설정
    const userName = useRef('');
    const userBank = useRef('');
    const userBankAccount = useRef('');
    const userBankAccountSaveYn = useRef('');
    const userAmount = useRef('');
    // userName 설정 변경
    const [requestUserName,setRequestUserName] = useState('')
    // 유저정보 조회
    const fetchUserInfo = async (user_data) => {
      try {
        const user_info = user_data;
        setUser(user_info);
        setPoints(user_info.point_account.balance);
        const bankInfo = await getRefundAccount()
        //account 정보 집어넣기
        setRequestUserName(bankInfo.account_name ? bankInfo.account_name : user_info.nickname)
        userName.current.querySelector('input').value = bankInfo.account_name ? bankInfo.account_name : user_info.nickname;
        dispatchExchange({ type: 'CHANGE_VALUE', name: 'bankAccount', value: bankInfo.account_no });
        dispatchExchange({ type: 'CHANGE_VALUE', name: 'bank', value: bankInfo.bank_name });
        dispatchExchange({ type: 'CHANGE_VALUE', name: 'bankCode', value: bankInfo.bank_code });
        dispatchExchange({ type: 'CHANGE_VALUE', name: 'bankIdx', value: bankInfo.bank_idx });
      } catch (error) {
        await alertComp('알림','네트워크 에러가 발생하여 사용자 계좌 확인이 불가합니다. 잠시후 다시 시도해주세요',undefined,'확인')
        history.replace('/')
      } finally {
        setLoading(false);
      }
    }
    const getPointAccount = async() => {
      try{
        const result = await PointService.getPointAccount();
        setPointAccount(result)
      }catch(err){
        alert('사용자 포인트 정보를 가져오는데 실패하였습니다.')
      }
    }
    const getUserInfo = async() => {
      try{
        const result = await MemberService.getUserData()
        setUserData(result)
        fetchUserInfo(result);  // 유저정보 조회
      }catch(err){
        alert('사용자 정보를 가져오는데 실패하였습니다.')
      }
    }
    // 은행 변경 핸들러
    const bankChange = (bank, code , idx) => {
      let bankName = bank;
      dispatchExchange({ type: 'CHANGE_VALUE', name: 'bank', value: bankName });
      dispatchExchange({ type: 'CHANGE_VALUE', name: 'bankCode', value: code });
      dispatchExchange({ type: 'CHANGE_VALUE', name: 'bankIdx', value: idx });
      formValidate();
    }
  
    // 다음 버튼 핸들러
    const onSubmit = () => {
      formValidate();
      inputFocus();
  
      if (submitted) {
        validExchange();  // 환전 신청 검증하기
      }
    }
  
    // 환전 신청 검증하기
    const validExchange = async () => {
      setLoading(true);
        const param = {
          'account_name' : requestUserName,
          'account' : exchange.bankAccount.value,
          'bank' : exchange.bank.value,
          'bank_idx': exchange.bankIdx.value,
          'bank_code' : exchange.bankCode.value,
          'points' : Number(exchange.amount.value.replace(/,/gi, "")),
          'account_save_yn' : (userBankAccountSaveYn.current.checked ? true : false),
          'active' : 'refund_request'
        }
      if (userData.refund_password) {// 환전 비밀번호 입력으로
        history.push({pathname:'/refund/password/verification',query:param},'/refund/password/verification');
      } else {// 환전 비밀번호 설정으로
        history.push({pathname:'/refund/password/regist',query:param},'/refund/password/regist')
      }
    }
  
    // 은행 검증
    const bankValidate = useCallback(() => {
      if (exchange.bank.value) {
        dispatchExchange({ type: 'CHANGE_VALID', name: 'bank', value: true });
        return true;
      } else {
        dispatchExchange({ type: 'CHANGE_VALID', name: 'bank', value: false });
        return false;
      }
    }, [exchange.bank.value]);
  
    // 계좌번호 검증
    const bankAccountValidate = useCallback(() => {
      const numregex = /^[0-9]+$/; 
      if (exchange.bankAccount.value) {
        dispatchExchange({ type: 'CHANGE_VALID', name: 'bankAccount', value: true });
        return true;
      }else {
        dispatchExchange({ type: 'CHANGE_VALID', name: 'bankAccount', value: false });
        return false;
      }
    }, [exchange.bankAccount.value]);
  
    // 환전 신청금액 검증
    const amountValidate = useCallback(() => {
      const amount = Number(exchange.amount.value.replace(/,/gi, ""));
      if (amount) {
        if (Number(amount) % 10 > 0) {
          dispatchExchange({ type: 'CHANGE_VALID', name: 'amount', value: false });
          dispatchExchange({ type: 'CHANGE_HELPTEXT', name: 'amount', value: '10원 단위 미만은 환전 불가합니다.' });
          return false;
        }
        if (pointAccount?.balance < Number(amount)) {
          dispatchExchange({ type: 'CHANGE_VALID', name: 'amount', value: false });
          dispatchExchange({ type: 'CHANGE_HELPTEXT', name: 'amount', value: '보유 포인트 이상 금액은 신청 불가능합니다.' });
          return false;
        }
        if (Number(amount) >= 2000) {
          if (Number(amount) > 1000000) {
            dispatchExchange({ type: 'CHANGE_VALID', name: 'amount', value: false });
            dispatchExchange({ type: 'CHANGE_HELPTEXT', name: 'amount', value: '최대 100만원 신청 가능합니다.' });
            return false;
          } else {
            dispatchExchange({ type: 'CHANGE_VALID', name: 'amount', value: true });
            return true;
          }
        } else {
          dispatchExchange({ type: 'CHANGE_VALID', name: 'amount', value: false });
          dispatchExchange({ type: 'CHANGE_HELPTEXT', name: 'amount', value: '2,000 포인트 이상부터 환전 가능.' });
          return false;
        }
      } else {
        dispatchExchange({ type: 'CHANGE_VALID', name: 'amount', value: false });
        dispatchExchange({ type: 'CHANGE_HELPTEXT', name: 'amount', value: '환전 신청금액을 입력하세요.' });
        return false;
      }
    }, [exchange.amount.value, points]);
  
    // 입력값 검증
    const formValidate = () => {
      let submit = true;
      if (!bankValidate()) submit = false;
      if (!bankAccountValidate()) submit = false;
      if (!amountValidate()) submit = false;
      if (!exchange.bank.value || !exchange.bankAccount.value || !exchange.amount.value) {
        submit = false;
      }
      if(exchange.bankAccount.value){
        const numregex = /^[0-9]+$/; 
        if(!numregex.test(exchange.bankAccount.value)){
          submit = false;
        }
      }
      if (submit) {
        setSubmitted(true);
      } else {
        setSubmitted(false);
      }
    }
  
    // 포커스
    const inputFocus = () => {
      if (!exchange.bank.valid) {
        userBank.current.querySelector('input').focus();
      } else if (!exchange.bankAccount.valid) {
        userBankAccount.current.querySelector('input').focus();
      } else if (!exchange.amount.valid) {
        userAmount.current.querySelector('input').focus();
      }
    }
  
    useEffect(() => {
      // fetchUserInfo();  // 유저정보 조회
      getUserInfo();
      getPointAccount()
    }, []);
    useEffect(() => {
      if (exchange.bank.value || exchange.bankAccount.value || exchange.amount.value) {
        let submit = true;
        if (exchange.bank.value) {  // 은행
          if (!bankValidate()) submit = false;
        }
        if (exchange.bankAccount.value) { // 계좌번호
          if (!bankAccountValidate()) submit = false;
        }
        if (exchange.amount.value) { // 금액
          if (!amountValidate()) submit = false;
        }
        if(exchange.bankAccount.value){
          const numregex = /^[0-9]+$/; 
          if(!numregex.test(exchange.bankAccount.value)){
            alertComp('알림','계좌번호는 숫자만 입력 가능합니다.',undefined,'확인')
            dispatchExchange({ type: 'CHANGE_VALUE', name: 'bankAccount', value: "" }); 
            submit = false;
          }
        }
        if (submit) {
          setSubmitted(true);
        } else {
          setSubmitted(false);
        }
  
        if (!exchange.bank.value || !exchange.bankAccount.value || !exchange.amount.value) {
          setSubmitted(false);
        }
      }
    }, [
      exchange.bank.value,
      exchange.bankAccount.value,
      exchange.amount.value,
      bankValidate,
      bankAccountValidate,
      amountValidate
    ]);
  
  
    return (
      <>
        <div className="page-point sub-title">
          <div className="page-header">
            <p className="sub">1회 최대 100만원 까지 신청 가능합니다.</p>
          </div>
  
          <form className="form-exchange">
            <fieldset>
              <legend className="a11y">환전 신청 정보 입력</legend>
  
              <TextField
                id="userName"
                name="userName"
                className="input-item"
                label="이름 (예금주명)"
                placeholder="이름을 입력하세요."
                InputLabelProps={{ shrink: true }}
                ref={userName}
                disabled
              />
  
              <div className={`input-item${exchange.bank.valid ? '' : ' error'}`} ref={userBank}>
                <span className="input-label">은행</span>
                <input
                  type="text"
                  id="userBank"
                  name="userBank"
                  className="input-control select"
                  placeholder="은행 선택"
                  onClick={()=>{setBankModal(true)}}
                  value={exchange.bank.value}
                  onFocus={formValidate}
                />
                <p className="error-text">{exchange.bank.valid ? '' : exchange.bank.helpText}</p>
                <PointBankModal banks={banks} setCloseModal={function (value) {
                setBankModal(false)
            }} bankModal={bankModal} bankChange={bankChange} />
              </div>
  
              <div className="input-item account">
                <TextField
                  type="text"
                  id="userBankAccount"
                  name="userBankAccount"
                  className="input-item"
                  label="계좌번호"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  placeholder="(‘-‘ 제외) 계좌번호를 입력하세요."
                  InputLabelProps={{ shrink: true }}
                  onChange={event => { 
                    const trimValue = event.target.value.trim()
                    dispatchExchange({ type: 'CHANGE_VALUE', name: 'bankAccount', value: trimValue }); 
                    formValidate(); 
                  }}
                  onFocus={formValidate}
                  error={exchange.bankAccount.valid ? false : true}
                  helperText={exchange.bankAccount.helpText}
                  ref={userBankAccount}
                  value={exchange.bankAccount.value}
                />
  
                <label htmlFor="userBankAccountSave" className="input-checkbox">
                  <input type="checkbox" name="userBankAccountSave" id="userBankAccountSave" ref={userBankAccountSaveYn} />
                  <span className="checkbox"></span>
                    계좌정보 저장
                </label>
              </div>
  
              <TextField
                type="text"
                id="userAmount"
                name="userAmount"
                className="input-item amount"
                label="환전 신청금액"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                autoComplete='off'
                InputLabelProps={{ shrink: true }}
                onChange={event => { dispatchExchange({ type: 'CHANGE_VALUE', name: 'amount', value: event.target.value }); formValidate(); }}
                onFocus={formValidate}
                error={exchange.amount.valid ? false : true}
                helperText={exchange.amount.helpText}
                ref={userAmount}
                value={exchange.amount.value}
              />
  
              <p className="total-point">현재 보유 포인트 : {Util.formatNumber(pointAccount?.balance)} P</p>
              <div className='description'>
                  <p className='title'>계좌번호 입력 시 주의사항</p>
                  <p className='contents'>1. 가입자 본인 명의가 아닌 타인의 계좌번호를 입력할 경우 입금이 불가능합니다.</p>
                  <p className='contents'>2. 입출금 가능한 계좌번호로 입력 바랍니다. (가상계좌 불가)</p>
              </div>
              <div className="btn-wrap">
                <button type="button" className={`btn${submitted ? " btn-active" : " btn-disabled"}`} onClick={onSubmit}>
                  다음
                  </button>
              </div>
            </fieldset>
          </form>
        </div>
        <ProgressFixed loading={loading} />
      </>
    );
};

export default RefundRequest;