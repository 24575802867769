import React, { useContext, useEffect, useState } from 'react';
import style from './WithDraw.module.css'
import {useLocation, useHistory } from "react-router-dom";
import DefaultInput from '../../../Modules/MyPageModules/Private/DefaultInput/DefaultInput';
import { AppContext } from '../../../AppContext';
import { TermCheckIcon } from '../../../assets/icons/SVG';
import AlertModal from '../../../Modules/Modal/AlertModal/AlertModal';
import WithDrawViewModel from './WithDrawViewModel';
import ComformModal from '../../../Modules/Modal/ConfirmModal/ComformModal';

const WithDraw = () => {
    const { auth } = useContext(AppContext);
    const history = useHistory()
    const location = useLocation()
    const {
        user,
        id,
        password,
        privateTerms,
        exchangeTerms,
        withdrawBtnEnable,
        setId,
        setPassword,
        setExchangeTerms,
        setPrivateTerms,
        requestWithdraw
    } = WithDrawViewModel.SetPasswordValidationHook()
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [location]);
    return (
        <div className={style["page-start"]}>
            <div className={style.root}>
                <div className={style.title}>회원탈퇴</div>
                <div className={style["info-box"]}>
                    <div>
                        <p>{auth.user.nickname}님의 보유 포인트</p>
                        <p>{auth.user.point_account.balance.toLocaleString()}P</p>
                    </div>
                    <hr></hr>
                    <div>
                        <p>탈퇴 주의사항</p>
                        <p>탈퇴 주의사항
                        회원 탈퇴 시 현재 이용 중인 추가 서비스(수퍼빈루키, 
                        수퍼빈모아 등)도 이용이 불가하며, 회사가 보유한 모든 
                        개인정보는 즉시 삭제됩니다. 
                        보유한 포인트(씨앗 포함) 또한 모두 소멸됩니다. 
                        <br/><br/>    
                        포인트는 탈퇴와 동시에 즉시 소멸되므로, 잔여 포인트 
                        환전 신청 및 환전 완료 후 탈퇴하시길 권장 드립니다. 
                        <br/><br/>     
                        단, &#60;수퍼빈 이용약관&#62;에 의거하여 2,000포인트 미만의 잔여 포인트는 환전이 불가합니다. 
                        <br/><br/>     
                        보유한 포인트는 10포인트 단위까지만 환전이 
                        가능합니다. (예: 2,895포인트 중 2,890포인트만 
                        신청 가능) 
                        <br/><br/>
                        회원 탈퇴 후에는 보유한 개인정보가 모두 삭제되어 
                        환전이 불가하며 회사는 탈퇴한 회원의 포인트를 복구할 수 없습니다. 
                        <br/><br/>
                        아래 사항을 전체 동의하지 않을 경우 탈퇴는 불가하며, 
                        궁금한 사항은 수퍼빈 카카오톡 채널 혹은 대표번호로 
                        문의 바랍니다.</p>
                    </div>
                </div>
                <div className={`${style['item']}`}>
                    <DefaultInput type='text' value={id} title={'아이디'} holder={'아이디를 입력해주세요'} 
                    onFocus={function () {
                    } }
                    onBlur={function () {
                    } }
                    InputData={function (value) {
                        setId(value)
                    } }></DefaultInput>
                </div>
                <div className={`${style['item']}`}>
                    <DefaultInput type='password' value={password} title={'비밀번호'} holder={'비밀번호를 입력해주세요'} 
                    InputData={function (value) {
                        setPassword(value)
                    } }
                    onFocus={function () {
                    } }
                    onBlur={function () {
                    } }
                    ></DefaultInput>
                </div>
                <div className={`${style['item']}`}>
                    <div className={style['term']} onClick={()=>{
                        setPrivateTerms(!privateTerms)
                    }}>
                        <TermCheckIcon checked={privateTerms}></TermCheckIcon>
                        <p>포인트 소멸 및 개인정보 삭제에 동의합니다.</p>
                    </div>
                    <div className={style['term']} onClick={()=>{
                        setExchangeTerms(!exchangeTerms)
                    }}>
                        <TermCheckIcon checked={exchangeTerms}></TermCheckIcon>
                        <p>탈퇴 후 포인트 환전 불가에 동의합니다.</p>
                    </div >
                </div>
                <div className={style["bottom-container-box"]}>
                    <button className={style["btn-normal"]} onClick={()=>{history.goBack()}}>취소하기</button>
                    <button disabled={!withdrawBtnEnable} className={style[withdrawBtnEnable? "btn-enable":"btn-disable"]} onClick={()=>{requestWithdraw()}}>탈퇴하기</button>
                </div>
            </div>
        </div>
    );
};

export default WithDraw;