import React from 'react';
import DaumPostCode from 'react-daum-postcode'
import style from './PostCode.module.css'
const PostCode = (props) => {
    const complete = (adressObject) =>{
        props.setPostCodeValue(adressObject.fullAddress,adressObject.zonecode)
    }
    const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = ''; 
    
    if (data.addressType === 'R') {
        if (data.bname !== '') {
        extraAddress += data.bname;
        }
        if (data.buildingName !== '') {
        extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
        }
        fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
    }
    data.fullAddress = fullAddress
    complete(data)
    }
    return (
        <>
        <div className={style["post-modal-background"]}></div>
            <div className={style["post-modal-wrap"]}>
                <div className={style["modal-post-code"]}>
                    <div className={style["close"]}>
                        <svg onClick={()=>{props.onClose()}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.40039 5.3999L18.6004 18.5999" stroke="#222222" strokeWidth="2"/>
                            <path d="M18.5996 5.3999L5.39961 18.5999" stroke="#222222" strokeWidth="2"/>
                        </svg>
                    </div>
                    <DaumPostCode className={style["postcode"]}
                    onComplete={handleComplete}
                    />
            </div>
        </div>
        </>
    );
};

export default PostCode;