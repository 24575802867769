import React from 'react';
import { useEffect } from 'react';
import backbtn from '../../assets/img/ico_nephron_back.png';
import Progress from '../../layout/Progress';
import favorite_ok from '../../assets/img/ico_nephron_favorite_ok.svg';
import favorite_not from '../../assets/img/ico_nephron_favorite_not.svg';
import nephron_ico_check from '../../assets/img/ico_nephron_check.png'
import nephron_ico_notice from '../../assets/img/ico_nephron_notice.png'
import nephron_ico_watch from '../../assets/img/ico_nephron_watch.png'
import nephron_ico_location from '../../assets/img/ico_nephron_location.png'
import { useState } from 'react';
import MyNephronService from '../../services/MyNephronService';
import NephronService from '../../services/NephronService';
import NephronDefailtImg from '../../assets/img/nephron_default.png';
import { useHistory } from 'react-router';
import { useContext } from 'react';
import { AppContext } from '../../AppContext';
import Util from '../../helpers/Util';

const Nephron = () => {
    const { auth } = useContext(AppContext);
    const [detail_modal,setnephron] = useState([])
    const [loading, setLoading] = useState(false);  // 로딩여부
    const history = useHistory();
    useEffect(() => {
        const obj = new URLSearchParams(window.location.search)
        getNephronData(obj.get('nephron'))
        setLoading(true)
    }, []);
    const getNephronData = async(device_idx) => {
        
        // 네프론 조회
        try{
            const result = await NephronService.getDevice(device_idx);
            getFavoriteData(result)
        }catch(error){console.error(error)}
    }
    const getFavoriteData = async(detail_data) => {
        let newArr = detail_data
        const result =  await MyNephronService.fetchItems();
        for(let i=0; i<result.data.list.length; i++){
            if(result?.data?.list[i]?.ds_device_id == newArr.info.id){
              newArr.favorite_sno = result.data.list[i].no_sno
              newArr.favorite_ok = "Y"
            }
        }
        const statusResult = await NephronService.getDeviceStatus(newArr.info.id)
        newArr.storage = statusResult.storage;
        newArr.status = statusResult.status.total_status;
        if(newArr.storage[0].type.startsWith("pp") || newArr.storage[0].type.startsWith("PP")){
          let newArrstorage = newArr.storage[0]
          newArr.storage = []
          newArr.storage[0] = newArrstorage
        }
        var closetime = newArr.info.close_time.substring(0,5)
        var opentime = newArr.info.open_time.substring(0,5)
        newArr.info.close_time = closetime
        newArr.info.open_time = opentime
        setLoading(false)
        setnephron(newArr)
    }
    const onFavorite = (item) => {
        if (item.favorite_ok === 'Y') {
          removeFavorite(item); // 즐겨찾기 해제
        } else {
          addFavorite(item);  // 즐겨찾기 등록
        }
    }
      // 즐겨찾기 등록
    const addFavorite = async (item) => {
        let formData = new FormData();
        formData.append('device_id', item.info.id);
        try {
        await MyNephronService.create(formData);
        getNephronData(item.info.id)
        } catch (error) {
        const error_code = error.response.data.error_code;
        const message = error.response.data.message;
        if (error_code < 0) {
            alert(message);
        }
        } finally {
        }
    }

    // 즐겨찾기 해제
    const removeFavorite = async (item) => {
        let formData = new FormData();
        formData.append('device_sno', item.favorite_sno);
        try {
        await MyNephronService.delete(formData);
        getNephronData(item.info.id)
        } catch (error) {
        const error_code = error.response.data.error_code;
        const message = error.response.data.message;
        if (error_code < 0) {
            alert(message);
        }
        } finally {
        }
    }
    // 투입 가능 자원 자세히 보기 핸들러
    const onInputEnableHomepage = () => {
        try{
            window.nativeApi.execute(window.NativeApiMethod.OPEN_BROWSER, {
                url: "http://www.superbin.co.kr/faq/6"
                }, "");
        }catch(err){
            console.error(err)
        }
    }
    // 투입 가능 자원 자세히 보기 핸들러
    const onInputNumHomepage = () => {
        try{
            window.nativeApi.execute(window.NativeApiMethod.OPEN_BROWSER, {
                url: "http://www.superbin.co.kr/faq/10"
            }, "");
        }catch(err){
            console.error(err)
        }
    }
    const onShareLink = async(item) => {
        const url = `http://www.superbin.co.kr/map?tab=search&markerId=${item.location.idx}&menu=result&detailId=${item.info.id}`
        if(navigator.clipboard != undefined){
          try {
            navigator.clipboard.writeText(url).then(() => {
              alert("링크를 복사했습니다.");
            });
          }catch(error){console.error(error)}
        }else{
          copyStringToClipboard(url);
        }
      }
    function copyStringToClipboard (string){
        try{
            function handler (event){
            event.clipboardData.setData('text/plain', string);
            event.preventDefault();
            document.removeEventListener('copy', handler, true);
            }
            document.addEventListener('copy', handler, true);
            document.execCommand('copy');
            alert('클립보드 복사 성공')
        }catch{
            alert('복사를 실패하였습니다. 다시 시도해주세요')
        }
    }
    return (
        <>
        <div className="twrap"> 
        <div className='page-nephron-background'> 
            <Progress loading={loading} />
            {!loading ? <>
                {!detail_modal?.location?.image_url? 
                <div className='page-nephron'>
                    <img src={NephronDefailtImg}></img>
                    <button className="top-left" onClick={()=>{history.goBack()}}><img src={backbtn}></img></button>
                    <button className='bottom-right' onClick={()=>{onFavorite(detail_modal)}}>
                    {detail_modal.favorite_ok == "Y"? <img src={favorite_ok}></img>:<img src={favorite_not}></img>}
                    </button>
                </div>
                :
                <div className='page-nephron'>
                    <img src={detail_modal?.location?.image_url}></img>
                    <button className="top-left" onClick={()=>{history.goBack()}}><img src={backbtn}></img></button>
                    <button className='bottom-right' onClick={()=>{onFavorite(detail_modal)}}>
                    {detail_modal.favorite_ok == "Y"? <img src={favorite_ok}></img>:<img src={favorite_not}></img>}
                    </button>
                </div>
                }
                <div className='page-nehron-con'>
                    <div className='nep_style'>
                        {detail_modal?.info?.output_destination_type === "SP0" || detail_modal?.info?.output_destination_type === "SP2" ? <p className='destination-SP0'>포인트 적립형</p> : <></>}
                        {detail_modal?.info?.output_destination_type === "SP1" ? <p className='destination-SP1'>포인트 기부형</p> : <></>}
                        {detail_modal?.info?.output_destination_type === "SS0" ? <p className='destination-SS0'>수퍼빈루키</p> : <></>}
                        {detail_modal?.info?.output_destination_type === "GP1" ? <p className='destination-GP1'>영수증 출력형</p> : <></>}
                    </div>
                    <p className='nep_title'>{detail_modal?.info?.name}</p>
                    <div className='nep_status'>
                        {detail_modal?.storage?.map((itemstr,index)=>{
                        return(
                        <>
                            {itemstr.type.startsWith("pe")||itemstr.type.startsWith("PE")? <span className="title">페트</span>:<></>}
                            {itemstr.type.startsWith("c")||itemstr.type.startsWith("C")? <span className="title">캔</span>:<></>}
                            {itemstr.type.startsWith("pp")||itemstr.type.startsWith("PP")? <span className="title">PP뚜껑</span>:<></>}
                            {Util.getNeflonConditionForDetail(detail_modal) == "possible" && <span className={itemstr.full? "true":"false"}>{itemstr.full? "용량 꽉참":"사용 가능"}</span>}
                            {Util.getNeflonConditionForDetail(detail_modal) == "exceed" && <span className="true">용량 꽉참</span>}
                            {Util.getNeflonConditionForDetail(detail_modal) == "impossible" && <span className="true">사용 불가</span>}
                            {index<detail_modal.storage.length-1? <span className="slice">|</span>:<></>}
                        </>
                        )
                        })}
                    </div>
                    <div className='nep_contents'>
                        <ul>
                            <li>
                                <div className='nep_ico'><img src={nephron_ico_check}/></div>
                                <div className='contents'>
                                    <div className='nep_label'>
                                        <p>투입 가능 자원</p>
                                        <p className='link' onClick={onInputEnableHomepage}>자세히보기</p> 
                                    </div>
                                    <div>
                                    {detail_modal?.collections?.map((item)=>{
                                    return(
                                        <>
                                        {item?.waste_type_code === "CAN0000" ? <p key={item?.idx}> {"모든 캔"}</p>:<></>}
                                        {item?.waste_type_code === "CAN0100" ? <p key={item?.idx}> {"음료 캔"}</p>:<></>}
                                        {item?.waste_type_code === "CAN1000" ? <p key={item?.idx}> {"알미늄 캔"}</p>:<></>}
                                        {item?.waste_type_code === "CAN2000" ? <p key={item?.idx}> {"철 캔"}</p>:<></>}
                                        {item?.waste_type_code === "PAP0000" ? <p key={item?.idx}> {"모든 종이"}</p>:<></>}
                                        {item?.waste_type_code === "PAP1000" ? <p key={item?.idx}> {"모든 골판지 박스."}</p>:<></>}
                                        {item?.waste_type_code === "PAP2000" ? <p key={item?.idx}> {"모든 종이팩"}</p>:<></>}
                                        {item?.waste_type_code === "PAP2100" ? <p key={item?.idx}> {"종이팩. 살균팩만."}</p>:<></>}
                                        {item?.waste_type_code === "PET0000" ? <p key={item?.idx}> {"모든 페트"}</p>:<></>}
                                        {item?.waste_type_code === "PET1000" ? <p key={item?.idx}> {"투명 페트"}</p>:<></>}
                                        {item?.waste_type_code === "PET1010" ? <p key={item?.idx}> {"투명 페트병 (라벨 제거 필수)"}</p>:<></>}
                                        {item?.waste_type_code === "PET1100" ? <p key={item?.idx}> {"뚜껑 제거 필수"}</p>:<></>}
                                        {item?.waste_type_code === "PET1110" ? <p key={item?.idx}> {"투명 페트병 (라벨과 뚜껑 제거 필수)"}</p>:<></>}
                                        {item?.waste_type_code === "PET2000" ? <p key={item?.idx}> {"유색 페트"}</p>:<></>}
                                        {item?.waste_type_code === "PET2010" ? <p key={item?.idx}> {"유색 페트. 라벨 제외."}</p>:<></>}
                                        {item?.waste_type_code === "PET2100" ? <p key={item?.idx}> {"유색 페트. 뚜껑 제외."}</p>:<></>}
                                        {item?.waste_type_code === "PET2110" ? <p key={item?.idx}> {"유색 페트. 뚜껑 제외. 라벨 제외."}</p>:<></>}
                                        {item?.waste_type_code === "PP0000" ? <p key={item?.idx}> {"모든 PP"}</p>:<></>}
                                        {item?.waste_type_code === "PP1000" ? <p key={item?.idx}> {"무색 PP"}</p>:<></>}
                                        {item?.waste_type_code === "PP1100" ? <p key={item?.idx}> {"무색 PP. 배달용기 뚜껑."}</p>:<></>}
                                        {item?.waste_type_code === "PP2000" ? <p key={item?.idx}> {"유색 PP"}</p>:<></>}
                                        {item?.waste_type_code === "PP2100" ? <p key={item?.idx}> {"유색 PP. 배달용기 뚜껑."}</p>:<></>}
                                        </>
                                    )
                                    })}
                                    </div>
                                </div>
                            </li>
                            {detail_modal?.input_policy ? 
                            <li>
                                <div className='nep_ico'><img src={nephron_ico_notice}/></div>
                                    <div className='contents'>
                                    <div className='nep_label'>
                                        <p>투입개수 제한</p>
                                        <p className='link' onClick={onInputNumHomepage}>자세히보기</p> 
                                    </div>
                                    <div>
                                        <p>1인 1일 {detail_modal?.input_policy?.max_amount_per_day}개 (1회 {detail_modal?.input_policy?.max_amount_per_input}개)</p>
                                    </div>
                                </div>
                            </li>
                            :<></>}
                            <li>
                                <div className='nep_ico'><img src={nephron_ico_watch}/></div>
                                <div className='contents'>
                                    <div className='nep_label'>
                                        <p>운영시간</p>
                                    </div>
                                    <div>
                                    <p>{detail_modal?.info?.open_time} ~ {detail_modal?.info?.close_time} <br/>기기고장, 적재함 용량이 꽉찬 경우 이용 불가</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className='nep_ico'><img src={nephron_ico_location}/></div>
                                <div className='contents'>
                                    <div className='nep_label'>
                                        <p>주소</p>
                                        <p className='link' onClick={()=>onShareLink(detail_modal)}>공유하기</p> 
                                    </div>
                                    <div>
                                        <p>{detail_modal?.location?.address} {detail_modal?.location?.address_detail}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='device-state'>
                    {detail_modal?.info?.output_destination_type === "SP2" ? <p>해당 네프론은 건물 내부에 설치된 것으로 주소지 건물 출입가능자만 이용 가능합니다. (외부인 사용 불가)</p> : <></>}
                    {detail_modal?.info?.output_destination_type === "SP1" ? <p>해당 네프론은 기부형 네프론으로 개인 포인트가 적립되지 않습니다.</p> : <></>}
                    {detail_modal?.info?.output_destination_type === "SS0" ? <p>해당 네프론은 학교 내부에 설치된 것으로 학교 학생들만 이용 가능합니다. (포인트 적립 불가, 외부인 사용 불가)</p> : <></>}
                    {detail_modal?.info?.output_destination_type === "GP1" ? <p>해당 네프론은 영수증형 네프론으로 개인 포인트가 적립되지 않습니다.</p> : <></>}
                </div>
                </> :<></>}
            </div>
        </div>
        </>
    );
};
export default Nephron;