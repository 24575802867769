import React from 'react';
import style from './GuardianCertificationSuccess.module.css'
const GuardianCertificationSuccess = () => {
    return (
        <div className={style['page-start']}>
            <div className={style['root']}>
                <div>
                    <p className={style['title']}>만 14세 미만 보호자 인증 완료</p>
                    <p className={style['description']}>보호자 인증이 완료되었습니다. [완료] 버튼을 누르면 서비스를 정상적으로 이용할 수 있습니다.</p>
                    <div className={style['allcheck-line']}><hr></hr></div>
                </div>
                <button className={style['next-btn']} onClick={()=>{
                    window.location.replace('/')
                }} disabled={false}>완료</button>
            </div>
        </div>
    );
};

export default GuardianCertificationSuccess;