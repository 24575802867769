import React, { useContext } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import AlertContext from '../../../../../layout/modal/CustomiseModal/AlertModal/AlertContext';
import PrivateService from '../../../../../services/PrivateService';
const RegistMainPhoneInsertCertinfoViewModel =  {
    InsertInfo : () => {
        const {alert : alertComp} = useContext(AlertContext)
        const history = useHistory()
        const location = useLocation()
        const postSms = async(bodyData) => {
            const body = {
                phone : bodyData.phoneHeader + bodyData.phone?.replace('-',''),
                name : bodyData.name,
                rr_number : bodyData.idCardFront + bodyData.sexNumber,
                telco : bodyData.telecom
            }
            try{
                const result = await PrivateService.sendAddPhoneSms(body)
                const query = {
                    active : location.query.active,
                    olddata: location.query.olddata ? location.query.olddata : '',
                    phone : bodyData.phoneHeader + bodyData.phone?.replace('-',''),
                    name : bodyData.name,
                    birthday : bodyData.birthday,
                    rr_number : bodyData.idCardFront + bodyData.sexNumber,
                    telco : bodyData.telecom,
                    request_seq_code : result.request_seq_code,
                    response_seq_code : result.response_seq_code,
                }
                history.replace({
                    pathname:'verificate-code',query:query
                },'verificate-code')
            }catch(err){
                console.error(err)
                if(err.response.status === 403){
                    await alertComp('본인 정보가 아닙니다', '로그인한 아이디의 개인 정보를 입력해주세요.',undefined,'확인')
                }
                else if(err.response.status === 409){
                    await alertComp('이미 계정에 등록된 번호입니다.', '계정의 번호를 확인해주세요',undefined,'확인')
                }else{
                    await alertComp('알림', '서버 에러가 발생하여 메인으로 돌아갑니다. 잠시 후 재 시도해주세요',undefined,'메인으로 돌아가기')
                    history.replace('/')
                }
            }
        }
        return {postSms}
    }
};

export default RegistMainPhoneInsertCertinfoViewModel;