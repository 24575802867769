import React, { useState, useEffect, useCallback } from 'react';
import UserService from '../../services/UserService';

const NotificationSetting = () => {
  const [isAll, setIsAll] = useState('');
  const [isFavorite, setIsFavorite] = useState('');
  const [isSleep, setIsSleep] = useState('');
  const [notificationData,setNotificationData] = useState({
    isAll : undefined,
    isFavorite : undefined,
    isSleep : undefined
  })
  // 앱 알림 설정 조회
  const fetchItem = async () => {
    try {
      const result = await UserService.fetchAppNotificationSetting();
      const data = result.data;
      setNotificationData({
        isAll : data.noti_all_yn ?? '0',
        isFavorite : data.noti_bm_yn ?? '0',
        isSleep : data.noti_sleep_yn ?? '0'
      })
    } catch (error) {
      const error_code = error.response.data.error_code;
      const message = error.response.data.message;
      if (error_code < 0) {
        alert(message);
      }
    } finally {
    }
  }


  useEffect(() => {
    fetchItem();  // 앱 알림 설정 조회
  }, []);

  const ChangeNotificationSetting = async(notificationByinput) => {
    const notiData = notificationByinput
    let formData = new FormData();
    formData.append('noti_all_yn', notiData.isAll);
    formData.append('noti_bm_yn', notiData.isFavorite);
    formData.append('noti_sleep_yn', notiData.isSleep);
    try {
      await UserService.updateAppNotificationSetting(formData);
      await fetchItem()
    } catch (error) {
      const error_code = error.response.data.error_code;
      const message = error.response.data.message;
      if (error_code < 0) {
        alert(message);
      }
    }
  }
  return (
    <>
      <div className="page-setting">
        <ul className="menu-list list-notification">
          <li className="switch-item">
            <label htmlFor="notificationAll">전체 알림</label>
            <label htmlFor="notificationAll" className="switch">
              <input
                type="checkbox"
                id="notificationAll"
                name="notificationAll"
                checked={notificationData?.isAll === "1"}
                onChange={(e) => {
                  const data = { ...notificationData };
                  data.isAll = !e.target.checked ? "0" : "1";
                  ChangeNotificationSetting(data);
                }}
              />
              <span className="slider"></span>
            </label>
          </li>
          <li className="switch-item">
            <label htmlFor="notificationOne">개별 알림</label>
            <label htmlFor="notificationOne" className="switch">
              <input
                type="checkbox"
                id="notificationOne"
                name="notificationOne"
                checked={notificationData?.isFavorite === "1"}
                onChange={(e) => {
                  const data = { ...notificationData };
                  data.isFavorite = !e.target.checked ? "0" : "1";
                  ChangeNotificationSetting(data);
                }}
              />
              <span className="slider"></span>
            </label>
            <p>‘즐겨찾는 네프론’으로 등록한 네프론 및 해당 지역에 공지사항이 있을 때 알림을 받습니다.</p>
          </li>
          <li className="switch-item">
            <label htmlFor="notificationDisturb">방해금지 설정</label>
            <label htmlFor="notificationDisturb" className="switch">
              <input
                type="checkbox"
                id="notificationDisturb"
                name="notificationDisturb"
                checked={notificationData?.isSleep === "1"}
                onChange={(e) => {
                  const data = { ...notificationData };
                  data.isSleep = !e.target.checked ? "0" : "1";
                  ChangeNotificationSetting(data);
                }}
              />
              <span className="slider"></span>
            </label>
            <p>오후 10시 ~ 오전 6시 사이에 알림을 받지 않습니다.</p>
          </li>
        </ul>
      </div>
    </>
  );
}

export default NotificationSetting;
