import React, { useContext, useEffect, useState } from 'react';
import {useLocation, useHistory } from "react-router-dom";
import ConfirmContext from '../../../layout/modal/CustomiseModal/ConfirmModal/ConfirmContext';
import AlertContext from '../../../layout/modal/CustomiseModal/AlertModal/AlertContext';
import { CryptoAES } from '../../../helpers/CryptoHelper';
import PrivateService from '../../../services/PrivateService';
import { AppContext } from '../../../AppContext';

const WithDrawViewModel =  {
    SetPasswordValidationHook : () => {
        const { auth } = useContext(AppContext);
        const {confirm: confirmComp }  = useContext(ConfirmContext)
        const {alert : alertComp} = useContext(AlertContext)
        const [id,setId] = useState("")
        const [password,setPassword] = useState("")
        const [privateTerms,setPrivateTerms] = useState(false)
        const [exchangeTerms,setExchangeTerms] = useState(false)
        const [withdrawBtnEnable,setWithdrawEnable] = useState(false)
        useEffect(()=>{ 
            checkValues()
        },[id,password,privateTerms,exchangeTerms])
        const checkValues = () => {
            var valueCheck = false
            if(id && password && privateTerms && exchangeTerms ){
                //모든 해지 동의 함
                valueCheck = true
            }else{
                //모든 해지 동의 안함.
                valueCheck = false
            }
            setWithdrawEnable(valueCheck)
        }
        //아이디 일치 체크
        const checkIdValue = async () => {
            var valueCheck = false
            if(id === auth.user.id){
                valueCheck = true
            }else{
                await alertComp('아이디/비밀번호가 일치하지 않습니다',undefined,undefined,'확인')
            }
            return valueCheck
        }
        //탈퇴 재확인
        const checkWithdrawOneMore = async() => {
            const result = await confirmComp('정말로 탈퇴하시겠습니까? 탈퇴 시  포인트 소멸 및 환전 신청 내역도 사라지며 포인트 현금 전환이 불가합니다.',undefined,undefined,'탈퇴','취소')
            return result
        }
        const requestWithdraw = async () => {
            let requestStatus = true
            let idCheckStatus = true
            idCheckStatus = await checkIdValue()
            if(idCheckStatus){
                requestStatus = await checkWithdrawOneMore()
            }
            if(requestStatus && idCheckStatus){deleteMember()}
        }
        //삭제
        const deleteMember = async() => {
            const body = {
                password:CryptoAES.encrypt(password),
                id : id,
                remove_privacy_and_point: true,
                expire_refund_service: true
            }
            try{
                const result = await PrivateService.withDrawAccount(body)
                await auth.logout()
            }catch(err){
                if(err.response.status === 409){
                    await alertComp('아이디/비밀번호가 일치하지 않습니다',undefined,undefined,'확인')
                }else{
                    await alertComp('관리자 에러입니다. 탈퇴를 재 시도해주세요',undefined,undefined,'확인')
                }
            }

        }
        return {auth,id,password,privateTerms,exchangeTerms,withdrawBtnEnable,setId,setPassword,setExchangeTerms,setPrivateTerms,requestWithdraw};
    },
};
export default WithDrawViewModel;