import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Login from '../pages/login/Login';
import Join from '../pages/login/Join';
import JoinTerms from '../pages/login/JoinTerms';
import NiceAuth from '../pages/login/NiceAuth';
import NiceAuthSuccess from '../pages/login/NiceAuthSuccess';
import NiceAuthParentsSuccess from '../pages/login/NiceAuthParentsSuccess';

import { AppContext } from '../AppContext';
import { Auth_FRONT } from "../config";

function LoginRouter() {
  const { auth } = useContext(AppContext);
  return (
    auth.user ?
      <Redirect to="/home" /> :
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/login/join" component={Join} />
        <Route path="/login/terms" component={() => {window.location.href = Auth_FRONT+"/join?redirect="+window.location.href;}} />
        <Route path="/login/niceAuth" component={NiceAuth} />
        <Route path="/login/niceAuthSuccess" component={NiceAuthSuccess} />
        <Route path="/login/niceAuthParentsSuccess" component={NiceAuthParentsSuccess} />
        <Redirect path="/login/*" to="/login" />
        
      </Switch>
  );
}

export default LoginRouter;
