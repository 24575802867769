import Service from './Index';
import AuthService from './AuthService';

const UserService = {
  //삭제 필요 
  getUserInfobyAuth:(param) => {
    return AuthService.post('/getUserinfo',param)
  },
  //삭제 필요
  fetchUserInfo: () => {
    return AuthService.get('/me', {});
  },
  updateUserInfo: (param) => {
    return AuthService.put('/me', param);
  },
  updatePassword: (param) => {
    return AuthService.post('/me/password', param);
  },
  validatePassword: (param) => {
    return AuthService.post('/me/password/validate', param);
  },
  updateExChangePassword: (param) => {
    return AuthService.put('/me/exchange-password', param);
  },
  fetchAppNotificationSetting: () => {
    return AuthService.get('/me/app-notification', {});
  },
  updateAppNotificationSetting: (param) => {
    // for (var pair of param.entries()) {
      // window.nativeApi.execute(window.NativeApiMethod.CHANGE_URL_WEB_VIEW,{url:pair[0]+ ', ' + pair[1] + "setAppNotificationSetting_param"});
    // }
    
    return AuthService.put('/me/app-notification', param);
  },
  withdrawal: (param) => {
    return AuthService.delete('/me', param);
  }
};

export default UserService;
