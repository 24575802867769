import React from 'react';
import { Switch, Redirect } from "react-router-dom";
import PrivateRoute from './route/PrivateRoute';
import Faq from '../pages/faq/Faq';

const FAQRouter = () => {
    return (
        <Switch>
          <PrivateRoute path="/faq" exact component={Faq} />
          <Redirect path="/faq/*" to="/faq" />
        </Switch>
      );
};

export default FAQRouter;