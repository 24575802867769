import Service from './Index';
import HttpHelper from '../helpers/HttpHelper/HttpHelper'
import RefundHttpHelper from '../helpers/HttpHelper/RefundHttpHelper'
const PointService = {
  fetchPoints: () => {
    return Service.get('/mypoint', {});
  },
  fetchItems: (type, param) => {
    return Service.get('/mypoint/' + type, param);
  },
  fetchItem: (device_id) => {
    return Service.get(`/mypoint/reserve/${device_id}`);
  },
  getPointList : (param) => {
    return HttpHelper.get('/point-transaction', param);
  },
  getPointDetail : (idx) => {
    return HttpHelper.get('/point-transaction/'+idx, null);
  },
  getRefundList : (param) => {
    return HttpHelper.get('/refund', param);
  },
  getRefundRequestToken: (data) => {
    return HttpHelper.post('/refund-auth', data);
  },
  postRefundRequest : (data,token) => {
    return RefundHttpHelper.post("/refund",data,token)
  },
  getTotalRefundRequest : () => {
    return HttpHelper.get("/refund-summary",null,null)
  },
  postRefundRequestWithPassword : (data) => {
    return HttpHelper.post("/refund-with-password",data,null)
  },
  getPointAccount : () => {
    return HttpHelper.get("/point-account",null)
  },
  validateExchangePassword: (param) => {
    return HttpHelper.post('/mypoint/exchange/validate', param);
  },
  applyExchange: (param) => {
    return Service.post('/mypoint/exchange/apply', param);
  },
  //채필규
  checkExanging: (param) => {
    return Service.get('/mypoint/exchanging',param)
  }
};
export default PointService;
