// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SexSelector_input-box__diPKa{width: 100%;}\n.SexSelector_input-box__diPKa > p{font-weight: 400; font-size: 14px; line-height: 17px; color: #9E9E9E;}\n.SexSelector_input-box__diPKa > div{display: flex; padding: 15px 0px;}\n.SexSelector_input-box__diPKa > div > button{flex: 1 1; margin-right: 8px; padding: 15px 0px;font-weight: 700; font-size: 16px; line-height: 19px;}\n.SexSelector_input-box__diPKa > div > button:last-of-type{margin:0px;}\n.SexSelector_selected__Jrjo-{background: rgba(57, 151, 254, .1); border: 1px solid #3997FE; border-radius: 6px; color: #3997FE; }\n.SexSelector_not-selected__3e7vi{background: transparent; border: 1px solid #BDBDBD; border-radius: 6px; color: #9E9E9E;}\n", "",{"version":3,"sources":["webpack://src/Modules/MyPageModules/Private/SexSelector/SexSelector.module.css"],"names":[],"mappings":"AAAA,8BAAW,WAAW,CAAC;AACvB,kCAAe,gBAAgB,EAAE,eAAe,EAAE,iBAAiB,EAAE,cAAc,CAAC;AACpF,oCAAiB,aAAa,EAAE,iBAAiB,CAAC;AAClD,6CAA0B,SAAO,EAAE,iBAAiB,EAAE,iBAAiB,CAAC,gBAAgB,EAAE,eAAe,EAAE,iBAAiB,CAAC;AAC7H,0DAAuC,UAAU,CAAC;AAClD,6BAAU,kCAAkC,EAAE,yBAAyB,EAAE,kBAAkB,EAAE,cAAc,EAAE;AAC7G,iCAAc,uBAAuB,EAAE,yBAAyB,EAAE,kBAAkB,EAAE,cAAc,CAAC","sourcesContent":[".input-box{width: 100%;}\n.input-box > p{font-weight: 400; font-size: 14px; line-height: 17px; color: #9E9E9E;}\n.input-box > div{display: flex; padding: 15px 0px;}\n.input-box > div > button{flex: 1; margin-right: 8px; padding: 15px 0px;font-weight: 700; font-size: 16px; line-height: 19px;}\n.input-box > div > button:last-of-type{margin:0px;}\n.selected{background: rgba(57, 151, 254, .1); border: 1px solid #3997FE; border-radius: 6px; color: #3997FE; }\n.not-selected{background: transparent; border: 1px solid #BDBDBD; border-radius: 6px; color: #9E9E9E;}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-box": "SexSelector_input-box__diPKa",
	"selected": "SexSelector_selected__Jrjo-",
	"not-selected": "SexSelector_not-selected__3e7vi"
};
export default ___CSS_LOADER_EXPORT___;
