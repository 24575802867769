import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import qs from 'query-string';

const NiceAuth = () => {
  const location = useLocation();
  const { search } = location;
  const params = qs.parse(search);	// 문자열의 쿼리스트링을 Object로 변환

  useEffect(() => {
    var niceAuthForm = document.createElement('form'); // set attribute (form) 
    niceAuthForm.name = 'form_chk';
    niceAuthForm.method = 'post';
    niceAuthForm.action = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb';

    var input1 = document.createElement('input');
    input1.setAttribute('type', 'hidden');
    input1.setAttribute('name', 'm');
    input1.setAttribute('value', 'checkplusService');
    input1.setAttribute('action', '');
    var input2 = document.createElement('input');
    input2.setAttribute('type', 'hidden');
    input2.setAttribute('name', 'EncodeData');
    input2.setAttribute('value', params.sEncData);

    niceAuthForm.appendChild(input1);
    niceAuthForm.appendChild(input2);

    document.body.appendChild(niceAuthForm);

    niceAuthForm.submit();
  }, [params.sEncData]);

  return (
    <>
      <div className="page-join">

      </div>
    </>
  )
}

export default NiceAuth;