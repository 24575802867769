import React from 'react';
import PrivateInsertInfoComponent from '../../common-privateInfo-component/insert-certInfo-component/PrivateInsertInfoComponent';
import ChangeMainPhoneInsertCertinfoViewModel from './ChangeMainPhoneInsertCertinfoViewModel';
const ChangeMainPhoneInsertCertinfo = () => {
    const {postSms} = ChangeMainPhoneInsertCertinfoViewModel.InsertInfo()
    return (
        <PrivateInsertInfoComponent 
            title={'번호 변경을 위한 본인인증'} 
            description={'기존에 사용하셨던 전화번호가 변경된 경우 변경된 전화번호와 본인 정보를 기재하여 인증해주세요.'} 
            setData={function (data) {
                postSms(data)
        } }></PrivateInsertInfoComponent>
    );
};

export default ChangeMainPhoneInsertCertinfo;