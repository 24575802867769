import React, { useEffect, useState , useContext } from 'react';
import RefundPasswordRegistViewModel from './RefundPasswordRegistViewModel';
import {useLocation , useHistory} from 'react-router-dom';
import style from './RefundPasswordRegist.module.css'
import RefundPasswordModule from '../../../../Modules/RefundModules/RefundPasswordModule/RefundPasswordModule';
import RefundPasswordVerificationModule from '../../../../Modules/RefundModules/RefundPasswordVerificationModule/RefundPasswordVerificationModule';
import AlertContext from '../../../../layout/modal/CustomiseModal/AlertModal/AlertContext';
const RefundPasswordRegist = () => {
    const location = useLocation()
    const history = useHistory()
    const {alert : alertComp} = useContext(AlertContext)
    const [firstPassword,setFirstPassword] = useState("")
    const [secondPassword,setSecondPassword] = useState("")
    const [secondPasswordData,setSecondPasswordData] = useState([])
    const [firstPasswordData,setFirstPasswordData] = useState([])
    const [passwordValidate,setPasswordValidate] = useState(true)
    useEffect(()=>{
        if(!location.query){history.replace('/')}
    },[])
    useEffect(()=>{
        setFirstPassword(RefundPasswordRegistViewModel.onSetFirstValue(firstPasswordData))
        setSecondPassword(RefundPasswordRegistViewModel.onSetSecondValue(secondPasswordData))
    },[secondPasswordData,firstPasswordData])
    useEffect(()=>{
        const secondArr = [...secondPasswordData]
        var secondArrString = ""
        secondArr.forEach(element => {
            secondArrString = secondArrString + element
        });
        if(secondArrString){
            if(firstPassword === secondArrString){
                setPasswordValidate(true)
            }else{
                setPasswordValidate(false)
            }
        }
    },[secondPasswordData])
    useEffect(()=>{
        refundActionOver()
    },[secondPassword])

    const refundActionOver = async() => {
        if(secondPassword && passwordValidate){
            try{
                await RefundPasswordRegistViewModel.onPostRefundRequest(location.query,secondPassword)
                history.replace({pathname:'/refund/request/done',query:location.query},'/refund/request/done')    
            }catch(err){
                await alertComp('알림','서버 오류로 인해 환전 신청에 실패하였습니다. 다시 시도해주세요',undefined,'확인')
                history.replace('/')
            }
        }
    }
    return (
    <div className={style["page-start"]}>
        {!firstPassword?
        <RefundPasswordModule 
            title={'환전비밀번호 설정'} 
            description={'포인트 환전 시 사용할 비밀번호를 설정해주세요. (숫자 6자리)'}
            passwordArr={firstPasswordData}
            onClick={function(data){
            if(data === "지우기"){setFirstPasswordData([])}
            else{
                const passwordArr = [...firstPasswordData]
                passwordArr.push(data)
                setFirstPasswordData(passwordArr)
            }}}>
        </RefundPasswordModule>
        :
        <RefundPasswordVerificationModule
        title={'환전비밀번호 설정'} 
        description={'앞서 입력한 숫자 6자리를 동일하게 재입력해주세요'}
        passwordArr={secondPasswordData}
        passwordValidate={passwordValidate}
        onClick={function(data){
        if(data === "지우기"){setSecondPasswordData([])}
        else{
            const passwordArr = [...secondPasswordData]
            passwordArr.push(data)
            setSecondPasswordData(passwordArr)
        }}}>
        </RefundPasswordVerificationModule>
        }
    </div>
    );
};

export default RefundPasswordRegist;