import CryptoJS from "crypto-js";

// const KEY = 'super_bin_aessecesljwpdoaedcfsyt';
const ACCOUNT_IV = 'u3#pu8En^y6wQn6#'
const ACCOUNT_KEY = 'm%ibr@3ur&dg$W4eM9#$z0b3m#kSi%3h'
const REFUND_KEY = 's8AElNJyZjVzJ0f8D8maN3lQXUaMvN91'
const REFUND_IV = 'e00pmPLoXWBlu6vl'
export const CryptoAES = {
  // AES 암호화
  encrypt: (text) => {
    return CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(ACCOUNT_KEY),
    {
      iv: CryptoJS.enc.Utf8.parse(ACCOUNT_IV),
      keySize: 256 / 32,
      mode:CryptoJS.mode.CBC,
      padding:CryptoJS.pad.Pkcs7
    }
    ).toString();
  }
}
export const RefundCryptoAES = {
  // AES 암호화
  encrypt: (text) => {
    return CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(REFUND_KEY),
    {
      iv: CryptoJS.enc.Utf8.parse(REFUND_IV),
      keySize: 256 / 32,
      mode:CryptoJS.mode.CBC,
      padding:CryptoJS.pad.Pkcs7
    }
    ).toString();
  }
}
