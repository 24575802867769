import React from 'react';
import axios, { AxiosError } from 'axios';
import { API_BFF_APP_URL } from '../../config';
const RefundHttpHelper =  {
    post: async (url,data,token) => {
        const instance = axios.create({
            baseURL : API_BFF_APP_URL,
            headers : {
                Authorization: `Bearer ${token}`
            }
        })
        try{
            const response = await instance.post(url,data)
            return response
        }catch(error){
            throw error
        }
    },
};

export default RefundHttpHelper;