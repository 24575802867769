/*global kakao*/
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import qs from 'query-string';
import Modal from '@material-ui/core/Modal';
import markerImg from '../../assets/img/ico_map_marker.png';
import NephronService from '../../services/NephronService';
import MyNephronService from '../../services/MyNephronService';
import noDataImage from '../../assets/img/img_list_no_data.svg'
import DataLoadingImage from '../../assets/img/img_list_loading.svg'
import nephron_ico_check from '../../assets/img/ico_nephron_check.png'
import nephron_ico_notice from '../../assets/img/ico_nephron_notice.png'
import nephron_ico_watch from '../../assets/img/ico_nephron_watch.png'
import nephron_ico_location from '../../assets/img/ico_nephron_location.png'
import NephronDefailtImg from '../../assets/img/nephron_default.png';
import backbtn from '../../assets/img/ico_nephron_back.png';
import favorite_ok from '../../assets/img/ico_nephron_favorite_ok.svg';
import favorite_not from '../../assets/img/ico_nephron_favorite_not.svg';
import Util from '../../helpers/Util';
import Progress from '../../layout/Progress';
import { useContext } from "react";
import { AppContext } from '../../AppContext';
import moment from 'moment';
import style from './Location.module.css'

let currentMarker = null;  // 현재위치 마커

const Location = () => {
  const { auth } = useContext(AppContext);
  const location = useLocation();
  const { search } = location;
  const params = qs.parse(decodeURI(search));	// 문자열의 쿼리스트링을 Object로 변환

  const DEFAULT_LATITUDE = 37.5502426;
  const DEFAULT_LONGITUDE = 127.0810676;
  const MAP_LEVEL = 6;

  const [isFirst, setIsFirst] = useState(true); // 최초여부
  const [isLocation, setIsLocation] = useState(false); // 위치 사용 여부
  const [position, setPosition] = useState(['', '']);

  const [kmap, setKmap] = useState(null);

  const [nephronData, setNephronData] = useState([]); // 네프론 데이터 리스트
  const [nephronDetail, setNephronDetail] = useState([]); // 네프론 상세 리스트
  const [list, setList] = useState([]); // 네프론 리스트
  const [listloading,setlistloading] = useState(false) // 네프론 리스트 로딩
  const [photo, setPhoto] = useState(''); // 네프론 사진 url
  const markerId = useRef('');
  const [loading, setLoading] = useState(false);  // 로딩여부

  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [detail_modal,set_detatil_modal] = useState({})
  const [searchWord, setSearchWord] = useState(params.ds_inst_place);
  const [isBlock,setisBlock] = useState(true);

  const [decorationOpen,setDecorationOpen] = useState(true)

  useEffect(() => {
    const unblock = history.block((loc,action)=>{
      if(action === 'POP' && !isBlock){
        onClosePhoto()
        return false
      }
    })
    return () => unblock()

  }, [isBlock]);
  // 카카오맵 초기화
  const initKakaoMap = useCallback(() => {
    kakao.maps.load(() => {
      let container = document.getElementById("map");
      let options = {
        center: new kakao.maps.LatLng(DEFAULT_LATITUDE, DEFAULT_LONGITUDE),
        level: MAP_LEVEL
      };

      setKmap(new window.kakao.maps.Map(container, options));
    });
  }, []);
  const overlay = {};
  // 마커 설정
  const setDeviceMarker = useCallback(() => { 
    if (kmap) {
      if (nephronData.length) {
        // const overlay = [];
        const createOverlay = (data) => { 
          let title = ""
          // PP , C , CP , P 구분 확인
          data = Util.setNeflonConditionForMapPin(data)
          let items = ""
          data.display.alldisplay.map((item,index)=>{
            items = items + '<div class="'+item.waste_state+'"><span class ="title">'+item.waste_name+'</span><span class ="cont">'+item.waste_state_string+'</span></div>'
            if(index  < data.display.alldisplay.length-1){
              if(data.display.alldisplay[index+1].waste_state == "avaliable" || data.display.alldisplay[index].waste_state == "avaliable"){
                items = items + '<div class="vline avaliable"></div>'
              }else{
                items = items + '<div class="vline unavaliable"></div>'
              }
            }
          })
          return `<button class="bubble" id="marker-${data.idx}" onclick="onNephron(this, '${data.idx}')">
                  ${items}
                  </button>`;
        }
        
        nephronData.forEach((item) => {
          overlay[item.ds_device_id] = 
            new kakao.maps.CustomOverlay({
              map: kmap,
              position: new kakao.maps.LatLng(item.latitude, item.longitude),
              content: createOverlay(item)
            });
        });
      }
    }
  }, [nephronData, kmap]);

  // 현재위치 조회
  const getCurrentPosition = useCallback(() => {
    window[window.NativeApiCallbackMethod.GET_GPS] = function (response) {
      if (response.latitude && response.longitude) {
        setIsLocation(true);
        setPosition([response.latitude, response.longitude]);
      } else {
        setIsLocation(false);
      }
    }
    try{
      window.nativeApi.execute(window.NativeApiMethod.GET_GPS, {}, window.NativeApiCallbackMethod.GET_GPS);
    }catch(err){
      console.error(err)
    }
  }, []);

  // 현위치 버튼 핸들러
  const onCurrentLocation = () => {
    getCurrentPosition();
  }
  const rebuildLocationStatus = (locationList , statusList) => {

    for(let l = 0; l<locationList.length; l++){
      locationList[l].status = []
      locationList[l].storage = []
      for(let i=0; i<locationList[l].display_devices.length; i++){
        statusList.forEach((item)=>{
          if(locationList[l].display_devices[i] === item.deviceId){
            if(item.storage[0].type.startsWith("pp") ||item.storage[0].type.startsWith("PP")){
              const itemstorageChange = item.storage[0]
              item.storage = []
              item.storage[0] = itemstorageChange
            }
            for(let k=0; k<item.storage.length; k++){
              item.storage[k].status = item.status.total_status
            }
            item.storage.forEach(function(item2){
              locationList[l].storage.push(item2)
            })
            locationList[l].status[i] = item.status.total_status
          }
        })
      }
    }
    return locationList;
  }
  const buildMarkerData = (deviceList) => {
    deviceList.forEach((items) => {
      items.display = []
      items.display.input_wastes = []
      if(items.input_wastes){
        items.input_wastes.forEach((wastes) => {
          if(wastes === "캔"){
            items.display.input_wastes.push("캔")
          }else if(wastes === "투명 페트"){
            items.display.input_wastes.push("페트")
          }else{
            items.display.input_wastes.push("PP")
          }
        })
      }
      var canCount = 0
      var canTrueCount = 0
      var canFalseCount = 0
      var canImpossbleCount = 0
      var petCount = 0
      var petTrueCount = 0
      var petFalseCount = 0
      var petImpossbleCount = 0
      var ppCount = 0
      var ppTrueCount = 0
      var ppFalseCount = 0
      var ppImpossbleCount = 0
      items.storage.forEach((checktype) => {
            if(checktype.type === "CAN" || checktype.type === "can"){
              canCount = canCount+1
              if(Util.getNeflonCondition(checktype) === "possible"){
                if(!checktype.full){
                  canTrueCount = canTrueCount +1
                }else{
                  canFalseCount = canFalseCount + 1
                }
              }else if(Util.getNeflonCondition(checktype) === "exceed"){
                canFalseCount = canFalseCount + 1
              }else{
                canImpossbleCount = canImpossbleCount + 1
              }
            }else if(checktype.type === "PET" || checktype.type === "pet"){
              petCount = petCount+1
              if(Util.getNeflonCondition(checktype) === "possible"){
                if(!checktype.full){
                  petTrueCount = petTrueCount +1
                }else{
                  petFalseCount = petFalseCount + 1
                }
              }else if(Util.getNeflonCondition(checktype) === "exceed"){
                petFalseCount = petFalseCount + 1
              }else{
                petImpossbleCount = petImpossbleCount + 1
              }
            }else{
              ppCount = ppCount+1
              if(Util.getNeflonCondition(checktype) === "possible"){
                if(!checktype.full){
                  ppTrueCount = ppTrueCount +1
                }else{
                  ppFalseCount = ppFalseCount + 1
                }
              }else if(Util.getNeflonCondition(checktype) === "exceed"){
                ppFalseCount = ppFalseCount + 1
              }else{
                ppImpossbleCount = ppImpossbleCount + 1
              }
            }
        })
        const displaycheck = Util.setNeflonConditionCheck(canCount,canTrueCount,canFalseCount,canImpossbleCount,petCount,petTrueCount,petFalseCount,petImpossbleCount,ppCount,ppTrueCount,ppFalseCount,ppImpossbleCount)
        items.display.can_state = displaycheck.can_state
        items.display.pet_state = displaycheck.pet_state
        items.display.pp_state = displaycheck.pp_state

        
    })
    return deviceList
  }
  const rebuildDeviceStatus = (deviceList, statusList) =>{
    deviceList.forEach((device) =>  {
      statusList.forEach((item) =>{
        if(device.ds_device_id === item.deviceId){
          device.status = item.status.total_status;
          device.storage = item.storage
        }
      })
    });

    return deviceList;
  }


  // 네프론 전체 조회
  const searchNephronData = async () => {
    var param = {
      filter: {
        'is_shown_user':true
      }
    }
    try {
      const result = await NephronService.getDeviceLocations(param)
      const data = result;
      const status = await NephronService.getAllDeviceStatus();
      data.list = rebuildLocationStatus(data.list , status)
      data.list = buildMarkerData(data.list)
      setNephronData(data.list);      
    }catch(error){
      console.error(error)
    }
  }

  // 네프론 검색어 조회
  const searchNephronDataWithSearchWord = useCallback(async () => {
    setlistloading(true);
    setList([]);  // 검색 리스트 초기화
    let wording = ""
    if(searchWord.indexOf("강원도") != -1){
      wording = searchWord.replace("강원도","강원특별자치도")
    }else if(searchWord.indexOf("전라북도") != -1){
      wording = searchWord.replace("전라북도","전북특별자치도")
    }else if(searchWord.indexOf("제주도") != -1){
      wording = searchWord.replace("제주도","제주특별자치도")
    }else{
      wording = searchWord
    }
    var param = {
      filter: {
        'is_shown_user':true,
        'name,address,region_1': 'like:' + wording
      }
    };
    try {
      searchNephronData();
      const result = await NephronService.getDeviceLocations(param);
      const data = result.list
      var searchList = {
        deviceId : [],
        latitude : [],
        longitude : [],
        location_idx : []
      }
      for(let i=0; i < data.length; i++){
        for(let l = 0; l<data[i].display_devices.length; l++){
          searchList.deviceId.push(data[i].display_devices[l])
          searchList.latitude.push(data[i].latitude)
          searchList.longitude.push(data[i].longitude)
        }
      }
      var deviceList = []
      for(let i=0; i < searchList.deviceId.length; i++){
        var statusDetail = await NephronService.getDeviceStatus(searchList.deviceId[i]); 
        deviceList[i] = statusDetail
        deviceList[i].status = statusDetail.status.total_status
        deviceList[i].latitude = searchList.latitude[i]
        deviceList[i].longitude = searchList.longitude[i]
        if(deviceList[i].storage[0].type.startsWith("pp") || deviceList[i].storage[0].type.startsWith("PP")){
          let newArrstorage = deviceList[i].storage[0]
          deviceList[i].storage = []
          deviceList[i].storage[0] = newArrstorage
        }
      }
      var finalList = [...deviceList]
      finalList.sort((a,b) => a.device_name < b.device_name ? -1 : 1)
      setList(finalList);
      setlistloading(false);
      updateMarkerData(nephronData);
    } catch (error) {
    } finally {
    }
  }, [searchWord]);

  // 네프론 상세 조회
  const searchNephronDetail = async (idx) => {
    setlistloading(true);
    try {
      updateDeviceStatus();
      const result = await NephronService.getDeviceLocationItem(idx);
      const data = result
      // data.list = await setFavoriteInfo(data.list);
      data.list = [{}]
      for(let i = 0; i < data.devices.length; i++){
        if(data.devices[i].is_shown_user == false)
          continue;
        var statusDetail = await NephronService.getDeviceStatus(data.devices[i].id);
        data.list[i] = {
          status : statusDetail.status.total_status,
          storage : statusDetail.storage,
          device_id : statusDetail.device_id,
          device_name : statusDetail.device_name,
          latitude : data.latitude,
          longitude : data.longitude,
          idx : data.idx
        }
        if(data.list[i].storage[0].type.startsWith("pp") || data.list[i].storage[0].type.startsWith("PP")){
          let newArrstorage = data.list[i].storage[0]
          data.list[i].storage = []
          data.list[i].storage[0] = newArrstorage
        }
      }
      var finalList = [...data.list]
      finalList.sort((a,b) => a.device_name < b.device_name ? -1 : 1)
      setNephronDetail(finalList);
      setlistloading(false);
      updateMarkerData(nephronData);
    } catch (error) {
    } finally {
    }
  }

  const updateDeviceStatus = async() =>{
    const status = await NephronService.getAllDeviceStatus();
    let data = rebuildDeviceStatus(nephronData, status)
    setNephronData(data);
  }

  const updateMarkerData = (deviceList) =>{
    deviceList.forEach((item) =>{
      var element = document.getElementById("marker-" + item.ds_device_id);
      if(element != null){
        element.classList.remove("breakdown");
        element.classList.remove("possible");
        element.classList.remove("full");
        element.classList.add(Util.getNeflonConditionForMap(item));
      }
    });
  }

  // 즐겨찾기 설정 버튼 핸들러
  const onFavorite = (item) => {
    if (item.favorite_ok === 'Y') {
      removeFavorite(item); // 즐겨찾기 해제
    } else {
      addFavorite(item);  // 즐겨찾기 등록
    }
  }

  // 즐겨찾기 등록
  const addFavorite = async (item) => {
    let formData = new FormData();
    formData.append('device_id', item.info.id);
    try {
      await MyNephronService.create(formData);
      NephromDetailForModal(item.info.id)

    } catch (error) {
      const error_code = error.response.data.error_code;
      const message = error.response.data.message;
      if (error_code < 0) {
        alert(message);
      }
    } finally {
    }
  }

  // 즐겨찾기 해제
  const removeFavorite = async (item) => {
    let formData = new FormData();
    formData.append('device_sno', item.favorite_sno);
    try {
      await MyNephronService.delete(formData);
      NephromDetailForModal(item.info.id)
    } catch (error) {
      const error_code = error.response.data.error_code;
      const message = error.response.data.message;
      if (error_code < 0) {
        alert(message);
      }
    } finally {
    }
  }


  // 모달 닫기 버튼 핸들러
  const onClosePhoto = () => {
    setOpen(false);
    setisBlock(true)
  };

  const onShareLink = async(item) => {
    const url = `http://www.superbin.co.kr/map?tab=search&markerId=${item.location.idx}&menu=result&detailId=${item.info.id}`
    if(navigator.clipboard != undefined){
      try {
        navigator.clipboard.writeText(url).then(() => {
          alert("링크를 복사했습니다.");
        });
      }catch(error){console.log(error)}
    }else{
      copyStringToClipboard(url);
    }
  }
  function copyStringToClipboard (string){
    try{
      function handler (event){
        event.clipboardData.setData('text/plain', string);
        event.preventDefault();
        document.removeEventListener('copy', handler, true);
      }
      document.addEventListener('copy', handler, true);
      document.execCommand('copy');
      alert('클립보드 복사 성공')
    }catch{
      alert('복사를 실패하였습니다. 다시 시도해주세요')
    }
	}
  // 검색한 아이템 클릭 핸들러
  const onClickSearchItem = (item, index) => {
    var moveLatLon = new kakao.maps.LatLng(item.latitude, item.longitude);
    kmap.setLevel(MAP_LEVEL);
    kmap.setCenter(moveLatLon);

    updateDeviceStatus();
    updateMarkerData(nephronData);

    markerId.current.value = item.idx;
    const btnNefron = document.querySelectorAll(".bubble");
    [].map.call(btnNefron, (item) => {
      item.classList.remove('active');
    });
    if(document.getElementById("marker-"+item.idx)){
      document.getElementById("marker-"+item.idx).classList.add("active");
    }
  }

  // 검색 버튼 핸들러
  const onSearch = useCallback(() => {
    setIsFirst(false);
    if (searchWord) {
      setNephronDetail([]); // 네프론 상세 리스트 초기화
      searchNephronDataWithSearchWord();  // 네프론 검색어 조회
    }
  }, [searchWord, searchNephronDataWithSearchWord]);
  const onDelete = ()=>{
    setSearchWord('')
  }

  useEffect(() => {
    // 네프론 전체 조회
    searchNephronData();
  }, []);

  useEffect(() => {
    if (nephronData) {
      // 마커 클릭 이벤트
      window['onNephron'] = function (btn, idx) {
        setIsFirst(false);
        setList([]);  // 검색 리스트 초기화
        markerId.current.value = idx;
        // 선택한 네프론에 대한 정보 표시
        const btnNefron = document.querySelectorAll(".bubble");
        [].map.call(btnNefron, (item) => {
          item.classList.remove('active');
        });
        btn.classList.add('active');
        searchNephronDetail(idx);  // 네프론 상세 조회
      }
      if(isFirst){
        setDeviceMarker();
      }
    }
  }, [nephronData, setDeviceMarker]);

  useEffect(() => {
    initKakaoMap();
  }, [initKakaoMap]);

  useEffect(() => {
    if (kmap) {
      getCurrentPosition();
    }
  }, [kmap, getCurrentPosition]);

  useEffect(() => {
    if (kmap) {
      // 카카오 지도 드래그 이벤트
      kakao.maps.event.addListener(kmap, 'dragend', function () {
        // 선택한 네프론에 대한 정보 표시
        const btnNefron = document.querySelectorAll(".bubble");
        [].map.call(btnNefron, (item) => {
          item.classList.remove('active');
        });
        if (document.getElementById("marker-" + markerId.current.value)) {
          document.getElementById("marker-" + markerId.current.value).classList.add('active');
        }
      });


      // 카카오 지도 줌 이벤트
      kakao.maps.event.addListener(kmap, 'zoom_changed', function () {
        // 선택한 네프론에 대한 정보 표시
        const btnNefron = document.querySelectorAll(".bubble");
        [].map.call(btnNefron, (item) => {
          item.classList.remove('active');
        });
        if (document.getElementById("marker-" + markerId.current.value)) {
          document.getElementById("marker-" + markerId.current.value).classList.add('active');
        }
      });
    }
  }, [kmap]);

  useEffect(() => {
    if (kmap && position[0] && position[1] && isLocation) {
      const imageSrc = markerImg;
      const imageSize = new kakao.maps.Size(54, 54);
      const imageOption = { offset: new kakao.maps.Point(23, 23) };
      const markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);
      const markerPosition = new kakao.maps.LatLng(position[0] || DEFAULT_LATITUDE, position[1] || DEFAULT_LONGITUDE);

      if (currentMarker) {
        currentMarker.setMap(null);
      }

      if (Number(position[0]) && Number(position[1])) {
        var moveLatLon = new kakao.maps.LatLng(position[0] || DEFAULT_LATITUDE, position[1] || DEFAULT_LONGITUDE);
        kmap.setCenter(moveLatLon);

        currentMarker = new kakao.maps.Marker({
          position: markerPosition,
          image: markerImage
        });

        currentMarker.setMap(kmap);
      }
    }
  }, [position, kmap, isLocation]);
  const DetailItem = (item_idx) => {
    setisBlock(false)
    set_detatil_modal({})
    setOpen(true)
    setLoading(true)
    NephromDetailForModal(item_idx)
  }
  const NephromDetailForModal = async(item_idx) =>{
    try{
      const result = await NephronService.getDevice(item_idx); 
      FavoriteForModal(result)
    }catch(error){

    }
  }
  const FavoriteForModal = async(detail_data) => {
      let newArr = detail_data
      const result =  await MyNephronService.fetchItems();
      for(let i=0; i<result.data.list.length; i++){
          if(result?.data?.list[i]?.ds_device_id == newArr.info.id){
            newArr.favorite_sno = result.data.list[i].no_sno
            newArr.favorite_ok = "Y"
          }
      }
      const statusResult = await NephronService.getDeviceStatus(newArr.info.id)
      newArr.storage = statusResult.storage;
      newArr.status = statusResult.status.total_status;
      if(newArr.storage[0].type.startsWith("pp") || newArr.storage[0].type.startsWith("PP")){
        let newArrstorage = newArr.storage[0]
        newArr.storage = []
        newArr.storage[0] = newArrstorage
      }
      var closetime = newArr.info.close_time.substring(0,5)
      var opentime = newArr.info.open_time.substring(0,5)
      newArr.info.close_time = closetime
      newArr.info.open_time = opentime
      // console.log(result)
      set_detatil_modal(newArr)
      setLoading(false)
  }
    // 투입 가능 자원 자세히 보기 핸들러
    const onInputEnableHomepage = () => {
      try{
        window.nativeApi.execute(window.NativeApiMethod.OPEN_BROWSER, {
          url: "http://www.superbin.co.kr/faq/6"
        }, "");
      }catch(err){
        console.error(err)
      }
    }
    // 투입 가능 자원 자세히 보기 핸들러
    const onInputNumHomepage = () => {
      try{
        window.nativeApi.execute(window.NativeApiMethod.OPEN_BROWSER, {
          url: "http://www.superbin.co.kr/faq/10"
        }, "");
      }catch(err){
        console.l(err)
      }
    }
  return (
    <>
      <div className="page-location">
        <button type="button" className="btn-location" onClick={onCurrentLocation}>
          <span className="a11y">현 위치 확인</span>
        </button>
        {decorationOpen ? <div className={style["description"]}>
          <div class={style["marquee"]}>
            <div>
              <span>각 지역별 통신 상황에 따라 네프론의 상태가 실제와 다를 수 있으니 참고로만 활용 해주시길 부탁드립니다.</span>
            </div>
          </div>
          <button onClick={()=>{setDecorationOpen(false)}}>닫기</button>            
        </div> :<></>}
        <div id="map" className="location-map" ></div>
        <div className="location-search">
          <div className="input-item">
            <input
              type="text"
              className="input-control"
              placeholder="기기명, 주소, 지역 등 검색"
              onChange={e => setSearchWord(e.target.value)}
              onKeyPress={e => { if (e.key === 'Enter') { onSearch(); e.target.blur(); } }}
              value={searchWord} />
            {searchWord? 
              <button type="button" className="btn-delete_loca" onClick={onDelete}>
              <span className="a11y">삭제</span>
            </button>:<></>  
            }
            <button type="button" className="btn-search" onClick={onSearch}>
              <span className="a11y">검색</span>
            </button>
            {searchWord? 
              <button type="button" className="btn-line">
              <span className="a11y">중심</span>
            </button>:<></>  
            }
          </div>

          <div className="search-list">
            {isFirst ?
              <div className="item-empty">
                <p>가까운 네프론을 찾아보세요.</p>
                <p className="sub">내 위치에 가까운 네프론을 검색할 수 있습니다.</p>
              </div>
              : <></>
            }
            {!isFirst && listloading?
              <div className="item-empty">
                <img src={DataLoadingImage}/>
                <p>검색중입니다.</p>
              </div>
              : <></>
            }
            {!isFirst && !list.length && !nephronDetail.length && !listloading?
              <div className="item-empty">
                <img src={noDataImage} alt="" />
                <p>검색결과가 없습니다.</p>
              </div>
              :
              list.map((item, index) => {
                return (
                  <div className="search-item item-select" key={index} onClick={() => onClickSearchItem(item, index)}>
                    <dl className="info">
                      <dt className="a11y">네프론 지점</dt>
                      <dd className="place">{item.device_name}</dd>
                      <dt className="a11y">주소</dt>
                      <dd className="status">
                      { item.storage && item.storage.map((itemstr,index)=>{
                        return(
                          <>
                            {itemstr.type.startsWith("pe")||itemstr.type.startsWith("PE")? <span>페트&nbsp;</span>:<></>}
                            {itemstr.type.startsWith("c")||itemstr.type.startsWith("C")? <span>캔&nbsp;</span>:<></>}
                            {itemstr.type.startsWith("pp")||itemstr.type.startsWith("PP")? <span>PP뚜껑&nbsp;</span>:<></>}
                            {Util.getNeflonCondition(item) == "possible" && <span className={itemstr.full? "true":"false"}>{itemstr.full? "용량 꽉참":"사용 가능"}</span>}
                            {Util.getNeflonCondition(item) == "exceed" && <span className="true">용량 꽉참</span>}
                            {Util.getNeflonCondition(item) == "impossible" && <span className="true">사용 불가</span>}
                            <span className="slice">{index<item.storage.length-1? "|":""}</span>&nbsp;
                          </>
                        )
                      })}
                      </dd>
                    </dl>
                    <span style={{zIndex:1}} onClick={()=>{DetailItem(item.device_id)}} className={`nefron-state ${Util.getNeflonCondition(item)}`}>상세</span>
                  </div>
                );
              })
            }
            {!listloading?
              nephronDetail.map((item, index) => {
                return (
                  <div className="search-item item-select" key={index} onClick={() => onClickSearchItem(item, index)}>
                    <dl className="info">
                      <dt className="a11y">네프론 지점</dt>
                      <dd className="place">{item.device_name}</dd>
                      <dt className="a11y">주소</dt>
                      <dd className="status">
                      {item.storage.map((itemstr,index)=>{
                        return(
                          <>
                            {itemstr.type.startsWith("pe")||itemstr.type.startsWith("PE")? <span className="title">페트&nbsp;</span>:<></>}
                            {itemstr.type.startsWith("c")||itemstr.type.startsWith("C")? <span className="title">캔&nbsp;</span>:<></>}
                            {itemstr.type.startsWith("pp")||itemstr.type.startsWith("PP")? <span className="title">PP뚜껑&nbsp;</span>:<></>}
                            {Util.getNeflonCondition(item) == "possible" && <span className={itemstr.full? "true":"false"}>{itemstr.full? "용량 꽉참":"사용 가능"}</span>}
                            {Util.getNeflonCondition(item) == "exceed" && <span className="true">용량 꽉참</span>}
                            {Util.getNeflonCondition(item) == "impossible" && <span className="true">사용 불가</span>}
                            {index<item.storage.length-1? <span className="slice">|</span>:<></>}
                          </>
                        )
                      })}
                      </dd>
                    </dl>
                    <span style={{zIndex:1}} onClick={()=>{DetailItem(item.device_id)}} className={`nefron-state ${Util.getNeflonCondition(item)}`}>상세</span>
                  </div>
                );
              })
              :<></>
            }
          </div>
        </div>
      </div>

      {/* 네프론 상세보기 */}
      <Modal
        className="modal modal-alert modal-photo"
        open={open}
        onClose={onClosePhoto}
        disableRestoreFocus
        disableBackdropClick
      >
        <div className="modal-all">
          <div className='modal-background'>
            <Progress loading={loading} />
            {!loading ? <>
              {!detail_modal?.location?.image_url? 
                <div className='page-nephron'>
                  <img src={NephronDefailtImg}></img>
                  <button className="top-left" onClick={()=>{onClosePhoto()}}><img src={backbtn}></img></button>
                  <button className='bottom-right' onClick={()=>{onFavorite(detail_modal)}}>
                  {detail_modal.favorite_ok === "Y"? <img src={favorite_ok}></img>:<img src={favorite_not}></img>}
                  </button>
                  </div>
                  :
                <div className='page-nephron'>
                  <img src={detail_modal?.location?.image_url}></img>
                  <button className="top-left" onClick={()=>{onClosePhoto()}}><img src={backbtn}></img></button>
                  <button className='bottom-right' onClick={()=>{onFavorite(detail_modal)}}>
                  {detail_modal.favorite_ok === "Y"? <img src={favorite_ok}></img>:<img src={favorite_not}></img>}
                  </button>
                </div>
              }
            <div className='nep_style'>
              {detail_modal?.info?.output_destination_type === "SP0" || detail_modal?.info?.output_destination_type === "SP2" ? <p className='destination-SP0'>포인트 적립형</p> : <></>}
              {detail_modal?.info?.output_destination_type === "SP1" ? <p className='destination-SP1'>포인트 기부형</p> : <></>}
              {detail_modal?.info?.output_destination_type === "SS0" ? <p className='destination-SS0'>수퍼빈루키</p> : <></>}
              {detail_modal?.info?.output_destination_type === "GP1" ? <p className='destination-GP1'>영수증 출력형</p> : <></>}
            </div>
            <p className='nep_title'>{detail_modal?.info?.name}</p>
            <div className='nep_status'>
                {detail_modal?.storage?.map((itemstr,index)=>{
                  return(
                    <>
                      {itemstr.type.startsWith("pe")||itemstr.type.startsWith("PE")? <span>페트&nbsp;</span>:<></>}
                      {itemstr.type.startsWith("c")||itemstr.type.startsWith("C")? <span>캔&nbsp;</span>:<></>}
                      {itemstr.type.startsWith("pp")||itemstr.type.startsWith("PP")? <span>PP뚜껑&nbsp;</span>:<></>}
                      {Util.getNeflonConditionForDetail(detail_modal) == "possible" && <span className={itemstr.full? "true":"false"}>{itemstr.full? "용량 꽉참":"사용 가능"}</span>}
                      {Util.getNeflonConditionForDetail(detail_modal) == "exceed" && <span className="true">용량 꽉참</span>}
                      {Util.getNeflonConditionForDetail(detail_modal) == "impossible" && <span className="true">사용 불가</span>}
                      {index<detail_modal.storage.length-1? <span className="slice">|</span>:<></>}
                    </>
                  )
                })}
            </div>
            <div className='nep_contents'>
                <ul>
                    <li>
                        <div className='nep_ico'><img src={nephron_ico_check}/></div>
                        <div className='contents'>
                            <div className='nep_label'>
                                <p>투입 가능 자원 </p>
                                <p className='link' onClick={onInputEnableHomepage}>자세히보기</p> 
                            </div>
                            <div>
                              {detail_modal?.collections?.map((item)=>{
                                return(
                                  <>
                                  {item?.waste_type_code === "CAN0000" ? <p key={item?.idx}> {"모든 캔"}</p>:<></>}
                                  {item?.waste_type_code === "CAN0100" ? <p key={item?.idx}> {"음료 캔"}</p>:<></>}
                                  {item?.waste_type_code === "CAN1000" ? <p key={item?.idx}> {"알미늄 캔"}</p>:<></>}
                                  {item?.waste_type_code === "CAN2000" ? <p key={item?.idx}> {"철 캔"}</p>:<></>}
                                  {item?.waste_type_code === "PAP0000" ? <p key={item?.idx}> {"모든 종이"}</p>:<></>}
                                  {item?.waste_type_code === "PAP1000" ? <p key={item?.idx}> {"모든 골판지 박스."}</p>:<></>}
                                  {item?.waste_type_code === "PAP2000" ? <p key={item?.idx}> {"모든 종이팩"}</p>:<></>}
                                  {item?.waste_type_code === "PAP2100" ? <p key={item?.idx}> {"종이팩. 살균팩만."}</p>:<></>}
                                  {item?.waste_type_code === "PET0000" ? <p key={item?.idx}> {"모든 페트"}</p>:<></>}
                                  {item?.waste_type_code === "PET1000" ? <p key={item?.idx}> {"투명 페트"}</p>:<></>}
                                  {item?.waste_type_code === "PET1010" ? <p key={item?.idx}> {"투명 페트병 (라벨 제거 필수)"}</p>:<></>}
                                  {item?.waste_type_code === "PET1100" ? <p key={item?.idx}> {"뚜껑 제거 필수"}</p>:<></>}
                                  {item?.waste_type_code === "PET1110" ? <p key={item?.idx}> {"투명 페트병 (라벨과 뚜껑 제거 필수)"}</p>:<></>}
                                  {item?.waste_type_code === "PET2000" ? <p key={item?.idx}> {"유색 페트"}</p>:<></>}
                                  {item?.waste_type_code === "PET2010" ? <p key={item?.idx}> {"유색 페트. 라벨 제외."}</p>:<></>}
                                  {item?.waste_type_code === "PET2100" ? <p key={item?.idx}> {"유색 페트. 뚜껑 제외."}</p>:<></>}
                                  {item?.waste_type_code === "PET2110" ? <p key={item?.idx}> {"유색 페트. 뚜껑 제외. 라벨 제외."}</p>:<></>}
                                  {item?.waste_type_code === "PP0000" ? <p key={item?.idx}> {"모든 PP"}</p>:<></>}
                                  {item?.waste_type_code === "PP1000" ? <p key={item?.idx}> {"무색 PP"}</p>:<></>}
                                  {item?.waste_type_code === "PP1100" ? <p key={item?.idx}> {"무색 PP. 배달용기 뚜껑."}</p>:<></>}
                                  {item?.waste_type_code === "PP2000" ? <p key={item?.idx}> {"유색 PP"}</p>:<></>}
                                  {item?.waste_type_code === "PP2100" ? <p key={item?.idx}> {"유색 PP. 배달용기 뚜껑."}</p>:<></>}
                                </>
                                )
                              })}
                            </div>
                        </div>
                    </li>
                    {detail_modal?.input_policy ? 
                    <li>
                      <div className='nep_ico'><img src={nephron_ico_notice}/></div>
                        <div className='contents'>
                          <div className='nep_label'>
                              <p>투입개수 제한</p>
                              <p className='link' onClick={onInputNumHomepage}>자세히보기</p> 
                          </div>
                          <div>
                              <p>1인 1일 {detail_modal?.input_policy?.max_amount_per_day}개 (1회 {detail_modal?.input_policy?.max_amount_per_input}개)</p>
                          </div>
                      </div>
                    </li>
                    :<></>}
                    <li>
                        <div className='nep_ico'><img src={nephron_ico_watch}/></div>
                        <div className='contents'>
                            <div className='nep_label'>
                                <p>운영시간</p>
                            </div>
                            <div>
                                <p>{detail_modal?.info?.open_time} ~ {detail_modal?.info?.close_time} <br/>기기고장, 적재함 용량이 꽉찬 경우 이용 불가</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='nep_ico'><img src={nephron_ico_location}/></div>
                        <div className='contents'>
                            <div className='nep_label'>
                                <p>주소</p>
                                <p className='link' onClick={()=>onShareLink(detail_modal)}>공유하기</p> 
                            </div>
                            <div>
                                <p>{detail_modal?.location?.address} {detail_modal?.location?.address_detail}</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className='device-state'>
                  {detail_modal?.info?.output_destination_type === "SP2" ? <p>해당 네프론은 건물 내부에 설치된 것으로 주소지 건물 출입가능자만 이용 가능합니다. (외부인 사용 불가)</p> : <></>}
                  {detail_modal?.info?.output_destination_type === "SP1" ? <p>해당 네프론은 기부형 네프론으로 개인 포인트가 적립되지 않습니다.</p> : <></>}
                  {detail_modal?.info?.output_destination_type === "SS0" ? <p>해당 네프론은 학교 내부에 설치된 것으로 학교 학생들만 이용 가능합니다. (포인트 적립 불가, 외부인 사용 불가)</p> : <></>}
                  {detail_modal?.info?.output_destination_type === "GP1" ? <p>해당 네프론은 영수증형 네프론으로 개인 포인트가 적립되지 않습니다.</p> : <></>}
            </div>
          </> : <></>}
          </div>                    
        </div>
      </Modal>
      <input type="hidden" ref={markerId} />
    </>
  )
}

export default Location;