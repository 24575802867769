import React from 'react';

const RefundPasswordVerificationModuleModelView =  {
    numberReload : () =>{
        const numbers = [0,1,2,3,4,5,6,7,8,9]
        const random = []
        while (numbers.length > 0) { // numbers 배열에서 하나씩 추출되면서 제거되므로 numbers가 1개 있을 때까지 반복문을 돌린다.
            const num = Math.floor(Math.random() * numbers.length); // 첫번째 랜덤 숫자 범위 : 1 <= x <= 45이므로, Math.random() * (45 - 1 + 1) = Math.random() * 45
            const newArr = numbers.splice(num, 1); // numbers 배열에서 해당 랜덤 넘버 추출
            const value = newArr[0]; // numbers는 배열이므로 요소 값만 추출해 변수에 담는다.
            if(numbers.length == 0){random.push("재배열")}
            random.push(value); // 추출한 값을 random 배열에 담는다.
            if(numbers.length == 0){random.push("지우기")}
        }
        return random
    }
};

export default RefundPasswordVerificationModuleModelView;