import React from 'react';
import { RefundCryptoAES } from '../../../../helpers/CryptoHelper';
import PointService from '../../../../services/PointService';
const RefundPasswordRegistViewModel = {
    onSetFirstValue : (firstPasswordArr) => {
        if(firstPasswordArr.length < 6){
            const newArr = [...firstPasswordArr]
            return ""
        }else if(firstPasswordArr.length === 6){
            const newArr = [...firstPasswordArr]
            var firstInputString = ""
            newArr.forEach(element => {
                firstInputString = firstInputString + element
            });
            return firstInputString
        }
    },
    onSetSecondValue : (secondPasswordArr) => {
        if(secondPasswordArr.length < 6){
            const newArr = [...secondPasswordArr]
            return ""
        }else if(secondPasswordArr.length === 6){
            const newArr = [...secondPasswordArr]
            var secondInputString = ""
            newArr.forEach(element => {
                secondInputString = secondInputString + element
            });
            return secondInputString
        }
    },
    onSetRefundPassword : async(query,password) => {
        const body = {
            password : RefundCryptoAES.encrypt(password)
        }
        const result = await PointService.postRefundPassword(body)
        return result
    },
    onPostRefundRequest : async(query,password) => {
        const body = {
            refund_password: RefundCryptoAES.encrypt(password),
            request_point: Number(query.points),
            bank_idx: Number(query.bank_idx),
            account_no: query.account,
            account_name: query.account_name,
            save_refund_account: query.account_save_yn === "true" ? true : false
        }
        try{
            const result = await PointService.postRefundRequestWithPassword(body)
            return result
        }catch(err){
            throw err
        }
    },
};

export default RefundPasswordRegistViewModel;