import React, { useEffect, useState , useContext } from 'react';
import RefundPasswordVerificationViewModel from './RefundPasswordVerificationViewModel';
import {useLocation , useHistory} from 'react-router-dom';
import style from './RefundPasswordVerification.module.css'
import RefundPasswordModule from '../../../../Modules/RefundModules/RefundPasswordModule/RefundPasswordModule';
import { RefundCryptoAES } from '../../../../helpers/CryptoHelper';
import AlertContext from '../../../../layout/modal/CustomiseModal/AlertModal/AlertContext';
const RefundPasswordVerification = () => {
    const history = useHistory()
    const location = useLocation()
    const {alert : alertComp} = useContext(AlertContext)
    const [password,setPassword] = useState("")
    const [passwordData,setPasswordData] = useState([])
    useEffect(()=>{
        setPassword(RefundPasswordVerificationViewModel.onSetFirstValue(passwordData))
    },[passwordData])
    useEffect(()=>{
        if(password){onPasswordVerification(password)}
    },[password])
    const onPasswordVerification = async(password) => {
        try{
            const result = await RefundPasswordVerificationViewModel.onPasswordVerification(password)
            onRefundRequest(result.access_token)
        }catch(err){
            console.error(err)
            if(err.response.status === 403){
                await alertComp("잘못된 비밀번호입니다","정확한 비밀번호를 다시 입력해주세요.",undefined,'확인')
                setPasswordData([])
                setPassword("")
            }else{
                await alertComp('알림','서버 오류로 인해 비밀번호 확인에 실패하였습니다. 잠시후 다시 시도해주세요',undefined,'메인으로 돌아가기')
                history.replace('/')
            }
        }
    }
    const onRefundRequest = async(access_token) => {
        const body = {
            refund_password: RefundCryptoAES.encrypt(password),
            request_point: Number(location.query.points),
            bank_idx: Number(location.query.bank_idx),
            account_no: location.query.account,
            account_name: location.query.account_name,
            save_refund_account: location.query.account_save_yn
        }
        try{
            await RefundPasswordVerificationViewModel.onRefundRequest(body,access_token)
            history.replace({pathname:'/refund/request/done',query:location.query},'/refund/request/done')
        }catch(err){
            if(err.response.status === 401){
                await alertComp("알림","환전 엑세스 권한이 만료되었습니다. 돌아가서 환전을 재 신청해주세요",undefined,'확인')
            }else{
                await alertComp('알림','서버 오류로 인해 환전 신청에 실패하였습니다. 잠시후 다시 시도해주세요',undefined,'메인으로 돌아가기')
                history.replace('/')
            }
        }
    }
    return (
    <div className={style["page-start"]}>
        <RefundPasswordModule 
            title={'환전비밀번호 입력'} 
            description={'환전 비밀번호를 입력해주세요.'}
            passwordArr={passwordData}
            onClick={function(data){
            if(data === "지우기"){setPasswordData([])}
            else{
                const passwordArr = [...passwordData]
                passwordArr.push(data)
                setPasswordData(passwordArr)
            }}}>
        </RefundPasswordModule>
    </div>
    );
};

export default RefundPasswordVerification;