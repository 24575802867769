import React, { useEffect } from 'react';
import DaumPostcode from 'react-daum-postcode';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PostCodeDialog(props) {
  const address = props.address;
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    // Dialog 오픈 후 네이티브 back키 기능 동작 시 Dialog 닫힘 기능
    if (open) {
      try{
        window.nativeApi.execute(window.NativeApiMethod.IS_LAYER, {}, window.NativeApiCallbackMethod.IS_LAYER)
        window[window.NativeApiCallbackMethod.IS_LAYER] = function (response) {
          postClose();
        }
      }catch(err){
        console.error(err)
      }
    }
  }, [open]);

  const postOpen = () => {
    setOpen(true);
  };

  const postClose = () => {
    setOpen(false);
  };

  // 다음 주소 검색
  const postCode = (postData) => {
    let postcode = postData.zonecode;
    let fullAddress = postData.address;
    let extraAddress = '';

    if (postData.addressType === 'R') {
      if (postData.bname !== '') {
        extraAddress += postData.bname;
      }
      if (postData.buildingName !== '') {
        extraAddress += (extraAddress !== '' ? `, ${postData.buildingName}` : postData.buildingName);
      }
      fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
    }
    const data = {
      fullAddress:fullAddress,
      postcode:postcode
    }
    props.addressChange(data);
    postClose();
  }

  return (
    <>
      <div className="input-append">
        <button type="button" className="btn btn-active" onClick={postOpen}>검색</button>
      </div>

      <Dialog className="dialog" fullScreen open={open} onClose={postClose} TransitionComponent={Transition}>
        <div className="dialog-header">
          <button type="button" className="btn-close" onClick={postClose}>
            <span className="a11y">닫기</span>
          </button>
        </div>
        <div className="dialog-content">
          <DaumPostcode
            onComplete={postCode}
            defaultQuery={address + ""}
            height="100%"
            autoClose
          />
        </div>
      </Dialog>
    </>
  );
}