import React, { useState } from 'react';
import style from './AlertModal.module.css'
import WidthFullBtn from '../../Buttons/widthFullBtn/WidthFullBtn';
const AlertModal = (props) => {
    /** 
    * @param
    * title
    * description
    * onClick
    */
    return (
        <div className={style["modal-wrap"]}>
            <div className={style["modal"]}>
                <div className={style['title']}><p>{props.title}</p></div>
                <div className={style['description']}><p>{props.description}</p></div>
                <div>
                    <WidthFullBtn disabled={false} title={props.btnTitle} onClick={()=>{props.onClick()}}></WidthFullBtn>
                </div>
            </div>
        </div>
    );
};

export default AlertModal;