import React, { useState, useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import NotificationService from '../../services/NotificationService';

const Notification = () => {
  const [loading, setLoading] = useState(false);  // 로딩여부
  const [list, setList] = useState([]); // 리스트 
  const [totalCount, setTotalCount] = useState(0);  // 전체건수
  const [currentPage, setCurrentPage] = useState(0);  // 현재페이지
  const limit = 10;
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    searchList(); // 리스트 조회
  }, [limit, offset]);

  // 리스트 조회
  const searchList = async () => {
    setLoading(true);
    var param = { limit, offset };
    try {
      const result = await NotificationService.fetchItems(param);
      const data = result.data;
      setCurrentPage(offset / limit);
      if (data.total_count > 50) {
        setTotalCount(50);
      } else {
        setTotalCount(data.total_count);
      }
      setList([
        ...list,
        ...data.list
      ]);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  // 더보기 버튼 핸들러
  const onMore = () => {
    setOffset((currentPage + 1) * limit);
  }

  return (
    <>
      <div className="page-mypage">
        <div className="accordion-list">
          {!list.length ?
            <div className="item-empty">
              <p>알림이 없습니다.</p>
            </div>
            :
            list.map((item, index) => {
              return (
                <Accordion key={index} className="accordion-item">
                  <AccordionSummary
                    aria-controls="accordion-content1"
                    id="accordion-header1"
                    className="accordion-header"
                  >
                    <p className="title">
                      {item.ds_content}
                    </p>
                    <p className="date">{item.dt_send_dt}</p>
                  </AccordionSummary>
                  <AccordionDetails className="accordion-content">
                    <p>{item.ds_content}</p>
                  </AccordionDetails>
                </Accordion>
              );
            })
          }
        </div>
        {
          limit + offset < totalCount ?
            <div className="btn-wrap">
              <button type="button" className="btn btn-active" onClick={onMore}>더보기</button>
            </div>
            :
            <></>
        }
      </div>
    </>
  );
}

export default Notification;