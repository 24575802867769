import React from "react";
import { Switch, Redirect } from "react-router-dom";

import PrivateRoute from './route/PrivateRoute';

import Point from '../pages/point/Point';
import SaveList from '../pages/point/SaveList';
function PointRouter() {
  return (
    <Switch>
      <PrivateRoute path="/point" exact component={Point} />
      <PrivateRoute path="/point/save/list" component={SaveList} />
      <Redirect path="/point/*" to="/point" />
    </Switch>
  );
}

export default PointRouter;
