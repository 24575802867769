import React from 'react';
import { Switch, Redirect } from "react-router-dom";
import PrivateRoute from './route/PrivateRoute';
import TermsAndPoilcies from '../pages/terms/TermsAndPoilcies';

const TermsRouter = () => {
    return (
        <Switch>
          <PrivateRoute path="/terms" exact component={TermsAndPoilcies} />
          <Redirect path="/terms/*" to="/terms" />
        </Switch>
      );
};

export default TermsRouter;